import { useState } from "react";
import { BsSearch } from "react-icons/bs";

import TextField from "@Components/Inputs/TextField";

import useDebounce from "@Hooks/useDebounce";
import useGlobalSearch from "@Hooks/useGlobalSearch";

import { Deal } from "@Types/deal";
import { User } from "@Types/users";
import { Applicant } from "@Types/applicants";

import ResultsTable from "./ResultsTable";
import { ResultsTableProps, SearchState } from "./types";

import styles from "./Search.module.scss";

const transformSearchToSearchTerm = (search: string) => {
  if (!search) return {};
  return search?.length > 3 ? { search: { term: search } } : {};
};
const transformRequestDataToResultsTableProps = (requestData: {
  data: {
    applicants: Applicant[] | undefined;
    deals: Deal[] | undefined;
    users: User[] | undefined;
  };
  isLoading: {
    applicantsAreLoading: boolean;
    dealsAreLoading: boolean;
    usersAreLoading: boolean;
  };
  error: {
    applicantsError: unknown;
    dealsError: unknown;
    usersError: unknown;
  };
}): ResultsTableProps => {
  return {
    applicants: {
      data: requestData.data.applicants,
      loading: requestData.isLoading.applicantsAreLoading,
      error: requestData.error.applicantsError,
    },
    deals: {
      data: requestData.data.deals,
      loading: requestData.isLoading.dealsAreLoading,
      error: requestData.error.dealsError,
    },
    users: {
      data: requestData.data.users,
      loading: requestData.isLoading.usersAreLoading,
      error: requestData.error.usersError,
    },
  };
};
const Search = () => {
  const [state, setState] = useState<SearchState>({ value: "", focus: false });
  const debouncedValue = useDebounce<SearchState>(state, 1000);
  const requestResult = useGlobalSearch(
    transformSearchToSearchTerm(debouncedValue.value)
  );
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <BsSearch size={22} className={styles.icon} />
      </div>
      <div className={styles.textField}>
        <TextField
          value={state.value}
          inputStyle={{
            background: "#F3F3F3",
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            border: 0,
            paddingLeft: "5px",
          }}
          type="text"
          placeholder="Search"
          onChange={(e) =>
            setState((prev) => ({ ...prev, value: e.target.value }))
          }
          onFocus={() => setState((prev) => ({ ...prev, focus: true }))}
          onBlur={() => setState((prev) => ({ ...prev, focus: false }))}
        />
      </div>
      {state.focus && state.value.length > 3 && (
        <ResultsTable
          {...transformRequestDataToResultsTableProps(requestResult)}
          setFocus={(focus) => setState((prev) => ({ ...prev, focus }))}
        />
      )}
    </div>
  );
};
export default Search;
