import axios from "axios";

import {
  TitleIssueNote,
  CreateTitleIssueNoteRequest,
} from "@Types/title_issue_notes";
import { auth } from "@Firebase/firebase";

export const getTitleIssueNotes =
  (titleIssueId: string): (() => Promise<TitleIssueNote[]>) =>
  async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/title_issue_note/${titleIssueId}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result?.docs ?? [];
  };

export const createTitleIssueNote =
  (): ((
    titleIssueNote: CreateTitleIssueNoteRequest
  ) => Promise<TitleIssueNote>) =>
  async (titleIssueNote) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/title_issue_note/`,
      titleIssueNote,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.result;
  };
