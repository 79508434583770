import styles from "../TextField/input.module.scss";
interface SSNFieldProps {
  id?: string;
  value: string;
  name?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  required?: boolean;
  autofilled?: boolean;
}

const formatSSN = (ssn?: string) => {
  if (!ssn) return "";
  ssn = ssn.slice(0, 11).replace(/-/g, "");
  if (ssn.length <= 3) {
    return ssn;
  }
  if (ssn.length > 3 && ssn.length <= 5) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
  }
  if (ssn.length > 5) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
  }
};
const SSNField = ({
  id,
  onChange,
  value,
  required = true,
  name,
  autofilled = false,
}: SSNFieldProps) => {
  return (
    <div
      className={`${styles.inputContainer} ${autofilled && styles.autofilled}`}
    >
      {value && (
        <label className={styles.label} htmlFor={name}>
          {`Soc. Sec. No.${required ? " *" : ""}`}
          {autofilled ? (
            <span className={styles.autofilledLabel}> (Autofilled)</span>
          ) : (
            ""
          )}
        </label>
      )}

      <input
        id={id}
        value={formatSSN(value?.toString())}
        onChange={onChange}
        type="text"
        required={required}
        className={styles.input}
        placeholder={`Soc. Sec. No.${required ? " *" : ""}`}
      />
    </div>
  );
};
export default SSNField;
