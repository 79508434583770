import styles from "./input.module.scss";

interface TextAreaProps {
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  placeholder: string;
  value?: string | number;
  containerStyle?: React.CSSProperties;
  onKeyUp?: React.KeyboardEventHandler<HTMLTextAreaElement> | undefined;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  onFocus?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  onBlur?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  id?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  inputStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  className?: string;
  defaultRows?: number;
  showPlaceHolderTop?: boolean;
}

const TextArea = ({
  startIcon,
  endIcon,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  inputStyle,
  iconStyle,
  value,
  containerStyle,
  id,
  onKeyUp,
  defaultRows,
  name,
  required = false,
  disabled = false,
  showPlaceHolderTop = true,
  className = "",
}: TextAreaProps) => {
  return (
    <div
      className={styles.inputContainer + ` ${className}`}
      style={
        containerStyle
          ? containerStyle?.height
            ? containerStyle
            : { ...containerStyle }
          : {}
      }
    >
      {value && showPlaceHolderTop && (
        <label className={styles.label} htmlFor={name}>
          {`${placeholder}${required ? " *" : ""}`}
        </label>
      )}
      {startIcon ? (
        <div style={iconStyle} className={`${styles.icon} ${styles.left}`}>
          {startIcon}
        </div>
      ) : (
        <></>
      )}
      {endIcon ? (
        <div style={iconStyle} className={`${styles.icon} ${styles.right}`}>
          {endIcon}
        </div>
      ) : (
        <></>
      )}
      <textarea
        rows={defaultRows ?? 1}
        onKeyUp={onKeyUp}
        style={inputStyle}
        disabled={disabled}
        id={id}
        data-testid={id}
        name={name}
        required={required}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        className={
          startIcon
            ? [styles.input, styles.inputWithStartIcon].join(" ")
            : styles.input
        }
        placeholder={
          !value ? `${placeholder}${required ? " *" : ""}` : undefined
        }
      />
    </div>
  );
};
export default TextArea;
