type CircleProps = {
  text: string;
  size?: number;
  color?: string;
  style?: React.CSSProperties;
};

const Circle = ({ style, color = "#6B97F6", text, size = 50 }: CircleProps) => {
  return (
    <div style={style}>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={size / 2.2}
          stroke={color}
          strokeWidth="3"
        />
        <text
          fill={color}
          fontSize="20px"
          x="50%"
          y={`${size / 2.1}%`}
          dy="20px"
          fontWeight={500}
          textAnchor="middle"
        >
          {text}
        </text>
      </svg>
    </div>
  );
};

export default Circle;
