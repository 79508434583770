import { ReactNode } from "react";
import styles from "./Paper.module.scss";
type Props = {
  id?: string;
  title?: string;
  style?: React.CSSProperties;
  outerClassName?: string;
  children: ReactNode;
  tabIndex?: number;
};

const Paper = ({
  title,
  children,
  style,
  id,
  outerClassName,
  tabIndex,
}: Props) => {
  return (
    <div
      tabIndex={tabIndex || 0}
      id={id}
      className={`${styles.root} ${styles?.[outerClassName ?? ""] ?? ""}`}
      style={style}
    >
      {title && <h2 className={styles.title}>{title}</h2>}
      {children}
    </div>
  );
};

export default Paper;
