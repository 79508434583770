import { useId } from "react";
import styles from "./Radio.module.scss";

type RadioProps = {
  id?: string;
  group: string;
  label?: string;
  required?: boolean;
  possibleValues: PossibleRadioValue[];
  selectedValue: string | number | undefined | null;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  outerLabelStyle?: string;
  autofilled?: boolean;
};

type PossibleRadioValue = {
  label: string;
  value: string | number | undefined;
  id?: string;
  name?: string;
  description?: string;
};

function Radio({
  id,
  label,
  possibleValues,
  selectedValue,
  onChange,
  required,
  group,
  outerLabelStyle,
  autofilled = false,
}: RadioProps) {
  const radioId = id ?? useId();
  return (
    <div className={`${styles.container} ${autofilled && styles.autofilled}`}>
      {label && (
        <div>
          {`${label}${required ? " *" : ""}` ?? ""}{" "}
          {autofilled ? (
            <span className={styles.autofilledLabel}> (Autofilled)</span>
          ) : (
            ""
          )}
        </div>
      )}
      {possibleValues.map((possibleValue, index) => (
        <div key={index}>
          <label
            htmlFor={radioId + possibleValue.value}
            className={`${styles.container} ${[outerLabelStyle ?? ""]}`}
          >
            <input
              name={group + radioId}
              required={required}
              type="radio"
              id={radioId + possibleValue.value}
              value={possibleValue.value}
              onChange={onChange}
              checked={possibleValue.value === selectedValue}
            />
            {possibleValue?.label}
          </label>
          {possibleValue.description && (
            <div className={styles.description}>
              {possibleValue.description ?? ""}
            </div>
          )}
        </div>
      ))}

      <br />
    </div>
  );
}

export default Radio;
