import styles from "./LoaderSuccess.module.scss";

type Props = {
  isLoading: boolean;
  isSuccess: boolean;
  successMessage: string;
  footerMessage?: string;
};
const LoaderSuccess = ({
  isLoading,
  isSuccess,
  successMessage,
  footerMessage,
}: Props) => {
  return (
    <div className={styles.container}>
      <div id="loader" className={styles["Spinner"]}>
        <svg
          className={
            isLoading
              ? styles["Spinner-Viewbox-rotating"]
              : styles["Spinner-Viewbox-paused"]
          }
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className={
              isSuccess
                ? styles["Spinner-Circle-paused"]
                : styles["Spinner-Circle"]
            }
            cx="50"
            cy="50"
            r="45"
            style={{
              strokeDashoffset: 50,
              stroke: isSuccess ? "#15c969" : "#a5aec1",
            }}
            pathLength="100"
          />
        </svg>
        <svg
          className={
            isSuccess ? styles["Spinner-Check-shown"] : styles["Spinner-Check"]
          }
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7 14.17L2.83 10l-1.41 1.41L7 17 19 5l-1.41-1.42L7 14.17z" />
        </svg>
      </div>

      <div
        className={styles.successMessageContainer}
        style={!isSuccess ? { visibility: "hidden" } : {}}
      >
        <p className={styles.successMessage}>{successMessage}</p>
        <p className={styles.successMessageFooter}>
          {footerMessage ? footerMessage : "You may close this window."}
        </p>
      </div>
    </div>
  );
};

export default LoaderSuccess;
