import { useState } from "react";

import Button from "@Components/Button";
import Dialog from "@Components/Dialog";
import { DialogContent } from "@Components/Dialog/DialogContent";
import Grid from "@Components/Grid";
import Paper from "@Components/Paper";
import ApplicantViewForm from "@Pages/Applicants/ApplicantViewForm";

import IconButton from "@Components/IconButton";
import useCurrentUser from "@Hooks/useCurrentUser";
import {
  CreditBureau,
  ErrorResponse,
  SuccessResponse,
} from "@Types/credit_bureaus";
import { Deal, dealIsPastSubmittedForFunding } from "@Types/deal";
import { formatDate, formatSSN } from "@Utils/functions";
import { hasSectionPermissions } from "@Utils/permissions";
import { BiHide, BiShow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import styles from "./Applicant.module.scss";

type ApplicantProps = {
  type: "applicant" | "coApplicant";
  deal: Deal;
};

export const maskSSN = (ssn: string) => {
  const lastFourDigits = ssn?.slice(-4);
  if (lastFourDigits) {
    return `XXX-XX-${lastFourDigits}`;
  }
  return "Invalid SSN";
};

export const renderFieldData = (label: string, data: string, bold = false) => {
  return (
    <div className={styles.dataContainer}>
      <div className={styles.label}>{label}</div>
      {bold ? (
        <div className={styles.data}>
          <strong>{data}</strong>
        </div>
      ) : (
        <div className={styles.data}>{data}</div>
      )}
    </div>
  );
};
const isSuccessScore = (
  score: SuccessResponse | ErrorResponse | undefined
): score is SuccessResponse => "bureau_xml_data" in (score || {});

export const formatCreditBureausScores = (
  type: "applicant" | "coApplicant",
  creditBureau: CreditBureau | undefined
) => {
  if (!creditBureau) return "N/A";
  const score = creditBureau?.data["700CreditResponse"]?.Results;
  let result = null;
  if (isSuccessScore(score)) {
    result = score.bureau_xml_data?.[0].TU_Report?.[0]?.subject_segments?.map(
      (segment) => segment?.scoring_segments?.[0]?.scoring?.[0]?.score[0]
    );
    switch (type) {
      case "applicant":
        return `${result?.[0] ? result[0] : "N/A"}`;
      case "coApplicant":
        return `${result?.[1] ? result[1] : "N/A"}`;
    }
  }
  return "N/A";
};
const Applicant = ({ type, deal }: ApplicantProps) => {
  const currentUser = useCurrentUser();
  if (!hasSectionPermissions("deal", "applicants", currentUser)) return null;

  const applicant = deal?.data?.[type];
  const navigate = useNavigate();
  const [showSSN, setShowSSN] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  return applicant ? (
    <Paper title={type === "applicant" ? "Applicant" : "Co-Applicant"}>
      <Grid container xs={type === "applicant" ? 6 : 12}>
        <Grid xs={12}>
          {renderFieldData(
            "Name",
            `${applicant?.data.info.firstName ?? ""} ${
              applicant?.data.info.lastName ?? ""
            }`
          )}
        </Grid>
        <Grid xs={12}>
          {renderFieldData(
            "DOB",
            formatDate(`${applicant?.data.info.birthDate ?? ""}`)
          )}
        </Grid>
        <Grid xs={12}>
          {renderFieldData(
            "Credit score",
            deal?.data?.info?.extractedCreditScores?.[type]?.score
              ? deal?.data?.info?.extractedCreditScores?.[
                  type
                ]?.score.toString()
              : "N/A",
            true
          )}
        </Grid>
        <Grid xs={12}>
          <div className={styles.dataContainer}>
            <div className={styles.label}>SSN</div>
            <div
              className={styles.data}
              style={{
                marginTop: "-10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {showSSN
                ? formatSSN(`${applicant?.data.info.socialSecurityNumber}`)
                : maskSSN(applicant?.data.info.socialSecurityNumber)}
              <IconButton
                style={{ paddingBottom: "7px" }}
                onClick={() => setShowSSN(!showSSN)}
              >
                {showSSN ? <BiHide size={19} /> : <BiShow size={19} />}
              </IconButton>
            </div>
          </div>
        </Grid>
      </Grid>

      <Button
        style={{ width: type === "applicant" ? "40%" : "80%" }}
        className={styles.button}
        onClick={() => setOpenDialog(true)}
        variant="blue"
      >
        More info
      </Button>
      <Dialog
        id={`${type}Dialog`}
        open={openDialog}
        title={type === "applicant" ? "Applicant" : "Co-Applicant"}
        closeIcon
        titleColor="blue"
        size="lg"
        closeFn={() => {
          setOpenDialog(false);
        }}
      >
        <DialogContent>
          <ApplicantViewForm applicant={applicant?.data?.info} variant="blue" />
        </DialogContent>
      </Dialog>
    </Paper>
  ) : !dealIsPastSubmittedForFunding(deal.data.info.status) ? (
    <Paper
      title={type === "applicant" ? "Applicant" : "Co-Applicant"}
      style={{ height: "100%", boxSizing: "border-box", position: "relative" }}
    >
      <p>No {type === "applicant" ? "Applicant" : "Co-Applicant"}</p>
      <Button
        style={{ width: "80%" }}
        className={styles.coApplicantButton}
        onClick={() =>
          navigate("/add-co-applicant", {
            state: {
              applicant: deal?.data?.applicant?.data?.info,
              dealId: deal._id,
              collateral: deal.data.info.vehicle,
            },
          })
        }
        variant="blue"
      >
        Click here to add one
      </Button>
    </Paper>
  ) : null;
};

export default Applicant;
