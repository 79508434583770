import { useState } from "react";
import { CiCircleRemove } from "react-icons/ci";

import Grid from "@Components/Grid";
import Button from "@Components/Button";
import Select from "@Components/Select";
import useGetRoles from "@Hooks/useRoles";
import useGetTeams from "@Hooks/useTeams";
import IconButton from "@Components/IconButton";
import useCurrentUser from "@Hooks/useCurrentUser";
import TextField from "@Components/Inputs/TextField";
import { User, CreateUserRequest } from "@Types/users";
import { useResendMemberLink } from "@Hooks/useMembers";

import styles from "./User.module.scss";

type Props<T extends User | CreateUserRequest> = {
  id: string;
  type: "update" | "create";
  emailDisabled?: boolean;
  initialState: T;
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    user: T | undefined
  ) => void;
};
export function UserForm<T extends User | CreateUserRequest>({
  id,
  type,
  initialState,
  handleSubmit,
  emailDisabled = false,
}: Props<T>) {
  const [state, setState] = useState(initialState);
  const { sendLink } = useResendMemberLink();
  const handleResendLink = () => sendLink(id);
  const user = useCurrentUser();

  const { data: roles } = useGetRoles();
  const { data: teams } = useGetTeams();

  return (
    <div className={styles.formContainer}>
      <form data-testid={id} id={id} onSubmit={(e) => handleSubmit(e, state)}>
        <Grid container xs={12} spacing={2}>
          <Grid xs={4}>
            <TextField
              onChange={(e) =>
                setState((prev) => ({
                  ...(prev ?? {}),
                  data: {
                    ...(prev?.data ?? {}),
                    info: {
                      ...(prev?.data?.info ?? {}),
                      firstName: e.target.value,
                    },
                  },
                }))
              }
              id="firstName"
              placeholder="First Name"
              value={state?.data.info.firstName ?? ""}
              name="First Name"
              type="text"
              required
            />
          </Grid>
          <Grid xs={4}>
            <TextField
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  data: {
                    ...prev?.data,
                    info: {
                      ...prev?.data.info,
                      lastName: e.target.value,
                    },
                  },
                }))
              }
              id="lastName"
              placeholder="Last Name"
              value={state?.data.info.lastName ?? ""}
              name="Last Name"
              type="text"
              required
            />
          </Grid>
          <Grid xs={4}>
            <TextField
              onChange={
                !emailDisabled
                  ? (e) =>
                      setState((prev) => ({
                        ...prev,
                        data: {
                          ...prev?.data,
                          info: {
                            ...prev?.data.info,
                            email: e.target.value,
                          },
                        },
                      }))
                  : undefined
              }
              id="email"
              disabled={emailDisabled}
              placeholder="Email"
              required
              value={state?.data.info.email ?? ""}
              name="Email"
              type="text"
            />
          </Grid>

          <Grid xs={4}>
            <Select
              placeholder="User type"
              name="userType"
              id="userType"
              required
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  data: {
                    ...prev?.data,
                    info: {
                      ...prev?.data.info,
                      userType: e.target.value,
                    },
                  },
                }))
              }
              value={state?.data?.info?.userType}
              options={[
                {
                  label: "User",
                  value: "dealership-member",
                },
                ...(user?.data?.info?.userType !== "dealership-member"
                  ? [
                      {
                        label: "Admin",
                        value: "dealership-admin",
                      },
                      {
                        label: "Owner",
                        value: "owner",
                      },
                    ]
                  : []),
              ]}
            />
          </Grid>

          {state?.data?.info?.registrationLink ? (
            <Grid xs={4}>
              <Button type="button" variant="blue" onClick={handleResendLink}>
                Send Registration Link
              </Button>
            </Grid>
          ) : null}
          <Grid xs={12} container>
            <Grid xs={12}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                User Permissions
              </p>
            </Grid>

            <Grid
              container
              xs={6}
              style={{ marginBottom: "20px", paddingLeft: "10px" }}
            >
              <Grid xs={12}>
                <Grid xs={12}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    Roles
                  </div>
                </Grid>

                <Select
                  label="Select roles"
                  id="roles"
                  name="Select Roles"
                  value={undefined}
                  onChange={(e) => {
                    const role = JSON.parse(e.target.value);
                    if (state.data.roles?.find((r) => r._id === role._id))
                      return;
                    setState((prev) => ({
                      ...prev,
                      data: {
                        ...prev?.data,
                        roles: [...(state.data.roles ?? []), role],
                      },
                    }));
                  }}
                  options={roles?.map((role) => ({
                    value: JSON.stringify(role),
                    label: role.data.info.name,
                  }))}
                />
                <Grid xs={6}>
                  {(state?.data?.roles ?? []).map((value, index) => (
                    <IconButton
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        setState((prev) => ({
                          ...prev,
                          data: {
                            ...prev?.data,
                            roles: state?.data?.roles?.filter(
                              (role) => role._id !== value._id
                            ),
                          },
                        }));
                      }}
                      dataTestId={`r-remove-button-${index}`}
                      style={{ fontSize: "22px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        {value.data.info.name}
                        <CiCircleRemove size={22} color="red" />
                      </div>
                    </IconButton>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              xs={6}
              style={{ marginBottom: "20px", paddingLeft: "10px" }}
            >
              <Grid xs={12}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  Teams
                </p>

                <Select
                  label="Select teams"
                  id="teams"
                  name="Select teams"
                  value={undefined}
                  onChange={(e) => {
                    const team = JSON.parse(e.target.value);
                    if (state.data.teams?.find((r) => r._id === team._id))
                      return;
                    setState((prev) => ({
                      ...prev,
                      data: {
                        ...prev?.data,
                        teams: [...(state.data.teams ?? []), team],
                      },
                    }));
                  }}
                  options={teams?.map((team) => ({
                    value: JSON.stringify(team),
                    label: team.data.info.name,
                  }))}
                />
              </Grid>

              <Grid xs={12}>
                {(state?.data?.teams ?? []).map((value, index) => (
                  <IconButton
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      setState((prev) => ({
                        ...prev,
                        data: {
                          ...prev?.data,
                          teams: state?.data?.teams?.filter(
                            (team) => team._id !== value._id
                          ),
                        },
                      }));
                    }}
                    dataTestId={`r-remove-button-${index}`}
                    style={{ fontSize: "22px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      {value.data.info.name}
                      <CiCircleRemove size={22} color="red" />
                    </div>
                  </IconButton>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Button
              style={{ width: "30%" }}
              form={id}
              id={`${type}-user-button`}
              variant="gradient"
              type="submit"
            >
              {type === "create" ? "Create Account" : "Save Changes"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
