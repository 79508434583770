import { Deal } from "@Types/deal";
import Grid from "@Components/Grid";
import Paper from "@Components/Paper";
import { renderFieldData } from "@Pages/Applications/CreateApplication/ReviewApplication/ReviewApplication";

type TaxesProps = {
  deal: Deal;
};

const Taxes = ({ deal }: TaxesProps) => {
  const taxesAndFees = deal?.data?.info?.taxesAndFees;
  return (
    <Paper
      title={"Taxes And Fees"}
      style={{ height: "100%", boxSizing: "border-box" }}
    >
      <Grid container xs={12}>
        <Grid container xs={6}>
          <Grid xs={12}>
            {renderFieldData(
              "Doc fee",
              taxesAndFees?.documentRegistrationFees?.toString() ?? "-"
            )}
          </Grid>
          <Grid xs={12}>
            {renderFieldData(
              "Title transfer",
              taxesAndFees?.titleTransfer?.toString() ?? "-"
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default Taxes;
