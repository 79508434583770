export default ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21" cy="21" r="21" fill="currentColor" />
    <path
      d="M25.5967 24.0186V25.874H14.7324V24.5435L21.3364 12.2266H23.0698L21.3364 15.791L17.1372 24.0186H25.5967ZM23.6191 12.2266V30H21.4585V12.2266H23.6191Z"
      fill="white"
    />
  </svg>
);
