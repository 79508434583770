import html2canvas, { Options } from "html2canvas";
import { useState } from "react";

const useScreenshot = (): [
  string | null,
  React.Dispatch<React.SetStateAction<string | null>>,
  (node: HTMLElement, options: Partial<Options>) => Promise<string | void>
] => {
  const [image, setImage] = useState<string | null>(null);
  const takeScreenshot = (
    node: HTMLElement,
    options: Partial<Options>
  ): Promise<string> => {
    if (!node) {
      throw new Error("You should provide correct html node.");
    }
    return html2canvas(node, options).then((canvas) => {
      const croppedCanvas = document.createElement("canvas");
      const croppedCanvasContext = croppedCanvas.getContext("2d");
      const cropPositionTop = 0;
      const cropPositionLeft = 0;
      const cropWidth = canvas.width;
      const cropHeight = canvas.height;

      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeight;

      croppedCanvasContext?.drawImage(
        canvas,
        cropPositionLeft,
        cropPositionTop
      );

      const base64Image = croppedCanvas.toDataURL("png");

      setImage(base64Image);
      return base64Image;
    });
  };

  return [
    image,
    setImage,
    takeScreenshot,
    // {
    //   error,
    // },
  ];
};
export default useScreenshot;
