import Cleave from "cleave.js/react";
import styles from "../TextField/input.module.scss";

interface DateFieldProps {
  id?: string;
  placeholder?: string;
  required?: boolean;
  title: string;
  value: string | number | null | undefined;
  name?: string;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  autofilled?: boolean;
}

const DateField = ({
  id,
  placeholder,
  title,
  onChange,
  name,
  value,
  style,
  labelStyle,
  required = false,
  autofilled = false,
}: DateFieldProps) => {
  return (
    <div
      className={`${styles.inputContainer} ${autofilled && styles.autofilled}`}
    >
      <label className={styles.label} htmlFor={name} style={labelStyle}>
        {`${title}${required ? " *" : ""}`}
        {autofilled ? (
          <span className={styles.autofilledLabel}> (Autofilled)</span>
        ) : (
          ""
        )}
      </label>
      <Cleave
        options={{
          date: true,
          datePattern: ["m", "d", "Y"],
        }}
        style={style}
        pattern=".{10,10}"
        min={10}
        id={id}
        placeholder={placeholder}
        value={value ?? ""}
        onChange={onChange}
        name={name}
        required={required}
        className={styles.input}
      />
    </div>
  );
};
export default DateField;
