import { useParams } from "react-router-dom";

import Grid from "@Components/Grid";
import Paper from "@Components/Paper";
import Loading from "@Components/Loading";
import BackButton from "@Components/BackButton";
import { useGetChargeback } from "@Hooks/useChargebacks";
import { capitalize, formatDate, formatPrice } from "@Utils/functions";
import { renderFieldData } from "@Pages/Applications/CreateApplication/ReviewApplication/ReviewApplication";

import styles from "./Chargeback.module.scss";

export default function Chargeback() {
  const { id } = useParams<{ id: string }>();
  const { chargeback } = useGetChargeback(id as string);

  if (!id) return <>Error</>;
  if (!chargeback) return <Loading />;
  return (
    <Grid className={styles.container} xs={12} container spacing={1}>
      <Grid xs={12}>
        <BackButton />
      </Grid>
      <Grid xs={6}>
        <h2 className={styles.title}>Chargeback</h2>
      </Grid>
      <Grid xs={12}>
        <Paper title="Info" style={{ height: "100%", boxSizing: "border-box" }}>
          <Grid container xs={12}>
            <Grid xs={12}>
              {renderFieldData(
                "Reason",
                capitalize(chargeback?.data?.info?.reason) ?? "N/A"
              )}
            </Grid>
            <Grid xs={12}>
              {renderFieldData("Type", chargeback?.data?.info?.type ?? "N/A")}
            </Grid>
            <Grid xs={12}>
              {renderFieldData(
                "Amount",
                formatPrice(chargeback?.data?.info?.dealerChargebackAmount) ??
                  "N/A"
              )}
            </Grid>
            <Grid xs={12}>
              {renderFieldData(
                "Customer",
                `${chargeback?.data?.deal?.applicantFirstName ?? ""} ${
                  chargeback?.data?.deal?.applicantLastName
                }`
              )}
            </Grid>
            <Grid xs={12}>
              {renderFieldData(
                "Lender ",
                chargeback?.data?.lender?.data?.info?.name ?? "N/A"
              )}
            </Grid>
            <Grid xs={12}>
              {renderFieldData(
                "Chargeback date",
                formatDate(chargeback?.data?.info?.chargebackDate, "short") ??
                  "N/A"
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
