import { IoHelpCircleSharp } from "react-icons/io5";
import styles from "./Tickets.module.scss";
import IconButton from "@Components/IconButton";
import { FormEvent, Fragment, useCallback, useRef, useState } from "react";
import Dialog from "@Components/Dialog";
import { DialogContent } from "@Components/Dialog/DialogContent";
import { DialogActions } from "@Components/Dialog/DialogActions";
import Button from "@Components/Button";
import { useCreateDealershipTicket } from "@Hooks/useDealershipTickets";
import SubmitTicket from "./SubmitTicket";
import TicketsList from "./TicketsList";
import {
  DealershipTicket,
  DealershipTicketStatus,
} from "@Types/dealership_tickets";
import {
  browserName,
  browserVersion,
  deviceType,
  osName,
  osVersion,
} from "react-device-detect";
import { toast } from "react-toastify";
import useScreenshot from "@Hooks/useScreenshot";
import useOnClickOutside from "@Hooks/useClickOutside";

type TicketsMenuProps = {
  setDialogType: React.Dispatch<
    React.SetStateAction<"submitIssue" | "issuesList">
  >;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const TicketsMenu = ({
  setDialogType,
  setOpenDialog,
  setOpen,
}: TicketsMenuProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const closeFnCallback = useCallback(() => () => setOpen(false), [ref]);
  useOnClickOutside(ref, closeFnCallback);

  return (
    <div className={styles.ticketsMenu} ref={ref} id="ticket-menu">
      <div
        className={styles.ticketsMenuItem}
        onClick={() => {
          setOpenDialog(true);
          setOpen(false);
          setDialogType("submitIssue");
        }}
      >
        Submit an issue
      </div>
      <div
        className={styles.ticketsMenuItem}
        onClick={() => {
          setOpenDialog(true);
          setOpen(false);
          setDialogType("issuesList");
        }}
      >
        My issues
      </div>
    </div>
  );
};
type TicketDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setScreenshotProcessEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  type: "submitIssue" | "issuesList";
  image: string | null;
  setImage: React.Dispatch<React.SetStateAction<string | null>>;
};
const TicketsDialog = ({
  open,
  setOpen,
  setScreenshotProcessEnabled,
  image,
  setImage,
  type,
}: TicketDialogProps) => {
  const [selectedTicket, setSelectedTicket] = useState<
    DealershipTicket | undefined
  >();
  const [successState, setSuccessState] = useState(false);
  const { createDealershipTicket, loading } = useCreateDealershipTicket({
    onSuccessCb: () => {
      setSuccessState(true);
    },
  });

  const [issueDescription, setIssueDescription] = useState("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (issueDescription) {
      createDealershipTicket({
        data: {
          info: {
            deviceInfo: {
              deviceType,
              osName,
              osVersion,
            },
            browserInfo: {
              browserName,
              browserVersion,
            },
            status: DealershipTicketStatus.NEW,
            description: issueDescription,
            imageData: image as string,
          },
        },
      });
      setScreenshotProcessEnabled(false);
      setImage("");
    } else {
      toast.warn("Issue description cannot be empty", {
        position: "top-center",
      });
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setIssueDescription("");
    setSelectedTicket(undefined);
    setSuccessState(false);
  };
  const renderDialogContentByType = () => {
    switch (type) {
      case "submitIssue": {
        return (
          <DialogContent>
            <SubmitTicket
              issueDescription={issueDescription}
              setIssueDescription={setIssueDescription}
              image={image as string}
              setScreenshotProcessEnabled={setScreenshotProcessEnabled}
              closeDialogFn={handleCloseDialog}
              onSubmit={handleSubmit}
              loading={loading}
              success={successState}
            />
          </DialogContent>
        );
      }
      case "issuesList": {
        return (
          <DialogContent>
            <TicketsList
              selectedTicket={selectedTicket}
              setSelectedTicket={setSelectedTicket}
            />
          </DialogContent>
        );
      }
    }
  };
  const renderDialogActionsByType = () => {
    switch (type) {
      case "submitIssue": {
        return (
          <DialogActions align="space-between">
            <Button variant="blue" onClick={handleCloseDialog}>
              Close
            </Button>
            {!(loading || successState) && (
              <Button
                disabled={issueDescription?.length < 1}
                form="submitIssueForm"
                variant="blue"
                type="submit"
              >
                Send
              </Button>
            )}
          </DialogActions>
        );
      }
      case "issuesList": {
        return selectedTicket === undefined ? (
          <DialogActions align="space-between">
            <Button variant="blue" onClick={handleCloseDialog}>
              Close
            </Button>
          </DialogActions>
        ) : (
          <DialogActions align="space-between">
            <Button variant="blue" onClick={() => setSelectedTicket(undefined)}>
              Back
            </Button>
          </DialogActions>
        );
      }
    }
  };
  return (
    <Dialog
      id={"ticketsDialog"}
      open={open}
      title={"Manage tickets"}
      closeIcon
      containerStyle={{
        minWidth: "900px",
        top: "100px",
        transform: "unset",
        left: 0,
        bottom: 100,
      }}
      titleColor="blue"
      size="md"
      closeFn={handleCloseDialog}
    >
      {renderDialogContentByType()}
      {renderDialogActionsByType()}
    </Dialog>
  );
};
const Tickets = () => {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState<"submitIssue" | "issuesList">(
    "submitIssue"
  );
  const [image, setImage, takeScreenshot] = useScreenshot();

  const [screenshotProcessEnabled, setScreenshotProcessEnabled] =
    useState(false);
  return (
    <Fragment>
      <div className={styles.container}>
        <IconButton onClick={() => setOpen((prevOpen) => !prevOpen)}>
          <IoHelpCircleSharp size={30} color="#6B97F6" />
        </IconButton>
      </div>
      {open && (
        <TicketsMenu
          setOpen={setOpen}
          setDialogType={setDialogType}
          setOpenDialog={setOpenDialog}
        />
      )}
      <TicketsDialog
        open={openDialog}
        setOpen={setOpenDialog}
        image={image}
        setImage={setImage}
        type={dialogType}
        setScreenshotProcessEnabled={setScreenshotProcessEnabled}
      />
      {screenshotProcessEnabled && (
        <Button
          className={"flashing"}
          style={{
            ...(image ? { background: "green" } : {}),
            position: "fixed",
            width: "250px",
            whiteSpace: "nowrap",
            float: "right",
            right: 20,
            zIndex: "999",
            bottom: 10,
          }}
          variant="blue"
          onClick={async () => {
            const mapNode = document.querySelector(
              ".gm-style>div>div>div:last-child>div"
            );
            const originalStyle = mapNode?.getAttribute("style");
            if (mapNode) {
              const transformMatrix = getComputedStyle(
                mapNode as HTMLElement
              ).transform.split(",");
              (mapNode as HTMLElement).setAttribute(
                "style",
                "left:" +
                  parseFloat(transformMatrix[4]) +
                  "px;top:" +
                  parseFloat(transformMatrix[5]) +
                  "px;transform: none;position: absolute;z-index: 983;"
              );
            }
            takeScreenshot?.(document.getElementById("root") as HTMLElement, {
              backgroundColor: null,
              useCORS: true,
            }).then(() => {
              if (originalStyle && mapNode) {
                mapNode.setAttribute("style", originalStyle);
              }
              setOpenDialog(true);
            });
          }}
        >
          Take a screenshot
        </Button>
      )}
    </Fragment>
  );
};
export default Tickets;
