import { useState, ReactNode } from "react";

import { PermissionEntities, PermissionSubEntities } from "@Types/roles";

type TooltipProps = {
  children: ReactNode;
  imageName: PermissionEntities | PermissionSubEntities;
};

const Tooltip = ({ children, imageName }: TooltipProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };
  const getImage = () => {
    try {
      return `url(${require(`../../images/PermissionExamples/${imageName}.png`)})`;
    } catch (e) {
      console.log(`Image load error: ${e}`);
    }
  };

  const renderTooltip = () => {
    return (
      <div
        style={{
          background: getImage(),
          borderRadius: "9px",
          position: "absolute",
          top: -100,
          left: -300,
          zIndex: 9999,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          width: "600px",
          height: "300px",
        }}
      />
    );
  };

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {children}

      {isTooltipVisible && renderTooltip()}
    </div>
  );
};

export default Tooltip;
