export default ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="94"
    height="66"
    viewBox="0 0 94 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0317 13.5462C16.9148 13.5462 14.3784 16.054 14.3784 19.1357C14.644 26.5332 25.4206 26.5314 25.685 19.1357C25.685 16.054 23.1486 13.5462 20.0317 13.5462ZM20.0317 22.4894C15.5591 22.3737 15.5601 15.8972 20.0317 15.782C24.5044 15.8979 24.5033 22.3741 20.0317 22.4894Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M10.9882 9.07477C8.49363 9.07477 6.46555 11.081 6.46555 13.5464V31.4329C6.46555 33.8993 8.49363 35.9045 10.9882 35.9045H29.0787C31.5732 35.9045 33.6014 33.8993 33.6014 31.4329V13.5464C33.6014 11.081 31.5732 9.07477 29.0787 9.07477H10.9882ZM12.2016 33.6686C12.618 30.8608 14.5775 28.4349 17.2907 27.4482C17.8014 27.6957 18.3461 27.86 18.9029 27.9593V31.4329C18.9029 32.0507 19.4086 32.5507 20.0336 32.5507C20.6584 32.5507 21.1641 32.0507 21.1641 31.4329V27.9593C21.7204 27.86 22.2656 27.6957 22.7764 27.4482C25.4895 28.4349 27.4489 30.8608 27.8654 33.6686H12.2016ZM31.3401 13.5464V31.4329C31.3401 32.2983 30.8348 33.0419 30.1054 33.4137C29.5624 29.5858 26.7947 26.2981 22.9949 25.1647C22.6882 25.0719 22.3579 25.1142 22.0839 25.2769C20.8351 26.0226 19.233 26.0246 17.9833 25.2769C17.7091 25.1142 17.379 25.0708 17.0721 25.1647C13.2729 26.2985 10.5053 29.5858 9.96171 33.4137C9.23235 33.0424 8.72708 32.2983 8.72708 31.4329V13.5464C8.72708 12.3132 9.74164 11.3106 10.9884 11.3106H29.0789C30.3257 11.3106 31.3401 12.3132 31.3401 13.5464Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M7.395 44.4346H32.9197C34.5412 44.4008 34.5406 42.0285 32.9197 41.9973H7.395C4.67575 41.9973 2.46499 39.8117 2.46499 37.123V7.87675C2.46499 5.18931 4.67575 3.00237 7.395 3.00237H54.2299C56.9491 3.00237 59.1598 5.18931 59.1598 7.87675V16.0534C59.1747 17.6527 61.6056 17.6532 61.6248 16.0534V7.87675C61.6248 3.84509 58.3076 0.565186 54.2299 0.565186H7.395C3.31732 0.565186 0 3.84509 0 7.87675V37.123C0 41.1547 3.31732 44.4346 7.395 44.4346Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M55.481 18.8648C44.6075 18.8648 35.761 27.6115 35.761 38.3623C36.8444 64.2286 74.122 64.2217 75.2009 38.3623C75.2009 27.6115 66.3544 18.8648 55.481 18.8648ZM55.481 55.4227C45.9665 55.4227 38.226 47.7694 38.226 38.3623C39.1736 15.7299 71.7922 15.7369 72.7359 38.3623C72.7359 47.7694 64.9954 55.4227 55.481 55.4227Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M60.4149 29.8318H64.1124V33.4876C64.1378 35.0876 66.5509 35.091 66.5774 33.4876V28.6133C66.5774 27.9398 66.0261 27.3948 65.3449 27.3948H60.4149C58.7962 27.4215 58.7934 29.8048 60.4149 29.8318Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M45.6224 34.7063C46.3036 34.7063 46.8549 34.1611 46.8549 33.4876V29.8318H50.5524C52.1706 29.8064 52.1739 27.4209 50.5524 27.3948H45.6224C44.9413 27.3948 44.3899 27.9398 44.3899 28.6133V33.4876C44.3899 34.1611 44.9413 34.7063 45.6224 34.7063Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M65.3447 42.0181C64.6635 42.0181 64.1122 42.5632 64.1122 43.2367V46.8925H60.4147C58.7965 46.918 58.7931 49.3035 60.4147 49.3296H65.3447C66.0259 49.3296 66.5772 48.7846 66.5772 48.1111V43.2367C66.5772 42.5632 66.0259 42.0181 65.3447 42.0181Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M50.5524 46.8925H46.8549V43.2368C46.8296 41.6369 44.4164 41.6336 44.3899 43.2368V48.1112C44.3899 48.7847 44.9413 49.3298 45.6224 49.3298H50.5524C52.1711 49.3031 52.1739 46.9192 50.5524 46.8925Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M40.6935 38.3623C40.6935 39.0358 41.2449 39.5808 41.926 39.5808H69.041C69.7221 39.5808 70.2735 39.0358 70.2735 38.3623C70.2735 37.6888 69.7221 37.1436 69.041 37.1436H41.926C41.2449 37.1436 40.6935 37.6888 40.6935 38.3623Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M51.7681 10.8872C51.7681 10.2774 51.2685 9.78291 50.6513 9.78291H40.5987C39.9815 9.78291 39.4819 10.2774 39.4819 10.8872C39.4819 11.4971 39.9815 11.9916 40.5987 11.9916H50.6513C51.2685 11.9916 51.7681 11.4971 51.7681 10.8872Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M51.7681 15.3233C51.7681 14.7134 51.2685 14.2189 50.6513 14.2189H40.5987C39.9815 14.2189 39.4819 14.7134 39.4819 15.3233C39.4819 15.9332 39.9815 16.4276 40.5987 16.4276H50.6513C51.2685 16.4276 51.7681 15.9332 51.7681 15.3233Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="94" height="66" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
