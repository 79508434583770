import { useQuery } from "@tanstack/react-query";

import { getApplicants } from "@Http/applicants";
import { getManagers } from "@Http/users";

import { HttpQuery } from "@Types/http";
import { hasActionPermissions } from "@Utils/permissions";
import useCurrentUser from "./useCurrentUser";

const useChatSearch = (query: HttpQuery) => {
  const user = useCurrentUser();
  const userPermissions = hasActionPermissions("dealership_user", "read", user);
  const applicantsPermissions = hasActionPermissions("applicant", "read", user);

  const {
    data: applicants,
    isLoading: applicantsAreLoading,
    error: applicantsError,
  } = useQuery({
    queryKey: ["applicants-chat-search", query ?? {}],
    queryFn: getApplicants(query),
    enabled: !!Object.keys(query)?.length && applicantsPermissions,
  });
  const {
    data: users,
    isLoading: usersAreLoading,
    error: usersError,
  } = useQuery({
    queryKey: ["members-chat-search"],
    queryFn: getManagers(query),
    enabled: !!Object.keys(query)?.length && userPermissions,
  });

  return {
    data: { applicants, users },
    isLoading: { applicantsAreLoading, usersAreLoading },
    error: { applicantsError, usersError },
  };
};
export default useChatSearch;
