import mixpanel from "mixpanel-browser";

import Grid from "@Components/Grid";
import Paper from "@Components/Paper";
import BackButton from "@Components/BackButton";
import { useCreateTeam } from "@Hooks/useTeams";
import { CreateTeamRequest } from "@Types/teams";
import useCurrentUser from "@Hooks/useCurrentUser";

import { TeamForm } from "./TeamForm";
import styles from "./Team.module.scss";

const initialState: CreateTeamRequest = {
  data: {
    info: {
      name: "",
    },
  },
};
const CreateTeam = () => {
  const user = useCurrentUser();
  const { createTeam } = useCreateTeam();

  const handleSaveChanges = (
    e: React.FormEvent<HTMLFormElement>,
    state: CreateTeamRequest | undefined
  ) => {
    e.preventDefault();
    if (state) {
      createTeam(state);
      mixpanel.track("New team created", {
        email: user?.data?.info?.email,
        dealershipName: user?.data?.dealership?.data?.info?.name,
        name: state.data.info.name,
      });
    }
  };
  return (
    <div className={styles.root}>
      <Grid container xs={12}>
        <Grid xs={1}>
          <BackButton />
        </Grid>
        <Grid xs={12}>
          <Paper title="New Team" style={{ width: "100%" }}>
            <TeamForm<CreateTeamRequest>
              id="create-team"
              type="create"
              initialState={initialState}
              handleSubmit={handleSaveChanges}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateTeam;
