import { useState } from "react";
import { toast } from "react-toastify";

import Grid from "@Components/Grid";
import Button from "@Components/Button";

import { DateQuery } from "@Types/http";

import { DateFilterPreviewProps } from "./types";

import styles from "./Filters.module.scss";

const checkDateValidity = (from: Date, to: Date) =>
  from.getTime() < to.getTime();

type Range = {
  startDate: string;
  endDate: string;
};
const DateFilter = ({
  filterState,
  setFilterState,
  queryPath,
}: DateFilterPreviewProps) => {
  const [range, setRange] = useState(
    (filterState?.values as Range) ?? {
      startDate: "",
      endDate: "",
    }
  );

  const handleApplyFilter = () => {
    if (
      range?.startDate &&
      range?.endDate &&
      checkDateValidity(new Date(range.startDate), new Date(range.endDate))
    ) {
      const beginningOfTheDay = new Date(range?.startDate);
      beginningOfTheDay.setHours(0, 0, 0, 0);
      const endOfTheDay = new Date(range?.endDate);
      endOfTheDay.setHours(23, 59, 59, 999);
      setFilterState({
        query: {
          [queryPath.join(".")]: {
            $gte: beginningOfTheDay.toISOString(),
            $lte: endOfTheDay.toISOString(),
          },
        } as DateQuery,
        values: { endDate: range.endDate, startDate: range.startDate },
      });
    } else {
      handleRemoveFilters();
      toast.error("Invalid dates. Try again.", {
        position: "top-center",
      });
    }
  };

  const handleRemoveFilters = () => {
    setFilterState(undefined);
    setRange({
      startDate: "",
      endDate: "",
    });
  };

  return (
    <Grid data-testid={"date-filter"} container xs={12} spacing={1}>
      <Grid xs={6}>Start Date</Grid>
      <Grid xs={6}>End Date</Grid>
      <Grid xs={6}>
        <input
          data-testid={"start-date"}
          value={range.startDate?.toString()}
          onChange={(e) => {
            setRange((x) => ({ ...x, startDate: e.target.value }));
          }}
          name={"Start date"}
          type="date"
          className={styles.input}
        />
      </Grid>
      <Grid xs={6}>
        <input
          data-testid={"end-date"}
          value={range.endDate?.toString()}
          onChange={(e) => setRange((x) => ({ ...x, endDate: e.target.value }))}
          name={"End date"}
          type="date"
          className={styles.input}
        />
      </Grid>
      <Grid xs={6}>
        <Button
          dataTestId={"remove-filter"}
          style={{
            fontSize: "19.4861px",
          }}
          onClick={handleRemoveFilters}
        >
          Clear
        </Button>
      </Grid>
      <Grid xs={6}>
        <Button
          dataTestId={"apply-filter"}
          type="button"
          style={{
            fontSize: "19.4861px",
          }}
          variant="blue"
          onClick={() => handleApplyFilter()}
        >
          Apply Filter
        </Button>
      </Grid>
    </Grid>
  );
};

export default DateFilter;
