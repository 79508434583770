import { useState } from "react";
import { CiCircleRemove } from "react-icons/ci";

import Grid from "@Components/Grid";
import IconButton from "@Components/IconButton";
import TextField from "@Components/Inputs/TextField";
import { FilterPreviewProps } from "./types";

const ApplicantFilter = ({
  filterState,
  setFilterState,
}: FilterPreviewProps) => {
  const queryPath = [
    ["data", "applicant", "data", "info", "firstName"],
    ["data", "applicant", "data", "info", "lastName"],
    ["data", "coApplicant", "data", "info", "firstName"],
    ["data", "coApplicant", "data", "info", "lastName"],
  ];
  const [current, setCurrent] = useState("");
  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement> & {
      target: React.BaseSyntheticEvent<
        KeyboardEvent,
        EventTarget & HTMLInputElement,
        EventTarget
      > & { value: string };
    }
  ) => {
    if (e.keyCode === 13 && e?.target?.value?.trim() !== "") {
      const newValues = [...(filterState?.values ?? []), e.target.value];
      setFilterState({
        values: newValues,
        query: {
          ...(filterState?.query ?? {}),
          $or: [
            ...queryPath.map((childPath) => ({
              [childPath.join(".")]: {
                $in: newValues.map((value) => ({
                  value,
                  caseInsensitive: true,
                  partialSearch: true,
                })),
              },
            })),
          ],
        },
      });
      setCurrent("");
    }
  };

  const handleRemoveFilter = (value: string) => {
    const filteredState = filterState?.values?.filter(
      (x) => x !== value
    ) as string[];

    setFilterState({
      values: filteredState,
      query: {
        ...filterState?.query,
        $or: [
          ...queryPath.map((childPath) => ({
            [childPath.join(".")]: {
              $in: filteredState?.map((value) => ({
                value,
                caseInsensitive: true,
                partialSearch: true,
              })),
            },
          })),
        ],
      },
    });
  };
  return (
    <Grid container xs={12}>
      <Grid xs={12}>
        {(filterState?.values ?? []).map((value, index: number) => (
          <IconButton
            key={index}
            onClick={() => handleRemoveFilter(value)}
            flex
            style={{ fontSize: "16px" }}
            dataTestId={`applicant-filter-remove-button-${index}`}
          >
            {value}
            <CiCircleRemove size={18} />
          </IconButton>
        ))}
      </Grid>
      <TextField
        onKeyUp={handleKeyUp}
        onChange={(e) => setCurrent(e.target.value)}
        placeholder="Applicant"
        value={current}
        name="Email"
        required
        id={"applicant-filter-input"}
        type="email"
      />
    </Grid>
  );
};

export default ApplicantFilter;
