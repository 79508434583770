import { TbSearch } from "react-icons/tb";

import Grid from "@Components/Grid";
import TextField from "@Components/Inputs/TextField";
import IconButton from "@Components/IconButton";

import { TradeIn as TradeInType } from "@Types/deal";

import { useVinLookup } from "@Hooks/useVinLookup";

import styles from "./TradeIn.module.scss";

const TradeIn = ({
  state,
  setState,
}: {
  state: TradeInType | undefined;
  setState: (v: React.SetStateAction<TradeInType | undefined>) => void;
}) => {
  const { lookupVIN } = useVinLookup<TradeInType | undefined>(setState);
  const handleLookupVin = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (state?.VIN) {
      lookupVIN(state.VIN);
    }
  };
  return (
    <Grid container className={styles.container} spacing={2}>
      <Grid xs={12}>
        <p>Trade-In</p>
      </Grid>
      <Grid xs={8}>
        <div className={styles.vinContainer}>
          <TextField
            onChange={(e) =>
              setState((prev) => ({ ...prev, VIN: e.target.value }))
            }
            placeholder="VIN"
            value={state?.VIN ?? ""}
            name="VIN"
            type="text"
            id="VIN"
          />
          <IconButton
            onClick={(e) => handleLookupVin(e)}
            disabled={typeof state?.VIN !== "string" && state?.VIN}
          >
            <TbSearch size={20} />
          </IconButton>
        </div>
      </Grid>
      <Grid xs={4}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({
              ...(prev ?? {}),
              year: Number(e.target.value),
            }))
          }
          placeholder="Year"
          value={state?.year ?? undefined}
          name="Year"
          type="number"
          id="trade-Year"
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({ ...(prev ?? {}), make: e.target.value }))
          }
          placeholder="Make"
          value={state?.make ?? undefined}
          name="Make"
          type="text"
          id="trade-Make"
        />
      </Grid>

      <Grid xs={4}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({ ...(prev ?? {}), model: e.target.value }))
          }
          placeholder="Model"
          value={state?.model ?? undefined}
          name="Model"
          type="text"
          id="trade-Model"
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({
              ...(prev ?? {}),
              odometer: Number(e.target.value),
            }))
          }
          placeholder="Miles"
          value={state?.odometer ?? undefined}
          name="Miles"
          id="trade-Miles"
          type="number"
        />
      </Grid>
    </Grid>
  );
};

export default TradeIn;
