type CircularProgressBarProps = {
  progress: number;
  className?: string;
};

const CircularProgressBar = ({
  progress,
  className,
}: CircularProgressBarProps) => {
  const size = 80;
  const strokeWidth = 5;
  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
    <svg
      height={size}
      width={size}
      viewBox={viewBox}
      className={className}
      data-testid={"progress-bar"}
    >
      <circle
        fill="none"
        stroke="#e7eeff"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        fill="none"
        stroke={"#6b97f6"}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        strokeDasharray={[dash, circumference - dash] as unknown as string}
        strokeLinecap="round"
        style={{ transition: `all ${progress ? "0.5s" : "0.05s"}` }}
      />
      {progress !== 0 && progress !== 100 ? (
        <text
          data-testid={"progress-bar-text"}
          fill="black"
          fontSize="20px"
          x="50%"
          y="50%"
          dy="20px"
          textAnchor="middle"
        >
          {`${progress}%`}
        </text>
      ) : (
        <path
          x="50%"
          y="50%"
          dy="20px"
          transform={`translate(${size / 4}, ${size / 5})`}
          d="M20.7678 0.732231C19.7915 -0.244076 18.2085 -0.244076 17.2322 0.732231L1.32233 16.6421C0.346018 17.6184 0.346018 19.2014 1.32233 20.1777C2.29864 21.154 3.88155 21.154 4.85786 20.1777L19 6.03553L33.1421 20.1777C34.1184 21.154 35.7014 21.154 36.6777 20.1777C37.654 19.2014 37.654 17.6184 36.6777 16.6421L20.7678 0.732231ZM21.5 49.5L21.5 2.5L16.5 2.5L16.5 49.5L21.5 49.5Z"
          fill="#6B97F6"
        />
      )}
    </svg>
  );
};

export default CircularProgressBar;
