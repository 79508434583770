import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getRole,
  getRoles,
  updateRole,
  createRole,
  deleteRole,
} from "@Http/roles";
import { HttpQuery } from "@Types/http";
import { MutationError } from "@Types/index";
import { CreateRoleRequest, Role } from "@Types/roles";

const useGetRoles = (query?: HttpQuery) => {
  const queryKey = ["roles", query ?? []];
  const { data, isLoading, error } = useQuery<Role[], MutationError, Role[]>({
    queryKey: queryKey,
    queryFn: getRoles(query),
  });

  return {
    data,
    isLoading,
    error,
  };
};

export const useGetRole = (id: string) => {
  const queryKey = [`role-${id}`];
  const {
    data: role,
    isLoading,
    error,
    isSuccess,
  } = useQuery({
    queryKey: queryKey,
    queryFn: getRole(id),
  });

  return {
    isSuccess,
    role,
    isLoading,
    error,
  };
};

export const useDeleteRole = () => {
  const navigate = useNavigate();

  const mutation = useMutation<Role, MutationError, Role>(deleteRole(), {
    onSuccess: () => {
      navigate("/roles", { replace: true });

      toast.success("Successfully deleted a role.", {
        position: "top-center",
      });
    },
  });
  return {
    deleteRole: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useUpdateRole = () => {
  const mutation = useMutation<Role, MutationError, Role>(updateRole(), {
    onSuccess: () => {
      toast.success("Successfully updated an role.", {
        position: "top-center",
      });
    },
  });
  return {
    updateRole: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useCreateRole = () => {
  const navigate = useNavigate();
  const mutation = useMutation<Role, MutationError, CreateRoleRequest>(
    createRole(),
    {
      onSuccess: (data) => {
        toast.success("Successfully created an role.", {
          position: "top-center",
        });
        navigate(`/roles/${data._id}`, { replace: true });
      },
    }
  );
  return {
    creatRole: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useGetRoles;
