import axios from "axios";

import { auth } from "@Firebase/firebase";
import { Lender } from "@Types/lenders";
import { HttpQuery } from "@Types/http";

export const getLenders = (query?: HttpQuery): (() => Promise<Lender[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/lender`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );
    return response.data.result.docs;
  };
};
export const getLenderEstFundedDate = (
  id?: string
): (() => Promise<string>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      id
        ? `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/lender_est_funded_date/${id}`
        : `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/lender_est_funded_date`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.result;
  };
};
