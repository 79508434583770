import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  acceptTermsAndConditions,
  createUser,
  deleteUser,
  disableUser,
  getManagers,
  getUser,
  getUsers,
  resendRegistrationLink,
  updateUser,
} from "@Http/users";

import { HttpQuery } from "@Types/http";
import { MutationError } from "@Types/index";
import { CreateUserRequest, User } from "@Types/users";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../App";

const useGetMembers = (query?: HttpQuery) => {
  const queryKey = ["members", query ?? []];

  const { data, isLoading, error } = useQuery<User[], MutationError, User[]>({
    queryKey: queryKey,
    queryFn: getUsers(query),
  });

  return {
    data,
    isLoading,
    error,
  };
};
export const useGetManagers = (query?: HttpQuery) => {
  const queryKey = ["members-managers", query ?? []];

  const { data, isLoading, error } = useQuery<User[], MutationError, User[]>({
    queryKey: queryKey,
    queryFn: getManagers(query),
  });

  return {
    data,
    isLoading,
    error,
  };
};
export const useGetMember = (id: string) => {
  const queryKey = [`member-${id}`];

  const {
    data: user,
    isLoading,
    error,
    isSuccess,
  } = useQuery({
    queryKey: queryKey,
    queryFn: getUser(id),
  });

  return {
    isSuccess,
    user,
    isLoading,
    error,
  };
};

export const useDeleteMember = () => {
  const navigate = useNavigate();

  const mutation = useMutation<User, MutationError, User>(deleteUser(), {
    onSuccess: () => {
      navigate("/members", { replace: true });

      toast.success("Successfully deleted an user.", {
        position: "top-center",
      });
    },
  });
  return {
    deleteUser: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useDisableMember = () => {
  const mutation = useMutation<User, MutationError, User>(disableUser(), {
    onSuccess: (user) => {
      queryClient.setQueryData<User>([`member-${user._id}`], () => user);
      toast.success(
        `Successfully ${
          user.data.firebaseDisabled ? "disabled" : "enabled"
        } a user.`,
        {
          position: "top-center",
        }
      );
    },
  });
  return {
    disableUser: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useUpdateMember = (params?: {
  isDashboardUpdateOnly?: boolean;
}) => {
  const mutation = useMutation<User, MutationError, User>(updateUser(), {
    onSuccess: () => {
      if (params?.isDashboardUpdateOnly) {
        toast.success("Successfully updated the dashboard settings.", {
          position: "top-center",
        });
      } else {
        toast.success("Successfully updated an user.", {
          position: "top-center",
        });
      }
    },
  });
  return {
    updateUser: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useCreateMember = () => {
  const navigate = useNavigate();
  const mutation = useMutation<User, MutationError, CreateUserRequest>(
    createUser(),
    {
      onSuccess: (data) => {
        toast.success("Successfully created an user.", {
          position: "top-center",
        });
        navigate(`/members/${data._id}`, { replace: true });
      },
    }
  );
  return {
    createUser: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};
export const useResendMemberLink = () => {
  const mutation = useMutation<User, MutationError, string>(
    resendRegistrationLink(),
    {
      onSuccess: () => {
        toast.success("Successfully sent a registration link.", {
          position: "top-center",
        });
      },
    }
  );
  return {
    sendLink: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useAcceptTermsAndConditions = () => {
  const mutation = useMutation<User, MutationError>(
    acceptTermsAndConditions(),
    {
      onSuccess: (data) => {
        toast.success("Successfully accepted terms and conditions.", {
          position: "top-center",
        });
        queryClient.setQueriesData<User>(["current-user"], () => {
          return data;
        });
      },
    }
  );
  return {
    accept: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useGetMembers;
