import axios from "axios";

import { auth } from "@Firebase/firebase";
import {
  CreateDealershipTicketNoteRequest,
  DealershipTicketNote,
} from "@Types/dealership_ticket_notes";

export const getDealershipTicketNotes =
  (dealershipTicketId: string): (() => Promise<DealershipTicketNote[]>) =>
  async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_ticket_note/${dealershipTicketId}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result?.docs ?? [];
  };

export const createDealershipTicketNote =
  (): ((
    dealershipTicketNote: CreateDealershipTicketNoteRequest
  ) => Promise<DealershipTicketNote>) =>
  async (dealershipTicketNote) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_ticket_note/`,
      dealershipTicketNote,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.result;
  };
