import { useParams } from "react-router-dom";

import Grid from "@Components/Grid";
import BackButton from "@Components/BackButton";
import Paper from "@Components/Paper";
import { useGetApplicant } from "@Hooks/useApplicants";
import ApplicantViewForm from "./ApplicantViewForm";
import styles from "./Applicants.module.scss";

const Applicant = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) return <>Error</>;

  const { applicant } = useGetApplicant(id);
  if (!applicant) return <>Loading...</>;
  return applicant ? (
    <div className={styles.root}>
      <Grid container xs={12}>
        <Grid xs={1.5}>
          <BackButton />
        </Grid>
        <Grid xs={12}>
          <Paper
            title="Applicant Information"
            style={{ background: "#ffffff" }}
          >
            <div style={{ marginTop: "40px" }}>
              <ApplicantViewForm
                applicant={applicant?.data?.info}
                variant="blue"
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  ) : null;
};

export default Applicant;
