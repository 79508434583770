import { useMutation } from "@tanstack/react-query";

import { MutationError } from "@Types/index";
import { createDealershipTicketNote } from "@Http/dealership_ticket_notes";
import {
  CreateDealershipTicketNoteRequest,
  DealershipTicketNote,
} from "@Types/dealership_ticket_notes";

export const useCreateDealershipTicketNote = () => {
  const mutation = useMutation<
    DealershipTicketNote,
    MutationError,
    CreateDealershipTicketNoteRequest
  >(createDealershipTicketNote());
  return {
    createDealershipTicketNote: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useCreateDealershipTicketNote;
