import { useEffect } from "react";

const useRedirect = () => {
  useEffect(() => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    const shouldRedirect = isAndroid || isIOS;

    if (shouldRedirect) {
      const url = isAndroid
        ? "https://play.google.com/store/apps/details?id=org.web.finance.direct.dealership.mobile"
        : "https://apps.apple.com/us/app/wfd-dealer-app/id6464062656";
      window.location.href = url;
    }
  }, []);
};

export default useRedirect;
