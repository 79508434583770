import { Navigate, Outlet, useLocation } from "react-router-dom";

import useCurrentUser from "@Hooks/useCurrentUser";

function RequireOwnerPermissions() {
  const location = useLocation();
  const user = useCurrentUser();
  if (user && user.data.info.userType !== "owner") {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return <Outlet />;
}

export default RequireOwnerPermissions;
