import { AiOutlineCheckCircle } from "react-icons/ai";

import { default as ApplicantInformationIcon } from "../../CreateApplication/svg/ApplicantInformation";
import SendApplication from "../../CreateApplication/svg/SendApplication";

import Button from "@Components/Button";
import Grid from "@Components/Grid";
import ApplicantInformation from "../../CreateApplication/ApplicantInformation";
import ReviewApplication from "../../CreateApplication/ReviewApplication";

import useCurrentUser from "@Hooks/useCurrentUser";

import { default as mixpanel } from "mixpanel-browser";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { default as styles } from "../../CreateApplication/CreateApplication.module.scss";
import { initialApplicantState } from "@Types/applicants";
import { useAddCoApplicantToDeal } from "@Hooks/useApplicants";
import Two from "@Pages/Applications/CreateApplication/svg/Two";
import One from "@Pages/Applications/CreateApplication/svg/One";

type ActionProps = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  max: number;
  saved?: boolean;
  nextButtonText?: string;
};
export const Actions = ({
  step,
  setStep,
  max,
  saved,
  nextButtonText = "Next",
}: ActionProps) => {
  return (
    <Grid container xs={12} className={styles.buttonContainer}>
      {step > 1 && (
        <Button
          variant="white"
          onClick={() => {
            setStep(step - 1);
          }}
        >
          Back
        </Button>
      )}

      {step === max && (
        <Button
          disabled={saved}
          id="send-create-application"
          form="add-co-applicant-to-deal"
          type="submit"
        >
          Send
        </Button>
      )}
      {step < max && (
        <Button
          id="next-button"
          onClick={(e) => {
            const form = document.getElementById(
              "add-co-applicant-to-deal"
            ) as HTMLFormElement;

            if (form.checkValidity()) {
              e.preventDefault();
              e.stopPropagation();
              setStep(step + 1);
            }
          }}
        >
          {nextButtonText}
        </Button>
      )}
    </Grid>
  );
};

const renderDoneIcon = (currentStep: number, step: number) => {
  return currentStep > step ? (
    <div className={styles.completedIcon}>
      <AiOutlineCheckCircle size={50} />
    </div>
  ) : null;
};

const CoApplicantToDeal = () => {
  const { state } = useLocation();
  const user = useCurrentUser();
  const [coApplicant, setCoApplicant] = useState(initialApplicantState);
  const [currentStep, setCurrentStep] = useState(1);
  const { addCoApplicantToDeal } = useAddCoApplicantToDeal();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (currentStep === 2) {
      mixpanel.track("Co-Applicant added to deal", {
        email: user?.data?.info?.email,
        dealershipName: user?.data?.dealership?.data?.info?.name,
      });
      addCoApplicantToDeal({
        applicant: {
          ...coApplicant,
          birthDate: coApplicant.birthDate
            ? new Date(coApplicant.birthDate ?? "").toISOString()
            : null,
          driverLicenseExpires: coApplicant.driverLicenseExpires
            ? new Date(coApplicant.driverLicenseExpires ?? "").toISOString()
            : null,
          driverLicenseIssued: coApplicant.driverLicenseIssued
            ? new Date(coApplicant.driverLicenseIssued ?? "").toISOString()
            : null,
          socialSecurityNumber: coApplicant.socialSecurityNumber.replace(
            /[- ]/g,
            ""
          ),
          annualIncome: coApplicant?.monthlyIncome
            ? coApplicant.monthlyIncome * 12
            : null,
        },
        dealId: state.dealId,
      });
    }
  };

  return (
    <form id="add-co-applicant-to-deal" onSubmit={handleSubmit}>
      <Grid container className={styles.mainContainer}>
        <h2 className={styles.title}>Add Co-applicant</h2>
        <Grid className={styles.container} container xs={12}>
          <Grid
            container
            xs={12}
            className={
              styles.stepTitle +
              (currentStep === 1
                ? ` ${styles.active}`
                : currentStep > 1
                ? ` ${styles.completed}`
                : ` ${styles.inactive}`)
            }
          >
            <One />
            <ApplicantInformationIcon />
            <p>Co-Applicant information</p>
            {renderDoneIcon(currentStep, 1)}
          </Grid>
          {currentStep === 1 && (
            <Grid
              container
              xs={12}
              id="applicant"
              className={styles.stepContainer}
            >
              <Grid xs={12} id="applicant">
                <ApplicantInformation
                  type="coApplicant"
                  title="Co-Applicant"
                  mainApplicantState={state.applicant}
                  state={coApplicant}
                  setState={setCoApplicant}
                />
              </Grid>
              <Grid xs={12} className={styles.actions}>
                <Actions step={currentStep} max={2} setStep={setCurrentStep} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid className={styles.container} container xs={12}>
          <Grid
            container
            xs={12}
            className={
              styles.stepTitle +
              (currentStep === 2 ? ` ${styles.active}` : ` ${styles.inactive}`)
            }
          >
            <Two />
            <SendApplication />
            <p> Review Application </p>
          </Grid>
          {currentStep === 2 && (
            <Grid container xs={12} className={styles.stepContainer}>
              <ReviewApplication
                applicant={state.applicant}
                coApplicant={coApplicant}
                collateral={state.collateral}
              />
              <Grid xs={12} className={styles.actions}>
                <Actions step={currentStep} max={2} setStep={setCurrentStep} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default CoApplicantToDeal;
