import { uploadStipulation } from "@Http/stipulations";
import { MutationError } from "@Types/index";
import { Stipulation } from "@Types/stipulations";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useUploadStipulation = (
  stipulationSetterCb: React.Dispatch<React.SetStateAction<Stipulation[]>>
) => {
  const mutation = useMutation<
    Stipulation,
    MutationError,
    Stipulation & { file: File }
  >(uploadStipulation(), {
    onSuccess: (data) => {
      stipulationSetterCb((prevStips) =>
        prevStips.map((stip) => (stip._id === data._id ? data : stip))
      );
      toast.success("Successfully uploaded a stipulation.", {
        position: "top-center",
      });
    },
  });
  return {
    uploadStipulation: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};
