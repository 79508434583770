import { ChatSwitch, NotificationSwitch } from "@Components/Switch/Switch";
import { useBrowserNotifications } from "@Hooks/useBrowserNotifications";
import useGetChannels from "@Hooks/useChannels";
import { useChangeChatStatus, useGetChatMember } from "@Hooks/useChatMembers";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useStickyState } from "@Hooks/useStickyState";
import { Channel } from "@Types/channels";
import { ChatMember } from "@Types/chat_members";
import React, { useState } from "react";
import { BsChatDotsFill } from "react-icons/bs";
import { queryClient } from "../../App";
import ApplicantsChat from "./ApplicantsChat";
import styles from "./Chat.module.scss";
import ChatSearch from "./ChatSearch";
import OpenedChat from "./OpenedChat";
import WFDChat from "./WFDChat";

export const ChatMenu = ({
  onClick,
  containerClassName,
  channels,
}: {
  containerClassName?: string;
  onClick: (index: string) => void;
  channels?: Channel[];
}) => {
  const currentUser = useCurrentUser();
  const { requestBrowserNotificationPermissions } = useBrowserNotifications();

  const [chatNotificationsEnabled, setChatNotificationsEnabled] =
    useStickyState(false, "chatNotificationsEnabled");

  const { data: chatMember } = useGetChatMember(
    currentUser?.data.info.email as string,
    "dealership_user",
    currentUser?._id ? true : false
  );

  const { changeChatStatus } = useChangeChatStatus();

  const handleToggleNotifications = () => {
    if (!chatNotificationsEnabled) requestBrowserNotificationPermissions();
    setChatNotificationsEnabled(!chatNotificationsEnabled);
  };

  const handleToggleSwitch = () => {
    changeChatStatus(chatMember?._id as string);
    queryClient.setQueriesData<ChatMember>(["chat_member"], (oldChatMember) => {
      if (oldChatMember) {
        return {
          ...oldChatMember,
          data: {
            ...(oldChatMember?.data ?? {}),
            info: {
              ...(oldChatMember?.data?.info ?? {}),
              status:
                oldChatMember?.data?.info?.status === "active"
                  ? "away"
                  : "active",
            },
          },
        };
      }
    });
  };
  return (
    <div
      className={
        containerClassName
          ? `${styles[containerClassName]}`
          : `${styles.chatContainer}`
      }
    >
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <BsChatDotsFill size={25} color="#6B97F6" />
          <p className={styles.chatTitle}>chat</p>
        </div>
        <ChatSwitch
          isActive={chatMember?.data.info.status === "active"}
          toggleFunction={handleToggleSwitch}
        />
        <NotificationSwitch
          isActive={chatNotificationsEnabled}
          toggleFunction={handleToggleNotifications}
        />
      </div>

      <div className={styles.searchContainer}>
        <ChatSearch />
      </div>
      <div className={styles.sectionsContainer}>
        <WFDChat
          onClick={onClick}
          channels={(channels ?? [])?.filter(
            (channel) => channel.data.info.type === "user"
          )}
        />
        <div className={styles.divider}></div>
        <ApplicantsChat
          onClick={onClick}
          channels={(channels ?? [])?.filter(
            (channel) => channel.data.info.type === "applicant"
          )}
        />
      </div>
    </div>
  );
};

const ChatMessages = ({
  setOpen,
  selectedChannel,
}: {
  selectedChannel?: Channel;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className={`${styles.chatContainer} ${styles.openChatBackground}`}>
      <OpenedChat setOpen={setOpen} selectedChannel={selectedChannel} />
    </div>
  );
};
const Chat = () => {
  const [open, setOpen] = useState(false);
  const [selectedChannelId, setSelectedChannelId] = useStickyState<
    string | undefined
  >(undefined, "channelId");
  const user = useCurrentUser();
  const { data: channels } = useGetChannels(
    user?.data?.info?.email,
    undefined,
    user?._id ? true : false
  );
  const visibleChannels = (channels ?? [])?.filter(
    (channel) => !channel.data.info.hidden
  );
  return (
    <div>
      {open && typeof selectedChannelId === "string" ? (
        <ChatMessages
          selectedChannel={visibleChannels.find(
            (visiblechannel) => visiblechannel._id === selectedChannelId
          )}
          setOpen={setOpen}
        />
      ) : (
        <ChatMenu
          onClick={(id) => {
            setOpen(true);
            setSelectedChannelId(id);
          }}
          channels={visibleChannels}
        />
      )}
    </div>
  );
};

export default Chat;
