import Grid from "@Components/Grid";
import Paper from "@Components/Paper";
import useCurrentUser from "@Hooks/useCurrentUser";
import { renderFieldData } from "@Pages/Applications/CreateApplication/ReviewApplication/ReviewApplication";
import { Collateral as CollateralType, CollateralTypes } from "@Types/deal";
import { formatPrice } from "@Utils/functions";
import { hasSectionPermissions } from "@Utils/permissions";

type CollateralProps = {
  collateral: CollateralType;
  type: CollateralTypes;
};

const Collateral = ({ collateral, type }: CollateralProps) => {
  const currentUser = useCurrentUser();
  if (!hasSectionPermissions("deal", "collateral", currentUser)) return null;

  return (
    <Paper
      title={"Collateral"}
      style={{ height: "100%", boxSizing: "border-box" }}
    >
      <Grid container xs={12}>
        <Grid container xs={6}>
          <Grid xs={12}>{renderFieldData("VIN", collateral?.VIN ?? "-")}</Grid>
          <Grid xs={12}>
            {renderFieldData("Year", collateral?.year?.toString() ?? "-")}
          </Grid>
          <Grid xs={12}>
            {renderFieldData("Make", collateral?.make ?? "-")}
          </Grid>
          <Grid xs={12}>
            {renderFieldData("Model", collateral?.model ?? "-")}
          </Grid>
          <Grid xs={12}>
            {renderFieldData(
              "Odometer",
              collateral?.odometer?.toString() ?? "-"
            )}
          </Grid>
        </Grid>
        <Grid container xs={6}>
          <Grid xs={12}>
            {renderFieldData("Unit Status", collateral?.unitStatus ?? "-")}
          </Grid>
          <Grid xs={12}>{renderFieldData("Collateral Type", type)}</Grid>
          <Grid xs={12}>
            {renderFieldData("Unit Type", collateral?.unitType ?? "-")}
          </Grid>
          <Grid xs={12}>
            {renderFieldData(
              "Invoice",
              formatPrice(collateral?.invoice) ?? "-"
            )}
          </Grid>
          <Grid xs={12}>
            {renderFieldData(
              collateral?.unitStatus === "used" ? "NADA Clean Retail" : "MSRP",
              formatPrice(collateral?.MSRP) ?? "-"
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default Collateral;
