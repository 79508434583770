import {
  useInfiniteQuery,
  useMutation,
  InfiniteData,
} from "@tanstack/react-query";

import { MutationError } from "@Types/index";
import { HttpQuery, PaginatedResult } from "@Types/http";
import { DealershipNotification } from "@Types/dealership_notifications";
import {
  deleteAllDealershipNotifications,
  deleteDealershipNotification,
  getDealershipNotifications,
  updateDealershipNotification,
} from "@Http/dealership_notifications";
import { queryClient } from "../App";

const useGetDealershipNotifications = (query?: HttpQuery) => {
  const queryKey = ["dealership_notifications"];

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery<
    PaginatedResult<DealershipNotification[]>,
    MutationError,
    PaginatedResult<DealershipNotification[]>
  >({
    queryKey: queryKey,
    queryFn: getDealershipNotifications(query),
    getNextPageParam: (lastPage) => {
      return lastPage?.nextPage || undefined;
    },
  });

  return {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  };
};
export const useDeleteDealershipNotification = () => {
  const mutation = useMutation<
    DealershipNotification,
    MutationError,
    DealershipNotification
  >(deleteDealershipNotification(), {
    onSuccess: (deletedNotification) => {
      queryClient.setQueriesData<
        InfiniteData<PaginatedResult<DealershipNotification[]>>
      >(["dealership_notifications"], (oldData) => {
        const newPagesArray = oldData?.pages.map((page) => {
          const realPageType = page as unknown as PaginatedResult<
            DealershipNotification[]
          >;
          return {
            ...realPageType,
            docs: realPageType.docs.filter(
              (val) => val._id !== deletedNotification._id
            ),
          };
        });
        return {
          pages: newPagesArray,
          pageParams: oldData?.pageParams,
        } as InfiniteData<PaginatedResult<DealershipNotification[]>>;
      });
    },
  });
  return {
    deleteDealershipNotification: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};
export const useDeleteAllDealershipNotifications = () => {
  const mutation = useMutation<string, MutationError, void>(
    deleteAllDealershipNotifications(),
    {
      onSuccess: () => {
        queryClient.setQueriesData<
          InfiniteData<PaginatedResult<DealershipNotification[]>>
        >(["dealership_notifications"], () => {
          return {
            pages: [],
            pageParams: [],
          };
        });
      },
    }
  );
  return {
    deleteAllDealershipNotifications: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useUpdateDealershipNotification = () => {
  const mutation = useMutation<
    DealershipNotification,
    MutationError,
    DealershipNotification
  >(updateDealershipNotification(), {
    onSuccess: (entry) => {
      queryClient.setQueriesData<
        InfiniteData<PaginatedResult<DealershipNotification[]>>
      >(["dealership_notifications"], (oldData) => {
        const newPagesArray = oldData?.pages.map((page) => {
          const realPageType = page as unknown as PaginatedResult<
            DealershipNotification[]
          >;
          const foundIndex = (realPageType.docs ?? [])?.findIndex(
            (oldEntry) => oldEntry?._id === entry?._id
          );
          return {
            ...realPageType,
            docs: realPageType.docs.map((x, index) =>
              index === foundIndex ? entry : x
            ),
          };
        });
        return {
          pages: newPagesArray,
          pageParams: oldData?.pageParams,
        } as InfiniteData<PaginatedResult<DealershipNotification[]>>;
      });
    },
  });
  return {
    updateDealershipNotification: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useGetDealershipNotifications;
