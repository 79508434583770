import { AiFillCheckCircle } from "react-icons/ai";

import Circle from "@Components/Stepper/Circle";
import { Line } from "@Components/Stepper/Stepper";
import { TitleIssueStatus } from "@Types/title_issues";

import styles from "./TitleIssues.module.scss";

const steps = [
  { title: "Upload title documents", value: 1 },
  { title: "Verification", value: 2 },
  { title: "Documents verified", value: 3 },
];
export default function TitleIssueStepper({
  oldTitleStatus,
  newTitleStatus,
  receiptStatus,
}: {
  oldTitleStatus: TitleIssueStatus;
  newTitleStatus: TitleIssueStatus;
  receiptStatus: TitleIssueStatus;
}) {
  const currentStep =
    oldTitleStatus === "awaiting" &&
    newTitleStatus === "awaiting" &&
    receiptStatus === "awaiting"
      ? 1
      : oldTitleStatus === "resolved" &&
        newTitleStatus === "resolved" &&
        receiptStatus === "resolved"
      ? 3
      : 2;

  return (
    <div className={styles.stepperContainer}>
      {steps.map((step, index) => (
        <div key={index} className={styles.stepperItem}>
          <div className={styles.stepIcon}>
            {step.value < currentStep ? (
              <AiFillCheckCircle size={51.95} color={"#6B97F6"} />
            ) : step.value > currentStep ? (
              <Circle size={51.95} color={"#D9D9D9"} text={`${step.value}`} />
            ) : currentStep === steps.length ? (
              <AiFillCheckCircle size={51.95} color={"#17DB71"} />
            ) : (
              <Circle size={51.95} color={"#6B97F6"} text={`${step.value}`} />
            )}
            <h3 className={styles.stepTitle}>{step.title}</h3>
          </div>

          <div className={styles.line}>
            {step.value === steps.length ? null : (
              <Line dashed={currentStep <= step.value} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
