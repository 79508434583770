import Button from "@Components/Button";
import Dialog from "@Components/Dialog";
import { DialogActions } from "@Components/Dialog/DialogActions";
import { DialogContent } from "@Components/Dialog/DialogContent";
import Select from "@Components/Select";
import useCurrentUser from "@Hooks/useCurrentUser";
import useStatusChange from "@Hooks/useStatusChange";
import { Deal, DealStatus, ReasonsDead } from "@Types/deal";
import Grid from "@mui/system/Unstable_Grid/Grid";
import mixpanel from "mixpanel-browser";
import { Fragment, useState } from "react";
import { FcCancel } from "react-icons/fc";

type Props = {
  deal: Deal;
  requestedStatus: DealStatus.Dead | DealStatus.DidNotProcess;
};

export default ({ deal, requestedStatus }: Props) => {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState<ReasonsDead | undefined>(undefined);
  const { statusChange } = useStatusChange();

  const user = useCurrentUser();
  const handleGoToDidNotProcess = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (reason) {
      statusChange({
        dealId: deal._id,
        reason,
        status: requestedStatus,
      });
      mixpanel.track(requestedStatus, {
        email: user?.data?.info?.email,
        dealershipName: user?.data?.dealership?.data?.info?.name,
        dealId: deal._id,
      });
      setOpen(false);
    }
  };
  return (
    <Fragment>
      <div style={{ position: "relative" }}>
        <Button
          style={{
            fontSize: "12px",
            background: "white",
            color: "rgb(213,0,0)",
          }}
          onClick={() => setOpen(true)}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            Mark the deal as &quot;Did not move forward&quot;
            <FcCancel size="20px" style={{ marginLeft: 5 }} />
          </div>
        </Button>
      </div>
      <Dialog
        titleColor="blue"
        id="change-status"
        open={open}
        title='Mark deal as "Did not move forward"'
        closeIcon
        size="lg"
        closeFn={() => {
          setOpen(false);
        }}
      >
        <DialogContent>
          <Grid
            container
            xs={12}
            spacing={2}
            style={{ boxSizing: "border-box" }}
          >
            <Grid xs={12}>
              <p style={{ fontSize: "16px" }}>
                Select a reason to why the deal did not move forward.
              </p>
            </Grid>
            <Grid xs={12}>
              <form
                id="change-status-did-not-process"
                onSubmit={handleGoToDidNotProcess}
              >
                <Select
                  id="select-status-reason"
                  name="Reason"
                  label="Select reason"
                  required
                  inputStyle={{ border: "1px solid #f3f3f3" }}
                  value={reason}
                  onChange={(e) => setReason(e.target.value as ReasonsDead)}
                  options={Object.values(ReasonsDead)?.map((reason) => ({
                    value: reason,
                    label: reason,
                  }))}
                />
              </form>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="blue" onClick={() => setOpen(false)}>
            Close
          </Button>
          <Button
            form="change-status-did-not-process"
            variant="blue"
            type="submit"
            disabled={!reason}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
