import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";

import { MutationError } from "@Types/index";
import { createDealerNote } from "@Http/dealer_notes";
import { CreateDealerNoteRequest, DealerNote } from "@Types/dealer_notes";

export const useCreateNote = () => {
  const mutation = useMutation<
    DealerNote,
    MutationError,
    CreateDealerNoteRequest
  >(createDealerNote(), {
    onSuccess: () => {
      toast.success("Successfully created a note.", {
        position: "top-center",
      });
    },
  });
  return {
    createNote: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};
