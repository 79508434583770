import { useState, useRef, Fragment } from "react";
import { IoDocumentOutline, IoEyeOutline } from "react-icons/io5";
import { IoIosWarning } from "react-icons/io";
import Grid from "@Components/Grid";
import { Deal } from "@Types/deal";

import IFrameURLModal from "../PreviewDocuments/IFrameURLModal";

import styles from "./UploadStipulations.module.scss";
import Button from "@Components/Button";
import mixpanel from "mixpanel-browser";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useUploadStipulation } from "@Hooks/useStipulations";
import Loading from "@Components/Loading";
import { Stipulation } from "@Types/stipulations";
import Tooltip from "@Components/Tooltip";
import { hasSectionPermissions } from "@Utils/permissions";

type UploadStipulationsProps = {
  deal: Deal;
};
const UploadStipulations = ({ deal }: UploadStipulationsProps) => {
  const currentUser = useCurrentUser();
  if (!hasSectionPermissions("deal", "documents", currentUser)) return null;

  const [stipulations, setStipulations] = useState<Stipulation[]>(
    deal.data.stipulations ?? []
  );
  const { uploadStipulation, loading } = useUploadStipulation(setStipulations);
  const [stipulationForDialog, setStipulationForDialog] = useState<
    Stipulation | undefined
  >(undefined);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    stipulation: Stipulation
  ) => {
    if (e?.target?.files && e?.target?.files?.length) {
      handleUploadStipulation(e.target.files[0], stipulation);
    }
  };
  const user = useCurrentUser();
  const handleUploadStipulation = (file: File, stipulation: Stipulation) => {
    uploadStipulation({
      ...stipulation,
      file,
    });
    mixpanel.track("Stipulation upload", {
      email: user?.data?.info?.email,
      dealershipName: user?.data?.dealership?.data?.info?.name,
    });
  };
  return (
    <>
      <Grid container xs={12}>
        <div className={styles.container} id="uploadStipulations" tabIndex={-1}>
          <Grid xs={12}>
            <p className={styles.title}>Upload Stipulations</p>
          </Grid>
          <Grid xs={12} className={styles.docs}>
            <Grid container xs={12}>
              {(stipulations ?? [])?.map((stipulation, index) => (
                <Grid
                  key={index}
                  container
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                  className={styles.item}
                >
                  {!stipulation?.data?.info?.url && (
                    <Grid xs={2}>
                      <Tooltip
                        content={"Please upload the stipulation!"}
                        tooltipStyle={{
                          background: "#fff",
                          color: "#000",
                          padding: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        <IoIosWarning size={40} color={"#ffcc00"} />
                      </Tooltip>
                    </Grid>
                  )}
                  <Grid xs={2}>
                    <IoDocumentOutline
                      size={40}
                      color={
                        stipulation?.data?.info?.url ? "#6B97F6" : "#A5AEC1"
                      }
                    />
                  </Grid>
                  <Grid
                    xs={!stipulation?.data?.info?.url ? 8 : 10}
                    container
                    alignItems="center"
                  >
                    <Grid xs={!stipulation?.data?.info?.url ? 8 : 12} container>
                      <Grid xs={stipulation?.data?.info?.url ? 10 : 12}>
                        <span className={styles.documentName}>
                          {stipulation?.data?.info?.description}
                        </span>
                      </Grid>

                      {stipulation?.data?.info?.url && (
                        <Grid xs={2}>
                          <IoEyeOutline
                            size={20}
                            onClick={() => setStipulationForDialog(stipulation)}
                            color="#6B97F6"
                            style={{
                              cursor: "pointer",
                              marginLeft: "10px",
                              marginTop: "2.5px",
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                    {!stipulation?.data?.info?.url && (
                      <Grid xs={4}>
                        <Button
                          variant="blue"
                          className={styles.button}
                          style={{ paddingLeft: 10, paddingRight: 10 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            inputFile?.current?.click();
                          }}
                        >
                          {loading ? (
                            <Loading size={18} text={undefined} />
                          ) : (
                            <Fragment>
                              Upload
                              <input
                                ref={inputFile}
                                type="file"
                                id="uploadStipulationsInput"
                                hidden
                                onChange={(e) => {
                                  handleFileChange(e, stipulation);
                                }}
                              />
                            </Fragment>
                          )}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </Grid>
      {stipulationForDialog && (
        <IFrameURLModal
          fileName={stipulationForDialog?.data?.info?.description}
          url={stipulationForDialog?.data?.info?.url}
          closeDialog={() => setStipulationForDialog(undefined)}
        />
      )}
    </>
  );
};

export default UploadStipulations;
