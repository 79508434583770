import { useCallback, ReactNode, useRef } from "react";
import { VscClose } from "react-icons/vsc";

import IconButton from "../IconButton";

import useOnClickOutside from "@Hooks/useClickOutside";

import styles from "./Dialog.module.scss";

type DialogProps = {
  open: boolean;
  children: ReactNode;
  title?: string;
  closeFn?: () => void;
  callback?: () => unknown;
  closeIcon?: boolean;
  id: string;
  size?: "xs" | "sm" | "lg" | "md" | "xl";
  titleColor?: "blue" | "default";
  containerStyle?: React.CSSProperties;
  spacing?: boolean;
};

const Dialog = ({
  open,
  size = "sm",
  children,
  containerStyle,
  title,
  titleColor = "default",
  closeFn,
  id,
  closeIcon = false,
  spacing = true,
}: DialogProps) => {
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const closeFnCallback = useCallback(() => closeFn, [dialogRef]);
  useOnClickOutside(dialogRef, closeFnCallback);
  return open ? (
    <dialog
      ref={dialogRef}
      id={id}
      data-testid="dialog"
      className={`${styles.dialogContainer} ${styles[size]} }`}
      style={containerStyle}
      // TODO: Fix (Cannot be triggered)
      // onClose={() => {
      //   closeFn?.();
      //   callback?.();
      // }}
      open={true}
    >
      <div>
        {title ? (
          <div className={styles.dialogHeader}>
            <p className={`${styles.title} ${styles[titleColor]}`}>{title}</p>
            {closeIcon && (
              <IconButton dataTestId="close-dialog-button" onClick={closeFn}>
                <VscClose size={30} />
              </IconButton>
            )}
          </div>
        ) : (
          <></>
        )}
        <div
          className={`${styles.dialogContent}  ${spacing && styles["spacing"]}`}
        >
          {children}
        </div>
      </div>
    </dialog>
  ) : null;
};

export default Dialog;
