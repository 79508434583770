import { useMutation, useQuery } from "@tanstack/react-query";

import {
  addCoApplicantToDeal,
  getApplicant,
  getApplicants,
} from "@Http/applicants";

import { MutationError } from "@Types/index";
import { HttpQuery } from "@Types/http";
import { Applicant, ApplicantInfo } from "@Types/applicants";
import { useNavigate } from "react-router-dom";
import { Deal } from "@Types/deal";
import { toast } from "react-toastify";

const useGetApplicants = (query?: HttpQuery) => {
  const queryKey = ["applicants", query ?? []];

  const { data, isLoading, error } = useQuery<
    Applicant[],
    MutationError,
    Applicant[]
  >({
    queryKey: queryKey,
    queryFn: getApplicants(query),
  });

  return {
    data,
    isLoading,
    error,
  };
};
export const useGetApplicant = (id: string) => {
  const queryKey = [`applicant-${id}`];

  const {
    data: applicant,
    isLoading,
    error,
  } = useQuery<Applicant, MutationError, Applicant>({
    queryKey: queryKey,
    queryFn: getApplicant(id),
  });

  return {
    applicant,
    isLoading,
    error,
  };
};
export const useAddCoApplicantToDeal = () => {
  const navigate = useNavigate();
  const mutation = useMutation<
    Deal,
    MutationError,
    { applicant: ApplicantInfo; dealId: string }
  >(addCoApplicantToDeal(), {
    onSuccess: (data) => {
      toast.success("Successfully added co-applicant to the deal.", {
        position: "top-center",
      });

      navigate(`/applications/${data._id}`, { replace: true });
    },
  });
  return {
    addCoApplicantToDeal: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};
export default useGetApplicants;
