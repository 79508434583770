import axios from "axios";

import { auth } from "@Firebase/firebase";
import {
  Deal,
  DealStatus,
  ReasonsDead,
  ReasonsDidNotProcess,
} from "@Types/deal";

export const statusChange = (): ((request: {
  dealId: string;
  reason: ReasonsDidNotProcess | ReasonsDead;
  status: DealStatus.Dead | DealStatus.DidNotProcess;
}) => Promise<Deal>) => {
  return async (request) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/status_change/`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};
