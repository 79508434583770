import { auth } from "@Firebase/firebase";
import { HttpQuery } from "@Types/http";
import {
  CreateDealershipTicketRequest,
  DealershipTicket,
} from "@Types/dealership_tickets";
import axios from "axios";

export const createDealershipTicket = (): ((
  ticket: CreateDealershipTicketRequest
) => Promise<DealershipTicket>) => {
  return async (ticket) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_ticket/`,
      ticket,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.result;
  };
};
export const getDealershipTicket = (
  id: string
): (() => Promise<DealershipTicket>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_ticket/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.result;
  };
};
export const getDealershipTickets =
  (query?: HttpQuery): (() => Promise<DealershipTicket[]>) =>
  async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_ticket/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return response.data.result?.docs ?? [];
  };
