import axios from "axios";

import { auth } from "@Firebase/firebase";

import { HttpQuery } from "@Types/http";
import { Chargeback } from "@Types/chargebacks";

export const getChargebacks = (
  query?: HttpQuery
): (() => Promise<Chargeback[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/chargeback/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return response.data.result?.docs ?? [];
  };
};

export const getChargeback = (id: string): (() => Promise<Chargeback>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/chargeback/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.result;
  };
};
