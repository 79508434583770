import styles from "./Ticket.module.scss";
import { getDealershipTicketStatusColor } from "./TicketsList";
import { capitalize } from "@Utils/functions";
import { useGetDealershipTicket } from "@Hooks/useDealershipTickets";
import Loading from "@Components/Loading";
import { Fragment } from "react";
import DealershipTicketNotes from "./TicketNotes";
type Props = {
  ticketId: string;
};

const Ticket = ({ ticketId }: Props) => {
  const { dealershipTicket, isLoading } = useGetDealershipTicket(ticketId);
  return (
    <div className={styles.container}>
      {isLoading || !dealershipTicket ? (
        <Loading size={25} text={"Loading"} />
      ) : (
        <Fragment>
          <div className={styles.itemContainer}>
            <div className={styles.itemHeader}>Description of the issue</div>
            <div className={styles.description}>
              {dealershipTicket.data.info.description}
            </div>
          </div>
          <div className={styles.itemContainerHalf}>
            <div className={styles.itemContainer}>
              <div className={styles.itemHeader}>Status</div>
              <div
                style={{
                  textAlign: "center",

                  fontSize: "16px",
                  color: getDealershipTicketStatusColor(
                    dealershipTicket.data.info.status
                  ),
                  fontWeight: "bold",
                }}
              >
                {capitalize(dealershipTicket.data.info.status)}
              </div>
            </div>
            {dealershipTicket.data.info.url ? (
              <div className={styles.itemContainer}>
                <div className={styles.itemHeader}>Screenshot of the issue</div>
                <a
                  href={dealershipTicket.data.info.url}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  Click here to open the image in an external window
                </a>
              </div>
            ) : null}
          </div>

          <div className={styles.itemContainer}>
            <div className={styles.notesHeader}>Notes</div>
            <div className={styles.notesContainer}>
              <DealershipTicketNotes
                dealershipTicketId={dealershipTicket._id}
                notes={dealershipTicket.data.dealershipTicketNotes}
              />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};
export default Ticket;
