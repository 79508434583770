import { Outlet } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";

import Grid from "@Components/Grid";
import IconButton from "@Components/IconButton";
import Logo from "@Components/Logo";
import SignInImage from "../../images/SignIn/SignInImage.png";

import styles from "./LoginLayout.module.scss";
const resolveBack = (path: string) => {
  switch (path) {
    case "/sign-in/mfa":
      return "/sign-in";
    default:
      return "/";
  }
};
const Layout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const showBackButton = pathname !== "/sign-in";
  const back = resolveBack(pathname);
  return (
    <Grid container className={styles.container}>
      <Grid
        xs={0}
        display={{ xs: "none", sm: "block" }}
        sm={7}
        style={{
          background: "linear-gradient(90deg, #F2798E -0.11%, #708EC9 99.89%)",
        }}
      >
        <div
          style={{
            background: `url("${SignInImage}")`,
            mixBlendMode: "multiply",
            position: "sticky",
            top: 0,
            backgroundPosition: "center",
            backgroundSize: "cover",
            opacity: 0.8,
            width: "100%",
            height: "100vh",
          }}
        ></div>
        <Logo className={styles.logo} />
      </Grid>
      <Grid
        xs={12}
        sm={5}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        position={"relative"}
      >
        {showBackButton && (
          <IconButton
            onClick={() => navigate(back)}
            style={{ position: "absolute", top: "40px", left: "20px" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IoIosArrowRoundBack size={30} />
              <span style={{ fontSize: "16px" }}>Back</span>
            </div>
          </IconButton>
        )}
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Layout;
