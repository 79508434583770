import { useContext } from "react";
import CurrentUserContext from "@Contexts/CurrentUserContext";

export default function () {
  const context = useContext(CurrentUserContext);
  if (!context) throw "Current user context not in scope!";
  if (context?.error) console.log(context.error);

  return context?.user;
}
