import Dialog from "@Components/Dialog";
import { DialogContent } from "@Components/Dialog/DialogContent";
import { renderTermsAndConditions } from "./TermsAndConditions";
import styles from "./TermsAndConditions.module.scss";

const TermsAndConditionsDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Dialog
      titleColor="blue"
      title="Terms & conditions"
      id="termsandconditions"
      open={open}
      closeIcon
      size="lg"
      closeFn={() => {
        setOpen(false);
      }}
    >
      <DialogContent>
        <div className={styles.dialog}>{renderTermsAndConditions()}</div>
      </DialogContent>
    </Dialog>
  );
};

export default TermsAndConditionsDialog;
