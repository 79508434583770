import { FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";

import { Team } from "@Types/teams";
import Grid from "@Components/Grid";
import Paper from "@Components/Paper";
import Loading from "@Components/Loading";
import BackButton from "@Components/BackButton";
import IconButton from "@Components/IconButton";
import { useDeleteTeam, useGetTeam, useUpdateTeam } from "@Hooks/useTeams";

import { TeamForm } from "./TeamForm";
import styles from "./Team.module.scss";

const TeamView = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) return <>Error</>;

  const { team } = useGetTeam(id);
  const { updateTeam } = useUpdateTeam();
  const { deleteTeam } = useDeleteTeam();

  const handleSaveChanges = (
    e: React.FormEvent<HTMLFormElement>,
    team: Team | undefined
  ) => {
    e.preventDefault();
    if (team) updateTeam(team);
  };

  return team ? (
    <div className={styles.root}>
      <Grid container xs={12}>
        <Grid xs={1.5}>
          <BackButton />
        </Grid>
        <Grid container xs={12}>
          <Paper
            style={{ position: "relative", width: "100%" }}
            title="Edit Team Details"
          >
            <div className={styles.deleteUser}>
              <IconButton
                onClick={() => deleteTeam(team)}
                flex
                style={{ fontSize: "20px", flex: 1 }}
              >
                Delete team
                <FaTrash style={{ marginLeft: 10 }} size={20} color="#eb8190" />
              </IconButton>
            </div>
            <TeamForm<Team>
              id={team._id}
              type="update"
              initialState={team}
              handleSubmit={handleSaveChanges}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  ) : (
    <Loading />
  );
};

export default TeamView;
