import { useState } from "react";

export function useStickyState<T>(
  data: T,
  key: string
): [T, (value: React.SetStateAction<T>) => void] {
  const [value, setValue] = useState<T>(() => {
    const stickyValue = window.localStorage.getItem(key);
    try {
      if (stickyValue) {
        const parsedStickyValue = JSON.parse(stickyValue);
        return parsedStickyValue;
      }
      return data;
    } catch (e) {
      return stickyValue;
    }
  });

  return [
    value,
    (newValue) => {
      window.localStorage.setItem(key, JSON.stringify(newValue));
      setValue(newValue);
    },
  ];
}
