import { auth } from "@Firebase/firebase";
import { ChatMember, ChatMemberType } from "@Types/chat_members";
import axios from "axios";

export const updateChatMember = (): ((_id: string) => Promise<ChatMember>) => {
  return async (_id) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/chat_member/${_id}`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.result;
  };
};

export const getChatMember = (
  email: string,
  type: ChatMemberType
): (() => Promise<ChatMember>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/chat_member/${email}/${type}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};
