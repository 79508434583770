import { useEffect } from "react";

function useOnClickOutside(
  ref: React.MutableRefObject<HTMLDivElement | HTMLDialogElement | null>,
  handler?: () => (() => void) | undefined
) {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event?.target as Node)) {
        return;
      }
      handler?.()?.();
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
export default useOnClickOutside;
