import { TbSearch } from "react-icons/tb";

import Grid from "@Components/Grid";
import TextField from "@Components/Inputs/TextField";
import Select from "@Components/Select";
import IconButton from "@Components/IconButton";

import {
  Collateral as CollateralType,
  CollateralTypes,
  collateralTypes,
} from "@Types/deal";

import { useVinLookup } from "@Hooks/useVinLookup";

import styles from "./Collateral.module.scss";
import CurrencyField from "@Components/Inputs/CurrencyField";

const Collateral = ({
  state,
  collateralType,
  setCollateralType,
  setState,
}: {
  collateralType: CollateralTypes;
  setCollateralType: (v: React.SetStateAction<CollateralTypes>) => void;
  state: CollateralType;
  setState: (v: React.SetStateAction<CollateralType>) => void;
}) => {
  const { lookupVIN } = useVinLookup<CollateralType>(setState);
  const handleLookupVin = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (state?.VIN) {
      lookupVIN(state?.VIN);
    }
  };
  return (
    <Grid container className={styles.container} spacing={2}>
      <Grid xs={8}>
        <div className={styles.vinContainer}>
          <TextField
            onChange={(e) =>
              setState((prev) => ({ ...prev, VIN: e.target.value }))
            }
            placeholder="VIN"
            value={state?.VIN ?? ""}
            name="VIN"
            type="text"
            required
            id="VIN"
          />
          <IconButton
            onClick={(e) => handleLookupVin(e)}
            disabled={typeof state?.VIN !== "string" && state?.VIN}
          >
            <TbSearch size={20} />
          </IconButton>
        </div>
      </Grid>
      <Grid xs={4}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              year: Number(e.target.value) || null,
            }))
          }
          placeholder="Year"
          value={state.year ?? undefined}
          name="Year"
          type="number"
          id="Year"
          required
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({ ...prev, make: e.target.value }))
          }
          placeholder="Make"
          value={state?.make ?? ""}
          name="Make"
          id="Make"
          type="text"
          required
        />
      </Grid>

      <Grid xs={4}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({ ...prev, model: e.target.value }))
          }
          placeholder="Model"
          value={state?.model ?? ""}
          name="Model"
          required
          type="text"
          id="Model"
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({ ...prev, odometer: Number(e.target.value) }))
          }
          placeholder="Miles"
          required
          value={state?.odometer ?? undefined}
          name="Miles"
          type="number"
          id="Miles"
        />
      </Grid>
      <Grid xs={4}>
        <Select
          placeholder="Unit status"
          name="Unit status"
          required
          id="unitStatus"
          value={state?.unitStatus ?? ""}
          onChange={(e) => {
            setState((prev) => ({
              ...prev,
              unitStatus: e.target.value,
            }));
          }}
          options={[
            {
              value: "new",
              label: "New",
            },
            {
              value: "used",
              label: "Used",
            },
          ]}
        />
      </Grid>
      <Grid xs={4}>
        <CurrencyField
          required
          id={"msrp"}
          name="msrp"
          placeholder={
            state.unitStatus === "new" ? "MSRP" : "NADA Clean Retail"
          }
          value={state.MSRP}
          onValueChange={(values) => {
            setState((prev) => ({
              ...prev,
              MSRP: values?.floatValue ?? null,
            }));
          }}
        />
      </Grid>
      <Grid xs={4}>
        <Select
          placeholder="Collateral Type"
          name="CollateralType"
          id="collateralType"
          required
          value={collateralType ?? ""}
          onChange={(e) => {
            setCollateralType(e.target.value as CollateralTypes);
          }}
          options={collateralTypes.map((type) => ({
            label: type,
            value: type,
          }))}
        />
      </Grid>
    </Grid>
  );
};

export default Collateral;
