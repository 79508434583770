import { auth } from "@Firebase/firebase";
import { HttpQuery } from "@Types/http";
import {
  DailyDealershipProfit,
  DetailedDealershipProfit,
  PerformanceDealershipProfit,
} from "@Types/profit";
import axios from "axios";

export const getDailyDealershipProfit = (
  query?: HttpQuery
): (() => Promise<DailyDealershipProfit>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/daily_dealership_profit/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );
    return response.data.result;
  };
};
export const getDetailedDealershipProfit = (
  query?: HttpQuery
): (() => Promise<DetailedDealershipProfit>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/detailed_dealership_profit/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );
    return response.data.result;
  };
};
export const getPerformanceDealershipProfit = (
  query?: HttpQuery
): (() => Promise<PerformanceDealershipProfit>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/performance_dealership_profit/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );
    return response.data.result;
  };
};
