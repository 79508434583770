import { useParams } from "react-router-dom";

import Grid from "@Components/Grid";
import Paper from "@Components/Paper";
import Loading from "@Components/Loading";
import BackButton from "@Components/BackButton";
import { useGetTitleIssue } from "@Hooks/useTitleIssues";

import Overview from "./Overview";
import styles from "./TitleIssues.module.scss";
import TitleIssuesNotes from "./TitleIssuesNotes";
import TitleIssueStepper from "./TitleIssueStepper";
import UploadTitleIssueDocument from "./UploadTitleIssueDocument";

export default function TitleIssue() {
  const { id } = useParams<{ id: string }>();
  const { titleIssue } = useGetTitleIssue(id as string);

  if (!id) return <>Error</>;
  if (!titleIssue) return <Loading />;
  return (
    <Grid className={styles.container} xs={12} container spacing={1}>
      <Grid xs={12}>
        <BackButton />
      </Grid>
      <Grid xs={6}>
        <h2 className={styles.title}>Title Info</h2>
        <Grid
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid xs={11}>
            <TitleIssueStepper
              oldTitleStatus={
                titleIssue.data.info.oldTitleRegistrationInfo.status
              }
              newTitleStatus={titleIssue.data.info.titleRegistrationInfo.status}
              receiptStatus={titleIssue.data.info.receiptInfo.status}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={6}>
        <Paper
          title="Overview"
          style={{ height: "100%", boxSizing: "border-box" }}
        >
          <Overview titleIssue={titleIssue} />
        </Paper>
      </Grid>

      <Grid xs={6}>
        <Paper title="Upload Title Documents">
          <UploadTitleIssueDocument titleIssue={titleIssue} />
        </Paper>
      </Grid>
      <Grid xs={6} className={styles.notes}>
        <Paper title="Title Notes">
          <TitleIssuesNotes
            titleIssueId={titleIssue._id}
            notes={titleIssue.data.titleIssueNotes ?? []}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
