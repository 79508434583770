import { createChannel, getChannels, hideChannel } from "@Http/channels";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Channel, CreateChannelRequest, MemberChannel } from "@Types/channels";
import { HttpQuery } from "@Types/http";
import { MutationError } from "@Types/index";
import { toast } from "react-toastify";
import { queryClient } from "../App";

const useGetChannels = (email?: string, query?: HttpQuery, enabled = true) => {
  const queryKey = ["channels", query ?? []];

  const { data, isLoading, error } = useQuery<
    Channel[],
    MutationError,
    Channel[]
  >({
    queryKey: queryKey,
    queryFn: getChannels(email),
    enabled,
  });

  return {
    data,
    isLoading,
    error,
  };
};

export const useCreateChannel = () => {
  const mutation = useMutation<Channel, MutationError, CreateChannelRequest>(
    createChannel(),
    {
      onSuccess: () => {
        toast.success("Successfully created a channel.", {
          position: "top-center",
        });
      },
    }
  );
  return {
    createChannel: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};
export const useHideChannel = () => {
  const mutation = useMutation<MemberChannel, MutationError, Channel>(
    hideChannel(),
    {
      onSuccess: (hiddenChannel) => {
        toast.success("Successfully hid a channel.", {
          position: "top-center",
        });

        queryClient.setQueriesData<Channel[]>(["channels"], (oldData) => {
          return oldData?.filter(
            (channel) => channel._id !== hiddenChannel.data.channelId
          );
        });
      },
    }
  );
  return {
    hideChannel: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useGetChannels;
