import { NumericFormat, OnValueChange } from "react-number-format";

import TextField from "@Components/Inputs/TextField";

import styles from "./CurrencyField.module.scss";
interface CurrencyFieldProps {
  placeholder: string;
  value?: number | null;
  containerStyle?: React.CSSProperties;
  onValueChange?: OnValueChange;
  id: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  inputStyle?: React.CSSProperties;
  className?: string;
  autofilled?: boolean;
}
const CurrencyField = ({
  placeholder,
  onValueChange,
  inputStyle,
  value,
  containerStyle,
  id,
  name,
  required = false,
  disabled = false,
  className = "",
  autofilled = false,
}: CurrencyFieldProps) => {
  return (
    <div
      className={
        styles.inputContainer +
        ` ${className} ${autofilled && styles.autofilled}`
      }
      style={
        containerStyle
          ? containerStyle?.height
            ? containerStyle
            : { ...containerStyle, height: "42px" }
          : { height: "42px" }
      }
    >
      {typeof value === "number" && (
        <label className={styles.label} htmlFor={name}>
          {placeholder}
          {autofilled ? (
            <span className={styles.autofilledLabel}> (Autofilled)</span>
          ) : (
            ""
          )}
        </label>
      )}
      <NumericFormat
        id={id}
        name={name}
        disabled={disabled}
        required={required}
        thousandSeparator=","
        value={value || ("" as unknown as number)}
        placeholder={placeholder}
        prefix="$"
        decimalScale={2}
        // fixedDecimalScale
        customInput={TextField}
        onValueChange={onValueChange}
        style={inputStyle}
      />
    </div>
  );
};
export default CurrencyField;
