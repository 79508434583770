import React from "react";
import Grid from "@mui/system/Unstable_Grid";
import { FaInfoCircle } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";

import useCurrentUser from "@Hooks/useCurrentUser";

import styles from "./TitleIssues.module.scss";

export default function Limits({ showText }: { showText?: boolean }) {
  const currentUser = useCurrentUser();
  const titleManagement =
    currentUser?.data?.dealership?.data?.info?.titleManagement;

  if (!titleManagement) return null;

  const { isRestricted, outstandingDeals, outstandingTitlesFundingLimit } =
    titleManagement;

  const fundingSuspended =
    (outstandingDeals || 0) > (outstandingTitlesFundingLimit || 0);

  const showWarning = fundingSuspended;

  if (!isRestricted) return null;

  return (
    <Grid container spacing={1}>
      {showText && (
        <Grid xs={12}>
          <div className={styles.warning}>
            <FaInfoCircle color="#6b97f6" size={100} />
            <div>
              To maintain compliance and avoid potential restrictions, please
              <span className={styles.important}>
                {" "}
                upload the missing documents{" "}
              </span>
              related to these outstanding deals as soon as possible.
              <span className={styles.important}>
                {" "}
                Thank you for your prompt attention to this matter.
              </span>
            </div>
          </div>
        </Grid>
      )}
      <Grid xs={12} display="flex">
        <div
          className={`${styles.limitContainer} ${
            fundingSuspended
              ? styles.limitContainerBorderOver
              : styles.limitContainerBorder
          }`}
        >
          {fundingSuspended && <IoIosWarning color="#eb8190" size={34} />}

          {`Outstanding deals: ${outstandingDeals} Limit: ${outstandingTitlesFundingLimit}`}
        </div>
      </Grid>

      {showWarning && showText && (
        <Grid
          xs={12}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <h3>
            You have exceeded the allowed missing title documents limit. Upload
            documents now.
          </h3>
        </Grid>
      )}
    </Grid>
  );
}
