import { FcDeleteDatabase } from "react-icons/fc";

import { MutationError, ServerError } from "@Types/index";

import styles from "./Error.module.scss";

type ErrorProps = {
  error: MutationError;
};
const isServerError = (error: unknown): error is ServerError => {
  if (typeof error === "object" && error !== null) {
    return Object.hasOwn(error, "response");
  }
  return false;
};
export const isExceptionalError = (error?: MutationError) => {
  if (isServerError(error)) {
    if (
      error?.response?.request?.responseURL?.includes("settings") &&
      error?.response?.data.result === "Dealership settings not found."
    ) {
      return true;
    }
    return false;
  }
  return false;
};
export const resolveErrorMessage = (
  error?: MutationError
): string | undefined => {
  if (isServerError(error)) {
    if (error?.response?.data?.result) {
      return `Error: ${error?.response?.data?.result}`;
    } else if (error?.response?.data?.message) {
      return `Error: ${error?.response?.data?.message}`;
    } else if (error?.message) {
      return `Error: ${error.message}`;
    }
  } else {
    if (error?.message) {
      return `Error: ${error.message}`;
    }
  }
  if (
    typeof error === "object" &&
    error !== null &&
    Object.keys(error)?.length
  ) {
    return `Error: ${JSON.stringify(error)}`;
  }
  if (error) {
    return `Error: ${error?.toString() || "Something went wrong"}`;
  }
  return undefined;
};

const Error = ({ error }: ErrorProps) => {
  return (
    <div data-testid={"error"} className={styles.container}>
      <FcDeleteDatabase size={40} />
      <p>{resolveErrorMessage(error)}</p>
    </div>
  );
};

export default Error;
