import LoaderSuccess from "@Components/LoaderSuccess";
import React, { FormEvent, Fragment } from "react";
import styles from "./SubmitTicket.module.scss";
import Button from "@Components/Button";
import TextArea from "@Components/Inputs/TextArea";

type Props = {
  loading: boolean;
  success: boolean;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  issueDescription: string;
  image: string;
  setScreenshotProcessEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setIssueDescription: React.Dispatch<React.SetStateAction<string>>;
  closeDialogFn: () => void;
};

const SubmitTicket = ({
  loading,
  success,
  onSubmit,
  image,
  setScreenshotProcessEnabled,
  issueDescription,
  setIssueDescription,
  closeDialogFn,
}: Props) => {
  return (
    <Fragment>
      {loading || success ? (
        <LoaderSuccess
          successMessage="Thank you for submitting your ticket.
                            We sincerely apologize for any inconvenience
                            this may have caused you. Our team is
                            already working on your issue and will get
                            back to you as soon as possible. 
                            Your patience and understanding are
                            greatly appreciated during this time.
                            "
          isLoading={loading}
          isSuccess={success}
          footerMessage="You may close this window or manage your issues from the 'My issues' tab"
        />
      ) : (
        <form
          className={styles.form}
          id="submitIssueForm"
          name="submittIssueForm"
          onSubmit={onSubmit}
        >
          <div>
            <p className={styles.question}>
              <strong>1.</strong> To better describe the problem, you can
              optionally take a screenshot of the issue. To do so, hit the
              &ldquo;<strong>Take a screenshot</strong>&rdquo; button. This
              window will then close and whenever the issue is visible on the
              screen, click the &ldquo;<strong>Take a screenshot</strong>&rdquo;
              on the mostright bottom of your screen. You can retake the
              screenshot as many times as you would like. You can skip this
              step.{" "}
              <a
                href="./screenshot_button_location.png"
                target="_blank"
                rel="noreferrer"
              >
                (Example photo of the button location)
              </a>
            </p>
          </div>
          <div>
            <Button
              variant="blue"
              onClick={() => {
                setScreenshotProcessEnabled(true);
                closeDialogFn();
              }}
            >
              {image?.length > 0 ? "Retake screenshot" : "Take a screenshot"}
            </Button>
          </div>
          {image?.length > 0 && (
            <div className={styles.takenImagePreview}>
              <p className={styles.takenImagePreviewHeader}>
                Taken Image Preview
              </p>
              <img src={image} alt="" />
            </div>
          )}
          <div>
            <p className={styles.question}>
              <strong>2.</strong> Use the below box to describe your issue. This
              way our team will better understand the problem and work on
              resolving it as soon as possible.
            </p>
          </div>
          <div>
            <TextArea
              inputStyle={{ border: "1px solid #a5aec1" }}
              defaultRows={3}
              onChange={(e) => setIssueDescription(e.target.value)}
              showPlaceHolderTop={false}
              placeholder="Issue description"
              value={issueDescription}
              name="issueDescription"
              required
            />
          </div>
        </form>
      )}
    </Fragment>
  );
};
export default SubmitTicket;
