import { ReactNode } from "react";

import { getCurrentUser } from "@Http/users";
import { User } from "@Types/users";
import { MutationError } from "@Types/index";
import { useQuery } from "@tanstack/react-query";
import CurrentUserContext from "./index";
import { resolveErrorMessage } from "@Components/Error/Error";
import LoadingFullScreen from "@Components/Loading/LoadingFullScreen";

const CurrentUserProvider = ({ children }: { children: ReactNode }) => {
  const queryKey = ["current-user"];

  const { data: user, error } = useQuery<User, MutationError>({
    queryKey: queryKey,
    queryFn: getCurrentUser(),
  });
  return (
    <CurrentUserContext.Provider
      value={{ user, error: resolveErrorMessage(error) }}
    >
      {user && user?.data?.dealership?.data?.info?.name ? (
        children
      ) : (
        <LoadingFullScreen text="Awaiting user data" />
      )}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;
