import { Navigate, Outlet, useLocation } from "react-router-dom";
import { CurrentUser } from "../../App";
function RedirectOnLogin({ currentUser }: { currentUser: CurrentUser }) {
  const location = useLocation();
  if (currentUser && currentUser.emailVerified) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return <Outlet />;
}

export default RedirectOnLogin;
