import Loading from "@Components/Loading";
import Select from "@Components/Select";
import Switch from "@Components/Switch";
import {
  useGetDailyDealershipProfit,
  useGetDetailedDealershipProfit,
  useGetPerformanceDealershipProfit,
} from "@Hooks/useDealershipProfit";
import { Tier } from "@Types/profit";
import { ChangeEvent, useState } from "react";
import Chart from "react-apexcharts";
import { RxDoubleArrowRight } from "react-icons/rx";
import styles from "./ProfitDashboard.module.scss";
function range(size: number, startAt = 0) {
  return Array.from({ length: size }, (x, i) => startAt + i);
}
const monthNumberToLabelMap: { [key: string]: string } = {
  "1": "January",
  "2": "February",
  "3": "March",
  "4": "April",
  "5": "May",
  "6": "June",
  "7": "July",
  "8": "August",
  "9": "September",
  "10": "October",
  "11": "November",
  "12": "December",
};
const ProfitDashboard = () => {
  const [time, setTime] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  const [monthly, setMonthly] = useState(true);

  const { dailyDealershipProfit, isLoading: isLoadingDayliProfit } =
    useGetDailyDealershipProfit({
      query: {
        year: time.year.toString(),
        month: monthly
          ? time.month.toString()
          : (undefined as unknown as string),
      },
    });
  const { detailedDealershipProfit, isLoading: isLoadingDetails } =
    useGetDetailedDealershipProfit({
      query: {
        year: time.year.toString(),
        month: monthly
          ? time.month.toString()
          : (undefined as unknown as string),
      },
    });
  const { performanceDealershipProfit, isLoading: isLoadingPerformance } =
    useGetPerformanceDealershipProfit({
      query: {
        year: time.year.toString(),
        month: time.month.toString(),
      },
    });

  const daysInMonth = new Date(time.year, time.month ?? 0, 0).getDate();

  const series = monthly
    ? range(daysInMonth, 1).map((day) => {
        return parseFloat(
          (
            dailyDealershipProfit?.find(
              (profit) => profit._id === `${time.year}/${time.month}/${day}`
            ) || {}
          ).dealerProfit?.toFixed(2) || "0"
        );
      })
    : range(12, 1).map((month) => {
        return parseFloat(
          (
            dailyDealershipProfit?.find(
              (profit) => profit._id === `${time.year}/${month}`
            ) || {}
          ).dealerProfit?.toFixed(2) || "0"
        );
      });
  const state = {
    options: {
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: number) {
          return (
            "$" +
            val.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          );
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      grid: {
        show: true, // you can either change hear to disable all grids

        yaxis: {
          lines: {
            show: true, //or just here to disable only y axis
          },
        },
      },
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: monthly
          ? range(daysInMonth, 1)
          : range(12, 1).map((m) => monthNumberToLabelMap[m.toString()]),
      },
    },

    series: [
      {
        name: "Daily profit",
        data: series,
      },
    ],
  };

  const calculatePercentage = (a: number, b: number) => {
    return ((a - b) / ((a + b) / 2)) * 100;
  };

  const profitBase =
    (performanceDealershipProfit?.currentDealershipStatistics
      ?.dealershipProfit || 0) /
    ((performanceDealershipProfit?.tier?.commissionAmount || 0) / 100);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.headerTitle}>Profit dashboard</h3>
        <div className={styles.headerControlsContainer}>
          <span style={{ marginRight: "10px" }}>Monthly?</span>
          <Switch
            isActive={monthly}
            toggleFunction={() => {
              return setMonthly(!monthly);
            }}
          />
          <div className={styles.headerSelectContainer}>
            <Select
              placeholder="Year"
              name="yars"
              value={time.year}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setTime({ ...time, year: parseInt(e.target.value) });
              }}
              options={[-3, -2, -1, 0]
                ?.map((m) => {
                  return new Date().getFullYear() + m;
                })
                ?.map((m) => ({
                  value: m.toString(),
                  label: m.toString(),
                }))}
            />
          </div>
          {monthly && (
            <div className={styles.headerSelectContainer}>
              <Select
                placeholder="Month"
                name="months"
                value={time.month}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  setTime({ ...time, month: parseInt(e.target.value) });
                }}
                options={range(12, 1)?.map((m) => ({
                  value: m.toString(),
                  label: monthNumberToLabelMap?.[m.toString()]?.toString(),
                }))}
              />
            </div>
          )}
        </div>
      </div>

      {isLoadingDayliProfit || isLoadingDetails || isLoadingPerformance ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            marginTop: "100px",
            padding: 20,
            overflow: "hidden",
          }}
        >
          <Loading text="Loading data" />
        </div>
      ) : (
        <>
          <div className={styles.blueTitle}>
            Daily profit ({monthNumberToLabelMap[time.month]})
          </div>
          <div className={styles.chartContainer}>
            {dailyDealershipProfit && (
              <Chart
                options={state.options}
                series={state.series}
                type="bar"
                height="300"
                width="100%"
              />
            )}
          </div>
          <div className={styles.detailedProfitContainer}>
            {[
              {
                title: "YTD profit",
                amount: detailedDealershipProfit?.currentYTDProfit,
                performance: calculatePercentage(
                  detailedDealershipProfit?.currentYTDProfit || 0,
                  detailedDealershipProfit?.previousYTDProfit || 0
                ),
              },
              ...(monthly
                ? [
                    {
                      title: "MTD profit",
                      amount: detailedDealershipProfit?.currentMTDProfit,
                      performance: calculatePercentage(
                        detailedDealershipProfit?.currentMTDProfit || 0,
                        detailedDealershipProfit?.previousMTDProfit || 0
                      ),
                    },
                    {
                      title: "Deals count",
                      amount: detailedDealershipProfit?.currentDealsCount,
                      type: "number",
                      performance: calculatePercentage(
                        detailedDealershipProfit?.currentDealsCount || 0,
                        detailedDealershipProfit?.previousDealsCount || 0
                      ),
                    },
                  ]
                : []),
              {
                title: "Average profit per deal",
                amount: detailedDealershipProfit?.averageProfitPerDeal,
              },
            ].map((data, index) => {
              return (
                <div key={index} className={styles.detailedProfitBoxContainer}>
                  <div className={styles.detailedProfitBoxTitle}>
                    {data.title}
                  </div>
                  <div className={styles.detailedProfitBoxDataContainer}>
                    <div className={styles.detailedProfitBoxMainData}>
                      {data.type === "number"
                        ? data.amount?.toString()
                        : "$" + (data.amount || 0)?.toFixed(2)}
                    </div>
                    {typeof data.performance === "number" && (
                      <div
                        style={{
                          color: (data.performance || 0) <= 0 ? "red" : "green",
                        }}
                      >
                        ({(data.performance || 0).toFixed(2)}%)
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {monthly && (
            <>
              <div className={styles.blueTitle}>Performance information</div>
              <div className={styles.performanceContainer}>
                {(performanceDealershipProfit?.tiers || [])?.map(
                  (tier: Tier, index) => {
                    const isActiveTier =
                      tier.commissionAmount ===
                      performanceDealershipProfit?.tier?.commissionAmount;
                    return (
                      <div
                        className={styles.performanceBoxAndArrowContainer}
                        key={index}
                      >
                        <div
                          className={styles.performanceBoxContainer}
                          style={{
                            border: isActiveTier
                              ? "3px solid rgb(117 199 117)"
                              : "3px solid rgb(243, 243, 243)",
                          }}
                        >
                          <div
                            className={styles.performanceBoxTitle}
                            style={{
                              color: isActiveTier
                                ? "rgb(117 199 117)"
                                : "#6b97f6",
                            }}
                          >
                            Tier {index + 1} {isActiveTier && "(Active)"}
                          </div>
                          <div className={styles.performanceBoxData}>
                            Profit percent: {tier.commissionAmount}%
                          </div>
                          <div className={styles.performanceBoxData}>
                            Profit amount : $
                            {(
                              profitBase * (tier.commissionAmount / 100) || 0
                            ).toFixed(2)}
                            {tier.commissionAmount >
                              (performanceDealershipProfit?.tier
                                ?.commissionAmount || 0) && " + "}
                          </div>
                          {index > 0 && (
                            <div className={styles.performanceBoxData}>
                              Tier requirements:
                              <br /> More than {tier.conditions[0].value -
                                1}{" "}
                              funded deals
                            </div>
                          )}
                        </div>
                        {isActiveTier &&
                          performanceDealershipProfit?.dealsCountToNextTier &&
                          performanceDealershipProfit.nextTier && (
                            <div
                              className={`${styles.performanceArrowContainer} ${styles.performanceArrowContainerAnimate}`}
                            >
                              <div className={styles.performanceArrowData}>
                                {
                                  performanceDealershipProfit?.dealsCountToNextTier
                                }{" "}
                                more deal
                                {performanceDealershipProfit?.dealsCountToNextTier ==
                                1
                                  ? ""
                                  : "s"}{" "}
                                required
                              </div>
                              <RxDoubleArrowRight color="#fff" size={30} />
                            </div>
                          )}
                      </div>
                    );
                  }
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProfitDashboard;
