import { useState } from "react";
import { toast } from "react-toastify";

import Grid from "@Components/Grid";
import Button from "@Components/Button";
import { formatDate } from "@Utils/functions";
import useCurrentUser from "@Hooks/useCurrentUser";
import TextArea from "@Components/Inputs/TextArea";

import styles from "./TicketNotes.module.scss";
import { DealershipTicketNote } from "@Types/dealership_ticket_notes";
import useCreateDealershipTicketNote from "@Hooks/useDealershipTicketNotes";

export default function DealershipTicketNotes({
  notes,
  dealershipTicketId,
}: {
  dealershipTicketId: string;
  notes: DealershipTicketNote[];
}) {
  const currentUser = useCurrentUser();

  const [newNote, setNewNote] = useState("");
  const { createDealershipTicketNote } = useCreateDealershipTicketNote();

  const handleCreateNote = () => {
    if (newNote) {
      createDealershipTicketNote({
        data: {
          dealershipTicketId,
          info: {
            seen: {
              dealer: true,
              wfd: false,
            },
            note: newNote,
            creator: "dealer",
            user: {
              firstName: currentUser?.data.info.firstName ?? null,
              lastName: currentUser?.data.info.lastName ?? null,
              email: currentUser?.data.info.email ?? null,
            },
          },
        },
      });
      setNewNote("");
    } else
      toast.warn("Note cannot be empty", {
        position: "top-center",
      });
  };
  return (
    <Grid container xs={12} spacing={1}>
      <Grid container xs={12}>
        <div className={styles.noteContainer}>
          {notes?.map((dealershipTicketNote) => (
            <div
              className={
                dealershipTicketNote.data.info.user.email ===
                currentUser?.data.info.email
                  ? styles.senderNote
                  : styles.receiverNote
              }
              key={dealershipTicketNote._id}
            >
              {dealershipTicketNote.data.info.note}
              <div className={styles.noteCreator}>
                {[
                  dealershipTicketNote.data?.info?.user?.firstName,
                  dealershipTicketNote?.data?.info?.user?.lastName,
                  formatDate(dealershipTicketNote?.createdAt, "medium", true),
                ]
                  .filter((x) => x)
                  .join(" ")}
              </div>
            </div>
          ))}
        </div>
      </Grid>

      <Grid xs={8} style={{ marginBottom: "10px" }}>
        <TextArea
          inputStyle={{ border: "1px solid #a5aec1" }}
          onChange={(e) => setNewNote(e.target.value)}
          showPlaceHolderTop={false}
          placeholder="Note"
          value={newNote}
          name="Note"
          required
        />
      </Grid>
      <Grid xs={4} style={{ marginBottom: "10px" }}>
        <Button variant="blue" onClick={handleCreateNote}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}
