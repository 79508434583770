import axios from "axios";

import { auth } from "@Firebase/firebase";

import { CreatCollateralChangeRequest, Collateral } from "@Types/deal";

export const updateCollateral = (): ((
  request: CreatCollateralChangeRequest
) => Promise<Collateral>) => {
  return async (request) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/collateral/`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};
