import { useState } from "react";
import mixpanel from "mixpanel-browser";

import {
  Deal,
  initialCollateralState,
  initialFinancialInfoState,
  FinancialInfo as FinancialInfoType,
} from "@Types/deal";
import Paper from "@Components/Paper";
import Button from "@Components/Button";
import Dialog from "@Components/Dialog";
import Grid from "@mui/system/Unstable_Grid";
import useCurrentUser from "@Hooks/useCurrentUser";
import { DialogActions } from "@Components/Dialog/DialogActions";
import { DialogContent } from "@Components/Dialog/DialogContent";
import useCreateCollateralChange from "@Hooks/useUpdateCollateral";
import Collateral from "@Pages/Applications/CreateApplication/Collateral";
import FinancialInfo from "@Pages/Applications/CreateApplication/FinancialInfo";

import styles from "./ChangeRequests.module.scss";
import { hasSectionPermissions } from "@Utils/permissions";

const dealToFinancialInfo = (deal: Deal): FinancialInfoType => {
  return {
    payment: { totalTaxes: deal?.data?.info?.payment?.totalTaxes || 0 },
    taxesAndFees: {
      documentRegistrationFees:
        deal?.data?.info?.taxesAndFees?.documentRegistrationFees || 0,
      titleTransfer: deal?.data?.info?.taxesAndFees?.titleTransfer || 0,
    },
    price: {
      price: deal?.data?.info?.price?.price || 0,
      totalCash: deal?.data?.info?.price?.totalCash || 0,
      totalTrade: deal?.data?.info?.price?.totalTrade || 0,
      payoff: deal?.data?.info?.price?.payoff || 0,
      payoffBank: deal?.data?.payoffBank?.data?.info?.name || "",
    },
  };
};
const ChangeRequests = ({ deal }: { deal: Deal }) => {
  const user = useCurrentUser();
  const currentUser = useCurrentUser();
  if (!hasSectionPermissions("deal", "collateral", currentUser)) return null;

  const { collateralChangeRequest } = useCreateCollateralChange();
  const [openDialog, setOpenDialog] = useState(false);
  const [collateral, setCollateral] = useState(
    deal.data.info.vehicle ?? initialCollateralState
  );

  const [financialInfo, setFinancialInfo] = useState(
    dealToFinancialInfo(deal) ?? initialFinancialInfoState
  );

  const [collateralType, setCollateralType] = useState(
    deal.data.info.type ?? "Automotive"
  );
  const handleSendCollateralChangeRequest = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    collateralChangeRequest({
      data: { dealId: deal._id, collateral, financialInfo, collateralType },
    });
    mixpanel.track("Collateral ChangeRequest", {
      email: user?.data?.info?.email,
      dealershipName: user?.data?.dealership?.data?.info?.name,
      dealId: deal._id,
    });
    setOpenDialog(false);
  };
  return (
    <Paper
      title={"Change Request"}
      style={{ height: "100%", boxSizing: "border-box" }}
    >
      If you need to change the collateral, please fill out the form below.
      <br />
      <Button
        className={styles.button}
        onClick={() => {
          setOpenDialog(true);
        }}
        variant="blue"
      >
        Create Change Request
      </Button>
      <Dialog
        titleColor="blue"
        id="create-change-request"
        open={openDialog}
        title="Collateral Change Request"
        closeIcon
        size="lg"
        closeFn={() => {
          setOpenDialog(false);
        }}
      >
        <DialogContent>
          <form
            className={styles.form}
            id="change-request"
            onSubmit={handleSendCollateralChangeRequest}
          >
            <Collateral
              collateralType={collateralType}
              setCollateralType={setCollateralType}
              state={collateral}
              setState={setCollateral}
            />
            <Grid xs={12}>
              <FinancialInfo
                state={financialInfo}
                setState={setFinancialInfo}
              />
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="blue" onClick={() => setOpenDialog(false)}>
            Close
          </Button>
          <Button form="change-request" variant="blue" type="submit">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ChangeRequests;

{
  /* <div className={styles.container}>
<h2 className={styles.title}>Change requests</h2>
<Button
  className={styles.button}
  onClick={() => {
    setOpenDialog(!openDialog);
  }}
  variant="blue"
>
  Create Change Request
</Button>
<Dialog
  open={openDialog}
  title="Create Change Request"
  closeIcon
  closeFn={() => {
    setOpenDialog(false);
  }}
>
  <DialogContent>
    adsadasdasdadsadasdasdadsadasdasdadsadasdasdadsadasdasdadsadasdasdadsadasdasdadsadasdasdad
  </DialogContent>
  <DialogActions>
    <Button variant="blue">Close</Button>
    <Button variant="blue">Send</Button>
  </DialogActions>
</Dialog>
</div> */
}
