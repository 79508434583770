import { TbFileCheck } from "react-icons/tb";
import styles from "./Notifications.module.scss";
import IconButton from "@Components/IconButton";
import { CgClose } from "react-icons/cg";
import {
  DealershipNotification,
  DealershipNotificationType,
} from "@Types/dealership_notifications";
import { Fragment, MouseEvent, SetStateAction, useState } from "react";
import {
  useDeleteDealershipNotification,
  useUpdateDealershipNotification,
} from "@Hooks/useNotifications";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { GiNotebook } from "react-icons/gi";
import { RxFileText } from "react-icons/rx";
import { BsChatText } from "react-icons/bs";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { HiOutlineDocumentAdd } from "react-icons/hi";

type Props = {
  notification: DealershipNotification;
  setNotificationsMenuOpen: React.Dispatch<SetStateAction<boolean>>;
};
const buildNotificationDescription = (notification: DealershipNotification) => {
  switch (notification.data.info.type) {
    case DealershipNotificationType.Note:
      return (
        <Fragment>
          {notification.data.info.body.description} for{" "}
          <strong>{notification.data.info.body.customersNames}</strong> by{" "}
          <strong>{notification.data.info.body.managerNames}</strong>
        </Fragment>
      );
    case DealershipNotificationType.Stipulation:
      return (
        <Fragment>
          {notification.data.info.body.description} for{" "}
          <strong>{notification.data.info.body.customersNames}</strong> was
          uploaded by{" "}
          <strong>{notification.data.info.body.managerNames}</strong>
        </Fragment>
      );
    case DealershipNotificationType.Application:
      return (
        <Fragment>
          {notification.data.info.body.description}
          {": "}
          <strong>{notification.data.info.body.customersNames}</strong>
        </Fragment>
      );

    case DealershipNotificationType.Chat:
    case DealershipNotificationType.ScanLicense:
    case DealershipNotificationType.TitleIssue:
      return <Fragment>{notification.data.info.body.description}</Fragment>;
  }
};
const calculateDaysElapsed = (createdAtStringDate: string) => {
  const today = new Date();
  const createdDate = new Date(createdAtStringDate);
  const days = Math.floor(
    Math.abs(today.getTime() - createdDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  const months = Math.floor(days / 30);
  if (days < 30) {
    if (days === 0) {
      return "Less than a day ago";
    }
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (months > 12) {
    const years = today.getFullYear() - createdDate.getFullYear();
    return `${years} year${years > 1 ? "s" : ""} ago`;
  }
  return `${months} month${months > 1 ? "s" : ""} ago`;
};
const handleRedirectNotification = (
  notification: DealershipNotification,
  navigate: NavigateFunction
) => {
  switch (notification.data.info.type) {
    case DealershipNotificationType.Note:
    case DealershipNotificationType.Stipulation:
    case DealershipNotificationType.Application:
      navigate(`/applications/${notification.data.info.referenceId}`, {
        replace: true,
      });
      break;
    case DealershipNotificationType.Chat:
      navigate("/chat", {
        replace: true,
      });
      break;
    case DealershipNotificationType.TitleIssue:
      navigate(`/title_info/${notification.data.info.referenceId}`, {
        replace: true,
      });
      break;
    case DealershipNotificationType.TitleIssues:
      navigate("/title_info", {
        replace: true,
      });
      break;
    case DealershipNotificationType.ScanLicense:
      navigate(
        `/incomplete_applications/${notification.data.info.referenceId}`,
        {
          replace: true,
        }
      );
  }
};
const renderIconByNotificationType = (notification: DealershipNotification) => {
  switch (notification.data.info.type) {
    case DealershipNotificationType.TitleIssue:
    case DealershipNotificationType.TitleIssues:
      return <HiOutlineDocumentAdd size={30} color="#08C552" />;
    case DealershipNotificationType.Note:
      return <GiNotebook size={30} color="#08C552" />;
    case DealershipNotificationType.Stipulation:
      return <TbFileCheck size={30} color="#08C552" />;
    case DealershipNotificationType.Application:
      return <RxFileText size={30} color="#08C552" />;
    case DealershipNotificationType.Chat:
      return <BsChatText size={30} color="#08C552" />;
    case DealershipNotificationType.ScanLicense:
      return <IoIosInformationCircleOutline size={30} color="#08C552" />;
  }
};
export default ({ notification, setNotificationsMenuOpen }: Props) => {
  const [deleteAnimationTrigger, setDeleteAnimationTrigger] = useState(false);
  const navigate = useNavigate();
  const { deleteDealershipNotification } = useDeleteDealershipNotification();
  const { updateDealershipNotification } = useUpdateDealershipNotification();
  const handleDelete = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteAnimationTrigger(true);
    setTimeout(() => {
      deleteDealershipNotification(notification);
      setDeleteAnimationTrigger(false);
    }, 800);
  };
  const handleUpdateNotification = () => {
    if (!notification?.data?.info?.seenAt) {
      const newNotification = structuredClone(notification);
      newNotification.data.info.seenAt = new Date().toISOString();
      updateDealershipNotification(newNotification);
    }
  };
  const handleOpen = () => {
    handleUpdateNotification();
    setNotificationsMenuOpen(false);
    handleRedirectNotification(notification, navigate);
  };

  return (
    <div
      className={
        deleteAnimationTrigger
          ? styles.notificationContainerForDeletion
          : styles.notificationContainer
      }
      onClick={handleOpen}
    >
      <div
        className={styles.newBadge}
        style={{
          visibility: notification.data.info?.seenAt ? "hidden" : "visible",
        }}
      >
        {" "}
      </div>
      <div className={styles.iconCircle}>
        {renderIconByNotificationType(notification)}
      </div>
      <div className={styles.notificationBody}>
        <div className={styles.notificationTitle}>
          {notification.data.info.title}
        </div>
        <div className={styles.notificationDescription}>
          {buildNotificationDescription(notification)}
        </div>
      </div>
      <div className={styles.notificationTime}>
        {calculateDaysElapsed(notification.createdAt as string)}
      </div>
      <div className={styles.deleteNotificationButton}>
        <IconButton onClick={(e) => handleDelete(e)}>
          <CgClose size={15} />
        </IconButton>
      </div>
    </div>
  );
};
