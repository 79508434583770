import { CiCircleRemove } from "react-icons/ci";

import Grid from "@Components/Grid";
import Select from "@Components/Select";
import IconButton from "@Components/IconButton";

import { RegularQuery } from "@Types/http";

import { FilterPreviewProps } from "./types";

const SelectFilter = ({
  filterState,
  valuesForSelect,
  setFilterState,
  queryPath,
  partialSearch,
  caseInsensitive,
  placeholder,
}: FilterPreviewProps) => {
  const handleAddFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValues = [...(filterState?.values ?? []), e.target.value];
    setFilterState({
      values: newValues,
      query: {
        ...(filterState?.query ?? {}),
        [queryPath.join(".")]: {
          $in: newValues.map((value) => ({
            value,
            ...(partialSearch ? { partialSearch: true } : {}),
            ...(caseInsensitive ? { caseInsensitive: true } : {}),
          })),
        },
      } as RegularQuery,
    });
  };

  const handleRemoveFilter = (value: string) => {
    const filteredState = filterState?.values.filter((x) => x !== value) ?? [];

    setFilterState({
      values: filteredState,
      query: {
        ...(filterState?.query ?? {}),
        [queryPath.join(".")]: {
          $in: filteredState.map((value) => ({
            value,
            ...(partialSearch ? { partialSearch: true } : {}),
            ...(caseInsensitive ? { caseInsensitive: true } : {}),
          })),
        },
      } as RegularQuery,
    });
  };
  return (
    <Grid container xs={12}>
      <Grid xs={12}>
        {(filterState?.values ?? []).map((value, index: number) => (
          <IconButton
            key={index}
            onClick={() => handleRemoveFilter(value)}
            flex
            style={{ fontSize: "16px" }}
          >
            {value}
            <CiCircleRemove size={18} />
          </IconButton>
        ))}
      </Grid>
      <Select
        name={placeholder}
        placeholder={placeholder}
        value={undefined}
        onChange={handleAddFilter}
        options={valuesForSelect}
      />
    </Grid>
  );
};

export default SelectFilter;
