import axios from "axios";

import { auth } from "@Firebase/firebase";

import { Settings } from "@Types/settings";

export const getSettings = (): (() => Promise<Settings>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/settings/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};

export const updateSettings = (): ((
  settings: Settings | Omit<Settings, "_id">
) => Promise<Settings>) => {
  return async (settings) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/settings`,
      settings,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};
