import { ReactNode } from "react";
import styles from "./button.module.scss";

interface ButtonProps {
  id?: string;
  children: ReactNode;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: "submit" | "button" | "reset";
  variant?: "white" | "gradient" | "blue" | "pink";
  className?: string;
  form?: string;
  disabled?: boolean;
  dataTestId?: string;
}
const Button = ({
  id,
  children,
  onClick,
  style,
  type,
  variant = "gradient",
  className = "",
  form,
  disabled,
  dataTestId,
}: ButtonProps) => {
  return (
    <button
      data-testid={dataTestId ?? id}
      id={id}
      disabled={disabled}
      form={form}
      type={type}
      onClick={onClick}
      style={style}
      className={styles.btn + ` ${styles[variant]} ${styles[className]}`}
    >
      <span> {children}</span>
    </button>
  );
};
export default Button;
