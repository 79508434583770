import { auth } from "@Firebase/firebase";
import { HttpQuery, PaginatedResult } from "@Types/http";
import axios from "axios";
import { DealershipNotification } from "@Types/dealership_notifications";
import { queryClient } from "../App";

export const deleteDealershipNotification = (): ((
  dealershipNotification: DealershipNotification
) => Promise<DealershipNotification>) => {
  return async (dealershipNotification) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.delete(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_notification/${dealershipNotification._id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};
export const deleteAllDealershipNotifications = (): (() => Promise<string>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.delete(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_notification`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};
export const getDealershipNotifications = (
  query?: HttpQuery
): (({
  pageParam = 1,
}) => Promise<PaginatedResult<DealershipNotification[]>>) => {
  return async ({ pageParam = 1 }) => {
    const token = await auth.currentUser?.getIdToken();
    if (query?.options) {
      query.options = {
        ...query.options,
        page: pageParam,
      };
    }
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_notification/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return response.data.result;
  };
};
export const updateDealershipNotification = (): ((
  dealershipNotification: DealershipNotification
) => Promise<DealershipNotification>) => {
  return async (dealershipNotification) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_notification/${dealershipNotification._id}`,
      dealershipNotification,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    queryClient.setQueryData<DealershipNotification>(
      [`dealership_notification-${dealershipNotification._id}`],
      () => response.data.result
    );
    return response.data.result;
  };
};
