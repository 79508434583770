export default ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21" cy="21" r="21" fill="currentColor" />
    <path
      d="M26.3647 28.1445V30H16.4282V28.3765L21.3232 21.8945C21.9255 21.097 22.3853 20.4215 22.7026 19.8682C23.02 19.3066 23.2397 18.8062 23.3618 18.3667C23.4839 17.9191 23.5449 17.4634 23.5449 16.9995C23.5449 16.4136 23.4513 15.8846 23.2642 15.4126C23.077 14.9325 22.8044 14.55 22.4463 14.2651C22.0882 13.9803 21.6569 13.8379 21.1523 13.8379C20.4932 13.8379 19.952 13.9925 19.5288 14.3018C19.1138 14.6029 18.8086 15.026 18.6133 15.5713C18.418 16.1165 18.3203 16.7432 18.3203 17.4512H16.1597C16.1597 16.4502 16.3428 15.5347 16.709 14.7046C17.0833 13.8745 17.6408 13.2153 18.3813 12.7271C19.1219 12.2306 20.0456 11.9824 21.1523 11.9824C22.1045 11.9824 22.9183 12.1818 23.5938 12.5806C24.2773 12.9712 24.7982 13.5246 25.1562 14.2407C25.5225 14.9487 25.7056 15.7788 25.7056 16.731C25.7056 17.2518 25.6323 17.7808 25.4858 18.3179C25.3475 18.8468 25.1522 19.3758 24.8999 19.9048C24.6558 20.4338 24.3669 20.9546 24.0332 21.4673C23.6995 21.98 23.3415 22.4845 22.959 22.981L19.0161 28.1445H26.3647Z"
      fill="white"
    />
  </svg>
);
