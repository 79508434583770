import { IoDocumentOutline, IoEyeOutline } from "react-icons/io5";

import Grid from "@Components/Grid";
import { Deal } from "@Types/deal";
import DocumentsDropzone from "../DocumentsDropzone/DocumentsDropzone";
import styles from "./UploadDocuments.module.scss";

import { useGetDocuments } from "@Hooks/useDocuments";
import { useState } from "react";
import IFrameURLModal from "../PreviewDocuments/IFrameURLModal";
import { Document } from "@Types/documents";
import useCurrentUser from "@Hooks/useCurrentUser";
import { hasSectionPermissions } from "@Utils/permissions";

type UploadDocumentsProps = {
  deal: Deal;
};
const UploadDocuments = ({ deal }: UploadDocumentsProps) => {
  const currentUser = useCurrentUser();
  if (!hasSectionPermissions("deal", "documents", currentUser)) return null;

  const { data: docs } = useGetDocuments(`${deal._id}_${deal.updatedAt}`, {
    query: { "data.dealId": deal._id },
  });

  const [documentForDialog, setDocumentForDialog] = useState<
    Document | undefined
  >(undefined);

  return (
    <>
      <Grid container xs={12}>
        <div className={styles.container} id="uploadDocuments" tabIndex={-1}>
          <Grid xs={12}>
            <p className={styles.title}>Upload Documents</p>
          </Grid>
          <Grid xs={12} container className={styles.innerContainer}>
            <DocumentsDropzone
              section={"required-documents"}
              dealId={deal._id}
              dashed
            />

            <Grid xs={6} className={styles.docs}>
              <Grid container xs={12}>
                {(docs ?? [])?.map((doc, index) => (
                  <Grid
                    key={index}
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                    className={styles.item}
                    onClick={() => setDocumentForDialog(doc)}
                  >
                    <Grid xs={2}>
                      <IoDocumentOutline size={60} color="#6B97F6" />
                    </Grid>
                    <Grid xs={10}>
                      <p style={{ position: "relative" }}>
                        <span className={styles.documentName}>
                          {doc.data.info.name}
                        </span>
                        <IoEyeOutline
                          size={20}
                          color="#6B97F6"
                          style={{ position: "absolute", marginLeft: "5px" }}
                        />
                      </p>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {documentForDialog && (
        <IFrameURLModal
          fileName={documentForDialog.data.info.name}
          url={documentForDialog.data.info.url}
          closeDialog={() => setDocumentForDialog(undefined)}
        />
      )}
    </>
  );
};

export default UploadDocuments;
