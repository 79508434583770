import Grid from "@Components/Grid";
import { renderFieldData } from "@Pages/Applications/CreateApplication/ReviewApplication/ReviewApplication";
import { ApplicantInfo } from "@Types/applicants";
import {
  calculateAge,
  capitalize,
  formatDate,
  formatNumberAsCurrency,
  formatPhoneNumber,
  formatSSN,
  isValidDate,
} from "@Utils/functions";

import styles from "./Applicants.module.scss";
const renderAddressReview = (
  applicant: ApplicantInfo,
  type: "current" | "previous"
) => {
  return (
    <Grid xs={12}>
      {renderFieldData("Number", applicant[`${type}AddressNumber`] ?? "-")}
      {renderFieldData("Address", applicant[`${type}Address`] ?? "-")}
      {renderFieldData("Zip Code", applicant[`${type}ZipCode`] ?? "-")}
      {renderFieldData("State", applicant[`${type}State`] ?? "-")}
      {renderFieldData("County", applicant[`${type}County`] ?? "-")}
      {renderFieldData("City", applicant[`${type}AddressNumber`] ?? "-")}
      {renderFieldData(
        "Years At Address",
        `${
          applicant[
            `yearsAt${type === "current" ? "Current" : "Previous"}Address`
          ] ?? "-"
        }`
      )}
      {renderFieldData(
        "Months At Address",
        `${
          applicant[
            `monthsAt${type === "current" ? "Current" : "Previous"}Address`
          ] ?? "-"
        }`
      )}
    </Grid>
  );
};

type ApplicantProps = {
  type?: "applicant" | "coApplicant";
  applicant: ApplicantInfo;
  variant?: "default" | "blue";
  noWrapper?: boolean;
};
const sectionTitleByType = (
  title: string,
  type?: "applicant" | "coApplicant"
) => (type ? `${capitalize(type)} - ${title}` : title);
const ApplicantViewForm = ({
  type,
  applicant,
  variant = "default",
  noWrapper = false,
}: ApplicantProps) => {
  const renderApplicant = () => {
    return (
      <>
        <Grid xs={6}>
          <div className={`${styles.container} ${styles[variant]}`}>
            <div className={styles.titleSecondary}>
              {sectionTitleByType("Personal Info", type)}
            </div>
            {renderFieldData(
              "Name",
              `${applicant.firstName ?? ""} ${applicant.middleName ?? ""} ${
                applicant.lastName ?? ""
              }`
            )}
            {renderFieldData(
              "Mobile Phone",
              `${formatPhoneNumber(applicant.mobilePhone) ?? "-"}`
            )}
            {renderFieldData(
              "Home Phone",
              `${formatPhoneNumber(applicant?.homePhone) ?? "-"}`
            )}
            {renderFieldData(
              "Social Security Number",
              `${formatSSN(applicant.socialSecurityNumber) ?? "-"}`
            )}
            {renderFieldData(
              "Date Of Birth",
              `${
                isValidDate(applicant?.birthDate) && applicant?.birthDate
                  ? formatDate(new Date(applicant.birthDate))
                  : "-"
              }`
            )}
            {renderFieldData(
              "Age",
              `${
                applicant.birthDate
                  ? calculateAge(new Date(applicant.birthDate))
                  : "-"
              }`
            )}
            {renderFieldData("Email", `${applicant.email ?? "-"}`)}
            {renderFieldData("Is US Citizen?", `${applicant.usCitizen ?? "-"}`)}
            {renderFieldData(
              "Marital Status",
              `${applicant.maritalStatus ?? "-"}`
            )}
          </div>
        </Grid>

        <Grid xs={6}>
          <div className={`${styles.container} ${styles[variant]}`}>
            <div className={styles.titleSecondary}>
              {sectionTitleByType("Current Address", type)}
            </div>
            {renderAddressReview(applicant, "current")}
          </div>
        </Grid>
        <Grid xs={12}>
          <div className={`${styles.container} ${styles[variant]}`}>
            <div className={styles.titletitleSecondary}>
              {sectionTitleByType("Property Information", type)}
            </div>
            <Grid xs={6}>
              {renderFieldData(
                "Property Ownership",
                `${applicant.propertyOwnership ?? "-"}`
              )}
              {applicant.propertyOwnership === "Owner"
                ? renderFieldData(
                    "Mortgage Per Month",
                    `${
                      formatNumberAsCurrency(applicant.mortgagePerMonth, "$") ??
                      "-"
                    }`
                  )
                : renderFieldData(
                    "Rent Per Month",
                    `${
                      formatNumberAsCurrency(applicant.rentPerMonth, "$") ?? "-"
                    }`
                  )}
            </Grid>
          </div>
        </Grid>
        <Grid xs={6}>
          <div className={`${styles.container} ${styles[variant]}`}>
            <div className={styles.titletitleSecondary}>
              {sectionTitleByType("Previous Address", type)}
            </div>

            {renderAddressReview(applicant, "previous")}
          </div>
        </Grid>

        <Grid xs={6}>
          <div className={`${styles.container} ${styles[variant]}`}>
            <div className={styles.titletitleSecondary}>
              {sectionTitleByType("Drivers License", type)}
            </div>
            {renderFieldData(
              "License Number",
              `${applicant.driverLicenseNumber ?? "-"}`
            )}
            {renderFieldData(
              "State",
              `${applicant.drivingLicenseState ?? "-"}`
            )}
            {renderFieldData(
              "License Issue Date",
              `${
                isValidDate(applicant?.driverLicenseIssued) &&
                applicant?.driverLicenseIssued
                  ? formatDate(new Date(applicant.driverLicenseIssued))
                  : "-"
              }`
            )}
            {renderFieldData(
              "License Expiry Date",
              `${
                isValidDate(applicant?.driverLicenseExpires) &&
                applicant?.driverLicenseExpires
                  ? formatDate(new Date(applicant.driverLicenseExpires))
                  : "-"
              }`
            )}
          </div>
        </Grid>
        <Grid xs={12}>
          <div className={`${styles.container} ${styles[variant]}`}>
            <div className={styles.titletitleSecondary}>
              {sectionTitleByType("Employment", type)}
            </div>

            <Grid container xs={12}>
              <Grid xs={6}>
                {renderFieldData(
                  "Employment Status",
                  `${applicant.employmentStatus ?? "-"}`
                )}
                {renderFieldData(
                  "Bussines Phone",
                  `${formatPhoneNumber(applicant.businessPhone) ?? "-"}`
                )}
                {renderFieldData("Employer", `${applicant.employer ?? "-"}`)}
                {renderFieldData(
                  "Job Occupation",
                  `${applicant.jobOccupation ?? "-"}`
                )}
                {renderFieldData(
                  "Years",
                  `${applicant.yearsAtCurrentJob ?? "-"}`
                )}
                {renderFieldData(
                  "Months",
                  `${applicant.monthsAtCurrentJob ?? "-"}`
                )}
                {renderFieldData(
                  "Anual Income",
                  `${
                    formatNumberAsCurrency(applicant.annualIncome, "$") ?? "-"
                  }`
                )}
              </Grid>
              <Grid xs={6}>
                {renderFieldData(
                  "Monthly Income",
                  `${
                    formatNumberAsCurrency(applicant.monthlyIncome, "$") ?? "-"
                  }`
                )}
                {renderFieldData(
                  "Additional Income",
                  `${
                    formatNumberAsCurrency(applicant.additionalIncome, "$") ??
                    "-"
                  }`
                )}
                {renderFieldData(
                  "Retirement Income",
                  `${
                    formatNumberAsCurrency(applicant.retirementIncome, "$") ??
                    "-"
                  }`
                )}
                {renderFieldData(
                  "Previous Employer",
                  `${applicant.previousEmployer ?? "-"}`
                )}
                {renderFieldData(
                  "Previous Job Occupation",
                  `${applicant.previousOccupation ?? "-"}`
                )}
                {renderFieldData(
                  "Years",
                  `${applicant.yearsAtPreviousJob ?? "-"}`
                )}
                {renderFieldData(
                  "Months",
                  `${applicant.monthsAtPreviousJob ?? "-"}`
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </>
    );
  };
  return noWrapper ? (
    renderApplicant()
  ) : (
    <Grid container xs={12} spacing={2}>
      {renderApplicant()}
    </Grid>
  );
};
export default ApplicantViewForm;
