import { FormEvent, Fragment, useState } from "react";
import Paper from "@Components/Paper";
import Button from "@Components/Button";
import TextField from "@Components/Inputs/TextField";
import Grid from "@mui/system/Unstable_Grid";
import { AiOutlineMail } from "react-icons/ai";
import {
  AdfType,
  EmailAdfData,
  Settings as SettingsType,
  SftpAdfData,
} from "@Types/settings";
import styles from "./Settings.module.scss";
import { UseMutateFunction } from "@tanstack/react-query";
import { MutationError } from "@Types/index";
import Radio from "@Components/Radio";

interface SettingsFormProps {
  settings?: SettingsType;
  updateSettings: UseMutateFunction<
    SettingsType,
    MutationError,
    SettingsType | Omit<SettingsType, "_id">,
    unknown
  >;
}
const getAdfDataByAdfType = (
  email: string | undefined,
  host: string | undefined,
  port: number | undefined,
  username: string | undefined,
  password: string | undefined,
  directory: string | undefined,
  dcid: string | undefined,
  adfType: AdfType
): SftpAdfData | EmailAdfData | undefined => {
  switch (adfType) {
    case "email":
      if (email) {
        return { sftp: false, email };
      }
      return undefined;
    case "sftp":
      if (
        host &&
        typeof port === "number" &&
        username &&
        password &&
        directory &&
        dcid
      ) {
        return {
          sftp: true,
          passwordEncrypted: false,
          host,
          dcid,
          port,
          password,
          directory,
          username,
        };
      }
      return undefined;
    default:
      return undefined;
  }
};

const SettingsForm = ({ settings, updateSettings }: SettingsFormProps) => {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [dcid, setDcid] = useState<string | undefined>(undefined);
  const [host, setHost] = useState<string | undefined>(undefined);
  const [port, setPort] = useState<number | undefined>(undefined);
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [directory, setDirectory] = useState<string | undefined>(undefined);

  const [adfType, setAdfType] = useState<AdfType>("email");
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const adfData = getAdfDataByAdfType(
      email,
      host,
      port,
      username,
      password,
      directory,
      dcid,
      adfType
    );
    e.preventDefault();
    if (adfData) {
      updateSettings({
        ...(settings ?? {}),
        data: {
          ...(settings?.data ?? {}),
          info: {
            ...(settings?.data.info ?? {}),
            adfEmails: [...(settings?.data?.info?.adfEmails ?? []), adfData],
          },
        },
      });
      setEmail(undefined);
      setHost(undefined);
      setPort(undefined);
      setUsername(undefined);
      setPassword(undefined);
      setDirectory(undefined);
      setDcid(undefined);
    }
  };
  const renderFieldsByAdfType = (adfType: AdfType) => {
    switch (adfType) {
      case "email":
        return (
          <Grid md={9} sm={12}>
            <TextField
              value={email}
              id={"adf-email-input"}
              type="email"
              showPlaceHolderTop
              required
              startIcon={<AiOutlineMail />}
              inputStyle={{ borderRadius: "5px" }}
              iconStyle={{ color: "#373739" }}
              placeholder="New Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        );
      case "sftp":
        return (
          <Fragment>
            <Grid md={9} sm={12}>
              <TextField
                value={dcid}
                id={"dcid"}
                type="text"
                required
                showPlaceHolderTop
                inputStyle={{ borderRadius: "5px" }}
                iconStyle={{ color: "#373739" }}
                placeholder="DCID"
                onChange={(e) => setDcid(e.target.value)}
              />
            </Grid>
            <Grid md={9} sm={12}>
              <TextField
                value={host}
                id={"host"}
                type="text"
                required
                showPlaceHolderTop
                inputStyle={{ borderRadius: "5px" }}
                iconStyle={{ color: "#373739" }}
                placeholder="Host"
                onChange={(e) => setHost(e.target.value)}
              />
            </Grid>
            <Grid md={9} sm={12}>
              <TextField
                value={port}
                id={"port"}
                type="number"
                required
                showPlaceHolderTop
                inputStyle={{ borderRadius: "5px" }}
                iconStyle={{ color: "#373739" }}
                placeholder="Port"
                onChange={(e) =>
                  setPort(e.target.value ? Number(e.target.value) : undefined)
                }
              />
            </Grid>
            <Grid md={9} sm={12}>
              <TextField
                value={username}
                id={"username"}
                type="text"
                required
                showPlaceHolderTop
                inputStyle={{ borderRadius: "5px" }}
                iconStyle={{ color: "#373739" }}
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid md={9} sm={12}>
              <TextField
                value={password}
                id={"password"}
                type="password"
                required
                showPlaceHolderTop
                inputStyle={{ borderRadius: "5px" }}
                iconStyle={{ color: "#373739" }}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid md={9} sm={12}>
              <TextField
                value={directory}
                id={"directory"}
                showPlaceHolderTop
                type="text"
                required
                inputStyle={{ borderRadius: "5px" }}
                iconStyle={{ color: "#373739" }}
                placeholder="Directory"
                onChange={(e) => setDirectory(e.target.value)}
              />
            </Grid>
          </Fragment>
        );
    }
  };
  return (
    <form
      data-testid={"settings-form"}
      id={"settings-form"}
      className={styles.settingsForm}
      onSubmit={handleSubmit}
    >
      <Paper
        title={adfType === "email" ? "Add ADF email" : "Add SFTP configuration"}
        outerClassName={styles.formPaper}
      >
        <Grid container spacing={5} xs={12}>
          <Grid md={9} sm={12}>
            <Radio
              id="sftpOrEmail"
              group="sftpOrEmail"
              required
              possibleValues={[
                {
                  value: "sftp",
                  label: "SFTP",
                  description: "Send ADF leads via SFTP credentials.",
                },
                {
                  value: "email",
                  label: "Email",
                  description: "Send ADF leads via email.",
                },
              ]}
              onChange={(e) => {
                switch (e.target.value) {
                  case "sftp":
                  case "email":
                    return setAdfType(e.target.value);
                }
              }}
              selectedValue={adfType}
            />
          </Grid>
          {renderFieldsByAdfType(adfType)}
          <Grid md={3} sm={12} className={styles.formButton}>
            <Button
              form={"settings-form"}
              id={"save-settings-button"}
              variant="gradient"
              type="submit"
            >
              Add {adfType === "email" ? "email" : "configuration"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};
export default SettingsForm;
