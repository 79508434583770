import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { MutationError } from "@Types/index";
import { Settings } from "@Types/settings";
import { getSettings, updateSettings } from "@Http/settings";
import { queryClient } from "../App";

export const useGetSettings = () => {
  const queryKey = ["settings"];

  const {
    data: settings,
    isLoading,
    error,
    isSuccess,
  } = useQuery({
    queryKey: queryKey,
    queryFn: getSettings(),
  });

  return {
    isSuccess,
    settings,
    isLoading,
    error,
  };
};

export const useUpdateSettings = () => {
  const mutation = useMutation<
    Settings,
    MutationError,
    Settings | Omit<Settings, "_id">
  >(updateSettings(), {
    onSuccess: (data) => {
      queryClient.setQueriesData<Settings>(["settings"], () => {
        return data;
      });
      toast.success("Successfully updated settings.", {
        position: "top-center",
      });
    },
  });
  return {
    updateSettings: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useGetSettings;
