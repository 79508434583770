import { useState } from "react";
import { CiCircleRemove } from "react-icons/ci";

import Button from "@Components/Button";
import Grid from "@Components/Grid";
import TextField from "@Components/Inputs/TextField";
import IconButton from "@Components/IconButton";

import { RegularQuery } from "@Types/http";

import { FilterPreviewProps } from "./types";

const TextFilter = ({
  filterState,
  setFilterState,
  queryPath,
  partialSearch,
  caseInsensitive,
  placeholder,
}: FilterPreviewProps) => {
  const [current, setCurrent] = useState("");

  const handleAddFilter = (value: string) => {
    if (value) {
      const newValues = [...(filterState?.values ?? []), value];
      setFilterState({
        values: newValues,
        query: {
          ...(filterState?.query ?? {}),
          [queryPath.join(".")]: {
            $in: newValues.map((value) => ({
              value,
              ...(partialSearch ? { partialSearch: true } : {}),
              ...(caseInsensitive ? { caseInsensitive: true } : {}),
            })),
          },
        } as RegularQuery,
      });
      setCurrent("");
    }
  };
  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement> & {
      target: React.BaseSyntheticEvent<
        KeyboardEvent,
        EventTarget & HTMLInputElement,
        EventTarget
      > & { value: string };
    }
  ) => {
    if (e.keyCode === 13 && e?.target?.value?.trim() !== "") {
      handleAddFilter(e.target.value);
    }
  };

  const handleRemoveFilter = (value: string) => {
    const filteredState = filterState?.values.filter((x) => x !== value) ?? [];

    setFilterState({
      values: filteredState,
      query: {
        ...(filterState?.query ?? {}),
        [queryPath.join(".")]: {
          $in: filteredState.map((value) => ({
            value,
            ...(partialSearch ? { partialSearch: true } : {}),
            ...(caseInsensitive ? { caseInsensitive: true } : {}),
          })),
        },
      } as RegularQuery,
    });
  };
  return (
    <Grid container xs={12} spacing={1}>
      <Grid xs={12}>
        {(filterState?.values ?? []).map((value, index: number) => (
          <IconButton
            key={index}
            onClick={() => handleRemoveFilter(value)}
            flex
            style={{ fontSize: "16px" }}
          >
            {value}
            <CiCircleRemove size={18} />
          </IconButton>
        ))}
      </Grid>
      <TextField
        onKeyUp={handleKeyUp}
        onChange={(e) => setCurrent(e.target.value)}
        placeholder={placeholder}
        value={current}
        name="Email"
        required
        type="email"
      />
      <Button
        type="button"
        variant="blue"
        onClick={() => handleAddFilter(current)}
      >
        Apply Filter
      </Button>
    </Grid>
  );
};

export default TextFilter;
