import { useQuery } from "@tanstack/react-query";

import { getApplications } from "@Http/applications";
import { getApplicants } from "@Http/applicants";
import { getUsers } from "@Http/users";

import { HttpQuery } from "@Types/http";
import { hasActionPermissions } from "@Utils/permissions";
import useCurrentUser from "./useCurrentUser";

const useGlobalSearch = (query: HttpQuery) => {
  const user = useCurrentUser();
  const dealPermissions = hasActionPermissions("deal", "read", user);
  const userPermissions = hasActionPermissions("dealership_user", "read", user);
  const applicantsPermissions = hasActionPermissions("applicant", "read", user);

  const {
    data: deals,
    isLoading: dealsAreLoading,
    error: dealsError,
  } = useQuery({
    queryKey: ["deals-global-search", query ?? {}],
    queryFn: getApplications(query),
    enabled: !!Object.keys(query)?.length && dealPermissions,
  });
  const {
    data: applicants,
    isLoading: applicantsAreLoading,
    error: applicantsError,
  } = useQuery({
    queryKey: ["applicants-global-search", query ?? {}],
    queryFn: getApplicants(query),
    enabled: !!Object.keys(query)?.length && applicantsPermissions,
  });
  const {
    data: users,
    isLoading: usersAreLoading,
    error: usersError,
  } = useQuery({
    queryKey: ["members-global-searchs", query ?? {}],
    queryFn: getUsers(query),
    enabled: !!Object.keys(query)?.length && userPermissions,
  });

  return {
    data: { applicants, deals, users },
    isLoading: { applicantsAreLoading, dealsAreLoading, usersAreLoading },
    error: { dealsError, applicantsError, usersError },
  };
};
export default useGlobalSearch;
