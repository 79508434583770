import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { updateCollateral } from "@Http/collateral";

import { CreatCollateralChangeRequest, Collateral } from "@Types/deal";
import { MutationError } from "@Types/index";

const useCreateCollateralChange = () => {
  const mutation = useMutation<
    Collateral,
    MutationError,
    CreatCollateralChangeRequest
  >(updateCollateral(), {
    onSuccess: () => {
      toast.success("Successfully added a collateral change request.", {
        position: "top-center",
      });
    },
  });
  return {
    collateralChangeRequest: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useCreateCollateralChange;
