import { auth } from "@Firebase/firebase";
import axios from "axios";

export interface AddressComponent<T> {
  /** is an array indicating the *type* of the address component. */
  types: T[];
  /** is the full text description or name of the address component as returned by the Geocoder. */
  long_name: string;
  /**
   * is an abbreviated textual name for the address component, if available.
   * For example, an address component for the state of Alaska may have a `long_name` of "Alaska" and a `short_name` of "AK"
   * using the 2-letter postal abbreviation.
   */
  short_name: string;
}

export interface NormalizedGeocodingResult {
  type: string;
  state?: AddressComponent<"administrative_area_level_1">;
  county?: AddressComponent<"administrative_area_level_2">;
  city?: AddressComponent<"locality">;
  postal_code?: AddressComponent<"postal_code">;
}

export interface ZipCodeLookupResult {
  local?: { primary_city: string; county: string; state: string };
  google?: NormalizedGeocodingResult[];
  usps?: {
    CityStateLookupResponse?: { ZipCode?: { City: string; State: string } };
  };
}

export type Result = {
  Value: null | string;
  ValueId: string | null;
  Variable: string;
  VariableId: number;
};

export const lookupZip = async (zip: string): Promise<ZipCodeLookupResult> => {
  const token = await auth.currentUser?.getIdToken();
  const response = await axios.get(
    `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/zip_code_lookup/${zip}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.result;
};
