import styles from "./Loading.module.scss";

export type LoadingProps = {
  size?: number;
  text?: string;
};
const LIGHT_GRAY = "#a5aec1";
const BLUE = "#6b97f6";
export default function Loading({ size = 50, text }: LoadingProps) {
  const message = typeof text === "string" ? text : undefined;
  return (
    <div className={styles.spinnerContainer}>
      <div
        className={styles.loadingSpinner}
        style={{
          height: `${size}px`,
          width: `${size}px`,
          border: `${Math.round(size / 5)}px solid ${LIGHT_GRAY}`,
          borderTop: `${Math.round(size / 5)}px solid ${BLUE}`,
        }}
      />
      {message ? <h2>{text}</h2> : null}
    </div>
  );
}
