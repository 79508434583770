import styles from "./Chat.module.scss";
const AttachementIcon = () => {
  return (
    <svg
      width="94"
      height="94"
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.0169601"
        width="93.0146"
        height="93.0146"
        rx="14.3099"
        fill="#E7EEFF"
      />
      <path
        d="M47.8078 30.3421L57.5115 39.9362C58.4677 40.8699 59.0002 42.147 59.0111 43.4777V45.9245V48.9079V73.1401C59.0111 76.3489 56.3705 78.9567 53.1215 78.9567H45.1999H31.8886H23.4997C20.6962 78.9567 18.4251 76.7137 18.4251 73.945V33.8836C18.4251 31.1148 20.6962 28.8719 23.4997 28.8719H44.2219C45.5693 28.8719 46.8516 29.3977 47.8078 30.3421Z"
        fill="#B7CDFF"
      />
      <path
        d="M56.7468 23.3261L56.7465 23.3258C55.656 22.2488 54.193 21.6492 52.6578 21.6492H31.9356C28.7454 21.6492 26.1455 24.204 26.1455 27.3764V67.4377C26.1455 70.6101 28.7454 73.1649 31.9356 73.1649H40.3245H53.6358H61.5574C65.1932 73.1649 68.1625 70.2452 68.1625 66.6329V42.4007V39.4173L68.1625 36.9705L68.1625 36.9646C68.1501 35.4478 67.5433 33.988 66.4489 32.9187C66.4484 32.9181 66.4478 32.9176 66.4473 32.9171L56.7468 23.3261Z"
        fill="white"
        stroke="#6B97F6"
        strokeWidth="1.43099"
      />
      <circle cx="47.1543" cy="47.718" r="14.0727" fill="#6B97F6" />
      <path
        d="M47.1541 41.295V54.1408"
        stroke="white"
        strokeWidth="1.69845"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.5769 47.7179H40.7312"
        stroke="white"
        strokeWidth="1.69845"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const AttachmentLoadingIcon = () => {
  return (
    <svg
      id="attachment-loading-icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 84 84"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      height={60}
      width={60}
    >
      <circle
        r="41.5899"
        transform="translate(41.5899 42.0001)"
        fill="#e7eeff"
      />
      <g
        className={styles.rotatingCircle}
        transform="translate(41.5899,42.0001) rotate(0)"
      >
        <circle
          r="40.5899"
          transform="translate(-0.000001,0.000005)"
          fill="none"
          stroke="#6b97f6"
          strokeWidth="2"
          strokeDashoffset="255.03"
          strokeDasharray="223"
        />
      </g>
      <path
        d="M49.3203,21.9578l8.2505,8.1574c.813.7938,1.2657,1.8796,1.275,3.0111v2.0803v2.5367v20.6032c0,2.7282-2.2451,4.9455-5.0076,4.9455h-6.7353-11.3178-7.1326c-2.3836,0-4.3146-1.9071-4.3146-4.2612v-34.0619c0-2.3541,1.931-4.2611,4.3146-4.2611h17.6189c1.1457,0,2.2359.4471,3.0489,1.25Z"
        fill="#6b97f6"
      />
      <line
        x1="31.8376"
        y1="48.6289"
        x2="51.3431"
        y2="48.6289"
        fill="none"
        stroke="#fff"
        strokeWidth="1.08928"
        strokeLinecap="round"
      />
      <line
        x1="31.8376"
        y1="40.4998"
        x2="51.3431"
        y2="40.4998"
        fill="none"
        stroke="#fff"
        strokeWidth="1.08928"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const AttachmentUploadedIcon = () => {
  return (
    <svg
      height={60}
      width={60}
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="41.5899" cy="42.0001" r="41.5899" fill="#E7EEFF" />
      <circle
        cx="41.5899"
        cy="42.0001"
        r="40.5899"
        stroke="#6B97F6"
        strokeWidth="2"
      />
      <path
        d="M49.3203 21.9578L57.5708 30.1152C58.3838 30.909 58.8365 31.9948 58.8458 33.1263V35.2066V37.7433V58.3465C58.8458 61.0747 56.6007 63.292 53.8382 63.292H47.1029H35.7851H28.6525C26.2689 63.292 24.3379 61.3849 24.3379 59.0308V24.9689C24.3379 22.6148 26.2689 20.7078 28.6525 20.7078H46.2714C47.4171 20.7078 48.5073 21.1549 49.3203 21.9578Z"
        fill="#6B97F6"
      />
      <line
        x1="31.8376"
        y1="48.6289"
        x2="51.3431"
        y2="48.6289"
        stroke="white"
        strokeWidth="1.08928"
        strokeLinecap="round"
      />
      <line
        x1="31.8376"
        y1="40.4998"
        x2="51.3431"
        y2="40.4998"
        stroke="white"
        strokeWidth="1.08928"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default AttachementIcon;
