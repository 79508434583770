import axios from "axios";

import { HttpQuery } from "@Types/http";
import { auth } from "@Firebase/firebase";
import { CreateRoleRequest, Role } from "@Types/roles";

import { queryClient } from "../App";

export const getRoles = (query?: HttpQuery): (() => Promise<Role[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_role/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return response.data.result?.docs ?? [];
  };
};

export const getRole = (id: string): (() => Promise<Role>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_role/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.result;
  };
};

export const updateRole = (): ((role: Role) => Promise<Role>) => {
  return async (role) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_role/${role._id}`,
      role,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    queryClient.setQueryData<Role>(
      [`role-${role._id}`],
      () => response.data.result
    );
    return response.data.result;
  };
};
export const deleteRole = (): ((role: Role) => Promise<Role>) => {
  return async (role) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.delete(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_role/${role._id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};

export const createRole = (): ((role: CreateRoleRequest) => Promise<Role>) => {
  return async (role) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_role/`,
      role,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};
