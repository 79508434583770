import { useState } from "react";

import TextField from "@Components/Inputs/TextField";

import useDebounce from "@Hooks/useDebounce";

import { User } from "@Types/users";
import { Applicant } from "@Types/applicants";

import ResultsTable from "./ResultsTable";
import { ResultsTableProps, SearchState } from "./types";

import styles from "./Search.module.scss";
import { BiSearch } from "react-icons/bi";
import useChatSearch from "@Hooks/useChatSearch";

const transformSearchToSearchTerm = (search: string) => {
  if (!search) return {};
  return search?.length > 1 ? { search: { term: search } } : {};
};
const transformRequestDataToResultsTableProps = (requestData: {
  data: {
    applicants: Applicant[] | undefined;
    users: User[] | undefined;
  };
  isLoading: {
    applicantsAreLoading: boolean;
    usersAreLoading: boolean;
  };
  error: {
    applicantsError: unknown;
    usersError: unknown;
  };
}): ResultsTableProps => {
  return {
    applicants: {
      data: requestData.data.applicants,
      loading: requestData.isLoading.applicantsAreLoading,
      error: requestData.error.applicantsError,
    },
    users: {
      data: requestData.data.users,
      loading: requestData.isLoading.usersAreLoading,
      error: requestData.error.usersError,
    },
  };
};
const Search = () => {
  const [state, setState] = useState<SearchState>({ value: "", focus: false });

  const debouncedValue = useDebounce<SearchState>(state, 1000);
  const requestResult = useChatSearch(
    transformSearchToSearchTerm(debouncedValue.value)
  );

  return (
    <div className={styles.container}>
      <TextField
        value={state.value}
        type="text"
        startIcon={<BiSearch />}
        inputStyle={{ background: "#F3F3F3", borderRadius: "5px" }}
        iconStyle={{ color: "#373739" }}
        placeholder="Find someone to chat"
        onChange={(e) =>
          setState((prev) => ({ ...prev, value: e.target.value }))
        }
        onFocus={() => setState((prev) => ({ ...prev, focus: true }))}
        onBlur={() => setState((prev) => ({ ...prev, focus: false }))}
      />
      {state.focus && state.value.length > 1 && (
        <ResultsTable
          {...transformRequestDataToResultsTableProps(requestResult)}
        />
      )}
    </div>
  );
};
export default Search;
