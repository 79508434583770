import { updateChatMember, getChatMember } from "@Http/chat_members";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ChatMember, ChatMemberType } from "@Types/chat_members";
import { MutationError } from "@Types/index";
import { queryClient } from "../App";

export const useChangeChatStatus = () => {
  const mutation = useMutation<ChatMember, MutationError, string>(
    updateChatMember(),
    {
      onError: () => {
        queryClient.setQueriesData<ChatMember>(
          ["chat_member"],
          (oldChatMember) => {
            if (oldChatMember) {
              return {
                ...oldChatMember,
                data: {
                  ...(oldChatMember?.data ?? {}),
                  info: {
                    ...(oldChatMember?.data?.info ?? {}),
                    status:
                      oldChatMember?.data?.info?.status === "active"
                        ? "away"
                        : "active",
                  },
                },
              };
            }
          }
        );
      },
    }
  );
  return {
    changeChatStatus: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};
export const useGetChatMember = (
  email: string,
  type: ChatMemberType,
  enabled = true
) => {
  const { data, isLoading, error } = useQuery<
    ChatMember,
    MutationError,
    ChatMember
  >({
    queryKey: ["chat_member"],
    queryFn: getChatMember(email, type),
    enabled,
  });

  return {
    data,
    isLoading,
    error,
  };
};
