import useRedirect from "@Hooks/useRedirect";

import styles from "./ScanDriverLicense.module.scss";

const ScanDriverLicense = () => {
  useRedirect();

  return (
    <div id="scan-driver-license" className={styles.container}>
      <h2>Download the Web Finance Direct App to continue.</h2>
    </div>
  );
};
export default ScanDriverLicense;
