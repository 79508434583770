import { TitleIssue } from "@Types/title_issues";
import { formatNumberAsCurrency } from "@Utils/functions";
import { formatCustomersNames } from "@Pages/Applications/Applications";
import { renderFieldData } from "@Pages/Applications/CreateApplication/ReviewApplication/ReviewApplication";

export default function Overview({ titleIssue }: { titleIssue: TitleIssue }) {
  return (
    <div>
      {renderFieldData(
        "DMV",
        titleIssue.data.deal?.data?.applicant?.data?.info?.currentState ?? ""
      )}
      {renderFieldData(
        "Fee",
        formatNumberAsCurrency(
          titleIssue.data?.deal?.data?.info?.taxesAndFees?.titleTransfer,
          "$"
        ) ?? ""
      )}
      {renderFieldData(
        "Lender",
        titleIssue.data.deal?.data?.lender?.data?.info?.name ?? ""
      )}
      {renderFieldData(
        "Applicants",
        titleIssue.data.deal ? formatCustomersNames(titleIssue.data.deal) : ""
      )}
    </div>
  );
}
