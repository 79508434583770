import { createMessage, getMessages, updateMessage } from "@Http/messages";
import { useMutation, useQuery } from "@tanstack/react-query";
import { HttpQuery } from "@Types/http";
import { MutationError } from "@Types/index";
import { CreateMessageRequest, Message } from "@Types/messages";

const useGetMessages = (
  channelId: string,
  query?: HttpQuery,
  enabled = true
) => {
  const queryKey = [`messages-${channelId}`, query ?? []];

  const { data, isLoading, isFetching, error } = useQuery<
    Message[],
    MutationError,
    Message[]
  >({
    queryKey: queryKey,
    queryFn: getMessages(channelId, query),
    enabled,
  });

  return {
    data,
    isLoading,
    isFetching,
    error,
  };
};

export const useCreateMessage = () => {
  const mutation = useMutation<Message, MutationError, CreateMessageRequest>(
    createMessage(),
    {
      onSuccess: () => {
        return;
      },
    }
  );
  return {
    createMessage: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useUpdateMessage = () => {
  const mutation = useMutation<Message, MutationError, string>(
    updateMessage(),
    {
      onSuccess: () => {
        return;
      },
    }
  );
  return {
    updateMessage: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useGetMessages;
