import { useState } from "react";
import { HiOutlineFilter, HiFilter } from "react-icons/hi";

import AnchoredDialog from "@Components/AnchoredDialog";
import { AnchoredDialogContent } from "@Components/AnchoredDialog/AnchoredDialogContent";
import IconButton from "@Components/IconButton";

import { FilterProps } from "./types";

const Filter = ({
  columnFilters,
  filterState,
  id,
  setFilterState,
}: FilterProps) => {
  const [open, setOpen] = useState(false);
  return (
    <div data-testid={id}>
      <IconButton
        dataTestId={"open-filter-button"}
        style={{ padding: 2, display: "flex" }}
        onClick={() => setOpen(!open)}
      >
        {filterState?.values.length ? (
          <HiFilter size={20} color="#6B97F6" />
        ) : (
          <HiOutlineFilter size={20} />
        )}
      </IconButton>

      <AnchoredDialog
        size="xs-min"
        open={open}
        closeFn={() => setOpen(false)}
        spacing={false}
      >
        <AnchoredDialogContent>
          {columnFilters.map((filter, index) => (
            <div key={index} data-testid={filter.placeholder}>
              <filter.preview
                placeholder={filter.placeholder}
                valuesForSelect={filter.valuesForSelect}
                queryPath={filter.queryPath}
                caseInsensitive={filter.caseInsensitive}
                partialSearch={filter.partialSearch}
                filterState={filterState}
                setFilterState={setFilterState}
              />
            </div>
          ))}
        </AnchoredDialogContent>
      </AnchoredDialog>
    </div>
  );
};
export default Filter;
