import useGetSettings, { useUpdateSettings } from "@Hooks/useSettings";
import SettingsForm from "./SettingsForm";
import SettingsTable from "./SettingsTable";
import Loading from "@Components/Loading";
import Grid from "@mui/system/Unstable_Grid";
import styles from "./Settings.module.scss";

const Settings = () => {
  const { settings, isLoading } = useGetSettings();
  const { updateSettings } = useUpdateSettings();

  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.title}>Settings</h2>
        <Grid container xs={12}>
          <Grid xs={12}>
            <SettingsTable
              settings={settings}
              isLoading={isLoading}
              updateSettings={updateSettings}
            />
          </Grid>
          <Grid xs={12}>
            {isLoading ? (
              <Loading />
            ) : (
              <SettingsForm
                settings={settings}
                updateSettings={updateSettings}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default Settings;
