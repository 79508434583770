import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getTitleIssue,
  getTitleIssues,
  updateTitleIssue,
} from "@Http/titile_issues";
import { HttpQuery } from "@Types/http";
import { MutationError } from "@Types/index";
import { AddTitleIssueDocumentRequest, TitleIssue } from "@Types/title_issues";

import { queryClient } from "../App";

const useGetTitleIssues = (query?: HttpQuery) => {
  const queryKey = ["title_issues", query ?? []];

  const { data, isLoading, error } = useQuery<
    TitleIssue[],
    MutationError,
    TitleIssue[]
  >({
    queryKey: queryKey,
    queryFn: getTitleIssues(query),
  });

  return {
    data,
    isLoading,
    error,
  };
};
export const useGetTitleIssue = (id?: string) => {
  const queryKey = [`title-issue-${id}`];

  const {
    data: titleIssue,
    isLoading,
    error,
  } = useQuery<TitleIssue, MutationError, TitleIssue>({
    queryKey: queryKey,
    queryFn: getTitleIssue(id as string),
    enabled: !!id,
  });

  return {
    titleIssue,
    isLoading,
    error,
  };
};

export const useUpdateTitleIssue = () => {
  const mutation = useMutation<
    TitleIssue,
    MutationError,
    AddTitleIssueDocumentRequest
  >(updateTitleIssue(), {
    onSuccess: (data) => {
      queryClient.setQueriesData<TitleIssue>(
        [`title-issue-${data._id}`],
        (oldData) => {
          if (oldData)
            return {
              ...oldData,
              data: {
                ...oldData.data,
                info: {
                  ...oldData?.data.info,
                  titleRegistrationInfo: data.data.info.titleRegistrationInfo,
                  receiptInfo: data.data.info.receiptInfo,
                  oldTitleRegistrationInfo:
                    data.data.info.oldTitleRegistrationInfo,
                },
              },
            };
        }
      );
      toast.success("Successfully updated a titile issue.", {
        position: "top-center",
      });
    },
  });
  return {
    update: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};
export default useGetTitleIssues;
