export default ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21" cy="21" r="21" fill="currentColor" />
    <path
      d="M22.8613 12.1289V30H20.7007V14.9487L16.9653 16.6089V14.5703L22.5317 12.1289H22.8613Z"
      fill="white"
    />
  </svg>
);
