import { RxPerson } from "react-icons/rx";

import Loading from "@Components/Loading";

import { formatFirstAndLastNames } from "@Utils/functions";

import { ResultsTableProps } from "./types";

import styles from "./Search.module.scss";
import { useCreateChannel } from "@Hooks/useChannels";
import useCurrentUser from "@Hooks/useCurrentUser";
import { Applicant } from "@Types/applicants";
import { BiUserCircle } from "react-icons/bi";
import { User } from "@Types/users";
import { hasActionPermissions } from "@Utils/permissions";

const ResultsTable = ({ applicants, users }: ResultsTableProps) => {
  const user = useCurrentUser();
  const userPermissions = hasActionPermissions("dealership_user", "read", user);
  const applicantsPermissions = hasActionPermissions("applicant", "read", user);
  const { createChannel } = useCreateChannel();

  if (!user) return <Loading />;
  const handleCreateChannelWithApplicant = (applicant: Applicant) => {
    createChannel({
      data: {
        sender: {
          memberId: user._id,
          email: user?.data.info?.email ?? "",
          type: "dealership_user",
        },
        receivers: [
          {
            memberId: applicant._id,
            email: applicant.data.info.email,
            type: "applicant",
          },
        ],
      },
    });
  };
  const handleCreateChannelWithUser = (selectedUser: User) => {
    createChannel({
      data: {
        sender: {
          memberId: user._id,
          email: user?.data.info?.email ?? "",
          type: "dealership_user",
        },
        receivers: [
          {
            memberId: selectedUser._id,
            email: selectedUser.data.info.email,
            type: "user",
          },
        ],
      },
    });
  };

  return (
    <div className={styles.tableContainer}>
      {userPermissions && (
        <div>
          <div className={styles.tableHeaderContainer}>
            <BiUserCircle size={30} className={styles.headerIcon} />
            Users
          </div>
          <table className={styles.table}>
            <tbody>
              {users?.loading && (
                <tr>
                  <td align="center" colSpan={4}>
                    <Loading size={25} text={undefined} />
                  </td>
                </tr>
              )}
              {users.data?.map((user, index) => (
                <tr
                  key={index}
                  onMouseDown={() => handleCreateChannelWithUser(user)}
                >
                  <>
                    <td align="left">
                      {formatFirstAndLastNames(
                        user?.data?.info?.firstName,
                        user?.data?.info?.lastName
                      )}
                    </td>
                  </>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {applicantsPermissions && (
        <div>
          <div className={styles.tableHeaderContainer}>
            <RxPerson size={30} className={styles.headerIcon} />
            Applicants
          </div>
          <table className={styles.table}>
            <tbody>
              {applicants?.loading && (
                <tr>
                  <td align="center" colSpan={4}>
                    <Loading size={25} text={undefined} />
                  </td>
                </tr>
              )}
              {applicants.data?.map((applicant, index) => (
                <tr
                  key={index}
                  onMouseDown={() =>
                    handleCreateChannelWithApplicant(applicant)
                  }
                >
                  <>
                    <td align="left">
                      {formatFirstAndLastNames(
                        applicant?.data?.info?.firstName,
                        applicant?.data?.info?.lastName
                      )}
                    </td>
                  </>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default ResultsTable;
