import styles from "./Badge.module.scss";
type BadgeProps = {
  badgeCount?: number;
  containerStyle?: React.CSSProperties;
  variant?: "count" | "dot";
  dotColor?: string;
};

function Badge({
  badgeCount,
  variant = "count",
  containerStyle,
  dotColor,
}: BadgeProps) {
  return badgeCount || variant === "dot" ? (
    <div className={styles.badgeContainer} style={containerStyle}>
      <div
        className={variant === "dot" ? styles.dot : undefined}
        style={dotColor ? { background: dotColor } : undefined}
      >
        {badgeCount}
      </div>
    </div>
  ) : null;
}
export default Badge;
