import {
  useCallback,
  ReactNode,
  useRef,
  useState,
  useLayoutEffect,
  RefObject,
} from "react";
import { VscClose } from "react-icons/vsc";

import IconButton from "@Components/IconButton";
import useClickOutside from "@Hooks/useClickOutside";

import styles from "./AnchoredDialog.module.scss";
import ReactDOM from "react-dom";

type DialogProps = {
  open: boolean;
  children: ReactNode;
  title?: string;
  closeFn?: () => void;
  callback?: () => unknown;
  closeIcon?: boolean;
  size?: "xs" | "sm" | "lg" | "xl" | "xs-min";
  titleColor?: "blue" | "default";
  spacing?: boolean;
  id?: string;
  style?: React.CSSProperties;
  anchorPosition?: "left" | "right";
  optionalRef?: RefObject<HTMLDivElement>;
};

const Dialog = ({
  open,
  size = "sm",
  children,
  title,
  titleColor = "default",
  closeFn,
  id,
  optionalRef,
  closeIcon = false,
  spacing = true,
  style = {},
}: DialogProps) => {
  const mandatoryRef = useRef<HTMLDivElement | null>(null);
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const closeFnCallback = useCallback(() => closeFn, [dialogRef]);
  useClickOutside(dialogRef, closeFnCallback);

  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(
    null
  );
  const element = optionalRef?.current || mandatoryRef.current;
  useLayoutEffect(() => {
    if (element) {
      const bounding = element.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      // || document.documentElement.clientWidth;
      const rightOffset = bounding?.right + 375;
      if (rightOffset > windowWidth) {
        element.style.right = "300px";
      }
      setWrapperElement(element);
    }
  }, [optionalRef, mandatoryRef, element]);
  return (
    <div
      data-testid={"anchored-dialog"}
      ref={mandatoryRef}
      style={{ position: "relative" }}
    >
      {open
        ? ReactDOM.createPortal(
            <div
              id={id}
              ref={dialogRef}
              className={`${styles.dialogContainer} ${styles[size]}`}
              style={style}
            >
              <div>
                {title ? (
                  <div className={styles.dialogHeader}>
                    <p className={`${styles.title} ${styles[titleColor]}`}>
                      {title}
                    </p>
                    {closeIcon && (
                      <IconButton
                        onClick={closeFn}
                        dataTestId="close-anchored-dialog-button"
                      >
                        <VscClose size={30} />
                      </IconButton>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className={`${styles.dialogContent}  ${
                    spacing && styles["spacing"]
                  }`}
                >
                  {children}
                </div>
              </div>
            </div>,
            wrapperElement ?? document.body
          )
        : null}
    </div>
  );
};

export default Dialog;
