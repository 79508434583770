import { Fragment, useRef } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AiFillCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

import { Deal, DealStatus, StatusHistory } from "@Types/deal";
import useOnClickOutside from "@Hooks/useClickOutside";
import { formatDate } from "@Utils/functions";
import { useGetLenderEstFundedDate } from "@Hooks/useLenders";
import { useGetWfdEstFundedDate } from "@Hooks/useDeals";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useGetTitleIssue } from "@Hooks/useTitleIssues";
import { getTitleIssueStatus } from "@Pages/TitleIssues/TitleIssues";

import NegativeIcon from "./NegativeIcon";
import WarningIcon from "./WarningIcon";
import Circle from "./Circle";

import styles from "./Stepper.module.scss";
import { TitleIssue } from "@Types/title_issues";
import { getLastStatus, modifyStepValue } from "./Stepper";
import { hasActionPermissions } from "@Utils/permissions";

type StepperStatus = (typeof statusToStepper)[DealStatus];

export type Step = {
  title: StepperStatus | "dealer funded" | "title documents uploaded";
  value: number;
  show: boolean;
  description?: string;
  action?: JSX.Element;
};

type StepperProps = {
  deal: Deal;
};

const BLUE = "#6B97F6";
const GRAY = "#D9D9D9";
const GREEN = "#17DB71";

type LineProps = {
  dashed?: boolean;
};

const Line = ({ dashed }: LineProps) => {
  return (
    <hr
      style={
        dashed
          ? {
              border: `1px dashed ${GRAY}`,
              borderRadius: "7px 7px 7px 7px",
            }
          : {
              borderTop: `1px solid ${BLUE}`,
              borderRadius: "7px 7px 7px 7px",
            }
      }
    />
  );
};
const wfdEstFundedDatePassedOrToday = (
  wfdEstFundedDateString: string | undefined
) => {
  if (!wfdEstFundedDateString) return false;
  const todayTimestamp = new Date().setHours(0, 0, 0, 0);
  const wfdEstFundedTimestamp = new Date(wfdEstFundedDateString).setHours(
    0,
    0,
    0,
    0
  );
  return todayTimestamp >= wfdEstFundedTimestamp;
};
const renderCircleOrIconBySteps = (
  currentStep: Step,
  activeStep: Step | undefined,
  fundingEnabled: boolean,
  paymentMethod: "ACH" | "Check",
  dealFundedDate: string | undefined,
  wfdEstFundedDateString?: string,
  titleIssueStatus?: string,
  titleIssueId?: string
) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLDivElement | null>(null);

  const closeFnCallback = () => () => {
    document.getElementById("triangle")?.classList.remove(styles.active);
    document
      .getElementById("dealerFundedTooltip")
      ?.classList.remove(styles.active);
  };
  const closeFnTitleCallback = () => () => {
    document.getElementById("titleTriangle")?.classList.remove(styles.active);
    document.getElementById("titleTooltip")?.classList.remove(styles.active);
  };
  useOnClickOutside(ref, closeFnCallback);
  useOnClickOutside(titleRef, closeFnTitleCallback);

  const wfdEstFundedDateIsTodayOrEarlier =
    paymentMethod === "ACH" && wfdEstFundedDateString
      ? new Date().setHours(0, 0, 0, 0) >=
        new Date(wfdEstFundedDateString).setHours(0, 0, 0, 0)
      : dealFundedDate
      ? new Date(dealFundedDate).setHours(0, 0, 0, 0) <=
        new Date().setHours(0, 0, 0, 0)
      : false;
  if (!activeStep) return <></>;
  if (activeStep.value > currentStep.value)
    return (
      <div>
        <AiFillCheckCircle size={51.95} color={BLUE} />
      </div>
    );

  if (
    activeStep.title === statusToStepper["funded"] &&
    currentStep.title === "dealer funded" &&
    fundingEnabled &&
    (paymentMethod === "Check" ||
      (paymentMethod === "ACH" &&
        wfdEstFundedDatePassedOrToday(wfdEstFundedDateString)))
  ) {
    return (
      <div
        ref={wfdEstFundedDateIsTodayOrEarlier ? ref : undefined}
        onMouseOver={
          wfdEstFundedDateIsTodayOrEarlier
            ? () => {
                document
                  .getElementById("triangle")
                  ?.classList.add(styles.active);
                document
                  .getElementById("dealerFundedTooltip")
                  ?.classList.add(styles.active);
              }
            : undefined
        }
      >
        <AiFillCheckCircle size={51.95} color={GREEN} />
        {wfdEstFundedDateIsTodayOrEarlier ? (
          <Fragment>
            <div className={styles.triangle} id="triangle"></div>
            <div className={styles.triangle} id="triangle"></div>
            <div
              className={styles.dealerFundedTooltip}
              id="dealerFundedTooltip"
            >
              <p>
                <strong>
                  If you dont see the amount in your account, wait 1 business
                  day and contact WFD.
                </strong>
              </p>
            </div>
          </Fragment>
        ) : null}
      </div>
    );
  }
  if (
    activeStep.title === statusToStepper["funded"] &&
    currentStep.title === "title documents uploaded" &&
    fundingEnabled &&
    (paymentMethod === "Check" ||
      (paymentMethod === "ACH" &&
        wfdEstFundedDatePassedOrToday(wfdEstFundedDateString)))
  ) {
    if (titleIssueStatus === "resolved")
      return (
        <div>
          <AiFillCheckCircle size={51.95} color={GREEN} />
        </div>
      );
    return (
      <div
        ref={titleRef}
        onMouseOver={() => {
          document
            .getElementById("titleTriangle")
            ?.classList.add(styles.active);
          document.getElementById("titleTooltip")?.classList.add(styles.active);
        }}
      >
        <Circle size={60} text={currentStep.value.toString()} />
        <Fragment>
          <div className={styles.triangle} id="titleTriangle"></div>
          <div className={styles.dealerFundedTooltip} id="titleTooltip">
            <p>
              <strong>
                Click{" "}
                <Link
                  to={`/title_info/${titleIssueId}`}
                  className={styles.link}
                >
                  here
                </Link>{" "}
                to upload title documents.
              </strong>
            </p>
          </div>
        </Fragment>
      </div>
    );
  }
  if (activeStep.value < currentStep.value)
    return <Circle color={GRAY} text={currentStep.value.toString()} />;

  if (activeStep.title === statusToStepper["funded"])
    return (
      <div>
        <AiFillCheckCircle size={51.95} color={GREEN} />
      </div>
    );

  //active
  return <Circle size={60} text={currentStep.value.toString()} />;
};

function mapStatusHistory(statusHistory: StatusHistory[]) {
  const usedStatuses = new Set(Object.values(statusToStepper));
  const statusMap = {} as Record<StepperStatus, string>;

  for (const entry of statusHistory) {
    const originalStatus = statusToStepper[entry.status];

    if (usedStatuses.has(originalStatus)) {
      if (
        !statusMap[originalStatus] ||
        new Date(entry.date).getTime() >
          new Date(statusMap[originalStatus]).getTime()
      ) {
        statusMap[originalStatus] = entry.date;
      }
    }
  }

  return statusMap;
}
const statusToStepper = {
  lead: "deal submitted",
  "credit check": "processing deal",
  "waiting for deal details": "processing deal",
  dead: "dead", //processing deal,
  "deal created": "processing deal",
  "pending lender decision": "awaiting lender decision",
  denied: "denied", // pending lender decision
  countered: "awaiting lender decision",
  approved: "approved",
  "sent to dealer": "awaiting signed documents",
  "submitted for funding": "submitted for funding",
  cancelled: "cancelled", // submitted fro funding
  "funding held": "funding held",
  "did not process": "did not process",
  funded: "funded",
} as const;

const renderLineBySteps = (
  currentStep: Step,
  activeStep: Step | undefined,
  steps: Step[],
  deal: Deal,
  lastStatus: DealStatus,
  fundingEnabled: boolean,
  wfdEstFundedDateString: string | undefined,
  fundingPaymentMethod?: "ACH" | "Check"
) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const closeFnCallback = () => () => {
    document.getElementById("triangle")?.classList.remove(styles.active);
    document
      .getElementById("fundingHeldTooltip")
      ?.classList.remove(styles.active);
  };
  useOnClickOutside(ref, closeFnCallback);
  if (!activeStep) return <></>;

  if (
    activeStep.title === statusToStepper["did not process"] &&
    statusToStepper[lastStatus] === currentStep.title
  ) {
    return (
      <div className={styles.negativeIconContainer}>
        <NegativeIcon text="Did not process" />
        <Line dashed />
      </div>
    );
  }

  if (
    activeStep.title === statusToStepper["dead"] &&
    statusToStepper[lastStatus] === currentStep.title
  )
    return (
      <div className={styles.negativeIconContainer}>
        <NegativeIcon text="dead" />
        <Line dashed />
      </div>
    );

  if (
    activeStep.title === statusToStepper["denied"] &&
    currentStep.title === statusToStepper["pending lender decision"]
  )
    return (
      <div className={styles.negativeIconContainer}>
        <NegativeIcon text="denied" />
        <Line dashed />
      </div>
    );
  if (
    activeStep.title === statusToStepper["cancelled"] &&
    currentStep.title === statusToStepper["submitted for funding"]
  )
    return (
      <div className={styles.negativeIconContainer}>
        <NegativeIcon text="cancelled" />
        <Line dashed />
      </div>
    );
  if (
    activeStep.title === statusToStepper["funding held"] &&
    currentStep.title === statusToStepper["submitted for funding"]
  ) {
    const fundingHeldNotes = (deal?.data?.notes ?? []).filter(
      (note) => note?.data?.info?.type === "funding_held"
    );
    const dealHasFundingHeldNotes =
      Array.isArray(fundingHeldNotes) && fundingHeldNotes?.length;
    return (
      <div
        ref={ref}
        className={styles.warningIconFundingHeldContainer}
        onMouseOver={() => {
          document.getElementById("triangle")?.classList.add(styles.active);
          document
            .getElementById("fundingHeldTooltip")
            ?.classList.add(styles.active);
        }}
      >
        <WarningIcon text="funding held" />
        <Line dashed />
        {dealHasFundingHeldNotes ? (
          <Fragment>
            <div className={styles.triangle} id="triangle"></div>
            <div className={styles.fundingHeldTooltip} id="fundingHeldTooltip">
              <p>
                <strong>Funding held notes:</strong>
              </p>
              <ol>
                {fundingHeldNotes.map((note, key) => (
                  <li style={{ marginBottom: "10px" }} key={key}>
                    {note?.data?.info?.note}
                  </li>
                ))}
              </ol>
            </div>
          </Fragment>
        ) : null}
      </div>
    );
  }
  if (
    activeStep.title === statusToStepper["sent to dealer"] &&
    currentStep.title === statusToStepper["sent to dealer"]
  ) {
    return (
      <div className={styles.warningIconContainer}>
        <WarningIcon text="Upload Documents" />
        <Line dashed />
      </div>
    );
  }

  if (currentStep.value === steps.filter((x) => x.show).length) return null;

  if (currentStep.value < activeStep.value) {
    return <Line />;
  }
  if (
    currentStep.title === statusToStepper["funded"] &&
    activeStep.title === statusToStepper["funded"] &&
    fundingEnabled &&
    ((fundingPaymentMethod === "ACH" &&
      wfdEstFundedDatePassedOrToday(wfdEstFundedDateString)) ||
      fundingPaymentMethod === "Check")
  ) {
    return <Line />;
  }
  if (
    currentStep.title === "dealer funded" &&
    activeStep.title === statusToStepper["funded"] &&
    fundingEnabled &&
    ((fundingPaymentMethod === "ACH" &&
      wfdEstFundedDatePassedOrToday(wfdEstFundedDateString)) ||
      fundingPaymentMethod === "Check")
  ) {
    return <Line />;
  }

  return <Line dashed />;
};
const renderStepTitle = (
  step: Step,
  fundingEnabled: boolean,
  fundingPaymentMethod?: "ACH" | "Check"
) => {
  switch (step.title) {
    case "dealer funded":
      return (
        <h3 style={{ fontSize: "12px", position: "absolute" }}>
          {step.title === "dealer funded" &&
          fundingPaymentMethod &&
          fundingPaymentMethod === "Check"
            ? "Check was sent"
            : step.title}
        </h3>
      );
    case "funded":
      return (
        <h3 style={{ fontSize: "12px", position: "absolute" }}>{`${
          fundingEnabled ? "WFD" : ""
        } ${step.title}`}</h3>
      );
    default:
      return (
        <h3 style={{ fontSize: "12px", position: "absolute" }}>{step.title}</h3>
      );
  }
};
const steps: Step[] = [
  { title: "deal submitted", value: 1, show: true },
  { title: "did not process", value: 1.5, show: false },
  { title: "processing deal", value: 2, show: true },
  { title: "dead", value: 2.5, show: false },
  { title: "awaiting lender decision", value: 3, show: true },
  { title: "denied", value: 3.5, show: false },
  { title: "approved", value: 4, show: true },
  { title: "awaiting signed documents", value: 5, show: true },
  { title: "submitted for funding", value: 6, show: true },
  { title: "cancelled", value: 6.5, show: false },
  { title: "funding held", value: 6.5, show: false },
  { title: "funded", value: 7, show: true },
  { title: "dealer funded", value: 8, show: true },
  { title: "title documents uploaded", value: 9, show: true },
];

export const bbStatusToDStatus = (status: DealStatus): Step | undefined => {
  return steps.find((s) => statusToStepper[status] === s.title);
};
function parseDate(dateString: string) {
  const parts = dateString.split("-");
  const year = parseInt(parts[0]);
  const month = parseInt(parts[1]);
  const day = parseInt(parts[2]);
  return `${year}-${month}-${day}`;
}

const StepperPreview = ({ deal }: StepperProps) => {
  const currentUser = useCurrentUser();

  const hasTitlePermissions = hasActionPermissions(
    "title_issue",
    "read",
    currentUser
  );

  let titleIssue: TitleIssue | undefined;
  if (hasTitlePermissions)
    titleIssue = useGetTitleIssue(deal.data.titleIssueId).titleIssue;

  const { data: lenderEstFundedDate } = useGetLenderEstFundedDate(
    deal?.data?.lenderId ?? deal?.data?.lender?._id,
    deal.data.info.status === DealStatus.SubmittedForFunding ||
      deal.data.info.status === DealStatus.Funded
  );
  const { data: wfdEstFundedDateString } = useGetWfdEstFundedDate(
    { dealId: deal?._id, lenderEstFundedDate },
    (deal.data.info.status === DealStatus.SubmittedForFunding ||
      deal.data.info.status === DealStatus.Funded) &&
      typeof lenderEstFundedDate === "string"
  );
  const lastStatus = getLastStatus(deal);

  const activeStep = modifyStepValue(
    deal,
    steps,
    statusToStepper
  )(bbStatusToDStatus(deal.data.info.status));
  const mappedStatusHistory = mapStatusHistory(
    deal.data.info?.statusHistory ?? []
  );
  const fundingEnabled =
    currentUser?.data?.dealership?.data?.info?.funding?.enabled || false;
  const filteredSteps = steps.filter((currentStep) =>
    currentStep.title === "dealer funded"
      ? fundingEnabled
      : currentStep.title === "title documents uploaded"
      ? !!titleIssue && hasTitlePermissions
      : true
  );
  const fundingPaymentMethod =
    deal.data.info.fundingPaymentMethod ||
    currentUser?.data?.dealership?.data?.info?.funding?.paymentMethod;

  const dealTitleIssueStatus = titleIssue
    ? getTitleIssueStatus(titleIssue?.data.info)
    : undefined;
  return (
    <ol className={styles.stepperPreview}>
      {(
        filteredSteps.map(
          modifyStepValue(deal, steps, statusToStepper)
        ) as Step[]
      ).map((currentStep, index) =>
        currentStep.show ? (
          <div
            style={{
              display: "flex",
              flex: "1 1",
              alignItems: "center",
              justifyContent: "center",
            }}
            key={index}
          >
            <li id={index?.toString()} className={styles.stepperItem}>
              {((activeStep?.value || -1) > currentStep.value ||
                (activeStep?.value || -1) === currentStep.value) &&
                mappedStatusHistory?.[currentStep.title as StepperStatus] !==
                  undefined && (
                  <ReactTooltip
                    anchorId={index?.toString()}
                    place="top"
                    style={{ fontSize: "10px" }}
                    content={formatDate(
                      new Date(
                        mappedStatusHistory[currentStep.title as StepperStatus]
                      ),
                      "medium"
                    )}
                  />
                )}
              {renderCircleOrIconBySteps(
                currentStep,
                activeStep,
                fundingEnabled,
                fundingPaymentMethod,
                deal.data.info.dealDates?.fundedAt,
                wfdEstFundedDateString,
                dealTitleIssueStatus,
                titleIssue?._id
              )}

              <div
                style={{
                  width: "100%",
                  lineHeight: 1.5,
                  textTransform: "capitalize",
                  justifyContent: "center",
                  whiteSpace: "break-spaces",
                  display: "flex",
                }}
              >
                {renderStepTitle(
                  currentStep,
                  fundingEnabled,
                  fundingPaymentMethod
                )}
                {currentStep.title === "funded" &&
                  activeStep?.title === DealStatus.SubmittedForFunding &&
                  fundingEnabled && (
                    <div
                      className={styles.stepperSecondaryTitle}
                      style={{ fontSize: "10px" }}
                    >
                      Estimated to date: {lenderEstFundedDate}
                    </div>
                  )}
                {currentStep.title === "dealer funded" &&
                  (activeStep?.title === DealStatus.Funded ||
                    activeStep?.title === DealStatus.SubmittedForFunding) &&
                  fundingEnabled && (
                    <div className={styles.stepperSecondaryTitle}>
                      {fundingPaymentMethod === "Check"
                        ? deal.data?.info?.dealDates?.fundedAt
                          ? parseDate(deal.data.info.dealDates.fundedAt)
                          : wfdEstFundedDateString
                        : wfdEstFundedDateString}
                    </div>
                  )}
              </div>
            </li>

            <div style={{ flex: 1 }}>
              {renderLineBySteps(
                currentStep,
                activeStep,
                filteredSteps,
                deal,
                lastStatus,
                fundingEnabled,
                wfdEstFundedDateString,
                fundingPaymentMethod
              )}
            </div>
          </div>
        ) : null
      )}
    </ol>
  );
};

export default StepperPreview;
