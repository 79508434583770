import axios from "axios";

import { auth } from "@Firebase/firebase";

import { Applicant, ApplicantInfo } from "@Types/applicants";
import { HttpQuery } from "@Types/http";
import { Deal } from "@Types/deal";

export const getApplicants = (
  query?: HttpQuery
): (() => Promise<Applicant[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/applicant/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return response.data.result?.docs ?? [];
  };
};

export const getApplicant = (id: string): (() => Promise<Applicant>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/applicant/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.result;
  };
};
export const addCoApplicantToDeal = (): ((request: {
  applicant: ApplicantInfo;
  dealId: string;
}) => Promise<Deal>) => {
  return async (request) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/co_applicant_to_deal/`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.result;
  };
};
