import { ReactNode } from "react";
import styles from "./Dialog.module.scss";

type DialogActionsProps = {
  children: ReactNode;
  align?: "end" | "center" | "space-between";
};

export const DialogActions = ({
  children,
  align = "end",
}: DialogActionsProps) => {
  return (
    <div
      data-testid="dialog-actions"
      className={[styles.dialogActions, styles[align]].join(" ")}
    >
      {children}
    </div>
  );
};
