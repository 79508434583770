export default ({ className }: { className?: string }) => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
      fill="currentColor"
    />
    <path
      d="M18.563 21.5542L15.98 20.9365L16.9121 12.6484H26.0986V15.2651H19.5737L19.1694 18.8926C19.3866 18.7653 19.716 18.6305 20.1577 18.4883C20.5994 18.3385 21.0936 18.2637 21.6401 18.2637C22.4338 18.2637 23.1375 18.3872 23.7515 18.6343C24.3654 18.8813 24.8857 19.2407 25.3125 19.7124C25.7467 20.1841 26.0762 20.7606 26.3008 21.4419C26.5254 22.1232 26.6377 22.8944 26.6377 23.7554C26.6377 24.4816 26.5254 25.1742 26.3008 25.833C26.0762 26.4844 25.7355 27.0684 25.2788 27.585C24.8221 28.0941 24.2493 28.4946 23.5605 28.7866C22.8717 29.0786 22.0557 29.2246 21.1123 29.2246C20.4085 29.2246 19.7272 29.1198 19.0684 28.9102C18.417 28.7005 17.8293 28.3898 17.3052 27.978C16.7886 27.5662 16.373 27.0684 16.0586 26.4844C15.7516 25.8929 15.5907 25.2191 15.5757 24.4629H18.7876C18.8325 24.9271 18.9523 25.3276 19.147 25.6646C19.3491 25.994 19.6149 26.2485 19.9443 26.4282C20.2738 26.6079 20.6593 26.6978 21.1011 26.6978C21.5129 26.6978 21.8647 26.6191 22.1567 26.4619C22.4487 26.3047 22.6846 26.0876 22.8643 25.8105C23.0439 25.526 23.175 25.1966 23.2573 24.8223C23.3472 24.4404 23.3921 24.0286 23.3921 23.5869C23.3921 23.1452 23.3397 22.7446 23.2349 22.3853C23.13 22.0259 22.9691 21.7152 22.752 21.4531C22.5348 21.1911 22.2578 20.9889 21.9209 20.8467C21.5915 20.7044 21.2059 20.6333 20.7642 20.6333C20.1652 20.6333 19.701 20.7269 19.3716 20.9141C19.0496 21.1012 18.7801 21.3146 18.563 21.5542Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_3_31">
        <rect width="42" height="42" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
