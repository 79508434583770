import {
  PermissionEntities,
  PermissionOperation,
  PermissionSubEntities,
} from "@Types/roles";
import { User } from "@Types/users";

export const hasActionPermissions = (
  entity: PermissionEntities,
  action: PermissionOperation,
  user?: User
) => {
  if (user) {
    if (user.data.info.userType === "owner") {
      return true;
    }

    const permissions = user?.data?.permissions?.[entity]?.permissions;
    if (permissions)
      return permissions.owner?.[action]?.hasPermissions ||
        permissions.other?.[action]?.hasPermissions ||
        permissions.group?.[action]?.hasPermissions
        ? true
        : false;
  }
  return false;
};

export const hasSectionPermissions = (
  entity: PermissionEntities,
  section: PermissionSubEntities,
  user?: User
) => {
  if (user) {
    if (user.data.info.userType === "owner") {
      return true;
    }

    const permissions =
      user?.data?.permissions?.[entity]?.subPages?.reduce(
        (acc: { [key in PermissionSubEntities]: boolean }, curr) => ({
          [curr.page]: curr.hasPermissions,
          ...acc,
        }),
        {} as { [key in PermissionSubEntities]: boolean }
      ) ?? ({} as { [key in PermissionSubEntities]: boolean });

    if (permissions) return permissions[section];
  }
  return false;
};
