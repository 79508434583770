import axios from "axios";

import { auth } from "@Firebase/firebase";
import { CreateDealerNoteRequest, DealerNote } from "@Types/dealer_notes";

export const createDealerNote = (): ((
  note: CreateDealerNoteRequest
) => Promise<DealerNote>) => {
  return async (note) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealer_note/`,
      note,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.result;
  };
};
