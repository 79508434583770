import axios from "axios";

import { auth } from "@Firebase/firebase";

import { CreateDealRequest, Deal } from "@Types/deal";

import { HttpQuery } from "@Types/http";
import download from "downloadjs";

export const getApplications = (query?: HttpQuery): (() => Promise<Deal[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/deal/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return response.data.result.docs ?? [];
  };
};
export const getDealsForCustomerDashboard = (
  query?: HttpQuery
): (() => Promise<Deal[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/deals_for_customer_dashboard/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return response.data.result ?? [];
  };
};
export const getApplication = (id: string): (() => Promise<Deal>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/deal/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.result;
  };
};

export const getApplicationPDF = (deal: Deal) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/deal/${deal._id}/application`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          responseType: "blob", // had to add this one here
        },
      }
    );
    const content = response.headers["content-type"];
    console.log(response.data);
    download(
      `data:application/pdf;base64,${response.data}`,
      "test.pdf",
      content
    );
  };
};

export const createApplication = (): ((
  application: CreateDealRequest
) => Promise<Deal>) => {
  return async (application) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/deal/`,
      application,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.result;
  };
};

export const getWfdEstFundedDate = (
  id: string,
  lenderEstFundedDate?: string
): (() => Promise<string>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/wfd_est_funded_date`,
      {
        id,
        lenderEstFundedDate,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.result;
  };
};
