import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

import { Query } from "@Types/http";
import Table from "@Components/Table";
import IconButton from "@Components/IconButton";
import { Column } from "@Components/Table/types";
import useGetTitleIssues from "@Hooks/useTitleIssues";
import TextFilter from "@Components/Filters/TextFilter";
import { TitleIssueNote } from "@Types/title_issue_notes";
import LenderFilter from "@Components/Filters/LenderFilter";
import { capitalize, formatNumberAsCurrency } from "@Utils/functions";
import { Info, TitleIssue, TitleIssueStatus } from "@Types/title_issues";

import Limits from "./Limits";
import styles from "./TitleIssues.module.scss";
import TitleIssueStatusCards from "./TitleIssueStatusCards";

export const getTitleIssueStatus = (data: Info) => {
  const { oldTitleRegistrationInfo, titleRegistrationInfo, receiptInfo } = data;

  const isRejected =
    oldTitleRegistrationInfo.status === "rejected" ||
    titleRegistrationInfo.status === "rejected" ||
    receiptInfo.status === "rejected";

  const isApproved =
    oldTitleRegistrationInfo.status === "resolved" &&
    titleRegistrationInfo.status === "resolved" &&
    receiptInfo.status === "resolved";

  const isNew =
    oldTitleRegistrationInfo.status === "awaiting" &&
    titleRegistrationInfo.status === "awaiting" &&
    receiptInfo.status === "awaiting";

  if (isRejected) {
    return "rejected";
  } else if (isApproved) {
    return "resolved";
  } else if (isNew) {
    return "awaiting";
  } else {
    return "pending";
  }
};
export const getTitleIssueStatusColor = (
  status: "awaiting" | "pending" | "resolved" | "rejected"
) => {
  switch (status) {
    case "awaiting":
      return "#C7C6CB";
    case "pending":
      return "#FFBB6C";

    case "rejected":
      return "#EC2F49";
    default:
      return "#17DB71";
  }
};
export type TitleQuery =
  | {
      active: TitleIssueStatus;
      query?: { [maybeAnd: string]: Query | string };
    }
  | undefined;
export default function TitleIssues() {
  const navigate = useNavigate();

  const [titleQuery, setTitleQuery] = useState<TitleQuery>(undefined);

  const columns: Column<TitleIssue>[] = [
    {
      id: "dmv",
      label: "DMV",
      value: (titleIssue) =>
        titleIssue.data.deal?.data?.applicant?.data?.info?.currentState ?? "",
      sortPath: [
        "data",
        "deal",
        "data",
        "applicant",
        "data",
        "info",
        "currentState",
      ],
      filters: [
        {
          placeholder: "DMV",
          preview: TextFilter,
          queryPath: [
            "data",
            "deal",
            "data",
            "applicant",
            "data",
            "info",
            "currentState",
          ],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },
    {
      label: "Lender",
      id: "lender",
      truncate: 15,
      value: (titleIssue) =>
        titleIssue.data?.deal?.data?.lender?.data?.info?.name ?? "N/A",
      sortPath: ["data", "deal", "data", "lender", "data", "info", "name"],
      filters: [
        {
          placeholder: "Lender",
          preview: LenderFilter,
          queryPath: ["data", "deal", "data", "lenderId"],
        },
      ],
    },
    {
      label: "Status",
      id: "status",
      truncate: 15,
      value: (titleIssue) =>
        capitalize(getTitleIssueStatus(titleIssue?.data?.info)) ?? "",
    },
    {
      id: "fee",
      label: "Fee",
      value: (titleIssue) =>
        formatNumberAsCurrency(
          titleIssue.data?.deal?.data?.info?.taxesAndFees?.titleTransfer,
          "$"
        ) ?? "",
    },

    {
      id: "lastComment",
      label: "Last Comment",
      value: (titleIssue) =>
        titleIssue?.data?.titleIssueNotes?.reduce(
          (acc: TitleIssueNote | undefined, note) =>
            acc?.createdAt &&
            new Date(note.createdAt).getTime() <
              new Date(acc.createdAt).getTime()
              ? acc
              : note,
          undefined
        )?.data.info.note ?? "",
    },

    {
      id: "actions",
      label: "Actions",
      value: (titleIssue) => (
        <IconButton
          id={`open-titleIssue-${titleIssue?._id}`}
          className={"blueCircle"}
          onClick={() => {
            navigate(`/title_info/${titleIssue._id}`);
          }}
        >
          <AiOutlineArrowRight size={18} />
        </IconButton>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Title Info</h2>
      <Limits showText={true} />
      <TitleIssueStatusCards
        titleQuery={titleQuery}
        setTitleQuery={setTitleQuery}
      />
      <Table<TitleIssue>
        columns={columns}
        entityType="title_info"
        query={titleQuery?.query}
        useGetFunction={useGetTitleIssues}
      />
    </div>
  );
}
