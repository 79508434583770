import { FaCircle } from "react-icons/fa";
import { Dispatch, SetStateAction } from "react";

import useGetTitleIssues from "@Hooks/useTitleIssues";
import { TitleIssueStatus } from "@Types/title_issues";

import styles from "./TitleIssues.module.scss";
import { TitleQuery, getTitleIssueStatus } from "./TitleIssues";

export default function TitleIssueStatusCards({
  titleQuery,
  setTitleQuery,
}: {
  titleQuery: TitleQuery;
  setTitleQuery: Dispatch<SetStateAction<TitleQuery>>;
}) {
  const { data } = useGetTitleIssues();

  const handleSetTitleQuery = (status: TitleIssueStatus) => {
    if (status === titleQuery?.active) {
      setTitleQuery(undefined);
    } else {
      switch (status) {
        case "resolved":
        case "awaiting":
          setTitleQuery({
            active: status,
            query: {
              "data.info.oldTitleRegistrationInfo.status": status,
              "data.info.titleRegistrationInfo.status": status,
              "data.info.receiptInfo.status": status,
            },
          });
          return;
        case "rejected":
        case "pending":
          setTitleQuery({
            active: status,
            query: {
              $or: [
                {
                  "data.info.oldTitleRegistrationInfo.status": status,
                },
                {
                  "data.info.titleRegistrationInfo.status": status,
                },
                {
                  "data.info.receiptInfo.status": status,
                },
              ],
            },
          } as unknown as TitleQuery);
      }
    }
  };

  const sortedTitleIssues = (data ?? []).reduce(
    (acc, issue) => {
      const status = getTitleIssueStatus(issue.data.info);
      return { ...acc, [status]: acc[status] + 1 };
    },
    {
      awaiting: 0,
      resolved: 0,
      pending: 0,
      rejected: 0,
    }
  );

  return (
    <div className={styles.flexContainer}>
      <div
        onClick={() => handleSetTitleQuery("awaiting")}
        className={styles.flexItem}
      >
        {`New(${sortedTitleIssues?.awaiting})`}

        {titleQuery?.active === "awaiting" ? (
          <FaCircle color="red" size={20} />
        ) : null}
      </div>

      <div
        onClick={() => handleSetTitleQuery("pending")}
        className={`${styles.flexItem} ${styles.pending}`}
      >
        {`Pending(${sortedTitleIssues?.pending})`}
        {titleQuery?.active === "pending" ? (
          <FaCircle color="red" size={20} />
        ) : null}
      </div>

      <div
        onClick={() => handleSetTitleQuery("rejected")}
        className={`${styles.flexItem} ${styles.rejected}`}
      >
        {`Rejected(${sortedTitleIssues?.rejected})`}
        {titleQuery?.active === "rejected" ? (
          <FaCircle color="red" size={20} />
        ) : null}
      </div>
      <div
        onClick={() => handleSetTitleQuery("resolved")}
        className={`${styles.flexItem} ${styles.approved}`}
      >
        {`Approved(${sortedTitleIssues?.resolved})`}
        {titleQuery?.active === "resolved" ? (
          <FaCircle color="red" size={20} />
        ) : null}
      </div>
    </div>
  );
}
