import { Column } from "@Components/Table/types";
import Table from "@Components/Table";
import ApplicantFilter from "@Components/Filters/ApplicantFilter";
import { Deal, DealStatus } from "@Types/deal";
import { formatDate, formatFirstAndLastNames } from "@Utils/functions";
import useGetDeals from "@Hooks/useDeals";
import DateFilter from "@Components/Filters/DateFilter";
import TextFilter from "@Components/Filters/TextFilter";
import {
  DateFilterPreviewProps,
  FilterPreviewProps,
} from "@Components/Filters/types";
import styles from "./ProfitReport.module.scss";
import Button from "@Components/Button";
import { useState } from "react";
import DateField from "@Components/Inputs/DateField";
import Badge from "@Components/Badge";

export const formatCustomersNames = (deal: Deal) => {
  return (
    `${formatFirstAndLastNames(
      deal?.data?.applicant?.data?.info?.firstName,
      deal?.data?.applicant?.data?.info?.lastName
    )}
    ${
      deal?.data?.coApplicant?.data?.info?.firstName
        ? " / " +
          formatFirstAndLastNames(
            deal?.data?.coApplicant?.data?.info?.firstName,
            deal?.data?.coApplicant?.data?.info?.lastName
          )
        : ""
    }`?.trim() || "N/A"
  );
};
function formatDateToMMDDYYYY(date: Date) {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}
function getQuarterToDate() {
  const today = new Date();
  const quarterStartDate = new Date(
    today.getFullYear(),
    Math.floor(today.getMonth() / 3) * 3,
    1
  );

  return formatDateToMMDDYYYY(quarterStartDate);
}
const ProfitReport = () => {
  const thirtyDaysAgoDate = new Date(
    new Date().setUTCDate(new Date().getUTCDate() - 30)
  );
  const [requestState, setRequestState] = useState<{
    from: string | undefined;
    to: string;
  }>({
    from: formatDateToMMDDYYYY(thirtyDaysAgoDate),
    to: formatDateToMMDDYYYY(new Date()),
  });
  const [activeRangeFilter, setActiveRangeFilter] = useState<
    "monthly" | "weekly" | "quarterly" | "yearly" | "all_time"
  >("monthly");

  const [enabled, setEnabled] = useState(true);
  const columns: Column<Deal>[] = [
    {
      label: "Funding Date",
      id: "fundingDate",
      value: (deal) =>
        formatDate(deal.data?.info?.dealDates?.fundedAt, "short"),
      filters: [
        {
          placeholder: "Funding date",
          preview: (props: FilterPreviewProps) => (
            <DateFilter {...(props as unknown as DateFilterPreviewProps)} />
          ),
          queryPath: ["data", "info", "dealDates", "fundedAt"],
        },
      ],
      sortPath: ["data", "info", "dealDates", "fundedAt"],
    },
    {
      label: "Customer Name",
      value: (deal) => formatCustomersNames(deal),
      id: "customer",
      truncate: 15,
      filters: [
        {
          placeholder: "Customer Name",
          preview: ApplicantFilter,
          queryPath: [],
        },
      ],
    },
    {
      id: "make",
      label: "Vehicle Make",
      truncate: 15,
      value: (deal) => deal?.data?.info?.vehicle?.make || "N/A",
      sortPath: ["data", "info", "vehicle", "make"],
      filters: [
        {
          placeholder: "Vehicle Make",
          preview: TextFilter,
          queryPath: ["data", "info", "vehicle", "make"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },
    {
      id: "model",
      label: "Vehicle Model",
      truncate: 15,
      value: (deal) => deal?.data?.info?.vehicle?.model || "N/A",
      sortPath: ["data", "info", "vehicle", "model"],
      filters: [
        {
          placeholder: "Vehicle Model",
          preview: TextFilter,
          queryPath: ["data", "info", "vehicle", "model"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },
    {
      id: "totalDealershipProfit",
      label: "Total Dealership Profit",
      truncate: 15,
      value: (deal) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(deal?.data?.info?.profit?.dealershipProfit?.totalProfit || 0),
      sortPath: ["data", "info", "profit", "dealershipProfit", "totalProfit"],
      total: (deals) => {
        const total = deals.reduce(
          (acc, deal) =>
            acc +
            (deal?.data?.info?.profit?.dealershipProfit?.totalProfit || 0),
          0
        );
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(total);
      },
    },
  ];
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Profit Report</h2>
      <div className={styles.controlsContainer}>
        <Button
          variant="blue"
          style={{ fontSize: "20px" }}
          onClick={() => {
            setEnabled(false);
            setRequestState({
              from: formatDateToMMDDYYYY(
                new Date(new Date().setUTCDate(new Date().getUTCDate() - 7))
              ),
              to: formatDateToMMDDYYYY(new Date()),
            });
            setActiveRangeFilter("weekly");
          }}
        >
          Weekly
        </Button>
        {activeRangeFilter === "weekly" && (
          <div style={{ marginLeft: "-5px" }}>
            <Badge
              variant="dot"
              containerStyle={{ width: "10px", height: "10px" }}
            />
          </div>
        )}
        <Button
          variant="blue"
          style={{ fontSize: "20px" }}
          onClick={() => {
            setEnabled(false);
            setRequestState({
              from: formatDateToMMDDYYYY(
                new Date(new Date().setUTCDate(new Date().getUTCDate() - 30))
              ),
              to: formatDateToMMDDYYYY(new Date()),
            });
            setActiveRangeFilter("monthly");
          }}
        >
          Monthly
        </Button>
        {activeRangeFilter === "monthly" && (
          <div style={{ marginLeft: "-5px" }}>
            <Badge
              variant="dot"
              containerStyle={{ width: "10px", height: "10px" }}
            />
          </div>
        )}
        <Button
          variant="blue"
          style={{ fontSize: "20px" }}
          onClick={() => {
            setEnabled(false);
            setRequestState({
              from: getQuarterToDate(),
              to: formatDateToMMDDYYYY(new Date()),
            });
            setActiveRangeFilter("quarterly");
          }}
        >
          Quarterly
        </Button>
        {activeRangeFilter === "quarterly" && (
          <div style={{ marginLeft: "-5px" }}>
            <Badge
              variant="dot"
              containerStyle={{ width: "10px", height: "10px" }}
            />
          </div>
        )}
        <Button
          variant="blue"
          style={{ fontSize: "20px" }}
          onClick={() => {
            setEnabled(false);
            setRequestState({
              from: formatDateToMMDDYYYY(
                new Date(new Date().getFullYear(), 0, 1)
              ),
              to: formatDateToMMDDYYYY(new Date()),
            });
            setActiveRangeFilter("yearly");
          }}
        >
          Yearly
        </Button>
        {activeRangeFilter === "yearly" && (
          <div style={{ marginLeft: "-5px" }}>
            <Badge
              variant="dot"
              containerStyle={{ width: "10px", height: "10px" }}
            />
          </div>
        )}
        <Button
          variant="blue"
          style={{ fontSize: "20px" }}
          onClick={() => {
            setEnabled(false);
            setRequestState({
              from: undefined,
              to: formatDateToMMDDYYYY(new Date()),
            });
            setActiveRangeFilter("all_time");
          }}
        >
          All Time
        </Button>
        {activeRangeFilter === "all_time" && (
          <div style={{ marginLeft: "-5px" }}>
            <Badge
              variant="dot"
              containerStyle={{ width: "10px", height: "10px" }}
            />
          </div>
        )}
        <DateField
          id="from"
          placeholder="mm/dd/yyyy"
          title="From"
          style={{ border: "solid #9e9ca7", borderWidth: "thin" }}
          labelStyle={{ top: "-18px" }}
          value={requestState.from ?? ""}
          onChange={(e) => {
            setRequestState((prev) => ({
              ...prev,
              from: e.target.value,
            }));
          }}
        />
        <DateField
          id="to"
          placeholder="mm/dd/yyyy"
          title="To"
          style={{ border: "solid #9e9ca7", borderWidth: "thin" }}
          labelStyle={{ top: "-18px" }}
          value={requestState.to ?? ""}
          onChange={(e) => {
            setRequestState((prev) => ({
              ...prev,
              to: e.target.value,
            }));
          }}
          required
        />
        <Button
          variant="blue"
          onClick={() => {
            setRequestState({
              from: formatDateToMMDDYYYY(
                new Date(new Date().setUTCDate(new Date().getUTCDate() - 30))
              ),
              to: formatDateToMMDDYYYY(new Date()),
            });
            setActiveRangeFilter("monthly");
          }}
        >
          Reset
        </Button>

        <Button onClick={() => setEnabled(true)}>Show</Button>
      </div>
      {enabled && (
        <Table<Deal>
          columns={columns}
          useGetFunction={useGetDeals}
          showTotalsFooter
          query={{
            "data.info.status": DealStatus.Funded,
            "data.info.dealDates.fundedAt": {
              ...(requestState.from
                ? { $gte: new Date(requestState.from).toISOString() }
                : {}),
              $lte: new Date(requestState.to).toISOString(),
            },
          }}
          projection={{
            "data.info.profit.dealershipProfit.totalProfit": 1,
          }}
          entityType="applications"
        />
      )}
    </div>
  );
};

export default ProfitReport;
