interface LogoProps {
  className: string;
}

function Logo({ className }: LogoProps) {
  return (
    <svg
      className={className}
      width="110"
      height="52"
      viewBox="0 0 110 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84 0H53.5L44.5 15.75L35.5 0H21.75L37.75 27.5L33.5 34.25L13.75 0H0L30 52H37L44.25 39.25L51.5 52H58.5L70 32H81.75L88.5 20.25H63.25L55 34.25L51.25 27.5L60.25 11.75H84C91.75 11.75 98.25 18 98.25 26C98.25 34 92 40.25 84 40.25H73L66.5 52H83.75C98 52 109.75 40.25 109.75 26C109.75 11.75 98.25 0 84 0Z"
        fill="white"
      />
    </svg>
  );
}
export default Logo;
