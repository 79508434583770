import useCurrentUser from "@Hooks/useCurrentUser";
import { useGetDealershipTickets } from "@Hooks/useDealershipTickets";
import styles from "./TicketsList.module.scss";
import {
  DealershipTicket,
  DealershipTicketStatus,
} from "@Types/dealership_tickets";
import IconButton from "@Components/IconButton";
import { MdKeyboardArrowRight } from "react-icons/md";
import { capitalize, formatDate } from "@Utils/functions";
import { Fragment } from "react";
import Ticket from "./Ticket";

type TicketItemProps = {
  dealershipTicket: DealershipTicket;
  onOpen: (ticket: DealershipTicket) => void;
};

export const getDealershipTicketStatusColor = (
  status: DealershipTicketStatus
) => {
  switch (status) {
    case DealershipTicketStatus.NEW:
      return "#6b97f6";
    case DealershipTicketStatus.IN_PROGRESS:
      return "#fce303";
    case DealershipTicketStatus.RESOLVED:
      return "#15c969";
    case DealershipTicketStatus.CLOSED:
      return "#a5aec1";
  }
};

const TicketItem = ({ dealershipTicket, onOpen }: TicketItemProps) => {
  return (
    <div
      className={styles.listItemContainer}
      onClick={() => onOpen(dealershipTicket)}
    >
      <div
        className={styles.status}
        style={{
          background: getDealershipTicketStatusColor(
            dealershipTicket.data.info.status
          ),
        }}
      ></div>
      <div className={styles.listItemBody}>
        <div className={styles.listItemTitle}>
          Description: {dealershipTicket?.data?.info?.description}
        </div>
        <div className={styles.listItemDescription}>
          Submitted at: {formatDate(dealershipTicket.createdAt, "medium")}
        </div>
      </div>
      <div className={styles.openListItemButton}>
        <IconButton onClick={() => onOpen(dealershipTicket)}>
          <MdKeyboardArrowRight color={"#5e5d65"} size={20} />
        </IconButton>
      </div>
    </div>
  );
};
type TicketsListProps = {
  selectedTicket: DealershipTicket | undefined;
  setSelectedTicket: React.Dispatch<
    React.SetStateAction<DealershipTicket | undefined>
  >;
};
const TicketsList = ({
  selectedTicket,
  setSelectedTicket,
}: TicketsListProps) => {
  const user = useCurrentUser();

  const { data: tickets } = useGetDealershipTickets({
    query: { "data.userId": user?._id as string },
  });
  const handleOpenTicket = (ticket: DealershipTicket) => {
    setSelectedTicket(ticket);
  };
  return (
    <div>
      {selectedTicket ? (
        <Ticket ticketId={selectedTicket._id} />
      ) : (
        <Fragment>
          <div className={styles.legendHeader}>Status legend</div>
          <div className={styles.colorsLegend}>
            {Object.values(DealershipTicketStatus).map((status, index) => (
              <div key={index} className={styles.colorsLegendItem}>
                <div
                  style={{ background: getDealershipTicketStatusColor(status) }}
                  className={styles.colorsLegendItemBox}
                ></div>
                <div className={styles.colorsLegendItemText}>
                  {capitalize(status)}
                </div>
              </div>
            ))}
          </div>
          {(tickets ?? [])?.map((ticket, index) => (
            <TicketItem
              onOpen={handleOpenTicket}
              key={index}
              dealershipTicket={ticket}
            />
          ))}
        </Fragment>
      )}
    </div>
  );
};
export default TicketsList;
