import { useState } from "react";

export function useBrowserNotifications() {
  const [permission, setPermission] = useState(Notification.permission);

  const requestPermission = () =>
    Notification.requestPermission().then((permission) => {
      setPermission(permission);
    });

  const playNotificationSound = () =>
    new Audio(`${process.env.PUBLIC_URL}/notification.mp3`).play();

  const showNotification = (title: string, options?: NotificationOptions) => {
    if (permission === "granted") {
      new Notification(title, {
        ...options,
        icon: `${process.env.PUBLIC_URL}/wfd-logo.png`,
      });

      playNotificationSound();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission((permission) => {
        if (permission === "granted") {
          new Notification(title, {
            ...options,
            icon: `${process.env.PUBLIC_URL}/wfd-logo.png`,
          });
          playNotificationSound();
        }
      });
    }
  };
  const requestBrowserNotificationPermissions = () => {
    if (Notification.permission === "default") {
      requestPermission();
    }
  };

  return {
    permission,
    requestBrowserNotificationPermissions,
    showNotification,
  };
}
