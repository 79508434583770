import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

import {
  FilterPreviewProps,
  DateFilterPreviewProps,
} from "@Components/Filters/types";
import Table from "@Components/Table";
import IconButton from "@Components/IconButton";
import { Chargeback } from "@Types/chargebacks";
import { Column } from "@Components/Table/types";
import useGetChargebacks from "@Hooks/useChargebacks";
import DateFilter from "@Components/Filters/DateFilter";
import { formatDate, formatPrice } from "@Utils/functions";
import LenderFilter from "@Components/Filters/LenderFilter";

import styles from "./Chargebacks.module.scss";

const Chargebacks = () => {
  const navigate = useNavigate();

  const columns: Column<Chargeback>[] = [
    {
      id: "Amount",
      label: "Amount",
      truncate: 15,
      value: (chargeback) =>
        formatPrice(chargeback?.data?.info?.dealerChargebackAmount ?? 0) ?? "",
    },
    {
      label: "Customer",
      value: (chargeback) =>
        `${chargeback?.data?.deal?.applicantFirstName ?? ""} ${
          chargeback?.data?.deal?.applicantLastName ?? ""
        }`,
      id: "customer",
      truncate: 15,
    },

    {
      label: "Type",
      id: "type",
      truncate: 15,
      value: (chargeback) => chargeback.data?.info?.type ?? "N/A",
    },
    {
      label: "Lender",
      id: "lender",
      truncate: 15,
      value: (deal) => deal.data?.lender?.data?.info?.name ?? "N/A",
      sortPath: ["data", "info", "lender", "data", "info", "name"],
      filters: [
        {
          placeholder: "Lender",
          preview: LenderFilter,
          queryPath: ["data", "lenderId"],
        },
      ],
    },

    {
      id: "chargebackDate",
      label: "Chargeback date",
      value: (chargeback) =>
        formatDate(chargeback?.data?.info?.chargebackDate, "short"),
      filters: [
        {
          placeholder: "Chargeback date",
          preview: (props: FilterPreviewProps) => (
            <DateFilter {...(props as unknown as DateFilterPreviewProps)} />
          ),
          queryPath: ["chargebackDate"],
        },
      ],
      sortPath: ["data", "info", "chargebackDate"],
    },
    {
      id: "actions",
      label: "Actions",
      value: (chargeback) => (
        <IconButton
          id={`open-chargeback-${chargeback?._id}`}
          className={"blueCircle"}
          onClick={() => {
            navigate(`/chargebacks/${chargeback._id}`);
          }}
        >
          <AiOutlineArrowRight size={18} />
        </IconButton>
      ),
    },
  ];
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Chargebacks</h2>
      <Table<Chargeback>
        columns={columns}
        projection={{
          "data.info.chargebackDate": 1,
        }}
        useGetFunction={useGetChargebacks}
        entityType="chargebacks"
      />
    </div>
  );
};

export default Chargebacks;
