import QrCode from "qrcode.react";
import { useState } from "react";

import Grid from "@Components/Grid";
import Button from "@Components/Button";
import Dialog from "@Components/Dialog";
import OrDivider from "@Components/OrDivider";
import { DialogContent } from "@Components/Dialog/DialogContent";
import useSendPushNotification from "@Hooks/useSendPushNotification";

import styles from "./styles.module.scss";
import NotificationExample from "../../../../images/NotificationExample.jpg";

const ScanDriverLicense = ({ dealId }: { dealId: string }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const { sendPushNotification } = useSendPushNotification();

  const handleSendPushNotification = () => {
    sendPushNotification(dealId);
  };
  return (
    <Grid xs={12} container>
      <Grid
        container
        xs={12}
        id="scan-driver-license"
        className={styles.scanContainer}
      >
        <Grid xs={12} container className={styles.scanItemContainer}>
          <Grid xs={5} className={styles.scanItem}>
            <div className={styles.scanText}>Scan QR code to open the app</div>
            <QrCode
              size={256}
              value={`${process.env.REACT_APP_URI}/scan-driver-license/${dealId}`}
            />
            <div
              className={styles.instructions}
              onClick={() => setOpenDialog(!openDialog)}
            >
              Instructions
            </div>
          </Grid>
          <Grid xs={2}>
            <OrDivider />
          </Grid>
          <Grid xs={5} className={styles.scanItem}>
            <div className={styles.scanText}>
              Send notification to your mobile phone
            </div>
            <div
              style={{
                background: `url("${NotificationExample}")`,
                borderRadius: "9px",
                position: "sticky",
                backgroundPosition: "center",
                backgroundSize: "cover",
                width: "458px",
                height: "424px",
              }}
            ></div>
            <Button
              type="button"
              className={styles.scanButton}
              onClick={handleSendPushNotification}
            >
              Send Notification
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        id={"InstructionsDialog"}
        open={openDialog}
        title={"Scan QR code instruction"}
        closeIcon
        titleColor="blue"
        closeFn={() => {
          setOpenDialog(false);
        }}
      >
        <DialogContent>
          <ol>
            <li>
              Ensure that you have the mobile app installed on your device and
              you are logged in.
            </li>
            <li>Scan the qr code with your camera.</li>
            <li>
              Once the QR code is successfully scanned, you will be redirected
              to the scanning section.
            </li>
            <li>
              After you have scanned the front and the back section of your
              driver license, you can continue in the mobile or the web app with
              the application.
            </li>
          </ol>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ScanDriverLicense;
