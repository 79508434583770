import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { toast } from "react-toastify";

import Grid from "@Components/Grid";
import { auth } from "@Firebase/firebase";
import Button from "@Components/Button";
import TextField from "@Components/Inputs/TextField";

import styles from "./Auth.module.scss";

interface Credentials {
  email: string;
}

function ForgotPassword() {
  const [credentials, setCredentials] = useState<Credentials>({
    email: "",
  });
  const navigate = useNavigate();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (credentials.email) {
      auth
        .sendPasswordResetEmail(credentials.email)
        .then(function () {
          toast.info(
            "Password reset link sent! Please, check your email to complete the update password process.",
            {
              position: "top-center",
            }
          );
          navigate("/sign-in");
        })
        .catch(function () {
          toast.error(
            "Password reset link sent! Please, check your email to complete the update password process."
          );
        });
    } else {
      toast.error("Enter valid email!");
    }
  }

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Grid
        container
        rowSpacing={1}
        alignItems="center"
        direction="column"
        style={{ maxWidth: "485px", margin: "auto" }}
      >
        <Grid xs={12}>
          <h1
            style={{
              textAlign: "center",
              fontSize: "38px",
              color: "#29282E",
              fontWeight: "700",
            }}
          >
            Reset your password
          </h1>
        </Grid>
        <Grid xs={12}>
          <TextField
            onChange={(e) => {
              setCredentials((x) => ({
                ...x,
                email: e.target.value,
              }));
            }}
            className={styles.authInput}
            placeholder="Email"
            value={credentials.email ?? ""}
            name="email"
            startIcon={<RxPerson />}
            type="text"
          />
        </Grid>
        <Grid xs={12}>
          <Button
            className={styles.authButton}
            type="submit"
            style={{ fontSize: "30px", marginTop: "10px" }}
          >
            Reset password
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default ForgotPassword;
