import styles from "./NotFound.module.scss";
const BrokenCar = () => {
  return (
    <>
      <svg
        id="eal0TcXL2Jf1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 988 761"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
      >
        <defs>
          <linearGradient
            id="eal0TcXL2Jf3-fill"
            x1="475.556"
            y1="752.83"
            x2="475.556"
            y2="686.861"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop id="eal0TcXL2Jf3-fill-0" offset="0%" stopColor="#fff" />
            <stop id="eal0TcXL2Jf3-fill-1" offset="100%" stopColor="#e6e7e8" />
          </linearGradient>
          <linearGradient
            id="eal0TcXL2Jf4-fill"
            x1="269.413"
            y1="563.102"
            x2="269.413"
            y2="254"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop id="eal0TcXL2Jf4-fill-0" offset="0%" stopColor="#c7c6cb" />
            <stop
              id="eal0TcXL2Jf4-fill-1"
              offset="100%"
              stopColor="rgba(199,198,203,0)"
            />
          </linearGradient>
          <linearGradient
            id="eal0TcXL2Jf9-fill"
            x1="46.6433"
            y1="528.764"
            x2="96.4888"
            y2="640.221"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop id="eal0TcXL2Jf9-fill-0" offset="0%" stopColor="#1d0633" />
            <stop id="eal0TcXL2Jf9-fill-1" offset="100%" stopColor="#192b59" />
          </linearGradient>
          <linearGradient
            id="eal0TcXL2Jf17-fill"
            x1="1646.4"
            y1="560.329"
            x2="304.4"
            y2="618.906"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop id="eal0TcXL2Jf17-fill-0" offset="0%" stopColor="#6b97f6" />
            <stop id="eal0TcXL2Jf17-fill-1" offset="100%" stopColor="#b7cdff" />
          </linearGradient>
          <linearGradient
            id="eal0TcXL2Jf50-fill"
            x1="449.4"
            y1="533.27"
            x2="375.084"
            y2="533.269"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop id="eal0TcXL2Jf50-fill-0" offset="0%" stopColor="#6b97f6" />
            <stop id="eal0TcXL2Jf50-fill-1" offset="100%" stopColor="#b7cdff" />
          </linearGradient>
          <linearGradient
            id="eal0TcXL2Jf54-fill"
            x1="386.4"
            y1="512.16"
            x2="244.4"
            y2="480.906"
            spreadMethod="pad"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0 0)"
          >
            <stop id="eal0TcXL2Jf54-fill-0" offset="0%" stopColor="#6b97f6" />
            <stop id="eal0TcXL2Jf54-fill-1" offset="100%" stopColor="#b7cdff" />
          </linearGradient>
        </defs>
        <path
          d="M312.938,374.47v67.411h-291.9284l-4.7355-52.926L182.294,123.768h68.804l-74.653,123.68L100.956,374.47h211.982ZM269.483,123.768v405.58h-86.91v-405.58h86.91ZM633,290.345v72.147c0,29.713-3.343,55.433-10.028,77.16-6.5,21.728-15.971,39.648-28.413,53.762-12.256,14.113-26.927,24.606-44.012,31.477-16.899,6.685-35.655,10.028-56.268,10.028-16.528,0-31.942-2.136-46.241-6.407s-27.206-10.864-38.719-19.778c-11.328-9.099-21.078-20.427-29.249-33.984-8.171-13.556-14.485-29.712-18.942-48.469-4.271-18.756-6.407-40.019-6.407-63.789v-72.147c0-30.084,3.25-55.897,9.75-77.439c6.685-21.541,16.249-39.369,28.691-53.483c12.442-14.113,27.113-24.513,44.012-31.198c17.085-6.685,35.934-10.028,56.547-10.028c16.528,0,31.849,2.136,45.962,6.407c14.3,4.271,27.206,10.864,38.72,19.777c11.514,8.914,21.263,20.149,29.248,33.706c8.171,13.556,14.393,29.713,18.664,48.469c4.457,18.57,6.685,39.834,6.685,63.789Zm-86.91,83.011v-94.431c0-13.928-.835-25.999-2.507-36.213-1.485-10.399-3.714-19.22-6.685-26.463-2.786-7.242-6.314-13.092-10.585-17.549-4.272-4.457-9.193-7.707-14.764-9.749-5.385-2.229-11.328-3.343-17.828-3.343-8.171,0-15.506,1.671-22.006,5.014-6.499,3.343-11.978,8.635-16.434,15.878-4.457,7.056-7.893,16.62-10.307,28.691-2.229,11.885-3.343,26.463-3.343,43.734v94.431c0,13.927.743,26.091,2.229,36.491c1.671,10.399,3.899,19.313,6.685,26.741c2.971,7.243,6.593,13.185,10.864,17.828c4.271,4.457,9.192,7.706,14.763,9.749s11.607,3.064,18.107,3.064c8.171,0,15.413-1.578,21.727-4.735c6.314-3.343,11.699-8.635,16.156-15.878c4.643-7.428,8.079-17.178,10.307-29.248c2.414-12.071,3.621-26.742,3.621-44.012Zm425.357,1.114v67.411h-291.928l-4.735-52.926l166.02-265.187h68.804l-74.654,123.68L759.465,374.47h211.982ZM927.992,123.768v405.58h-86.91v-405.58h86.91Z"
          fill="#e7eeff"
          stroke="#e7eeff"
          strokeDashoffset="1885.059906"
          strokeDasharray="0"
        />
        <path
          d="M475.573,750.995c-262.652,0-475.573122-6.952-475.573122-15.527s212.921122-15.527,475.573122-15.527c262.651,0,475.573,6.952,475.573,15.527s-212.922,15.527-475.573,15.527Z"
          fill="url(#eal0TcXL2Jf3-fill)"
        />
        <path
          className={styles.smokePath}
          d="M289.775,276.293c0,0-55.652,20.935-57.571,45.708s21.982,37.16,14.306,60.537c-7.677,23.377-28.961,29.483-22.506,47.627s27.216,33.67,28.263,51.116c1.046,17.446-17.272,3.14-23.203,14.48-5.932,11.34,22.679,67.341,22.679,67.341s27.041-10.991,31.403-15.701c4.536-4.711,8.374-4.711,5.931-17.097-2.442-12.387,1.047-20.063,10.468-26.343c9.42-6.281,19.365-11.514,15.701-23.727-3.664-12.212-57.222-28.96-56.699-40.474s7.676-2.442,10.293-22.33c2.617-20.063,26.518-31.054,36.636-40.3c10.293-9.247,8.025-17.621.524-23.203-7.502-5.757-25.82-19.365-26.169-39.253-.174-20.237,9.944-38.381,9.944-38.381Z"
          transform="translate(.000009 0)"
          opacity="0"
          fill="url(#eal0TcXL2Jf4-fill)"
        />
        <path
          d="M151.954,412.37c3.838-2.966,18.143-14.306,23.901-17.795c5.757-3.489,19.888-10.991,22.505-12.212s4.187-4.012,4.536-6.28.697-8.374,1.57-10.642c1.046-2.268,2.268-2.966,2.442-4.362.175-1.396,3.664-1.919,3.664-1.919l5.408,8.897l3.664,6.281c-.698,1.745-7.502,10.816-9.945,11.34-2.616.698-15.701,12.386-18.667,15.003-2.965,2.791-21.109,30.356-21.109,30.356L151.954,412.37Z"
          fill="#f2dad4"
        />
        <path
          d="M232.902,497.506c4.711,3.663,16.051,10.816,20.935,13.608c4.711,2.791,12.736,8.374,15.004,8.374s5.059.872,6.106,1.919c1.047,1.046,3.489,3.489,3.489,3.489l-20.237,5.059c0,0-6.106-3.664-14.131-5.234-8.025-1.744-22.68-7.85-24.948-10.293-2.442-2.093,13.782-16.922,13.782-16.922Z"
          fill="#f2dad4"
        />
        <path
          d="M222.261,435.396c0,0-5.408,9.246-7.328,9.944-2.093.698-4.187,1.57-4.361,2.966s-1.57,9.072-3.14,9.246c-1.57.175-4.013-3.14-5.583-2.617-1.57.524-10.467,8.025-14.48,7.502-4.012-.523-18.667-8.548-19.016-13.608-.349-5.059-.698-22.156,1.396-23.726c2.093-1.745,15.527-12.735,22.331-13.782c6.803-1.047,21.283,7.676,25.296,11.689c4.012,4.012,6.455,9.944,4.885,12.386Z"
          fill="#f2dad4"
        />
        <path
          d="M102.058,712.092c3.141,2.617,16.225,13.782,19.016,14.48c2.966.698,10.817,0,12.212,2.268c1.745,2.617-3.314,5.757-6.455,6.106-3.14.349-38.7292-.349-42.2184-1.047-3.4892-.872-6.8038-3.489-7.3272-4.361-.5233-.873-.8723-11.166,1.2212-13.608c1.919-2.617,23.5514-3.838,23.5514-3.838Z"
          fill="#6b97f6"
        />
        <path
          d="M107.118,540.595c0,0-4.013,73.621-5.757,83.565-1.745,9.77-5.7576,10.293-6.281,28.262-.5233,17.795,1.2213,45.011,4.536,51.291c3.315,6.281,6.629,12.212-1.9191,14.829-8.5485,2.617-16.0501,2.617-19.5392.872-3.4892-1.744-4.3615-6.455-5.4083-16.05-1.0467-9.595-3.1402-70.306-3.1402-79.727s1.0468-95.603,2.9658-98.743c1.919-2.966,34.543,15.701,34.543,15.701Z"
          fill="url(#eal0TcXL2Jf9-fill)"
        />
        <path
          d="M65.7713,714.881c1.3956,3.14,8.1995,9.944,12.7354,12.038c4.3614,2.093,9.5952,4.361,13.2589,4.71c3.6636.349,5.5826.698,6.106,2.791.5234,2.094-6.9784,5.06-15.0035,5.06-8.1995,0-27.5643-2.617-30.1812-2.443-2.4424.175-11.1653,1.221-12.7354-.349s-.6978-16.922-.3489-18.667c.349-1.57,26.1687-3.14,26.1687-3.14Z"
          fill="#6b97f6"
        />
        <path
          d="M103.628,542.168c0,0-17.4453,74.668-21.2834,84.263s-11.3398,17.795-12.3865,23.726c-1.0468,6.106-7.1528,49.023-5.7571,55.827s3.4891,9.246,2.0934,10.293c-1.3957.872-9.944,3.489-13.7821,6.106s-13.2589-1.221-14.1312-3.489s4.3615-63.677,7.5018-74.668s7.5016-27.565,10.293-33.845c2.7913-6.281,2.4424-15.701,2.0935-24.773-.5234-9.072,2.2679-22.68-.6979-28.96-3.1402-6.281-6.4549-8.549-7.1527-19.016-.6979-10.468,23.5518-25.82,39.253-22.854c9.4208,1.744,16.7482,15.876,13.9562,27.39Z"
          fill="#192b59"
        />
        <path
          d="M189.463,465.756c6.455,7.152,15.701,17.62,21.807,19.365c6.106,1.744,6.63-.698,10.468,2.268c3.838,2.965,13.956,9.42,13.782,11.688-.175,2.268-16.05,20.412-19.714,19.016-3.664-1.57-12.212-10.293-20.412-15.003-8.199-4.711-25.645-16.05-27.913-15.004-2.268.873-15.876,9.77-23.552,18.842s-13.607,17.097-13.607,23.203s1.919,12.735,4.361,17.097c2.442,4.187,6.629,6.28,4.361,9.246s-7.152-4.187-12.386-3.838-20.586-2.617-25.82-10.293c-5.2336-7.676-10.2929-16.05-20.7604-13.957-10.4675,2.094-23.3773,8.898-28.0877,7.502-6.2805-1.745-1.0468-11.34,6.4549-22.156c7.3272-10.817,44.3122-51.989,50.2442-62.98c5.931-10.991,17.794-27.913,29.483-33.147c11.689-5.233,20.063-9.944,24.075-6.106c4.013,3.838,6.106,10.119,6.106,11.863c0,1.745,2.966-.697,2.966-.697s8.374,16.573,8.2,19.888c-.175,3.314-1.919,7.676,1.919,9.944s7.153,3.489,7.502,6.978c.523,2.966-.524,5.059.523,6.281Z"
          fill="#6b97f6"
        />
        <path
          d="M202.547,398.588c8.025,1.222,16.399,5.758,20.237,13.434s5.931,14.48,5.931,19.19c0,4.885-4.012,9.072-6.629,8.2-5.583-1.919-1.047-11.34-4.013-12.736-4.361-2.093-5.233,1.396-5.408,3.664-.174,2.268-2.791.174-6.455,1.221-3.663,1.221-4.536,4.187-7.153,4.013-2.442-.175-4.187-3.49-3.14-6.63s1.221-6.978-1.047-7.327-9.246,4.536-12.037,8.374-8.898-4.536-9.77-7.502c-.698-2.966,5.932-11.514,11.514-15.701c5.583-4.187,12.212-9.072,17.97-8.2Z"
          fill="#373739"
        />
        <path
          d="M313.851,527.165c1.046,0,1.744-.872,1.744-1.745c0-.872-.872-1.744-1.744-1.744-5.06,0-9.072-4.013-9.072-9.072s4.012-9.072,9.072-9.072c1.046,0,1.744-.872,1.744-1.744c0-1.047-.872-1.745-1.744-1.745-6.979,0-12.736,5.757-12.736,12.735.175,6.804,5.757,12.387,12.736,12.387Z"
          fill="#192b59"
        />
        <path
          d="M382.761,687.316c0,0-154.918,1.745-174.807,0-19.888-1.744,3.838-70.481,3.838-70.481l94.557-44.661l82.867,24.075-6.455,91.067Z"
          fill="#5e5d65"
        />
        <path
          d="M912.591,668.646c0,0-32.449,7.153-89.672,13.259-57.222,6.28-94.73,5.408-94.73,5.408l41.172-89.671l70.83-29.484l52.163,21.982l20.237,33.845v44.661Z"
          fill="#5e5d65"
        />
        <path
          d="M177.25,571.65c30.53-32.1,81.297-41.347,102.755-46.929c21.459-5.583,47.976-15.004,64.724-23.203c16.748-8.2,118.806-59.316,178.471-71.702c59.665-12.212,126.657-10.119,126.657-10.119l113.397,1.57c28.611.349,56.525,9.77,79.553,26.867c28.786,21.458,65.597,48.848,72.052,53.733c6.106,4.536,21.458,10.118,23.377,62.63c1.919,52.687,2.791,88.974-7.676,96.65-10.468,7.676-21.11,13.957-21.11,13.957l-1.57.174c.175-2.268.349-4.536.349-6.804c0-42.916-34.543-77.634-77.285-78.157-42.742-.349-78.157,34.019-78.855,76.762-.174,8.548,1.047,16.922,3.489,24.773l-5.233.523-170.62,3.315-205.861,2.268-.524-24.424c0-43.092-35.066-78.158-78.157-78.158s-78.157,35.066-78.157,78.158l-.174,27.389-42.917.175c0,0-23.203-2.617-26.518-29.483-3.314-26.867-.698-67.865,29.833-99.965Z"
          fill="url(#eal0TcXL2Jf17-fill)"
        />
        <path
          d="M805.022,730.596c-31.215-13.261-45.771-49.317-32.51-80.533c13.261-31.215,49.317-45.77,80.532-32.51c31.216,13.261,45.771,49.317,32.51,80.533-13.261,31.215-49.316,45.771-80.532,32.51Z"
          fill="#29282e"
        />
        <path
          d="M829.025,716.801c-23.606,0-42.742-19.136-42.742-42.742s19.136-42.743,42.742-42.743s42.743,19.137,42.743,42.743-19.137,42.742-42.743,42.742Z"
          fill="#e7eeff"
        />
        <path
          d="M821.174,659.575c-1.396.698-2.617,1.745-3.664,2.792-1.221,1.221-2.965,1.395-4.361.349L804.6,656.61c-1.57-1.222-1.919-3.49-.523-4.885c3.838-4.362,8.897-7.676,14.48-9.421c2.094-.698,4.361.872,4.361,3.14v11.166c0,1.395-.697,2.442-1.744,2.965Z"
          fill="#29282e"
        />
        <path
          d="M840.713,662.367c-1.046-1.047-2.268-2.094-3.663-2.792-1.047-.523-1.745-1.744-1.745-2.965v-11.166c0-2.268,2.268-3.838,4.362-3.14c5.582,1.919,10.642,5.234,14.48,9.421c1.395,1.57,1.046,3.838-.524,4.885l-8.548,6.106c-1.396,1.046-3.14.872-4.362-.349Z"
          fill="#29282e"
        />
        <path
          d="M813.323,678.939c.175.523.349,1.047.524,1.396.349.872.349,2.093-.175,2.966l-5.931,10.293c-1.222,2.093-4.187,2.268-5.583.174-4.012-5.583-6.455-12.386-6.455-19.714c0-.872,0-1.57,0-2.268.175-2.617,3.14-4.012,5.234-2.442l10.991,7.85c.697.349,1.046,1.047,1.395,1.745Z"
          fill="#29282e"
        />
        <path
          d="M822.919,692.025c.697-1.221,2.093-1.919,3.314-1.57.873.175,1.919.175,2.792.175c1.046,0,1.919,0,2.791-.175c1.396-.174,2.617.349,3.315,1.57l5.408,9.246c1.047,1.92.174,4.187-1.919,4.885-3.14.873-6.281,1.396-9.77,1.396-3.315,0-6.629-.523-9.77-1.396-2.093-.698-2.965-2.965-1.919-4.885l5.758-9.246Z"
          fill="#29282e"
        />
        <path
          d="M844.377,680.335c.175-.524.349-1.047.524-1.396.174-.698.698-1.396,1.221-1.745l11.165-7.85c2.094-1.57,5.06-.175,5.234,2.442c0,.698,0,1.57,0,2.268c0,7.328-2.442,14.306-6.455,19.714-1.396,1.919-4.361,1.919-5.583-.174l-5.931-10.293c-.524-.873-.524-1.92-.175-2.966Z"
          fill="#29282e"
        />
        <path
          d="M831.991,666.905c0,1.396,1.046,2.443,2.442,2.443s2.442-1.047,2.442-2.443c0-1.395-1.046-2.442-2.442-2.442s-2.442,1.221-2.442,2.442Z"
          fill="#192b59"
        />
        <path
          d="M820.477,666.905c0,1.396,1.047,2.443,2.443,2.443s2.442-1.047,2.442-2.443c0-1.395-1.046-2.442-2.442-2.442s-2.443,1.221-2.443,2.442Z"
          fill="#192b59"
        />
        <path
          d="M837.749,679.463c-1.349,0-2.442-1.094-2.442-2.442c0-1.349,1.093-2.443,2.442-2.443s2.442,1.094,2.442,2.443c0,1.348-1.093,2.442-2.442,2.442Z"
          fill="#192b59"
        />
        <path
          d="M818.732,679.463c-1.348,0-2.442-1.094-2.442-2.442c0-1.349,1.094-2.443,2.442-2.443c1.349,0,2.443,1.094,2.443,2.443c0,1.348-1.094,2.442-2.443,2.442Z"
          fill="#192b59"
        />
        <path
          d="M829.026,687.314c-1.349,0-2.443-1.093-2.443-2.442s1.094-2.442,2.443-2.442s2.442,1.093,2.442,2.442-1.093,2.442-2.442,2.442Z"
          fill="#192b59"
        />
        <path
          d="M251.08,717.483c-23.981-23.981-23.981-62.863,0-86.845c23.982-23.981,62.864-23.981,86.845,0c23.982,23.982,23.982,62.863,0,86.845-23.981,23.982-62.863,23.982-86.845,0Z"
          fill="#29282e"
        />
        <path
          d="M294.486,716.799c-23.606,0-42.742-19.136-42.742-42.742s19.136-42.743,42.742-42.743s42.742,19.137,42.742,42.743-19.136,42.742-42.742,42.742Z"
          fill="#e7eeff"
        />
        <path
          d="M286.46,659.579c-1.396.698-2.617,1.745-3.664,2.792-1.221,1.221-2.966,1.395-4.362.349l-8.548-6.107c-1.57-1.221-1.919-3.489-.523-4.884c3.838-4.362,8.897-7.677,14.48-9.421c2.093-.698,4.361.872,4.361,3.14v11.165c0,1.396-.698,2.443-1.744,2.966Z"
          fill="#29282e"
        />
        <path
          d="M306.174,662.371c-1.047-1.047-2.268-2.094-3.664-2.792-1.047-.523-1.744-1.744-1.744-2.966v-11.165c0-2.268,2.268-3.838,4.361-3.14c5.583,1.919,10.642,5.234,14.48,9.421c1.396,1.57,1.047,3.838-.523,4.884l-8.549,6.107c-1.395,1.046-3.14.872-4.361-.349Z"
          fill="#29282e"
        />
        <path
          d="M278.609,678.943c.175.523.349,1.047.524,1.396.349.872.349,2.093-.175,2.965l-5.931,10.294c-1.221,2.093-4.187,2.267-5.583.174-4.012-5.583-6.455-12.387-6.455-19.714c0-.872,0-1.57,0-2.268.175-2.617,3.14-4.012,5.234-2.442l10.991,7.85c.698.349,1.221,1.047,1.395,1.745Z"
          fill="#29282e"
        />
        <path
          d="M288.205,692.029c.698-1.221,2.093-1.919,3.314-1.57.873.174,1.919.174,2.792.174c1.046,0,1.919,0,2.791-.174c1.396-.175,2.617.349,3.315,1.57l5.408,9.246c1.047,1.919.174,4.187-1.919,4.885-3.14.872-6.281,1.396-9.77,1.396-3.314,0-6.629-.524-9.769-1.396-2.094-.698-2.966-2.966-1.919-4.885l5.757-9.246Z"
          fill="#29282e"
        />
        <path
          d="M309.837,680.339c.175-.524.349-1.047.524-1.396.174-.698.698-1.396,1.221-1.745l10.991-7.85c2.093-1.57,5.059-.175,5.234,2.442c0,.698,0,1.57,0,2.268c0,7.327-2.443,14.306-6.455,19.714-1.396,1.919-4.362,1.919-5.583-.174l-5.932-10.294c-.348-.872-.348-1.919,0-2.965Z"
          fill="#29282e"
        />
        <path
          d="M297.452,666.903c0,1.396,1.046,2.443,2.442,2.443s2.442-1.047,2.442-2.443c0-1.395-1.046-2.442-2.442-2.442s-2.442,1.221-2.442,2.442Z"
          fill="#192b59"
        />
        <path
          d="M285.762,666.903c0,1.396,1.047,2.443,2.443,2.443c1.395,0,2.442-1.047,2.442-2.443c0-1.395-1.047-2.442-2.442-2.442-1.396,0-2.443,1.221-2.443,2.442Z"
          fill="#192b59"
        />
        <path
          d="M300.766,677.024c0,1.396,1.047,2.443,2.443,2.443c1.395,0,2.442-1.047,2.442-2.443c0-1.395-1.047-2.442-2.442-2.442-1.396,0-2.443,1.047-2.443,2.442Z"
          fill="#192b59"
        />
        <path
          d="M284.193,679.467c-1.349,0-2.443-1.094-2.443-2.443c0-1.348,1.094-2.442,2.443-2.442s2.442,1.094,2.442,2.442c0,1.349-1.093,2.443-2.442,2.443Z"
          fill="#192b59"
        />
        <path
          d="M292.043,684.876c0,1.396,1.047,2.442,2.442,2.442c1.396,0,2.443-1.046,2.443-2.442s-1.047-2.442-2.443-2.442c-1.395,0-2.442,1.221-2.442,2.442Z"
          fill="#192b59"
        />
        <path
          d="M871.768,517.22c.697-3.489,17.794-19.539,26.866-27.39c8.025,5.932,14.131,10.468,16.399,12.212c6.106,4.536,21.459,10.119,23.378,62.631c0,.349,0,.697,0,1.046-11.515-5.931-42.917-26.692-51.989-32.798-10.816-7.153-15.352-11.863-14.654-15.701Z"
          fill="#eb8190"
        />
        <path
          d="M379.796,575.662c4.012-28.262,11.339-48.674,11.514-48.848.174-.524.872-.872,1.396-.698.523.175.872.872.697,1.396c0,.174-7.327,20.411-11.339,48.499-3.664,25.645-5.234,63.328,8.025,97.348l215.63-2.617c-.698-12.038-5.757-115.317,6.28-159.629.175-.524.698-.873,1.396-.698.523.174.872.698.698,1.396-11.863,43.963-6.804,147.242-6.106,158.931l124.04-2.443c.523-5.233,3.489-29.134,9.944-37.683c6.629-8.722,71.004-87.229,76.936-94.556l-22.505-47.452c-.175-.524,0-1.222.523-1.396.523-.175,1.221,0,1.396.523L821,535.886c.175.349.175.872-.174,1.221-.698.872-70.481,86.008-77.46,95.08-6.804,8.897-9.595,37.334-9.595,37.508c0,.523-.523,1.047-1.047,1.047l-126.133,2.442-217.549,2.617c-.524,0-.872-.349-1.047-.698-13.433-34.891-11.863-73.272-8.199-99.441Z"
          fill="#b7cdff"
        />
        <path
          d="M857.287,514.775c0-8.2-6.28-14.829-14.306-15.527.349,0,.873,0,1.222,0c8.548,0,15.526,6.978,15.526,15.527c0,8.548-6.978,15.527-15.526,15.527-.349,0-.873,0-1.222,0c8.026-.698,14.306-7.328,14.306-15.527Z"
          fill="#a4cbe0"
        />
        <path
          d="M759.242,529.955l27.564-2.268c4.362-.349,7.676-4.362,7.327-8.723-.348-4.362-4.361-7.676-8.722-7.327l-27.565,2.267c-4.361.349-7.676,4.362-7.327,8.723.349,4.362,4.361,7.677,8.723,7.328Z"
          fill="#6b97f6"
        />
        <path
          d="M555.126,548.796l27.564-2.267c4.362-.349,7.677-4.362,7.328-8.723-.349-4.362-4.362-7.677-8.723-7.328l-27.565,2.268c-4.361.349-7.676,4.362-7.327,8.723.524,4.362,4.362,7.676,8.723,7.327Z"
          fill="#6b97f6"
        />
        <path
          d="M551.812,545.655l25.471-2.094c2.616-.174,4.71-2.617,4.535-5.234-.174-2.617-2.616-4.71-5.233-4.536l-25.471,2.094c-2.617.174-4.71,2.617-4.536,5.234.174,2.791,2.442,4.71,5.234,4.536Z"
          fill="#b7cdff"
        />
        <path
          d="M755.927,527.164l25.471-2.093c2.617-.175,4.711-2.617,4.536-5.234-.174-2.617-2.617-4.71-5.234-4.536l-25.47,2.094c-2.617.174-4.711,2.617-4.536,5.233.174,2.617,2.617,4.711,5.233,4.536Z"
          fill="#b7cdff"
        />
        <path
          d="M844.203,475.872c0,0-19.888-35.241-58.269-38.207-38.381-2.965-206.209-7.85-255.407,3.838-49.197,11.515-83.565,23.203-107.117,34.369-23.552,11.165-87.229,40.125-87.403,45.533-.175,5.408,45.882,14.655,63.154,14.655c17.271,0,163.816-12.736,222.608-18.842s159.978-10.293,180.913-18.492c20.935-8.025,40.649-17.446,41.521-22.854Z"
          fill="#373739"
        />
        <path
          d="M365.49,533.269h19.016c7.327,0,13.433-6.106,13.433-13.433c0-7.328-6.106-13.434-13.433-13.434h-18.841c-7.328,0-13.434,6.106-13.434,13.434-.174,7.327,5.932,13.433,13.259,13.433Z"
          fill="url(#eal0TcXL2Jf50-fill)"
        />
        <path
          d="M186.496,633.059l-4.536,37.683c-.174.872-.872,1.57-1.919,1.57h-10.817c-.872,0-1.57-.523-1.744-1.395l-8.2-29.484c-.348-.872.175-1.919,1.222-2.268l23.551-8.025c1.222-.523,2.617.698,2.443,1.919Z"
          fill="#5e5d65"
        />
        <path
          d="M154.744,607.237c4.711-12.386,12.038-24.424,22.68-35.589.349-.349.698-.698.872-1.047L239.182,554.9c0,0,10.119-2.966,10.642,1.919s-15.003,22.505-18.667,23.901c-3.664,1.57-67.341,25.121-71.877,25.819-1.744.349-3.14.524-4.536.698Z"
          fill="#f3f3f3"
        />
        <path
          d="M157.012,603.401c4.536-.872,68.388-24.424,71.877-25.82c3.489-1.395,17.62-17.271,18.667-23.028c1.221.349,1.919,1.046,2.094,2.442.523,4.885-15.004,22.505-18.667,23.901-3.664,1.57-67.341,25.122-71.877,25.82-1.57.349-3.14.523-4.536.523.523-1.221,1.047-2.442,1.57-3.664.349,0,.698-.174.872-.174Z"
          fill="#e7eeff"
        />
        <path
          d="M321.526,512.159L234.297,368.231c-.523-.872-1.046-1.57-1.744-2.268-3.838-4.012-17.446-17.271-25.995-13.258L293.264,497.33c1.047,1.745,2.617,3.141,4.536,4.013l23.726,10.816Z"
          fill="url(#eal0TcXL2Jf54-fill)"
        />
      </svg>
    </>
  );
};

export default BrokenCar;
