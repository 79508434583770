import axios from "axios";

export type VINLookupResult = {
  Count: number;
  Message: string;
  SearchCriteria: string;
  Results: Result[];
};

export type Result = {
  Value: null | string;
  ValueId: string | null;
  Variable: string;
  VariableId: number;
};

export const lookupVIN = async (vin: string): Promise<VINLookupResult> => {
  const response = await axios.get(
    `${process.env.REACT_APP_VIN_CHECK_API_URL}/${vin}?format=json`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );
  return response.data;
};
