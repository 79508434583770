import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  Deal,
  DealStatus,
  ReasonsDead,
  ReasonsDidNotProcess,
} from "@Types/deal";
import { MutationError } from "@Types/index";
import { statusChange } from "@Http/status_change";

const useStatusChange = () => {
  const mutation = useMutation<
    Deal,
    MutationError,
    {
      dealId: string;
      reason: ReasonsDidNotProcess | ReasonsDead;
      status: DealStatus.Dead | DealStatus.DidNotProcess;
    }
  >(statusChange(), {
    onSuccess: () => {
      toast.success("Successfully marked the deal as 'Did not move forward'.", {
        position: "top-center",
      });
    },
  });
  return {
    statusChange: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useStatusChange;
