import styles from "./Notifications.module.scss";
import { CgClose } from "react-icons/cg";
import { CgSearchLoading } from "react-icons/cg";

export default () => {
  return (
    <div className={styles.notificationContainer}>
      <div className={styles.newBadgeLoading}> </div>
      <div className={styles.iconCircleLoading}>
        <CgSearchLoading size={30} color="#5e5d65" />
      </div>
      <div className={styles.notificationBody}>
        <div className={styles.notificationTitle}>
          <div className={styles.titlePlaceholder}>
            <div className={styles.animatedTitlePlaceholder}></div>
          </div>
        </div>
        <div className={styles.notificationDescription}>
          <div className={styles.descriptionPlaceholder}>
            <div className={styles.animatedDescriptionPlaceholder}></div>
          </div>
        </div>
      </div>
      <div className={styles.notificationTime}></div>
      <div className={styles.deleteNotificationButtonLoading}>
        <CgClose size={15} color="#a5aec1" />
      </div>
    </div>
  );
};
