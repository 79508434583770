import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

import IconButton from "@Components/IconButton";
import { Column } from "@Components/Table/types";
import Table from "@Components/Table";
import AddButton from "@Components/AddButton";
import TextFilter from "@Components/Filters/TextFilter";
import UserFirstNameLastNameFilter from "@Components/Filters/UserFirstNameLastNameFilter";
import { User } from "@Types/users";
import useMembers from "@Hooks/useMembers";

import styles from "./Users.module.scss";

const Users = () => {
  const columns: Column<User>[] = [
    {
      id: "name",
      label: "Name",
      value: (user) =>
        `${user?.data?.info?.firstName ?? "First name"} ${
          user?.data?.info?.lastName ?? ""
        }`,
      sortPath: ["data", "info", "firstName"],
      filters: [
        {
          placeholder: "Name",
          preview: UserFirstNameLastNameFilter,
          queryPath: [],
        },
      ],
    },

    {
      id: "email",
      label: "Email",
      value: (user) => user.data.info.email ?? "",
      sortPath: ["data", "info", "email"],
      filters: [
        {
          placeholder: "Email",
          preview: TextFilter,
          queryPath: ["data", "info", "email"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },

    {
      id: "string",
      label: "Role",
      sortPath: ["data", "info", "userType"],
      value: (user) =>
        user?.data?.info?.userType === "dealership-admin"
          ? "Admin"
          : user?.data?.info?.userType === "owner"
          ? "Owner"
          : "Manager",
      filters: [
        {
          placeholder: "Role",
          preview: TextFilter,
          queryPath: ["data", "info", "userType"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },

    {
      id: "actions",
      label: "Actions",
      value: (user) => (
        <IconButton
          id={`open-user-${user?._id}`}
          className={"blueCircle"}
          onClick={() => {
            navigate(`/members/${user._id}`);
          }}
        >
          <AiOutlineArrowRight size={18} />
        </IconButton>
      ),
    },
  ];

  const navigate = useNavigate();

  return (
    <div className={styles.tableContainer}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <h2 className={styles.title}>Users</h2>

        <AddButton entity="member" id="create-new-member" />
      </div>
      <Table<User>
        columns={columns}
        useGetFunction={useMembers}
        entityType="members"
      />
    </div>
  );
};

export default Users;
