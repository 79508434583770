import axios from "axios";
import { auth } from "@Firebase/firebase";

import { convertBlobToBase64 } from "@Utils/functions";

import { AddDocumentRequest, Document } from "@Types/documents";
import { HttpQuery } from "@Types/http";
import SparkMD5 from "spark-md5";

export const uploadDocument = (
  setProgress: React.Dispatch<React.SetStateAction<number>>
): (({ file, data }: AddDocumentRequest) => Promise<Document>) => {
  return async ({ file, data: documentData }) => {
    const token = await auth.currentUser?.getIdToken();

    const uploadedFileResult = await uploadFile(file, token, setProgress);

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_document/`,
      {
        data: {
          ...documentData,
          info: {
            ...documentData.info,
            name: file.name,
            url: uploadedFileResult.result.fileUrl,
            metadata: uploadedFileResult.result.metadata,
          },
        },
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
        onUploadProgress: ({ progress }) => {
          if (progress) {
            setProgress(Math.round(progress * 100));
          }
        },
        onDownloadProgress: ({ progress }) => {
          if (!progress) {
            setProgress(0);
          }
          if (progress && Math.round(progress * 100)) {
            setProgress(0);
          }
        },
      }
    );

    return response.data.result;
  };
};
export const uploadFile = async (
  file: File,
  token: string | undefined,
  setProgress?: React.Dispatch<React.SetStateAction<number>>
): Promise<{ result: { fileUrl: string; metadata: object; name: string } }> => {
  const chunkSize = 1024 * 1024 * 10; // 10MB chunks
  const totalChunks = Math.ceil(file.size / chunkSize);
  const checksum = await calculateChecksum(file, totalChunks, chunkSize);
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);

      const base64chunk = await convertBlobToBase64(chunk);

      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_document_file/`,
          {
            totalChunks,
            chunkNumber: i,
            filename: file.name,
            filetype: file.type,
            checksum,
            chunk: (base64chunk as string).split(",")[1],
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
            withCredentials: true, // make the files to be send to the same instance because of cookie
          }
        );
        const progress = (i + 1) / totalChunks;

        setProgress?.(Math.round(progress * 100));
        if (resp?.data?.result?.fileUrl) {
          resolve(resp.data);
        }
      } catch (err) {
        setProgress?.(0);
        reject(err);
      }
    }
  });
};

export const uploadFundedDocument = (
  setProgress: React.Dispatch<React.SetStateAction<number>>
): (({ file, data }: AddDocumentRequest) => Promise<Document>) => {
  return async ({ file, data: documentData }) => {
    const token = await auth.currentUser?.getIdToken();

    const uploadedFileResult = await uploadFile(file, token, setProgress);

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_funding_document/`,
      {
        data: {
          ...documentData,
          info: {
            ...documentData.info,
            name: file.name,
            url: uploadedFileResult.result.fileUrl,
            metadata: uploadedFileResult.result.metadata,
          },
        },
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
        onUploadProgress: ({ progress }) => {
          if (progress) {
            setProgress(Math.round(progress * 100));
          }
        },
        onDownloadProgress: ({ progress }) => {
          if (!progress) {
            setProgress(0);
          }
          if (progress && Math.round(progress * 100)) {
            setProgress(0);
          }
        },
      }
    );

    return response.data.result;
  };
};

export const getDocuments = (
  query?: HttpQuery
): (() => Promise<Document[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_document`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );
    return response.data.result.docs;
  };
};

export const getFundingDocuments = (
  query?: HttpQuery
): (() => Promise<Document[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_funding_document`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );
    return response.data.result.docs;
  };
};
const calculateChecksum = (
  file: File,
  totalChunks: number,
  chunkSize: number
) => {
  return new Promise((resolve) => {
    const blobSlice = File.prototype.slice;
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    fileReader.onload = function (e) {
      if (e?.target?.result) {
        spark.append(e.target.result as ArrayBuffer);
      }
      currentChunk++;

      if (currentChunk < totalChunks) {
        loadNext();
      } else {
        resolve(spark.end());
      }
    };

    fileReader.onerror = function (e) {
      console.warn(e);
    };

    function loadNext() {
      const start = currentChunk * chunkSize;
      const end =
        start + chunkSize >= file.size ? file.size : start + chunkSize;

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }

    loadNext();
  });
};
