import { useNavigate } from "react-router-dom";
import { RxFileText, RxPerson } from "react-icons/rx";

import Loading from "@Components/Loading";

import { formatFirstAndLastNames } from "@Utils/functions";
import { formatCustomersNames } from "@Pages/Applications/Applications";

import { ResultsTableProps } from "./types";

import styles from "./Search.module.scss";
import useCurrentUser from "@Hooks/useCurrentUser";
import mixpanel from "mixpanel-browser";
import { Fragment } from "react";
import { hasActionPermissions } from "@Utils/permissions";

const ResultsTable = ({ applicants, deals, setFocus }: ResultsTableProps) => {
  const user = useCurrentUser();
  mixpanel.track("Performed search", {
    email: user?.data?.info?.email,
    dealershipName: user?.data?.dealership?.data?.info?.name,
  });
  const navigate = useNavigate();
  const navigateToEntry = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    entity: "applications" | "users" | "applicants",
    id: string
  ) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/${entity}/${id}`);
    setFocus?.(false);
  };
  const dealPermissions = hasActionPermissions("deal", "read", user);
  const applicantsPermissions = hasActionPermissions("applicant", "read", user);
  return (
    <div className={styles.tableContainer}>
      {dealPermissions && (
        <Fragment>
          <div
            className={[styles.tableHeaderContainer, styles.topBorders].join(
              " "
            )}
          >
            <RxFileText size={30} className={styles.headerIcon} />
            Deals
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th align="left">Customer</th>
                <th align="left">Ref. #</th>
                <th align="left">Lender</th>
                <th align="left">Status</th>
              </tr>
            </thead>
            <tbody>
              {deals?.loading && (
                <tr>
                  <td align="center" colSpan={4}>
                    <Loading size={25} text={undefined} />
                  </td>
                </tr>
              )}
              {deals.data?.map((deal, index) => (
                <tr
                  key={index}
                  onMouseDown={(e) =>
                    navigateToEntry(e, "applications", deal._id)
                  }
                >
                  <>
                    <td align="left">{formatCustomersNames(deal)}</td>
                    <td align="left">{deal?.data?.info?.refNumber}</td>
                    <td align="left">{deal?.data?.lender?.data?.info?.name}</td>
                    <td align="left">{deal?.data?.info?.status}</td>
                  </>
                </tr>
              ))}
            </tbody>
          </table>
        </Fragment>
      )}

      {applicantsPermissions && (
        <Fragment>
          <div className={styles.tableHeaderContainer}>
            <RxPerson size={30} className={styles.headerIcon} />
            Applicants
          </div>
          <table className={styles.table}>
            <thead>
              <th align="left">Name</th>
              <th align="left">City</th>
              <th align="left">Zip Code</th>
              <th align="left">Phone</th>
            </thead>
            <tbody>
              {applicants?.loading && (
                <tr>
                  <td align="center" colSpan={4}>
                    <Loading size={25} text={undefined} />
                  </td>
                </tr>
              )}
              {applicants.data?.map((applicant, index) => (
                <tr
                  key={index}
                  onMouseDown={(e) =>
                    navigateToEntry(e, "applicants", applicant._id)
                  }
                >
                  <>
                    <td align="left">
                      {formatFirstAndLastNames(
                        applicant?.data?.info?.firstName,
                        applicant?.data?.info?.lastName
                      )}
                    </td>
                    <td align="left">{applicant?.data?.info?.currentCity}</td>
                    <td align="left">
                      {applicant?.data?.info?.currentZipCode}
                    </td>
                    <td align="left">{applicant?.data?.info?.mobilePhone}</td>
                  </>
                </tr>
              ))}
            </tbody>
          </table>
        </Fragment>
      )}
    </div>
  );
};
export default ResultsTable;
