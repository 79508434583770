import { CiCircleRemove } from "react-icons/ci";
import { useState } from "react";

import Grid from "@Components/Grid";
import TextField from "@Components/Inputs/TextField";
import IconButton from "@Components/IconButton";

import { FilterPreviewProps } from "./types";

const UserNamesFilter = ({
  filterState,
  setFilterState,
}: FilterPreviewProps) => {
  const [current, setCurrent] = useState("");
  const queryPath = [
    ["data", "info", "firstName"],
    ["data", "info", "lastName"],
  ];
  const handleKeyUp = (
    e: React.KeyboardEvent<HTMLInputElement> & {
      target: React.BaseSyntheticEvent<
        KeyboardEvent,
        EventTarget & HTMLInputElement,
        EventTarget
      > & { value: string };
    }
  ) => {
    if (e.keyCode === 13 && e?.target?.value?.trim() !== "") {
      const newValues = [...(filterState?.values ?? []), e.target.value];
      setFilterState({
        values: newValues,
        query: {
          ...(filterState?.query ?? {}),
          $or: [
            ...queryPath.map((childPath) => ({
              [childPath.join(".")]: {
                $in: newValues.map((value) => ({
                  value,
                  caseInsensitive: true,
                  partialSearch: true,
                })),
              },
            })),
          ],
        },
      });
      setCurrent("");
    }
  };
  const handleRemoveFilter = (value: string) => {
    const filteredState = filterState?.values.filter((x) => x !== value) ?? [];

    setFilterState({
      values: filteredState,
      query: {
        ...(filterState?.query ?? {}),
        $or: [
          ...queryPath.map((childPath) => ({
            [childPath.join(".")]: {
              $in: filteredState.map((value) => ({
                value,
                caseInsensitive: true,
                partialSearch: true,
              })),
            },
          })),
        ],
      },
    });
  };
  return (
    <Grid container xs={12}>
      <Grid xs={12}>
        <ul>
          {(filterState?.values ?? []).map((value, index: number) => (
            <IconButton
              key={index}
              onClick={() => handleRemoveFilter(value)}
              flex
              style={{ fontSize: "16px" }}
            >
              {value}
              <CiCircleRemove size={18} />
            </IconButton>
          ))}
        </ul>
      </Grid>
      <TextField
        onKeyUp={handleKeyUp}
        onChange={(e) => setCurrent(e.target.value)}
        placeholder="Name"
        value={current}
        name="Email"
        required
        type="email"
      />
    </Grid>
  );
};

export default UserNamesFilter;
