import { useState } from "react";
import { CiCircleRemove } from "react-icons/ci";

import Grid from "@Components/Grid";
import Button from "@Components/Button";
import Select from "@Components/Select";
import useGetRoles from "@Hooks/useRoles";
import IconButton from "@Components/IconButton";
import TextField from "@Components/Inputs/TextField";
import { CreateTeamRequest, Team } from "@Types/teams";

import styles from "./Team.module.scss";

type Props<T extends Team | CreateTeamRequest> = {
  id: string;
  type: "update" | "create";
  initialState: T;
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    user: T | undefined
  ) => void;
};
export function TeamForm<T extends Team | CreateTeamRequest>({
  id,
  type,
  initialState,
  handleSubmit,
}: Props<T>) {
  const [state, setState] = useState(initialState);
  const { data: roles } = useGetRoles();

  return (
    <div className={styles.formContainer}>
      <form data-testid={id} id={id} onSubmit={(e) => handleSubmit(e, state)}>
        <Grid container xs={12} spacing={2}>
          <Grid xs={12}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Name
            </div>
          </Grid>
          <Grid xs={12}>
            <div
              style={{
                fontSize: "20px",
              }}
            >
              This name should be clear, so it allows perfect understanding of
              what the role enables users to do.
            </div>
          </Grid>

          <Grid xs={4}>
            <TextField
              onChange={(e) =>
                setState((prev) => ({
                  ...(prev ?? {}),
                  data: {
                    ...(prev?.data ?? {}),
                    info: {
                      ...(prev?.data?.info ?? {}),
                      name: e.target.value,
                    },
                  },
                }))
              }
              id="Name"
              placeholder="Name"
              value={state?.data.info.name ?? ""}
              name="Name"
              type="text"
              required
            />
          </Grid>

          <Grid
            container
            xs={12}
            style={{ marginBottom: "20px", paddingLeft: "10px" }}
          >
            <Grid xs={12}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "22px",
                }}
              >
                Roles
              </div>
            </Grid>
            <Grid xs={12}>
              <div
                style={{
                  fontSize: "20px",
                }}
              >
                This permissions will be applied to each teammate with in this
                team. Multiple roles can be selected.
              </div>
            </Grid>
            <Grid xs={4}>
              <Select
                label="Select roles"
                id="roles"
                name="Select Roles"
                value={undefined}
                onChange={(e) => {
                  const role = JSON.parse(e.target.value);
                  if (state.data.roles?.find((r) => r._id === role._id)) return;
                  setState((prev) => ({
                    ...prev,
                    data: {
                      ...prev?.data,
                      roles: [...(state.data.roles ?? []), role],
                    },
                  }));
                }}
                options={roles?.map((role) => ({
                  value: JSON.stringify(role),
                  label: role.data.info.name,
                }))}
              />
            </Grid>
            <Grid xs={8}>
              {(state?.data?.roles ?? []).map((value, index) => (
                <IconButton
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    setState((prev) => ({
                      ...prev,
                      data: {
                        ...prev?.data,
                        roles: state?.data?.roles?.filter(
                          (role) => role._id !== value._id
                        ),
                      },
                    }));
                  }}
                  dataTestId={`r-remove-button-${index}`}
                  style={{
                    fontSize: "22px",
                    border: "1px solid #6b97f6",
                    margin: "0px 5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    {value.data.info.name}
                    <CiCircleRemove size={22} color="red" />
                  </div>
                </IconButton>
              ))}
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Button
              style={{ width: "30%" }}
              form={id}
              id={`${type}-user-button`}
              variant="gradient"
              type="submit"
            >
              {type === "create" ? "Create Team" : "Save Changes"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
