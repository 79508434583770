import React from "react";
import { toast } from "react-toastify";

import { ZipCodeLookupResult, lookupZip } from "@Http/zip_code_lookup";

import { ApplicantInfo } from "@Types/applicants";
import {
  capitalizeFirstLetterLowerCaseOthers,
  getStateByPrefix,
} from "@Utils/functions";

export const useZipcodeLookup = (
  setState: React.Dispatch<React.SetStateAction<ApplicantInfo>>,
  setCities: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const lookup = (zip: string, type: "current" | "previous") => {
    lookupZip(zip)
      .then((zipcodeResult) => {
        setState((prevState) => ({
          ...prevState,
          [`${type}City`]:
            capitalizeFirstLetterLowerCaseOthers(
              zipcodeResult?.usps?.CityStateLookupResponse?.ZipCode?.City
            ) ?? null,
          [`${type}County`]:
            zipcodeResult?.google?.[0]?.county?.long_name?.replace(
              " County",
              ""
            ) ??
            zipcodeResult?.local?.county?.replace(" County", "") ??
            null,
          [`${type}State`]:
            getStateByPrefix(
              zipcodeResult?.usps?.CityStateLookupResponse?.ZipCode?.State ?? ""
            ) ?? null,
        }));
        setCities(resultToCities(zipcodeResult));
        toast.success("Successfully looked up zip code.", {
          position: "top-center",
        });
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message?.toString() || err?.toString(),
          {
            position: "top-center",
          }
        );
      });
  };

  return {
    lookupZipcode: lookup,
  };
};
const resultToCities = (result: ZipCodeLookupResult): string[] => {
  const x = [
    ...(result?.google?.map((x) => x?.city?.long_name) ?? []),
    result?.local?.primary_city,
    result?.usps?.CityStateLookupResponse?.ZipCode?.City,
  ]
    .map((x) => capitalizeFirstLetterLowerCaseOthers(x))
    .filter((x): x is string => typeof x === "string");

  const cities = [...Array.from(new Set(x))];

  return cities;
};
