import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/performance";
import "firebase/compat/storage";
import mixpanel from "mixpanel-browser";

import { User } from "@Types/users";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

export const performance = firebase.performance();
export const storage = firebase.storage();
export const auth = firebase.auth();

export const signOut = (user: User | undefined) => {
  mixpanel.track("Logout", {
    email: auth.currentUser?.email,
    dealershipName: user?.data?.dealership?.data?.info?.name,
  });
  return auth.signOut();
};

export const signIn = (credentials: { email: string; password: string }) => {
  return auth.signInWithEmailAndPassword(
    credentials.email,
    credentials.password
  );
};
export const signInWithCustomToken = async (token: string) => {
  return await auth.signInWithCustomToken(token);
};
