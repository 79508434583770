import {
  createDealershipTicket,
  getDealershipTicket,
  getDealershipTickets,
} from "@Http/dealership_tickets";
import { useMutation, useQuery } from "@tanstack/react-query";
import { HttpQuery } from "@Types/http";
import { MutationError } from "@Types/index";
import {
  CreateDealershipTicketRequest,
  DealershipTicket,
} from "@Types/dealership_tickets";
import { toast } from "react-toastify";

export const useCreateDealershipTicket = ({
  onSuccessCb,
}: {
  onSuccessCb?: () => void;
}) => {
  const mutation = useMutation<
    DealershipTicket,
    MutationError,
    CreateDealershipTicketRequest
  >(createDealershipTicket(), {
    onSuccess: () => {
      toast.success("Successfully submitted a ticket.", {
        position: "top-center",
      });
      onSuccessCb?.();
    },
  });
  return {
    createDealershipTicket: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};
export const useGetDealershipTicket = (id?: string) => {
  const queryKey = [`dealership-ticket-${id}`];

  const {
    data: dealershipTicket,
    isLoading,
    error,
  } = useQuery<DealershipTicket, MutationError, DealershipTicket>({
    queryKey: queryKey,
    queryFn: getDealershipTicket(id as string),
    enabled: !!id,
  });

  return {
    dealershipTicket,
    isLoading,
    error,
  };
};

export const useGetDealershipTickets = (query?: HttpQuery) => {
  const queryKey = ["dealership_tickets", query];

  const { data, isLoading, error } = useQuery<
    DealershipTicket[],
    MutationError,
    DealershipTicket[]
  >({
    queryKey,
    queryFn: getDealershipTickets(query),
  });

  return {
    data,
    isLoading,
    error,
  };
};
