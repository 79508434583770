import styles from "./Stepper.module.scss";

type NegativeIconProps = {
  text: string;
  size?: number;
  color?: string;
  style?: React.CSSProperties;
};

const NegativeIcon = ({
  style,
  color = "#EB8190",
  text,
}: NegativeIconProps) => {
  return (
    <div style={style} className={styles.negativeIcon}>
      <span
        style={{
          fontSize: "12px",
          fontWeight: 600,
          color: color,
          textTransform: "capitalize",
          textAlign: "center",
        }}
      >
        {text}
      </span>
      <svg
        width="41"
        height="40"
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.5859 20C40.5859 25.3043 38.4788 30.3914 34.7281 34.1421C30.9773 37.8929 25.8903 40 20.5859 40C15.2816 40 10.1945 37.8929 6.4438 34.1421C2.69307 30.3914 0.585938 25.3043 0.585938 20C0.585938 14.6957 2.69307 9.60859 6.4438 5.85786C10.1945 2.10714 15.2816 0 20.5859 0C25.8903 0 30.9773 2.10714 34.7281 5.85786C38.4788 9.60859 40.5859 14.6957 40.5859 20ZM13.9709 11.615C13.7362 11.3803 13.4179 11.2484 13.0859 11.2484C12.754 11.2484 12.4357 11.3803 12.2009 11.615C11.9662 11.8497 11.8344 12.1681 11.8344 12.5C11.8344 12.8319 11.9662 13.1503 12.2009 13.385L18.8184 20L12.2009 26.615C12.0847 26.7312 11.9925 26.8692 11.9296 27.021C11.8667 27.1729 11.8344 27.3356 11.8344 27.5C11.8344 27.6644 11.8667 27.8271 11.9296 27.979C11.9925 28.1308 12.0847 28.2688 12.2009 28.385C12.4357 28.6197 12.754 28.7516 13.0859 28.7516C13.2503 28.7516 13.413 28.7192 13.5649 28.6563C13.7167 28.5934 13.8547 28.5012 13.9709 28.385L20.5859 21.7675L27.2009 28.385C27.3172 28.5012 27.4551 28.5934 27.607 28.6563C27.7588 28.7192 27.9216 28.7516 28.0859 28.7516C28.2503 28.7516 28.413 28.7192 28.5649 28.6563C28.7167 28.5934 28.8547 28.5012 28.9709 28.385C29.0872 28.2688 29.1793 28.1308 29.2422 27.979C29.3051 27.8271 29.3375 27.6644 29.3375 27.5C29.3375 27.3356 29.3051 27.1729 29.2422 27.021C29.1793 26.8692 29.0872 26.7312 28.9709 26.615L22.3534 20L28.9709 13.385C29.0872 13.2688 29.1793 13.1308 29.2422 12.979C29.3051 12.8271 29.3375 12.6644 29.3375 12.5C29.3375 12.3356 29.3051 12.1729 29.2422 12.021C29.1793 11.8692 29.0872 11.7312 28.9709 11.615C28.8547 11.4988 28.7167 11.4066 28.5649 11.3437C28.413 11.2808 28.2503 11.2484 28.0859 11.2484C27.9216 11.2484 27.7588 11.2808 27.607 11.3437C27.4551 11.4066 27.3172 11.4988 27.2009 11.615L20.5859 18.2325L13.9709 11.615Z"
          fill="#EB8190"
        />
      </svg>
    </div>
  );
};

export default NegativeIcon;
