import { FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";

import { Role } from "@Types/roles";
import Grid from "@Components/Grid";
import Paper from "@Components/Paper";
import Loading from "@Components/Loading";
import BackButton from "@Components/BackButton";
import IconButton from "@Components/IconButton";
import { useDeleteRole, useGetRole, useUpdateRole } from "@Hooks/useRoles";

import { RoleForm } from "./RoleForm";
import styles from "./Role.module.scss";

const UpdateRole = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) return <>Error</>;

  const { role } = useGetRole(id);
  const { updateRole } = useUpdateRole();
  const { deleteRole } = useDeleteRole();

  const handleSaveChanges = (
    e: React.FormEvent<HTMLFormElement>,
    role: Role | undefined
  ) => {
    e.preventDefault();

    if (role) updateRole(role);
  };

  return role ? (
    <div className={styles.root}>
      <Grid container xs={12}>
        <Grid xs={1.5}>
          <BackButton />
        </Grid>
        <Grid container xs={12}>
          <Paper
            style={{ position: "relative", width: "100%" }}
            title="Edit Role Details"
          >
            <div className={styles.deleteUser}>
              <IconButton
                onClick={() => deleteRole(role)}
                flex
                style={{ fontSize: "20px", flex: 1 }}
              >
                Delete Role
                <FaTrash style={{ marginLeft: 10 }} size={20} color="#eb8190" />
              </IconButton>
            </div>

            <RoleForm<Role>
              id={role._id}
              type="update"
              initialState={role}
              handleSubmit={handleSaveChanges}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  ) : (
    <Loading />
  );
};

export default UpdateRole;
