import { CiCircleRemove } from "react-icons/ci";

import Grid from "@Components/Grid";
import Select from "@Components/Select";
import IconButton from "@Components/IconButton";
import { RegularQuery } from "@Types/http";

import { FilterPreviewProps } from "./types";

import useGetLenders from "@Hooks/useLenders";

const LenderFilter = ({
  filterState,
  setFilterState,
  queryPath,
}: FilterPreviewProps) => {
  const { data: lenders } = useGetLenders();
  const handleAddFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const label = e.target.options[e.target.selectedIndex].text;
    const newIds = [
      ...((filterState?.query as RegularQuery)?.[queryPath.join(".")]?.[
        "$in"
      ]?.map((x) => x?.value) ?? []),
      e.target.value,
    ];

    setFilterState({
      values: [...(filterState?.values ?? []), label],
      query: {
        ...(filterState?.query ?? {}),
        [queryPath.join(".")]: {
          $in: newIds.map((id) => ({
            value: id,
          })),
        },
      } as RegularQuery,
    });
  };
  const handleRemoveFilter = (value: string) => {
    const filteredState = filterState?.values.filter((x) => x !== value) ?? [];

    setFilterState({
      values: filteredState,
      query: {
        ...(filterState?.query ?? {}),
        [queryPath.join(".")]: {
          $in: filteredState.map((value) => ({
            value,
          })),
        },
      } as RegularQuery,
    });
  };
  return (
    <Grid container xs={12}>
      <Grid xs={12}>
        {(filterState?.values ?? []).map((value, index) => (
          <IconButton
            key={index}
            onClick={() => handleRemoveFilter(value)}
            flex
            style={{ fontSize: "16px" }}
          >
            {value}
            <CiCircleRemove size={18} />
          </IconButton>
        ))}
      </Grid>
      <Select
        name="Lender"
        value={undefined}
        onChange={handleAddFilter}
        options={lenders?.map((lender) => ({
          value: lender?._id,
          label: lender?.data?.info?.name,
        }))}
      />
    </Grid>
  );
};

export default LenderFilter;
