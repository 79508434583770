import { ReactNode } from "react";
import styles from "./Dialog.module.scss";

type DialogContentProps = {
  children: ReactNode;
};

export const DialogContent = ({ children }: DialogContentProps) => {
  return (
    <div data-testid="dialog-content" className={styles.dialogContent}>
      {children}
    </div>
  );
};
