import { ReactNode } from "react";
import styles from "./AnchoredDialog.module.scss";

type DialogContentProps = {
  children: ReactNode;
};

export const AnchoredDialogContent = ({ children }: DialogContentProps) => {
  return (
    <div
      data-testid={"anchored-dialog-content"}
      className={styles.dialogContent}
    >
      {children}
    </div>
  );
};
