import {
  IoIosArrowDropupCircle,
  IoIosArrowDropdownCircle,
} from "react-icons/io";
import React, { ReactNode, useState } from "react";

import IconButton from "@Components/IconButton";

type Props = {
  title: string;
  children: ReactNode;
};

const ExpandableComponent = ({ title, children }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div
        className="expandable-header"
        onClick={toggleExpand}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        <h2>{title}</h2>
        <IconButton>
          {isExpanded ? (
            <IoIosArrowDropupCircle
              size={30}
              onClick={toggleExpand}
              color="#6b97f6"
            />
          ) : (
            <IoIosArrowDropdownCircle
              size={30}
              onClick={toggleExpand}
              color="#6b97f6"
            />
          )}
        </IconButton>
      </div>
      {isExpanded && <div className="expandable-content">{children}</div>}
    </div>
  );
};

export default ExpandableComponent;
