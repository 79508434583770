import { useState } from "react";
import { FiInfo } from "react-icons/fi";
import { IoWarning } from "react-icons/io5";
import { FaQuestionCircle } from "react-icons/fa";

import {
  Role,
  PermissionNode,
  PermissionType,
  CreateRoleRequest,
  PermissionOperation,
  PermissionEntities,
  PermissionSubEntities,
} from "@Types/roles";
import Grid from "@Components/Grid";
import Button from "@Components/Button";
import Tooltip from "@Components/Tooltip";
import { capitalize } from "@Utils/functions";
import Checkbox from "@Components/Inputs/Checkbox";
import TextField from "@Components/Inputs/TextField";

import styles from "./Role.module.scss";
import ImageTooltip from "@Components/ImageTooltip";

type Props<T extends Role | CreateRoleRequest> = {
  id: string;
  type: "update" | "create";
  initialState: T;
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    user: T | undefined
  ) => void;
};
export function RoleForm<T extends Role | CreateRoleRequest>({
  id,
  type,
  initialState,
  handleSubmit,
}: Props<T>) {
  const [state, setState] = useState(initialState);
  const [selected, setSelected] = useState<PermissionEntities>("deal");
  const handlePermissionChange = (
    path: PermissionEntities,
    operation: PermissionOperation,
    value: boolean,
    type: PermissionType
  ) => {
    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        info: {
          ...prev.data.info,
          permissions: {
            ...prev.data.info?.permissions,
            [path]: updatePermission(
              prev.data.info?.permissions?.[path],
              path,
              operation,
              value,
              type
            ),
          },
        },
      },
    }));
  };

  const updatePermission = (
    node: PermissionNode | undefined,
    path: string,
    operation: PermissionOperation,
    value: boolean,
    type: PermissionType
  ): PermissionNode | null => {
    if (!node) return null;

    // Find the node with the given path
    if (node.page === path) {
      // Update the permission for the given operation
      return {
        ...node,
        permissions: {
          ...node.permissions,
          [type]: {
            ...node.permissions?.[type],
            [operation]: {
              ...node.permissions?.[type]?.[operation],
              hasPermissions: value,
            },
          },
        },
      };
    }

    return node;
  };

  const handleSubPermissionChange = (
    mainPage: PermissionEntities,
    subPage: PermissionSubEntities,
    checked: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        info: {
          ...prev.data.info,
          permissions: {
            ...prev.data.info.permissions,
            [mainPage]: {
              ...(prev?.data?.info?.permissions?.[mainPage] ?? {}),
              subPages:
                prev?.data?.info?.permissions?.[mainPage]?.subPages?.map(
                  (page) =>
                    page.page === subPage
                      ? { ...page, hasPermissions: checked }
                      : page
                ) ?? [],
            },
          },
        },
      },
    }));
  };

  const tooltipStyle = {
    background: "#fff",
    color: "#000",
    padding: "10px",
    border: "1px solid #8080804a",
    fontWeight: "bold",
  };
  const renderPermissionNodes = (node: PermissionNode | undefined) => {
    if (!node) return null;

    return (
      <Grid container spacing={2} className={styles.border}>
        <Grid xs={12}>
          <div className={styles.heading}>{capitalize(node.label)}</div>
        </Grid>
        <Grid>
          <div className={styles.description}>
            {capitalize(node.description)}
          </div>
        </Grid>
        <Grid>
          <ImageTooltip imageName={node.page}>
            <FaQuestionCircle color="#6b97f6" size={22} />
          </ImageTooltip>
        </Grid>
        <Grid xs={12}>
          <hr />
        </Grid>
        <Grid xs={12}>
          <Grid container xs={12} className={styles.permissionsContainer}>
            <Grid xs={12}>
              <Grid container xs={2} spacing={2}>
                <Grid>
                  <div className={styles.smallHeading}>Owner</div>
                </Grid>
                <Grid>
                  <Tooltip
                    content="Owner permissions grant the ability to manage one's own items, including reading, updating, creating, and deleting them."
                    tooltipStyle={tooltipStyle}
                  >
                    <FiInfo color="#6b97f6" size={20} />
                  </Tooltip>
                </Grid>
              </Grid>

              <div className={styles.checkboxContainer}>
                {Object.entries(node?.permissions?.owner).map(
                  ([operation, permission]) => (
                    <Checkbox
                      key={`owner-${node.page}-${operation}`}
                      placeholder={`${capitalize(operation)} own items`}
                      checked={permission?.hasPermissions ?? false}
                      onChange={(e) =>
                        handlePermissionChange(
                          node.page,
                          operation as PermissionOperation,
                          e.target.checked,
                          "owner"
                        )
                      }
                    />
                  )
                )}
              </div>
            </Grid>

            <Grid xs={12}>
              <Grid container xs={2} spacing={2}>
                <Grid>
                  <div className={styles.smallHeading}>Team</div>
                </Grid>
                <Grid>
                  <Tooltip
                    content="Team permissions provide access to manage team resources, encompassing actions such as reading, updating, creating, and deleting team-related items."
                    tooltipStyle={tooltipStyle}
                  >
                    <FiInfo color="#6b97f6" size={20} />
                  </Tooltip>
                </Grid>
              </Grid>
              <div className={styles.checkboxContainer}>
                {Object.entries(node.permissions.group).map(
                  ([operation, permission]) => (
                    <Checkbox
                      key={`group-${node.page}-${operation}`}
                      placeholder={`${capitalize(operation)} team resources`}
                      checked={permission?.hasPermissions ?? false}
                      onChange={(e) =>
                        handlePermissionChange(
                          node.page,
                          operation as PermissionOperation,
                          e.target.checked,
                          "group"
                        )
                      }
                    />
                  )
                )}
              </div>
            </Grid>

            <Grid xs={12}>
              <Grid container xs={4} spacing={2}>
                <Grid>
                  <div className={styles.smallHeading}>
                    Other(Non-owner/team)
                  </div>
                </Grid>
                <Grid>
                  <Tooltip
                    content="Permissions for non-owner/team members cover all actions related to accessing, updating, creating, and deleting resources outside of one's own ownership or team affiliation."
                    tooltipStyle={tooltipStyle}
                  >
                    <FiInfo color="#6b97f6" size={20} />
                  </Tooltip>
                </Grid>
              </Grid>
              <div className={styles.checkboxContainer}>
                {Object.entries(node.permissions.other).map(
                  ([operation, permission]) => (
                    <Checkbox
                      key={`other-${node.page}-${operation}`}
                      placeholder={`${capitalize(operation)} resources`}
                      checked={permission?.hasPermissions ?? false}
                      onChange={(e) =>
                        handlePermissionChange(
                          node.page,
                          operation as PermissionOperation,
                          e.target.checked,
                          "other"
                        )
                      }
                    />
                  )
                )}
              </div>
            </Grid>

            {node.subPages && (
              <Grid xs={12}>
                <hr />

                <Grid xs={12} container spacing={2}>
                  <Grid>
                    <div className={styles.smallHeading}>Sections </div>
                  </Grid>
                  <Grid>
                    <Tooltip
                      content="Section permissions control access to individual sections on the page, allowing users to either have access to the section or not."
                      tooltipStyle={tooltipStyle}
                    >
                      <FiInfo color="#6b97f6" size={20} />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid xs={12} container spacing={2}>
                  {node.subPages.map((subPage) => (
                    <Grid xs={3} key={subPage.page} container>
                      <Grid>
                        <Checkbox
                          key={`${subPage.page}`}
                          placeholder={subPage.page}
                          checked={subPage.hasPermissions ?? false}
                          onChange={(e) =>
                            handleSubPermissionChange(
                              node.page,
                              subPage.page,
                              e.target.checked
                            )
                          }
                        />
                      </Grid>
                      <Grid>
                        <ImageTooltip imageName={subPage.page}>
                          <FaQuestionCircle color="#6b97f6" size={20} />
                        </ImageTooltip>
                      </Grid>
                      {subPage.isSensitive && (
                        <Grid>
                          <Tooltip
                            content="Warning: This section may contain sensitive financial or other confidential information."
                            tooltipStyle={tooltipStyle}
                          >
                            <IoWarning color="#ffbb6c" size={22} />
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={styles.formContainer}>
      <form data-testid={id} id={id} onSubmit={(e) => handleSubmit(e, state)}>
        <Grid container xs={12} spacing={2}>
          <Grid xs={12}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Name
            </div>
          </Grid>

          <Grid xs={12}>
            <div
              style={{
                fontSize: "20px",
              }}
            >
              This name should be clear, so it allows perfect understanding of
              what the enables teammates users to do.
            </div>
          </Grid>

          <Grid xs={6}>
            <TextField
              onChange={(e) =>
                setState((prev) => ({
                  ...(prev ?? {}),
                  data: {
                    ...(prev?.data ?? {}),
                    info: {
                      ...(prev?.data?.info ?? {}),
                      name: e.target.value,
                    },
                  },
                }))
              }
              id="Name"
              placeholder="Name"
              value={state?.data.info.name ?? ""}
              name="Name"
              type="text"
              required
            />
          </Grid>
          <Grid xs={12}>
            <div
              style={{
                fontSize: "22px",
                fontWeight: 900,
              }}
            >
              Roles
            </div>
          </Grid>
          <Grid xs={12}>
            <div
              style={{
                fontSize: "20px",
              }}
            >
              These permissions will be applied to each user with this role.
            </div>
          </Grid>
          <Grid xs={2}>
            {Object.values(state?.data?.info.permissions).map((x) => (
              <div
                onClick={() => setSelected(x.page)}
                className={
                  selected === x.page
                    ? styles.permissionSelected
                    : styles.permission
                }
                key={x.page}
              >
                {capitalize(x.label)}
              </div>
            ))}
          </Grid>
          {selected ? (
            <Grid xs={10}>
              {renderPermissionNodes(state?.data.info?.permissions?.[selected])}
            </Grid>
          ) : null}

          <Grid xs={12}>
            <Button
              style={{ width: "30%" }}
              form={id}
              id={`${type}-user-button`}
              variant="gradient"
              type="submit"
            >
              {type === "create" ? "Create Team" : "Save Changes"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
