import axios from "axios";
import { auth } from "@Firebase/firebase";

export const sendNotification = (): ((dealId: string) => Promise<string>) => {
  return async (id) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/send_push_notification/${id}`,
      undefined,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.result;
  };
};
