import { BsTelephone } from "react-icons/bs";

import { formatPhoneNumber } from "@Utils/functions";

import styles from "../TextField/input.module.scss";
interface PhoneFieldProps {
  id?: string;
  placeholder?: string;
  name?: string;
  required?: boolean;
  hasIcon?: boolean;
  value: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  autofilled?: boolean;
}

const PhoneField = ({
  id,
  placeholder,
  onKeyUp,
  onChange,
  name,
  value,
  hasIcon = false,
  required = false,
  autofilled = false,
}: PhoneFieldProps) => {
  return (
    <div
      className={`${styles.inputContainer} ${autofilled && styles.autofilled}`}
    >
      {hasIcon && (
        <div className={styles.icon}>
          <BsTelephone />
        </div>
      )}
      {value && (
        <label className={styles.label} htmlFor={name}>
          {`${placeholder}${required ? " *" : ""}`}
          {autofilled ? (
            <span className={styles.autofilledLabel}> (Autofilled)</span>
          ) : (
            ""
          )}
        </label>
      )}
      <input
        id={id}
        onKeyUp={onKeyUp}
        value={formatPhoneNumber(value?.toString()) ?? ""}
        maxLength={14}
        onChange={onChange}
        name={name}
        required={required}
        type="tel"
        className={styles.input}
        placeholder={
          !value ? `${placeholder}${required ? " *" : ""}` : undefined
        }
      />
    </div>
  );
};
export default PhoneField;
