import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import EmailVerified from "@Components/EmailVerified";
import Layout from "@Components/Layout";
import LoginLayout from "@Components/LoginLayout";
import RedirectOnLogin from "@Components/RedirectOnLogin";
import RequireAuth from "@Components/RequireAuth";
import RequireDealershipOwner from "@Components/RequireAuth/RequireDealershipOwner";
import RequirePermissions from "@Components/RequirePermissions";
import Applicants from "@Pages/Applicants";
import Applicant from "@Pages/Applicants/Applicant";
import Applications from "@Pages/Applications";
import Application from "@Pages/Applications/Application";
import CoApplicantToDeal from "@Pages/Applications/Application/Applicants/AddCoApplicant";
import CreateApplication from "@Pages/Applications/CreateApplication";
import ForgotPassword from "@Pages/Auth/ForgotPassword";
import SignIn, { MfaState } from "@Pages/Auth/SignIn/SignIn";
import SignInMfa from "@Pages/Auth/SignInMfa";
import SignUp from "@Pages/Auth/SignUp";
import VerifyEmail from "@Pages/Auth/VerifyEmail";
import Chargebacks from "@Pages/Chargebacks";
import Chargeback from "@Pages/Chargebacks/Chargeback";
import ChatPage from "@Pages/Chat";
import Dashboard from "@Pages/Dashboards";
import CustomerDashboard from "@Pages/Dashboards/CustomerDashboard";
import ProfitDashboard from "@Pages/Dashboards/ProfitDashboard";
import NotFound from "@Pages/NotFound";
import ProfitReport from "@Pages/Reports/ProfitReport";
import Roles from "@Pages/Roles";
import CreateRole from "@Pages/Roles/Role/CreateRole";
import UpdateRole from "@Pages/Roles/Role/UpdateRole";
import ScanDriverLicense from "@Pages/ScanDriverLicense";
import Settings from "@Pages/Settings";
import Teams from "@Pages/Teams";
import CreateTeam from "@Pages/Teams/Team/CreateTeam";
import UpdateTeam from "@Pages/Teams/Team/UpdateTeam";
import TermsAndConditions from "@Pages/TermsAndConditions";
import TitleIssues from "@Pages/TitleIssues";
import TitleIssue from "@Pages/TitleIssues/TitleIssue";
import Users from "@Pages/Users";
import CreateUser from "@Pages/Users/User/CreateUser";
import UpdateUser from "@Pages/Users/User/UpdateUser";
import { CurrentUser } from "./App";
import SocketConnection from "./socket/SocketConnection";

const Router = ({ currentUser }: { currentUser: CurrentUser }) => {
  const [mfaState, setMfaState] = useState<MfaState>(undefined);
  return (
    <Routes>
      <Route element={<RedirectOnLogin currentUser={currentUser} />}>
        <Route element={<LoginLayout />}>
          <Route path="/sign-up" element={<SignUp />} />
          <Route
            path="/sign-in"
            element={<SignIn setMfaState={setMfaState} />}
          />

          <Route
            path="/sign-in/mfa"
            element={<SignInMfa mfaState={mfaState} />}
          />
          <Route path="/mfa-enroll" element={<>MFA</>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>
      </Route>
      <Route element={<EmailVerified currentUser={currentUser} />}>
        <Route element={<LoginLayout />}>
          <Route
            path="/sign-in/verify-email"
            element={<VerifyEmail currentUser={currentUser} />}
          />
        </Route>
      </Route>
      <Route element={<RequireAuth currentUser={currentUser} />}>
        <Route element={<TermsAndConditions />}>
          <Route element={<SocketConnection currentUser={currentUser} />}>
            <Route element={<RequirePermissions />}>
              <Route element={<Layout />}>
                <Route element={<Navigate to="/dashboard" />} path="/" />
                <Route element={<Dashboard />} path="/dashboard" />
                {/*  Applicants */}
                <Route element={<Applicants />} path="/applicants" />
                <Route
                  element={<CoApplicantToDeal />}
                  path="/add-co-applicant"
                />
                <Route element={<Applicant />} path="/applicants/:id" />
                {/* Deals */}
                <Route
                  element={<CreateApplication />}
                  path="/applications/new"
                />
                <Route element={<Applications />} path="/applications" />
                <Route element={<Application />} path="/applications/:id" />
                {/* Incomplete applications */}
                <Route
                  element={<CreateApplication />}
                  path="/incomplete_applications/:id"
                />
                {/*  Chargebacks */}
                <Route element={<Chargebacks />} path="/chargebacks" />
                <Route element={<Chargeback />} path="/chargebacks/:id" />
                {/*  Users */}
                <Route element={<Users />} path="/members" />
                <Route element={<CreateUser />} path="/members/new" />
                <Route element={<UpdateUser />} path="/members/:id" />
                {/*  Title issues */}
                <Route element={<TitleIssues />} path="/title_info" />
                <Route element={<TitleIssue />} path="/title_info/:id" />
                {/* Chat */}
                <Route element={<ChatPage />} path="/chat" />
                {/* Reports */}
                <Route element={<ProfitReport />} path="/profit_report" />
                <Route
                  element={<CustomerDashboard />}
                  path="/customer_dashboard"
                />
                <Route element={<ProfitDashboard />} path="/profit_dashboard" />
                <Route element={<RequireDealershipOwner />}>
                  {/* Settings */}
                  <Route element={<Settings />} path="/settings" />
                  {/* Permissions */}
                  <Route element={<Roles />} path="/roles" />
                  <Route element={<UpdateRole />} path="/roles/:id" />
                  <Route element={<CreateRole />} path="/roles/new" />
                  <Route element={<Teams />} path="/teams" />
                  <Route element={<UpdateTeam />} path="/teams/:id" />
                  <Route element={<CreateTeam />} path="/teams/new" />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="/scan-driver-license/:id" element={<ScanDriverLicense />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
