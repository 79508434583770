import { toast } from "react-toastify";
import { FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";

import {
  useGetMember,
  useDeleteMember,
  useUpdateMember,
  useDisableMember,
} from "@Hooks/useMembers";
import Grid from "@Components/Grid";
import Paper from "@Components/Paper";
import Button from "@Components/Button";
import Loading from "@Components/Loading";
import BackButton from "@Components/BackButton";
import IconButton from "@Components/IconButton";
import { User as UserType } from "@Types/users";
import useCurrentUser from "@Hooks/useCurrentUser";

import { UserForm } from "./UserForm";
import styles from "./User.module.scss";

const canUpdateOwner = (userToUpdate: UserType, currentUser?: UserType) => {
  const isUpdatingOwner = userToUpdate?.data?.info?.userType === "owner";
  const isCurrentUserOwner = currentUser?.data?.info?.userType === "owner";

  if (isUpdatingOwner && !isCurrentUserOwner) {
    toast.error("Unable to update owner.", {
      position: "top-center",
    });
    return false;
  }

  return true;
};

const User = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) return <>Error</>;
  const { user } = useGetMember(id);
  const currentUser = useCurrentUser();
  const { updateUser } = useUpdateMember();
  const { deleteUser } = useDeleteMember();
  const { disableUser } = useDisableMember();

  const handleSaveChanges = (
    e: React.FormEvent<HTMLFormElement>,
    user: UserType | undefined
  ) => {
    e.preventDefault();

    if (user && canUpdateOwner(user, currentUser)) updateUser(user);
  };

  const handleDisableUser = () => {
    if (user && canUpdateOwner(user, currentUser)) disableUser(user);
  };

  const handleDeleteUser = () => {
    if (user && canUpdateOwner(user, currentUser)) deleteUser(user);
  };

  return user ? (
    <div className={styles.root}>
      <Grid container xs={12}>
        <Grid xs={1.5}>
          <BackButton />
        </Grid>
        <Grid container xs={12}>
          <Paper style={{ position: "relative" }} title="Edit User Details">
            <div className={styles.deleteUser}>
              <Button
                className={styles.disableUser}
                onClick={handleDisableUser}
              >{`${
                user.data.firebaseDisabled ? "Enable" : "Disable"
              } user`}</Button>
              <IconButton
                onClick={handleDeleteUser}
                flex
                style={{ fontSize: "20px", flex: 1 }}
              >
                Delete user
                <FaTrash style={{ marginLeft: 10 }} size={20} color="#eb8190" />
              </IconButton>
            </div>

            <UserForm<UserType>
              id={user._id}
              type="update"
              emailDisabled={true}
              initialState={user}
              handleSubmit={handleSaveChanges}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  ) : (
    <Loading />
  );
};

export default User;
