import Grid from "@Components/Grid";
import CurrencyField from "@Components/Inputs/CurrencyField";
import DateField from "@Components/Inputs/DateField";
import PhoneField from "@Components/Inputs/PhoneField";
import SSNField from "@Components/Inputs/SSNField";
import TextField from "@Components/Inputs/TextField";
import Radio from "@Components/Radio";
import Select from "@Components/Select";

import { ApplicantInfo } from "@Types/applicants";

import { capitalize } from "@Utils/functions";
import States from "@Utils/states";

import Button from "@Components/Button";
import IconButton from "@Components/IconButton";
import Checkbox from "@Components/Inputs/Checkbox";
import { useZipcodeLookup } from "@Hooks/useZipcodeLookup";
import React, { useState } from "react";
import { GrLocationPin } from "react-icons/gr";
import { TbSearch } from "react-icons/tb";
import styles from "./ApplicantInformation.module.scss";
const timeInMonths = (years: number | null, months: number | null) => {
  return (years || 0) * 12 + (months || 0);
};
const handleFillWithOtherPersonCurrentAddressInfo = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  applicantInfo: ApplicantInfo,
  setFormState: React.Dispatch<React.SetStateAction<ApplicantInfo>>
) => {
  e.preventDefault();
  setFormState((formState) => ({
    ...formState,
    currentAddressNumber: applicantInfo.currentAddressNumber,
    currentAddress: applicantInfo.currentAddress,
    currentZipCode: applicantInfo.currentZipCode,
    currentState: applicantInfo.currentState,
    currentCity: applicantInfo.currentCity,
    currentCounty: applicantInfo.currentCounty,
    monthsAtCurrentAddress: applicantInfo.monthsAtCurrentAddress,
    yearsAtCurrentAddress: applicantInfo.yearsAtCurrentAddress,
  }));
};
const handleFillWithOtherPersonPreviousAddressInfo = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  applicantInfo: ApplicantInfo,
  setFormState: React.Dispatch<React.SetStateAction<ApplicantInfo>>
) => {
  e.preventDefault();

  setFormState((formState) => ({
    ...formState,
    previousAddressNumber: applicantInfo.previousAddressNumber,
    previousAddress: applicantInfo.previousAddress,
    previousZipCode: applicantInfo.previousZipCode,
    previousState: applicantInfo.previousState,
    previousCity: applicantInfo.previousCity,
    previousCounty: applicantInfo.previousCounty,
    monthsAtPreviousAddress: applicantInfo.monthsAtPreviousAddress,
    yearsAtPreviousAddress: applicantInfo.yearsAtPreviousAddress,
  }));
};
const ApplicantInformation = ({
  type,
  state,
  mainApplicantState,
  setState,
  title,
  parsedApplicant,
}: {
  type: "applicant" | "coApplicant";
  title: string;
  state: ApplicantInfo;
  mainApplicantState: ApplicantInfo;
  parsedApplicant?: ApplicantInfo;
  setState: (v: React.SetStateAction<ApplicantInfo>) => void;
}) => {
  const [middleNameRequired, setMiddleNameRequired] = useState(true);
  return (
    <Grid container xs={12} className={styles.container} spacing={2}>
      <Grid xs={12}>
        <p>{title}</p>
      </Grid>
      <Grid xs={3.5}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({ ...prev, firstName: e.target.value }))
          }
          placeholder="First Name"
          value={state.firstName ?? ""}
          name="firstName"
          type="text"
          required
          autofilled={
            parsedApplicant?.firstName &&
            parsedApplicant?.firstName === state.firstName
              ? true
              : false
          }
          id={`${type}-firstName`}
        />
      </Grid>
      <Grid xs={3}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({ ...prev, middleName: e.target.value }))
          }
          placeholder="Middle Name"
          value={state.middleName ?? ""}
          name="middleName"
          required={middleNameRequired}
          disabled={!middleNameRequired}
          type="text"
          autofilled={
            parsedApplicant?.middleName &&
            parsedApplicant?.middleName === state.middleName
              ? true
              : false
          }
          id={`${type}-middleName`}
        />
      </Grid>
      <Grid xs={2}>
        <Checkbox
          onChange={() => {
            if (middleNameRequired) {
              setState((prev) => ({ ...prev, middleName: null }));
            }
            setMiddleNameRequired((prev) => !prev);
          }}
          placeholder="I don't have a middle name:"
          checked={!middleNameRequired}
          name="middleNameRequired"
          id={`${type}-middleNameRequired`}
        />
      </Grid>
      <Grid xs={3.5}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({ ...prev, lastName: e.target.value }))
          }
          placeholder="Last Name"
          autofilled={
            parsedApplicant?.lastName &&
            parsedApplicant?.lastName === state.lastName
              ? true
              : false
          }
          value={state.lastName ?? ""}
          name="lastName"
          type="text"
          required
          id={`${type}-lastName`}
        />
      </Grid>
      <Grid xs={6}>
        <TextField
          disabled
          placeholder="Driver's License"
          value={"Driver's License"}
          name="driversLicense"
          type="text"
          id={`${type}-driversLicense`}
        />
      </Grid>
      <Grid xs={6}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              driverLicenseNumber: e.target.value,
            }))
          }
          autofilled={
            parsedApplicant?.driverLicenseNumber &&
            parsedApplicant?.driverLicenseNumber === state.driverLicenseNumber
              ? true
              : false
          }
          placeholder="ID No."
          value={state.driverLicenseNumber ?? ""}
          name="driverLicenseNumber"
          type="text"
          id={`${type}-driverLicenseNumber`}
        />
      </Grid>
      <Grid xs={4}>
        <Select
          id={`${type}-issuedBy`}
          placeholder="Issued by"
          name="Issued by"
          value={state.drivingLicenseState ?? ""}
          autofilled={
            parsedApplicant?.drivingLicenseState &&
            parsedApplicant?.drivingLicenseState === state.drivingLicenseState
              ? true
              : false
          }
          onChange={(e) => {
            setState((prev) => ({
              ...prev,
              drivingLicenseState: e.target.value,
            }));
          }}
          options={Object.entries(States).map(([state, { prefix }]) => ({
            value: state,
            label: `${prefix} - ${state}`,
          }))}
        />
      </Grid>
      <Grid xs={4}>
        <DateField
          id={`${type}-issuedAt`}
          placeholder="mm/dd/yyyy"
          title="ID issued at"
          autofilled={
            parsedApplicant?.driverLicenseIssued &&
            parsedApplicant?.driverLicenseIssued === state.driverLicenseIssued
              ? true
              : false
          }
          value={state.driverLicenseIssued ?? ""}
          onChange={(e) => {
            setState((prev) => ({
              ...prev,
              driverLicenseIssued: e.target.value,
            }));
          }}
        />
      </Grid>
      <Grid xs={4}>
        <DateField
          id={`${type}-expireAt`}
          placeholder="mm/dd/yyyy"
          title="ID expire date"
          autofilled={
            parsedApplicant?.driverLicenseExpires &&
            parsedApplicant?.driverLicenseExpires === state.driverLicenseExpires
              ? true
              : false
          }
          value={state.driverLicenseExpires ?? ""}
          onChange={(e) => {
            setState((prev) => ({
              ...prev,
              driverLicenseExpires: e.target.value,
            }));
          }}
        />
      </Grid>
      <Grid xs={6}>
        <DateField
          id={`${type}-dateOfBirth`}
          placeholder="mm/dd/yyyy"
          title="Date of birth"
          value={state.birthDate ?? ""}
          autofilled={
            parsedApplicant?.birthDate &&
            parsedApplicant?.birthDate === state.birthDate
              ? true
              : false
          }
          onChange={(e) => {
            setState((prev) => ({
              ...prev,
              birthDate: e.target.value,
            }));
          }}
          required
        />
      </Grid>
      <Grid xs={6}>
        <SSNField
          id={`${type}-ssn`}
          value={state.socialSecurityNumber}
          autofilled={
            parsedApplicant?.socialSecurityNumber &&
            parsedApplicant?.socialSecurityNumber === state.socialSecurityNumber
              ? true
              : false
          }
          onChange={(e) => {
            if (e.target.value.length <= 11) {
              setState((prev) => ({
                ...prev,
                socialSecurityNumber: e.target.value,
              }));
            }
          }}
          required
        />
      </Grid>
      <Grid xs={6}>
        <PhoneField
          id={`${type}-mobilePhone`}
          value={state.mobilePhone}
          placeholder="Primary phone"
          autofilled={
            parsedApplicant?.mobilePhone &&
            parsedApplicant?.mobilePhone === state.mobilePhone
              ? true
              : false
          }
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              mobilePhone:
                e.target.value !== ""
                  ? e.target.value?.replace(/\s|\+1|\(|\)|-/g, "")
                  : "",
            }))
          }
          required
        />
      </Grid>
      <Grid xs={6}>
        <PhoneField
          id={`${type}-homePhone`}
          value={state?.homePhone ?? ""}
          placeholder="Home phone"
          autofilled={
            parsedApplicant?.homePhone &&
            parsedApplicant?.homePhone === state.homePhone
              ? true
              : false
          }
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              homePhone:
                e.target.value !== ""
                  ? e.target.value?.replace(/\s|\+1|\(|\)|-/g, "")
                  : "",
            }))
          }
        />
      </Grid>
      <Grid xs={6}>
        <TextField
          id={`${type}-email`}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              email: e.target.value,
            }))
          }
          autofilled={
            parsedApplicant?.email && parsedApplicant?.email === state.email
              ? true
              : false
          }
          placeholder="Email address"
          value={state.email ?? ""}
          name="Email"
          required
          type="email"
        />
      </Grid>{" "}
      <Grid xs={6}>
        <Grid />
      </Grid>
      <Grid xs={6}>
        <Radio
          id={`${type}-usCitizen`}
          group="usCitizen"
          label="Is US citizen?"
          possibleValues={[
            {
              value: "Yes",
              label: "Yes",
            },
            {
              value: "No",
              label: "No",
            },
          ]}
          required
          autofilled={
            parsedApplicant?.usCitizen &&
            parsedApplicant?.usCitizen === state.usCitizen
              ? true
              : false
          }
          onChange={(e) => {
            switch (e.target.value) {
              case "Yes":
                setState((prev) => ({
                  ...prev,
                  usCitizen: "Yes",
                }));
                break;
              default:
                setState((prev) => ({
                  ...prev,
                  usCitizen: "No",
                }));
                break;
            }
          }}
          selectedValue={state.usCitizen}
        />
      </Grid>
      <Grid xs={6}>
        <Radio
          id={`${type}-maritalStatus`}
          group="maritalStatus"
          required
          label="Marital status"
          autofilled={
            parsedApplicant?.maritalStatus &&
            parsedApplicant?.maritalStatus === state.maritalStatus
              ? true
              : false
          }
          possibleValues={[
            {
              value: "Married",
              label: "Married",
            },
            {
              value: "Not married",
              label: "Not Married",
            },
          ]}
          onChange={(e) => {
            switch (e.target.value) {
              case "Married":
                setState((prev) => ({
                  ...prev,
                  maritalStatus: "Married",
                }));
                break;
              default:
                setState((prev) => ({
                  ...prev,
                  maritalStatus: "Not married",
                }));
                break;
            }
          }}
          selectedValue={state.maritalStatus}
        />
      </Grid>
      <Grid xs={12}>
        <AddressStructure
          applicantType={type}
          mainApplicantState={mainApplicantState}
          type="current"
          state={state}
          setState={setState}
          parsedApplicant={parsedApplicant}
        />
      </Grid>
      <Grid xs={12}>
        <p>Residential status</p>
      </Grid>
      <Grid xs={12}>
        <Radio
          id={`${type}-residentialStatus`}
          group="residentialStatus"
          required
          possibleValues={[
            {
              value: "Owner",
              label: "Owner",
            },
            {
              value: "Rent",
              label: "Rent",
            },
            {
              value: "Family",
              label: "Family",
            },
            {
              value: "Other",
              label: "Other",
            },
          ]}
          onChange={(e) => {
            switch (e.target.value) {
              case "Owner":
                setState((prev) => ({
                  ...prev,
                  propertyOwnership: "Owner",
                }));
                break;
              case "Rent":
                setState((prev) => ({
                  ...prev,
                  propertyOwnership: "Rent",
                }));
                break;
              case "Family":
                setState((prev) => ({
                  ...prev,
                  propertyOwnership: "Family",
                }));
                break;
              default:
                setState((prev) => ({
                  ...prev,
                  propertyOwnership: "Other",
                }));
                break;
            }
          }}
          selectedValue={state.propertyOwnership}
          autofilled={
            parsedApplicant?.propertyOwnership &&
            parsedApplicant?.propertyOwnership === state.propertyOwnership
              ? true
              : false
          }
        />
      </Grid>
      {state.propertyOwnership === "Owner" ? (
        <Grid xs={4}>
          <CurrencyField
            id={`${type}-` + "mortgagePerMonth"}
            name="mortgagePerMonth"
            placeholder="Mortgage Per Month"
            value={state.mortgagePerMonth}
            onValueChange={(values) => {
              setState((prev) => ({
                ...prev,
                mortgagePerMonth: values.floatValue || null,
              }));
            }}
            autofilled={
              parsedApplicant?.mortgagePerMonth &&
              parsedApplicant?.mortgagePerMonth === state.mortgagePerMonth
                ? true
                : false
            }
          />
        </Grid>
      ) : (
        state.propertyOwnership === "Rent" && (
          <Grid xs={4}>
            <CurrencyField
              id={`${type}-` + "rentPerMonth"}
              name="rentPerMonth"
              placeholder="Rent Per Month"
              value={state.rentPerMonth}
              autofilled={
                parsedApplicant?.rentPerMonth &&
                parsedApplicant?.rentPerMonth === state.rentPerMonth
                  ? true
                  : false
              }
              onValueChange={(values) => {
                setState((prev) => ({
                  ...prev,
                  rentPerMonth: values.floatValue || null,
                }));
              }}
            />
          </Grid>
        )
      )}
      {timeInMonths(state.yearsAtCurrentAddress, state.monthsAtCurrentAddress) <
        24 && (
        <Grid xs={12}>
          <AddressStructure
            applicantType={type}
            type="previous"
            state={state}
            mainApplicantState={mainApplicantState}
            parsedApplicant={parsedApplicant}
            setState={setState}
          />
        </Grid>
      )}
      <Grid xs={12}>
        <p>Employment status</p>
      </Grid>
      <Grid xs={12}>
        <Radio
          id={`${type}-employmentStatus`}
          group="employmentStatus"
          required
          possibleValues={[
            {
              value: "Employee",
              label: "Employee",
            },
            {
              value: "Business owner",
              label: "Business owner",
            },
            {
              value: "Retired",
              label: "Retired",
            },
          ]}
          onChange={(e) => {
            switch (e.target.value) {
              case "Business owner":
                setState((prev) => ({
                  ...prev,
                  employmentStatus: "Business owner",
                }));
                break;
              case "Employee":
                setState((prev) => ({
                  ...prev,
                  employmentStatus: "Employee",
                }));
                break;
              case "Retired":
                setState((prev) => ({
                  ...prev,
                  employmentStatus: "Retired",
                }));
                break;
            }
          }}
          selectedValue={state.employmentStatus}
          autofilled={
            parsedApplicant?.employmentStatus &&
            parsedApplicant?.employmentStatus === state.employmentStatus
              ? true
              : false
          }
        />
      </Grid>
      {state.employmentStatus !== "Retired" && (
        <Grid xs={12}>
          <EmploymentStructure
            applicantType={type}
            type="current"
            state={state}
            setState={setState}
            parsedApplicant={parsedApplicant}
          />
        </Grid>
      )}
      {state.employmentStatus === "Retired" ? (
        <>
          <Grid xs={6}>
            <CurrencyField
              required
              id={`${type}-` + "retirementIncome"}
              name="retirementIncome"
              placeholder="Retirement Income"
              value={state.retirementIncome}
              onValueChange={(values) => {
                setState((prev) => ({
                  ...prev,
                  retirementIncome: values.floatValue || null,
                }));
              }}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              required
              id={`${type}-sourceOfRetirementIncome`}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  sourceOfRetirementIncome: e.target.value,
                }))
              }
              placeholder="Source of additional income"
              value={state.sourceOfRetirementIncome}
              name={"sourceOfRetirementIncome"}
              type="text"
            />
          </Grid>
        </>
      ) : null}
      {timeInMonths(state.yearsAtCurrentJob, state.monthsAtCurrentJob) < 24 ||
      state.employmentStatus === "Retired" ? (
        <Grid xs={12}>
          <EmploymentStructure
            applicantType={type}
            type="previous"
            state={state}
            setState={setState}
            parsedApplicant={parsedApplicant}
          />
        </Grid>
      ) : null}
      <Grid xs={12}>
        <p>Additional Monthly Income</p>
      </Grid>
      <Grid xs={6}>
        <CurrencyField
          id={`${type}-` + "additionalIncome"}
          name="additionalIncome"
          placeholder="Additional Monthly Income"
          value={state.additionalIncome}
          onValueChange={(values) => {
            setState((prev) => ({
              ...prev,
              additionalIncome: values.floatValue || null,
            }));
          }}
          autofilled={
            parsedApplicant?.additionalIncome &&
            parsedApplicant?.additionalIncome === state.additionalIncome
              ? true
              : false
          }
        />
      </Grid>
      <Grid xs={6}>
        <TextField
          id={`${type}-sourceOfAdditionalIncome`}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              sourceOfAdditionalIncome: e.target.value,
            }))
          }
          placeholder="Source of additional income"
          value={state.sourceOfAdditionalIncome}
          name={"sourceOfAdditionalIncome"}
          type="text"
          autofilled={
            parsedApplicant?.sourceOfAdditionalIncome &&
            parsedApplicant?.sourceOfAdditionalIncome ===
              state.sourceOfAdditionalIncome
              ? true
              : false
          }
        />
      </Grid>
    </Grid>
  );
};

type AddressStructureProps = {
  type: "current" | "previous";
  applicantType: "applicant" | "coApplicant";
  mainApplicantState: ApplicantInfo;
  state: ApplicantInfo;
  setState: React.Dispatch<React.SetStateAction<ApplicantInfo>>;
  parsedApplicant?: ApplicantInfo;
};
type EmploymentStructureProps = {
  type: "current" | "previous";
  applicantType: "applicant" | "coApplicant";
  state: ApplicantInfo;
  setState: React.Dispatch<React.SetStateAction<ApplicantInfo>>;
  parsedApplicant?: ApplicantInfo;
};
const EmploymentStructure = ({
  applicantType,
  type,
  state,
  setState,
  parsedApplicant,
}: EmploymentStructureProps) => {
  return (
    <Grid container xs={12}>
      <Grid xs={12}>
        <p>{capitalize(type)} employer</p>
      </Grid>
      <Grid xs={6}>
        <TextField
          required
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              [type === "current" ? "employer" : "previousEmployer"]:
                e.target.value,
            }))
          }
          placeholder="Name"
          value={
            state[type === "current" ? "employer" : "previousEmployer"] ?? ""
          }
          autofilled={
            parsedApplicant?.[
              type === "current" ? "employer" : "previousEmployer"
            ] &&
            parsedApplicant?.[
              type === "current" ? "employer" : "previousEmployer"
            ] === state[type === "current" ? "employer" : "previousEmployer"]
              ? true
              : false
          }
          name={type === "current" ? "employer" : "previousEmployer"}
          type="text"
          id={`${applicantType}-${
            type === "current" ? "employer" : "previousEmployer"
          }`}
        />
      </Grid>
      {type === "current" && (
        <Grid xs={6}>
          <CurrencyField
            id={`${applicantType}-` + "monthlyIncome"}
            name="monthlyIncome"
            placeholder="Monthly Income"
            value={state.monthlyIncome}
            autofilled={
              parsedApplicant?.monthlyIncome &&
              parsedApplicant?.monthlyIncome === state.monthlyIncome
                ? true
                : false
            }
            onValueChange={(values) => {
              setState((prev) => ({
                ...prev,
                monthlyIncome: values.floatValue || null,
              }));
            }}
          />
        </Grid>
      )}
      {type === "current" && (
        <Grid xs={6}>
          <PhoneField
            id={`${applicantType}-businessPhone`}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                businessPhone:
                  e.target.value !== ""
                    ? e.target.value?.replace(/\s|\+1|\(|\)|-/g, "")
                    : "",
              }))
            }
            autofilled={
              parsedApplicant?.businessPhone &&
              parsedApplicant?.businessPhone === state.businessPhone
                ? true
                : false
            }
            required
            placeholder="Work phone"
            value={state?.businessPhone ?? ""}
          />
        </Grid>
      )}
      <Grid xs={6}>
        <TextField
          id={`${applicantType}-${
            type === "current" ? "jobOccupation" : "previousOccupation"
          }`}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              [type === "current" ? "jobOccupation" : "previousOccupation"]:
                e.target.value,
            }))
          }
          placeholder="Occupation/Job title"
          required
          autofilled={
            parsedApplicant?.[
              type === "current" ? "jobOccupation" : "previousOccupation"
            ] &&
            parsedApplicant?.[
              type === "current" ? "jobOccupation" : "previousOccupation"
            ] ===
              state[type === "current" ? "jobOccupation" : "previousOccupation"]
              ? true
              : false
          }
          value={
            state[
              type === "current" ? "jobOccupation" : "previousOccupation"
            ] ?? ""
          }
          name={type === "current" ? "jobOccupation" : "previousOccupation"}
          type="text"
        />
      </Grid>
      <Grid xs={12}>
        <p>Time at {capitalize(type)} employer</p>
      </Grid>
      <Grid xs={6}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              [`yearsAt${capitalize(type) as "Current" | "Previous"}Job`]:
                Math.abs(Number(e.target.value)) || 0,
            }))
          }
          placeholder="Years"
          required
          value={
            state[`yearsAt${capitalize(type) as "Current" | "Previous"}Job`] ||
            undefined
          }
          autofilled={
            parsedApplicant?.[
              `yearsAt${capitalize(type) as "Current" | "Previous"}Job`
            ] &&
            parsedApplicant?.[
              `yearsAt${capitalize(type) as "Current" | "Previous"}Job`
            ] ===
              state[`yearsAt${capitalize(type) as "Current" | "Previous"}Job`]
              ? true
              : false
          }
          name={`yearsAt${capitalize(type)}Job`}
          id={`${applicantType}-yearsAt${capitalize(type)}Job`}
          type="number"
        />
      </Grid>
      <Grid xs={6}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              [`monthsAt${capitalize(type) as "Current" | "Previous"}Job`]:
                Math.abs(Number(e.target.value)) || 0,
            }))
          }
          placeholder="Months"
          value={
            state[`monthsAt${capitalize(type) as "Current" | "Previous"}Job`] ||
            undefined
          }
          autofilled={
            parsedApplicant?.[
              `monthsAt${capitalize(type) as "Current" | "Previous"}Job`
            ] &&
            parsedApplicant?.[
              `monthsAt${capitalize(type) as "Current" | "Previous"}Job`
            ] ===
              state[`yearsAt${capitalize(type) as "Current" | "Previous"}Job`]
              ? true
              : false
          }
          name={`monthsAt${capitalize(type) as "Current" | "Previous"}Job`}
          id={`${applicantType}-monthsAt${capitalize(type)}Job`}
          type="number"
        />
      </Grid>
    </Grid>
  );
};

const AddressStructure = ({
  applicantType,
  mainApplicantState,
  type,
  state,
  setState,
  parsedApplicant,
}: AddressStructureProps) => {
  const [cities, setCities] = useState<string[]>([]);
  const { lookupZipcode } = useZipcodeLookup(setState, setCities);
  const handleLookupZipcode = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (type === "current" && state.currentZipCode) {
      lookupZipcode(state.currentZipCode, type);
    } else if (type === "previous" && state.previousZipCode) {
      lookupZipcode(state.previousZipCode, type);
    }
  };
  return (
    <Grid container xs={12} style={{ padding: 0 }}>
      <Grid xs={12}></Grid>
      <Grid xs={applicantType === "coApplicant" ? 2 : 12}>
        <p>{capitalize(type)} address</p>
      </Grid>
      {applicantType === "coApplicant" && (
        <Grid xs={10}>
          <Button
            onClick={(e) =>
              type === "current"
                ? handleFillWithOtherPersonCurrentAddressInfo(
                    e,
                    mainApplicantState,
                    setState
                  )
                : handleFillWithOtherPersonPreviousAddressInfo(
                    e,
                    mainApplicantState,
                    setState
                  )
            }
            variant="blue"
            style={{ whiteSpace: "nowrap", width: "25%" }}
          >
            Same as applicant
          </Button>
        </Grid>
      )}
      <Grid xs={4}>
        <TextField
          id={`${applicantType}-${type}AddressNumber`}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              [`${type}AddressNumber`]: e.target.value,
            }))
          }
          required
          placeholder="Street Num."
          autofilled={
            parsedApplicant?.[`${type}AddressNumber`] &&
            parsedApplicant?.[`${type}AddressNumber`] ===
              state[`${type}AddressNumber`]
              ? true
              : false
          }
          value={state[`${type}AddressNumber`] ?? ""}
          name={`${type}AddressNumber`}
          type="text"
        />
      </Grid>
      <Grid xs={8}>
        <TextField
          id={`${applicantType}-${type}Address`}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              [`${type}Address`]: e.target.value,
            }))
          }
          required
          placeholder="Street name"
          value={state[`${type}Address`] ?? ""}
          autofilled={
            parsedApplicant?.[`${type}Address`] &&
            parsedApplicant?.[`${type}Address`] === state[`${type}Address`]
              ? true
              : false
          }
          name={`${type}Address`}
          type="text"
        />
      </Grid>
      <Grid xs={4}>
        <div className={styles.zipCodeContainer}>
          <TextField
            required
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                [`${type}ZipCode`]: e.target.value,
              }))
            }
            placeholder="Zip"
            value={state[`${type}ZipCode`] ?? ""}
            name={`${type}ZipCode`}
            autofilled={
              parsedApplicant?.[`${type}ZipCode`] &&
              parsedApplicant?.[`${type}ZipCode`] === state[`${type}ZipCode`]
                ? true
                : false
            }
            type="text"
            id={`${applicantType}-${type}ZipCode`}
          />
          <IconButton
            onClick={(e) => handleLookupZipcode(e)}
            disabled={
              typeof state[`${type}ZipCode`] !== "string" &&
              (state?.[`${type}ZipCode`]?.length || 0) > 0
            }
          >
            <TbSearch size={20} />
          </IconButton>
        </div>
      </Grid>
      <Grid xs={4}>
        <TextField
          required
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              [`${type}County`]: e.target.value,
            }))
          }
          placeholder="County"
          value={state[`${type}County`] ?? ""}
          name={`${type}County`}
          autofilled={
            parsedApplicant?.[`${type}County`] &&
            parsedApplicant?.[`${type}County`] === state[`${type}County`]
              ? true
              : false
          }
          type="text"
          id={`${applicantType}-${type}County`}
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          required
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              [`${type}City`]: e.target.value,
            }))
          }
          placeholder="City"
          value={state[`${type}City`] ?? ""}
          name={`${type}City`}
          autofilled={
            parsedApplicant?.[`${type}City`] &&
            parsedApplicant?.[`${type}City`] === state[`${type}City`]
              ? true
              : false
          }
          type="text"
          id={`${applicantType}-${type}City`}
        />
      </Grid>
      <Grid xs={4}>
        <Select
          required={true}
          placeholder="State"
          name="State"
          value={state[`${type}State`] ?? ""}
          autofilled={
            parsedApplicant?.[`${type}State`] &&
            parsedApplicant?.[`${type}State`] === state[`${type}State`]
              ? true
              : false
          }
          onChange={(e) => {
            setState((prev) => ({
              ...prev,
              [`${type}State`]: e.target.value,
            }));
          }}
          options={Object.entries(States).map(([state, { prefix }]) => ({
            value: state,
            label: `${prefix} - ${state}`,
          }))}
          id={`${applicantType}-${type}State`}
        />
      </Grid>
      <Grid xs={12}>
        <ul className={styles.list}>
          {cities.length > 1 &&
            cities.map((city, index) => (
              <li
                className={styles.listItem}
                key={index}
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    [`${type}City`]: city,
                  }));
                }}
              >
                <div>
                  <GrLocationPin size={18} />
                </div>
                {city}
              </li>
            ))}
        </ul>
      </Grid>
      <Grid xs={12}>
        <p>Time at {capitalize(type)} Address</p>
      </Grid>
      <Grid xs={6}>
        <TextField
          required
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              [`yearsAt${capitalize(type) as "Current" | "Previous"}Address`]:
                Math.abs(Number(e.target.value)) || 0,
            }))
          }
          placeholder="Years"
          value={
            state[
              `yearsAt${capitalize(type) as "Current" | "Previous"}Address`
            ] || undefined
          }
          autofilled={
            parsedApplicant?.[
              `yearsAt${capitalize(type) as "Current" | "Previous"}Address`
            ] &&
            parsedApplicant?.[
              `yearsAt${capitalize(type) as "Current" | "Previous"}Address`
            ] ===
              state[
                `yearsAt${capitalize(type) as "Current" | "Previous"}Address`
              ]
              ? true
              : false
          }
          name={`yearsAt${capitalize(type)}Address`}
          type="number"
          id={`${applicantType}-yearsAt${capitalize(type)}Address`}
        />
      </Grid>
      <Grid xs={6}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              [`monthsAt${capitalize(type) as "Current" | "Previous"}Address`]:
                Math.abs(Number(e.target.value)) || 0,
            }))
          }
          placeholder="Months"
          value={
            state[
              `monthsAt${capitalize(type) as "Current" | "Previous"}Address`
            ] || undefined
          }
          autofilled={
            parsedApplicant?.[
              `monthsAt${capitalize(type) as "Current" | "Previous"}Address`
            ] &&
            parsedApplicant?.[
              `monthsAt${capitalize(type) as "Current" | "Previous"}Address`
            ] ===
              state[
                `monthsAt${capitalize(type) as "Current" | "Previous"}Address`
              ]
              ? true
              : false
          }
          name={`monthsAt${capitalize(type) as "Current" | "Previous"}Address`}
          type="number"
          id={`${applicantType}-monthsAt${capitalize(type)}Address`}
        />
      </Grid>
    </Grid>
  );
};

export default ApplicantInformation;
