import Button from "@Components/Button";
import BrokenCar from "./BrokenCar";
import { useNavigate } from "react-router-dom";

import styles from "./NotFound.module.scss";

type Props = {
  title?: string;
};

const NotFound = ({
  title = "We can’t find the page that you are looking for.",
}: Props) => {
  const navigate = useNavigate();
  return (
    <div id="not-found" className={styles.container}>
      <div style={{ flex: 1, paddingTop: 120 }} className={styles.item}>
        <div style={{ flex: 1 }}>
          <h1 style={{ margin: 0 }}>Oops!</h1>
        </div>
        <div>
          <p>{title}</p>
        </div>

        <div style={{ width: "100%" }}>
          <Button
            style={{ width: "80%", height: 64 }}
            onClick={() => navigate("/dashboard")}
          >
            Back To Home
          </Button>
        </div>
      </div>

      <div style={{ flex: 3 }} className={styles.item}>
        <BrokenCar />
      </div>
    </div>
  );
};
export default NotFound;
