import axios from "axios";

import {
  TitleIssue,
  TitleDocumentInfo,
  AddTitleIssueDocumentRequest,
} from "@Types/title_issues";

import { HttpQuery } from "@Types/http";
import { auth } from "@Firebase/firebase";
import { uploadFile } from "./documents";

export const getTitleIssues = (
  query?: HttpQuery
): (() => Promise<TitleIssue[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/title_issue/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return response.data.result?.docs ?? [];
  };
};

export const getTitleIssue = (id: string): (() => Promise<TitleIssue>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/title_issue/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.result;
  };
};

export const updateTitleIssue = (): (({
  file,
  titleIssue,
}: AddTitleIssueDocumentRequest) => Promise<TitleIssue>) => {
  return async ({
    titleIssue,
    file,
    uploadedTitleIssueDocumentTypes,
    WFDDocumentUsed,
  }) => {
    const token = await auth.currentUser?.getIdToken();

    const uploadedFileResult = await uploadFile(file, token);

    const updateDocumentInfo = (
      info: TitleDocumentInfo,
      uploadedTitleIssueDocumentType: boolean,
      uploadedFileUrl: string
    ): TitleDocumentInfo | undefined => {
      if (uploadedTitleIssueDocumentType) {
        return {
          ...info,
          WFDDocumentUsed,
          uploadedDate: new Date().toISOString(),
          status: "pending",
          documents: [...(info.documents ?? []), uploadedFileUrl],
        };
      }
    };

    const updatedTitleIssue: TitleIssue = {
      ...titleIssue,
      data: {
        ...titleIssue.data,
        info: {
          ...titleIssue.data.info,
          titleRegistrationInfo:
            updateDocumentInfo(
              titleIssue.data.info.titleRegistrationInfo,
              uploadedTitleIssueDocumentTypes.newTitle,
              uploadedFileResult.result.fileUrl
            ) ?? titleIssue.data.info.titleRegistrationInfo,
          oldTitleRegistrationInfo:
            updateDocumentInfo(
              titleIssue.data.info.oldTitleRegistrationInfo,
              uploadedTitleIssueDocumentTypes.oldTitle,
              uploadedFileResult.result.fileUrl
            ) ?? titleIssue.data.info.oldTitleRegistrationInfo,
          receiptInfo:
            updateDocumentInfo(
              titleIssue.data.info.receiptInfo,
              uploadedTitleIssueDocumentTypes.receipt,
              uploadedFileResult.result.fileUrl
            ) ?? titleIssue.data.info.receiptInfo,
        },
      },
    };

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/title_issue/${titleIssue._id}`,
      updatedTitleIssue,
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.result;
  };
};
