import { AiOutlinePlusCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import styles from "./AddButton.module.scss";

type AddButtonProps = {
  entity: string;
  id?: string;
};
const AddButton = ({ entity, id }: AddButtonProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.root}
      id={id}
      data-testid={id}
      onClick={() => {
        navigate("new");
      }}
    >
      <AiOutlinePlusCircle
        size={20}
        style={{ paddingRight: "10px", color: "#6b97f6" }}
      />
      <p>New {entity}</p>
    </div>
  );
};

export default AddButton;
