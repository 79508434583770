import States from "./states";
import { Buffer } from "buffer";

export function capitalize(s: string | undefined): string | undefined {
  return typeof s === "string"
    ? s.charAt(0).toUpperCase() + s.slice(1)
    : undefined;
}

export const isValidDate = (date: unknown) =>
  !isNaN(new Date(date as number | string).getTime());

export function calculateAge(birthDate: Date) {
  if (birthDate === null || birthDate === undefined) return;
  const currentYear = new Date().getUTCFullYear();

  const age = new Date(birthDate);
  return currentYear - age?.getUTCFullYear();
}

export function formatPhoneNumber(value: string | null) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;
  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number

  // we need to return the value with no formatting if its less than four digits
  // this is to avoid weird behavior that occurs if you  format the area code too early
  if (phoneNumber?.length < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumber?.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}

export const formatSSN = (ssn: string) =>
  ssn?.length === 9
    ? `${ssn.substr(0, 3)}-${ssn.substr(3, 2)}-${ssn.substr(5, 4)}`
    : ssn;

export const getBase64 = (file: File | Blob) => {
  return new Promise((resolve) => {
    let baseURL: string | ArrayBuffer | null = "";
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const convertFileToBase64 = (file: File) => {
  return getBase64(file).then((result) => ({
    name: file.name,
    lastModified: file.lastModified,
    lastModifiedDate: new Date(file.lastModified),
    size: file.size,
    type: file.type,
    webkitRelativePath: file.webkitRelativePath,
    binary: result,
  }));
};

export const convertBlobToBase64 = (blob: Blob) => {
  return getBase64(blob).then((result) => result);
};
export const formatDate = (
  date: string | undefined | Date,
  dateStyle: "full" | "long" | "medium" | "short" = "medium",
  withTime = false
) => {
  if (date === undefined || date === null) {
    return "";
  } else {
    const parsedDate = new Date(date);

    return !isNaN(parsedDate.getTime())
      ? new Intl.DateTimeFormat("en-US", {
          dateStyle,
          timeStyle: withTime ? "short" : undefined,
        }).format(parsedDate)
      : "";
  }
};
export const getMonth = (date: string | undefined | Date) => {
  if (date === undefined || date === null) {
    return "";
  } else {
    const parsedDate = new Date(date);
    return !isNaN(parsedDate.getTime())
      ? new Intl.DateTimeFormat("en-US", { month: "long" }).format(parsedDate)
      : "";
  }
};

export const formatPrice = (n: number | null | undefined) => {
  if (typeof n === "number") {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(n);
  }
  return n;
};

export const formatFirstAndLastNames = (
  fName: string | null | undefined,
  lName: string | null | undefined,
  separator = " ",
  fallback?: string
) => {
  return [fName, lName]?.filter((x) => x)?.join(separator) || fallback || "N/A";
};

export function isSetStateFunction<T>(
  maybeFunc: React.SetStateAction<T>
): maybeFunc is (...args: T[]) => T {
  return typeof maybeFunc === "function";
}

export const getElapsedTime = (date: string | undefined | Date) => {
  if (date === undefined || date === null) {
    return "";
  } else {
    const parsedDate = new Date(date);
    const now = new Date();

    const diff = now.getTime() - parsedDate.getTime();

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return "Just now";
    }
  }
};
export const capitalizeFirstLetterLowerCaseOthers = (s: string | undefined) => {
  if (typeof s !== "string") return;
  return s
    .split(" ")
    .map((el) => el.charAt(0).toUpperCase() + el.slice(1).toLowerCase())
    .join(" ");
};
export const getStateByPrefix = (selectedPrefix: string): string | null => {
  const stateInfo = Object.entries(States).find(
    (state) => state?.[1]?.prefix === selectedPrefix
  );
  return stateInfo ? stateInfo[0] : null;
};
export const formatNumberAsCurrency = (
  number: number | undefined | null,
  sign = ""
): string | undefined => {
  if (typeof number === "number") {
    return (
      sign +
      new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number)
    );
  }
  return;
};

export const encodeBase64 = (string: string) =>
  Buffer.from(string).toString("base64");
export const decodeBase64 = (base64String: string) =>
  Buffer.from(base64String, "base64").toString("utf-8");
