import { useQuery } from "@tanstack/react-query";

import { HttpQuery } from "@Types/http";
import { MutationError } from "@Types/index";
import { Chargeback } from "@Types/chargebacks";
import { getChargebacks, getChargeback } from "@Http/chargebacks";

const useGetChargebacks = (query?: HttpQuery) => {
  const queryKey = ["chargebacks", query ?? []];

  const { data, isLoading, error } = useQuery<
    Chargeback[],
    MutationError,
    Chargeback[]
  >({
    queryKey: queryKey,
    queryFn: getChargebacks(query),
  });

  return {
    data,
    isLoading,
    error,
  };
};
export const useGetChargeback = (id: string) => {
  const queryKey = [`chargeback-${id}`];

  const {
    data: chargeback,
    isLoading,
    error,
  } = useQuery<Chargeback, MutationError, Chargeback>({
    queryKey: queryKey,
    queryFn: getChargeback(id),
  });

  return {
    chargeback,
    isLoading,
    error,
  };
};

export default useGetChargebacks;
