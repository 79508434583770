import { toast } from "react-toastify";
import { useRef, useState } from "react";
import { AxiosProgressEvent } from "axios";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { IoCloudUpload, IoReloadCircleOutline } from "react-icons/io5";

import {
  ApplicationParserType,
  ApplicationParserTypes,
} from "@Types/dealerships";
import Button from "@Components/Button";
import Dialog from "@Components/Dialog";
import Select from "@Components/Select";
import Dropzone from "@Components/Dropzone";
import { ApplicantInfo } from "@Types/applicants";
import useCurrentUser from "@Hooks/useCurrentUser";
import { parseApplicationFile } from "@Http/file_applications";
import { DialogContent } from "@Components/Dialog/DialogContent";

import styles from "./ApplicationDropzone.module.scss";

interface DocumentsDropzoneProps {
  uploadCallback: (x: ParsedData) => void;
  applicationId: string;
}

export type ParsedData = {
  applicant: ApplicantInfo & { original: object };
  coApplicant: ApplicantInfo & { original: object };
};
const ApplicationDropzone = ({
  uploadCallback,
  applicationId,
}: DocumentsDropzoneProps) => {
  const user = useCurrentUser();

  const [applicationParser, setApplicationParser] = useState(
    user?.data?.dealership?.data?.info?.applicationParserType
  );

  const inputFile = useRef<HTMLInputElement | null>(null);
  const dropzoneRef = useRef(null);
  const [file, setFile] = useState<File | undefined>(undefined);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e?.target?.files?.length) {
      setFile(e.target.files[0]);
      return handleCreate(e.target.files[0]);
    }
  };
  const [loading, setLoading] = useState<boolean>(false);
  const progressChange = (x: AxiosProgressEvent) => {
    Math.round((x.progress || 0) * 100);
  };

  const handleCreate = async (file: File) => {
    if (file) {
      try {
        setLoading(true);
        const response = await parseApplicationFile(
          file,
          applicationId,
          applicationParser,
          progressChange
        );
        uploadCallback(response);
        setLoading(false);
        setFile(undefined);
        toast.success(
          "Information is prefilled successfully please continue with the form!",
          {
            position: "top-center",
          }
        );
      } catch (error: unknown) {
        setLoading(false);
        setFile(undefined);
        toast.error(
          "The system was not able to find information in this document!",
          {
            position: "top-center",
          }
        );
      }
    }
  };
  const [learnModal, setLearnModal] = useState(false);

  return (
    <Grid className={styles.dropZoneContainer}>
      <Select
        placeholder="Document type"
        name="Software"
        required
        id="softwareType"
        value={applicationParser ?? ""}
        onChange={(e) => {
          setApplicationParser(e.target.value as ApplicationParserTypes);
        }}
        options={[
          {
            value: ApplicationParserTypes.General,
            label: "General",
          },
          {
            value: ApplicationParserTypes.Frazer,
            label: "Frazer",
          },
          {
            value: ApplicationParserTypes.Reynolds,
            label: "Reynolds",
          },
          {
            value: ApplicationParserTypes.Dealertrack,
            label: "Dealertrack",
          },
          {
            value: ApplicationParserTypes.Carforsale,
            label: "Carforsale",
          },
          {
            value: ApplicationParserTypes.Dealercarsearch,
            label: "Dealercarsearch",
          },
        ]}
      />
      <Grid ref={dropzoneRef} className={`${styles.dropZone} ${styles.dashed}`}>
        {loading ? (
          <IoReloadCircleOutline
            className={"spin"}
            fontSize={80}
            style={{ color: "#6b97f6" }}
          />
        ) : (
          <IoCloudUpload fontSize={80} style={{ color: "#6b97f6" }} />
        )}

        <div
          className={styles.dropZoneContainer}
          id={"application-dropzone-container"}
        >
          <Dropzone createDocument={handleCreate} containerRef={dropzoneRef} />

          {file ? (
            <p className={styles.info}>Processing: {file.name ?? ""}</p>
          ) : (
            <p className={styles.info}>Drag file to upload or click</p>
          )}

          <button
            className={styles.learnMoreButton}
            onClick={() => {
              setLearnModal(true);
            }}
          >
            To learn how to generate application in your DMS click here!
          </button>

          <Button
            onClick={() => inputFile?.current?.click()}
            className={styles.button}
            variant="blue"
          >
            Upload
            <input
              ref={inputFile}
              type="file"
              id={"application-regular-upload-document-input"}
              hidden
              onClick={(e) => {
                (e.target as EventTarget & { value: null }).value = null;
              }}
              onChange={(e) => {
                handleFileChange(e);
              }}
            />
          </Button>
        </div>
        <Dialog
          id={"learnDialog"}
          open={learnModal}
          title={"Supported document types"}
          closeIcon
          titleColor="blue"
          size="lg"
          closeFn={() => {
            setLearnModal(false);
          }}
        >
          <DialogContent>
            <Grid container className={styles.supportedDocContainer}>
              {getDescriptionByType(applicationParser)}
              <Grid xs={6}>
                <h3>How to generate the document</h3>
                <div className={styles.howToStep}>1.Open lead</div>
                <div className={styles.howToStep}>2.Click print button</div>
                <div className={styles.howToStep}>3.Download the document</div>
                <div className={styles.howToStep}>4.Upload the document</div>
                <div className={styles.howToStep}>
                  5.Upload the document to the WFD dealer portal
                </div>
                <div className={styles.importantHowToStep}>
                  6. Please review the parsed data to ensure its accuracy, as
                  issues may occasionally arise.
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
};

const getDescriptionByType = (type: ApplicationParserType) => {
  switch (type) {
    case ApplicationParserTypes.Frazer:
      return (
        <Grid xs={6}>
          <h3>Frazer Credit Application</h3>
          <img src="/example_1.png" alt="" className={styles.documentPreview} />
        </Grid>
      );
    case ApplicationParserTypes.Reynolds:
      return (
        <Grid xs={6}>
          <h3>Reynolds& Reynolds Credit Application</h3>
          <img src="/example_2.png" alt="" className={styles.documentPreview} />
        </Grid>
      );
    case ApplicationParserTypes.Dealertrack:
      return (
        <Grid xs={6}>
          <h3>DealerTrack Credit Application</h3>
          <img
            src="/dealertrack_example.png"
            className={styles.documentPreview}
          />
        </Grid>
      );
    case ApplicationParserTypes.Dealercarsearch:
      return (
        <Grid xs={6}>
          <h3>Dealercarsearch Credit Application</h3>
          <img
            src="/dealercarserach_example.png"
            className={styles.documentPreview}
          />
        </Grid>
      );
    case ApplicationParserTypes.Carforsale:
      return (
        <Grid xs={6}>
          <h3>Carforsale Credit Application</h3>
          <img
            src="/carforsale_example.png"
            className={styles.documentPreview}
          />
        </Grid>
      );
    case ApplicationParserTypes.Automatic:
    case ApplicationParserTypes.General:
      return (
        <Grid xs={6}>
          <h3>General Credit Application</h3>
        </Grid>
      );
    default:
      return (
        <Grid xs={6}>
          <h3>Missing information</h3>
        </Grid>
      );
  }
};
export default ApplicationDropzone;
