export default ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21" cy="21" r="21" fill="currentColor" />
    <path
      d="M19.1992 20.0024H20.5908C21.2093 20.0024 21.722 19.8722 22.1289 19.6118C22.5358 19.3433 22.8369 18.9811 23.0322 18.5254C23.2357 18.0615 23.3374 17.5407 23.3374 16.9629C23.3374 16.2793 23.2479 15.7056 23.0688 15.2417C22.8898 14.7778 22.6213 14.4279 22.2632 14.1919C21.9132 13.9559 21.4697 13.8379 20.9326 13.8379C20.4281 13.8379 19.9886 13.964 19.6143 14.2163C19.2399 14.4604 18.951 14.8104 18.7476 15.2661C18.5441 15.7218 18.4424 16.259 18.4424 16.8774H16.2939C16.2939 15.9741 16.4811 15.1522 16.8555 14.4116C17.238 13.6711 17.7751 13.0811 18.4668 12.6416C19.1667 12.2021 19.9886 11.9824 20.9326 11.9824C21.8359 11.9824 22.6294 12.1737 23.313 12.5562C23.9966 12.9305 24.5296 13.492 24.9121 14.2407C25.3027 14.9813 25.498 15.9049 25.498 17.0117C25.498 17.4593 25.4126 17.9395 25.2417 18.4521C25.0789 18.9567 24.8145 19.4287 24.4482 19.8682C24.0902 20.3076 23.6182 20.6698 23.0322 20.9546C22.4544 21.2313 21.7464 21.3696 20.9082 21.3696H19.1992V20.0024ZM19.1992 21.8579V20.5029H20.9082C21.901 20.5029 22.7108 20.6372 23.3374 20.9058C23.964 21.1743 24.4482 21.5324 24.79 21.98C25.1318 22.4276 25.3678 22.9199 25.498 23.457C25.6364 23.986 25.7056 24.515 25.7056 25.0439C25.7056 25.874 25.5835 26.6105 25.3394 27.2534C25.1034 27.8963 24.7656 28.4416 24.3262 28.8892C23.8949 29.3368 23.3903 29.6745 22.8125 29.9023C22.2347 30.1302 21.604 30.2441 20.9204 30.2441C20.2531 30.2441 19.6346 30.1343 19.0649 29.9146C18.4953 29.6948 17.9948 29.3774 17.5635 28.9624C17.1322 28.5392 16.7944 28.0225 16.5503 27.4121C16.3143 26.7936 16.1963 26.0897 16.1963 25.3003H18.3569C18.3569 25.9188 18.4587 26.46 18.6621 26.9238C18.8656 27.3877 19.1585 27.7498 19.541 28.0103C19.9235 28.2625 20.3833 28.3887 20.9204 28.3887C21.4575 28.3887 21.9214 28.2707 22.312 28.0347C22.7026 27.7905 23.0037 27.4243 23.2153 26.936C23.4351 26.4478 23.5449 25.8333 23.5449 25.0928C23.5449 24.3522 23.4229 23.7459 23.1787 23.2739C22.9427 22.7938 22.605 22.4398 22.1655 22.2119C21.7261 21.9759 21.2012 21.8579 20.5908 21.8579H19.1992Z"
      fill="white"
    />
  </svg>
);
