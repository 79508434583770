import { useState } from "react";
import { toast } from "react-toastify";

import Grid from "@Components/Grid";
import Button from "@Components/Button";
import { formatDate } from "@Utils/functions";
import useCurrentUser from "@Hooks/useCurrentUser";
import TextArea from "@Components/Inputs/TextArea";
import { useCreateTitleIssueNote } from "@Hooks/useTitleIssueNotes";

import styles from "./TitleIssues.module.scss";
import { TitleIssueNote } from "@Types/title_issue_notes";

export default function TitleIssuesNotes({
  notes,
  titleIssueId,
}: {
  titleIssueId: string;
  notes: TitleIssueNote[];
}) {
  const currentUser = useCurrentUser();

  const [newNote, setNewNote] = useState("");
  const { createTitleIssueNote } = useCreateTitleIssueNote();

  const handleCreateNote = () => {
    if (newNote) {
      createTitleIssueNote({
        data: {
          titleIssueId,
          info: {
            note: newNote,
            creator: "dealer",
            user: {
              firstName: currentUser?.data.info.firstName ?? null,
              lastName: currentUser?.data.info.lastName ?? null,
              email: currentUser?.data.info.email ?? null,
            },
          },
        },
      });
      setNewNote("");
    } else
      toast.warn("Note cannot be empty", {
        position: "top-center",
      });
  };
  return (
    <Grid container xs={12}>
      <Grid container xs={12}>
        <div className={styles.noteContainer}>
          {notes?.map((titleIssueNote) => (
            <div
              className={
                titleIssueNote.data.info.user.email ===
                currentUser?.data.info.email
                  ? styles.myTitleIssueNote
                  : styles.otherTitleIssueNote
              }
              key={titleIssueNote._id}
            >
              {titleIssueNote.data.info.note}
              <div className={styles.noteCreator}>
                {[
                  titleIssueNote.data?.info?.user?.firstName,
                  titleIssueNote?.data?.info?.user?.lastName,
                  formatDate(titleIssueNote?.createdAt, "medium", true),
                ]
                  .filter((x) => x)
                  .join(" ")}
              </div>
            </div>
          ))}
        </div>
      </Grid>

      <Grid xs={8}>
        <TextArea
          onChange={(e) => setNewNote(e.target.value)}
          showPlaceHolderTop={false}
          placeholder="Note"
          value={newNote}
          name="Note"
          required
        />
      </Grid>
      <Grid xs={4}>
        <Button variant="blue" onClick={handleCreateNote}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}
