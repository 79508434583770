import Button from "@Components/Button";
import Dialog from "@Components/Dialog";
import { DialogActions } from "@Components/Dialog/DialogActions";
import { DialogContent } from "@Components/Dialog/DialogContent";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useStickyState } from "@Hooks/useStickyState";
import { useState } from "react";
import { MdOutlineNewReleases } from "react-icons/md";
import styles from "./WhatsNew.module.scss";

const WhatsNew = () => {
  const user = useCurrentUser();
  if (user && user.data.info.userType !== "owner") {
    return <></>;
  }

  const [isSeenRelease, setIsSeenRelease] = useStickyState(
    false,
    // change this name when releasing something new.
    "release-10/08/2024"
  );

  const [show, setShow] = useState(false);
  return (
    <>
      <button
        className={styles.button}
        onClick={() => {
          setShow(!show);
        }}
      >
        <span>
          <MdOutlineNewReleases />
        </span>

        {!isSeenRelease ? (
          <div style={{ paddingLeft: 10 }}>Click here to see whats new </div>
        ) : (
          ""
        )}
      </button>
      {show ? (
        <Dialog
          titleColor="blue"
          containerStyle={{
            top: "30px",
            transform: "unset",
            left: 0,
          }}
          id="create-change-request"
          open={show}
          title="Whats new?"
          closeIcon
          size="lg"
          closeFn={() => {
            setIsSeenRelease(true);
            setShow(false);
          }}
        >
          <DialogContent>
            <div>
              <h2>
                Release Announcement 10/08/2024: SFTP Configuration for External
                System Integration!
              </h2>
              <p>
                We’ve introduced a new option to seamlessly integrate with
                external systems via SFTP (Secure File Transfer Protocol) in
                addition to the previously available ADF email configuration.
                This allows users to configure and manage SFTP connections to
                send and receive data securely and efficiently.
              </p>

              <h3>Key Features:</h3>
              <ul>
                <li>
                  <strong>SFTP Configuration:</strong>
                  <ul>
                    <li>
                      Users can now select SFTP as an option to send files to
                      external systems. This is available alongside the existing
                      option to add ADF emails.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Two Configuration Options Available:</strong>
                  <ul>
                    <li>
                      <strong>ADF Emails: </strong>Continue to add ADF emails
                      for communication with external systems via email.
                    </li>
                    <li>
                      <strong>SFTP: </strong>New option to add SFTP
                      configuration for secure file transfers to external
                      systems.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>User Interface Updates:</strong>
                  <ul>
                    <li>
                      Two separate tables have been introduced, one for{" "}
                      <strong>ADF Emails</strong> and another for{" "}
                      <strong>SFTP Connections</strong>.
                    </li>
                    <li>
                      Each table allows you to easily manage and view the
                      respective configurations.
                    </li>
                  </ul>
                </li>
              </ul>
              <h3>Benefits:</h3>
              <ul>
                <li>
                  <strong>Enhanced Flexibility: </strong>Users can choose the
                  preferred method of integration (ADF emails or SFTP) based on
                  system requirements or security policies.
                </li>
                <li>
                  <strong>Secure File Transfers: </strong>With SFTP, ensure
                  secure, encrypted transfers of data files to external systems,
                  minimizing risks of data interception.
                </li>
                <li>
                  <strong>Streamlined Management: </strong>Separate
                  configuration tables allow users to quickly distinguish and
                  manage both ADF email and SFTP settings.
                </li>
              </ul>
              <h3>How to Use:</h3>
              <ul>
                <li>
                  Navigate to the <strong>Settings</strong> page from the drawer
                  menu on the left.
                </li>
                <li>
                  Select either <strong>ADF Email</strong> or{" "}
                  <strong>SFTP Configuration</strong> from the available
                  options.
                </li>
                <li>
                  For SFTP, enter the necessary connection details such as:
                  <ul>
                    <li>
                      <strong>Host</strong>
                    </li>
                    <li>
                      <strong>Port</strong>
                    </li>
                    <li>
                      <strong>Authentication credentials</strong>
                    </li>
                    <li>
                      <strong>
                        Directory(where data should be sent/received)
                      </strong>
                    </li>
                  </ul>
                </li>
                <li>Save the configuration to apply the changes.</li>
              </ul>

              <p>
                This feature provides a more secure and robust way to manage
                integrations with external systems, offering flexibility between
                email and SFTP methods for data exchange.
              </p>

              <p>
                <strong>Note: </strong>Ensure that all external systems are set
                up to receive data via the selected method (either ADF email or
                SFTP) to ensure seamless integration.
              </p>

              <p>
                <b>
                  Please use persmissions and roles from the drawer menu on the
                  left to enable this feature to other people from your company.
                </b>
              </p>

              <p>Best regards,</p>
              <p>The Web Finance Direct Team</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="blue"
              onClick={() => {
                setShow(false);
                setIsSeenRelease(true);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};
export default WhatsNew;
