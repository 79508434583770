import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

import { Team } from "@Types/teams";
import Table from "@Components/Table";
import useGetTeams from "@Hooks/useTeams";
import AddButton from "@Components/AddButton";
import IconButton from "@Components/IconButton";
import { Column } from "@Components/Table/types";

import styles from "./Teams.module.scss";

const Teams = () => {
  const columns: Column<Team>[] = [
    {
      id: "name",
      label: "Name",
      value: (team) => team.data.info.name,
    },

    {
      id: "actions",
      label: "Actions",
      value: (user) => (
        <IconButton
          id={`open-user-${user?._id}`}
          className={"blueCircle"}
          onClick={() => {
            navigate(`/team/${user._id}`);
          }}
        >
          <AiOutlineArrowRight size={18} />
        </IconButton>
      ),
    },
  ];

  const navigate = useNavigate();

  return (
    <div className={styles.tableContainer}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <h2 className={styles.title}>Теаms</h2>

        <AddButton entity="team" id="create-new-tean" />
      </div>
      <Table<Team>
        columns={columns}
        useGetFunction={useGetTeams}
        entityType="teams"
      />
    </div>
  );
};

export default Teams;
