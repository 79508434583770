import { useQuery } from "@tanstack/react-query";

import { getLenderEstFundedDate, getLenders } from "@Http/lenders";

import { Lender } from "@Types/lenders";
import { MutationError } from "@Types/index";
import { HttpQuery } from "@Types/http";

const useGetLenders = (query?: HttpQuery) => {
  const queryKey = ["lenders", query ?? []];
  const { data, isLoading, error } = useQuery<
    Lender[],
    MutationError,
    Lender[]
  >({
    queryKey: queryKey,
    queryFn: getLenders(query),
  });

  return {
    data,
    isLoading,
    error,
  };
};
export const useGetLenderEstFundedDate = (
  lenderId?: string,
  enabled?: boolean
) => {
  const queryKey = ["lender_est_funded_date", lenderId ?? []];
  const { data, isLoading, error } = useQuery<string, MutationError, string>({
    queryKey: queryKey,
    enabled,
    queryFn: getLenderEstFundedDate(lenderId),
  });

  return {
    data,
    isLoading,
    error,
  };
};
export default useGetLenders;
