import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getApplications,
  createApplication,
  getWfdEstFundedDate,
  getDealsForCustomerDashboard,
} from "@Http/applications";
import { Deal, CreateDealRequest } from "@Types/deal";
import { MutationError } from "@Types/index";
import { HttpQuery } from "@Types/http";
import { useNavigate } from "react-router-dom";

const useGetDeals = (query?: HttpQuery) => {
  const queryKey = ["deals", query];

  const { data, isLoading, error, isFetching } = useQuery<
    Deal[],
    MutationError,
    Deal[]
  >({
    queryKey: queryKey,
    queryFn: getApplications(query),
  });

  return {
    data,
    isFetching,
    isLoading,
    error,
  };
};
export const useGetDealsForCustomerDashboard = (query?: HttpQuery) => {
  const queryKey = ["deals_for_customer_dashboard", query];

  const { data, isLoading, error, isFetching } = useQuery<
    Deal[],
    MutationError,
    Deal[]
  >({
    queryKey: queryKey,
    queryFn: getDealsForCustomerDashboard(query),
  });

  return {
    data,
    isFetching,
    isLoading,
    error,
  };
};
export const useCreateDeal = () => {
  const navigate = useNavigate();
  const mutation = useMutation<Deal, MutationError, CreateDealRequest>(
    createApplication(),
    {
      onSuccess: (data) => {
        toast.success("Successfully created a deal.", {
          position: "top-center",
        });

        navigate(`/applications/${data._id}`, { replace: true });
      },
    }
  );
  return {
    createDeal: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};
export const useGetWfdEstFundedDate = (
  {
    dealId,
    lenderEstFundedDate,
  }: { dealId: string; lenderEstFundedDate?: string },
  enabled: boolean
) => {
  const queryKey = ["wfd_est_funded_date", dealId ?? []];
  const { data, isLoading, error } = useQuery<string, MutationError, string>({
    queryKey: queryKey,
    enabled,
    queryFn: getWfdEstFundedDate(dealId, lenderEstFundedDate),
  });

  return {
    data,
    isLoading,
    error,
  };
};
export default useGetDeals;
