import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@Components/Button";
import { auth, signOut } from "@Firebase/firebase";
import { CurrentUser } from "../../App";

import styles from "./Auth.module.scss";

const VerifyEmail = ({ currentUser }: { currentUser: CurrentUser }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      currentUser?.reload();
      navigate("/dashboard");
    }, 2000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div style={{ maxWidth: "450px" }}>
      <h1>Please verify email to log in!</h1>
      <Button
        className={styles.authButton}
        type="submit"
        onClick={() => {
          auth.currentUser
            ?.sendEmailVerification()
            .then(() => {
              toast.info("Email was sent!", {
                position: "top-center",
              });
            })
            .catch((error) => {
              toast.error(error.message.replace("Firebase: ", ""), {
                position: "top-center",
              });
            });
        }}
        style={{ fontSize: "30px", marginTop: "10px" }}
      >
        Re-send email
      </Button>
      <a href="/" className={styles.signOut} onClick={() => signOut(undefined)}>
        Sign out
      </a>
    </div>
  );
};

export default VerifyEmail;
