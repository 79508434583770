import IconButton from "@Components/IconButton";
import Table from "@Components/Table";
import { Column } from "@Components/Table/types";
import useGetIncompleteApplications, {
  useDeleteIncompleteApplication,
} from "@Hooks/useIncompleteApplications";
import { FlatDeal } from "@Types/deal";
import IncompleteDeal from "@Types/incomplete_application";
import { formatDate, formatFirstAndLastNames } from "@Utils/functions";
import { AiOutlineArrowRight } from "react-icons/ai";
import {
  BsCalendarFill,
  BsFillTrashFill,
  BsPencilSquare,
} from "react-icons/bs";
import { IoCalendarClearOutline } from "react-icons/io5";
import { MdOutlinePersonOutline, MdPerson } from "react-icons/md";
import { RiCarLine, RiCarFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import styles from "./UnfinishedApplications.module.scss";

export const formatCustomersNames = (deal: FlatDeal) => {
  return (
    `${formatFirstAndLastNames(
      deal?.applicant?.firstName,
      deal?.applicant?.lastName
    )}
    ${
      deal.coApplicant?.firstName
        ? " / " +
          formatFirstAndLastNames(
            deal?.coApplicant?.firstName,
            deal?.coApplicant?.lastName
          )
        : ""
    }`?.trim() || "N/A"
  );
};
export const formatCollateral = (deal: FlatDeal) =>
  [deal?.vehicle?.make, deal?.vehicle?.model]?.filter((x) => x)?.join(" - ") ||
  "N/A";
const UnfinishedApplications = () => {
  const {
    error,
    isLoading,
    data: incompleteApplications,
  } = useGetIncompleteApplications();
  const { deleteIncompleteDeal } = useDeleteIncompleteApplication();

  const handleRemove = (deal: IncompleteDeal) => {
    deleteIncompleteDeal(deal);
  };

  const columns: Column<IncompleteDeal>[] = [
    {
      id: "credit type",
      label: "Credit Type",
      value: (deal) =>
        deal?.data?.info?.creditType?.charAt(0).toUpperCase() +
        deal?.data?.info?.creditType?.slice(1),
    },
    {
      id: "applicants",
      label: "Applicants",
      value: (deal) => formatCustomersNames(deal?.data?.info),

      style: () => ({
        color: "#5e5d65",
      }),
      icon: <MdOutlinePersonOutline color="#9e9ca7" size={29} />,
      hoverIcon: <MdPerson color="#5e5d65" size={29} />,
    },
    {
      id: "collateral",
      label: "Collateral",
      value: (deal) => formatCollateral(deal?.data?.info),

      style: () => ({
        color: "#5e5d65",
      }),
      icon: <RiCarLine color="#9e9ca7" size={29} />,
      hoverIcon: <RiCarFill color="#5e5d65" size={29} />,
    },
    {
      id: "createdAt",
      label: " Created at",
      value: (deal) => formatDate(deal?.createdAt),

      style: () => ({
        color: "#5e5d65",
      }),
      icon: <IoCalendarClearOutline color="#9e9ca7" size={29} />,
      hoverIcon: <BsCalendarFill color="#5e5d65" size={29} />,
    },
    {
      id: "delete",
      label: "Delete",
      value: (deal) => {
        return (
          <IconButton
            className={"blueCircle"}
            onClick={() => handleRemove(deal)}
          >
            <BsFillTrashFill size={18} />
          </IconButton>
        );
      },
    },
    {
      id: "actions",
      label: "Actions",
      value: (incomplete_application) => {
        return (
          <Link to={`/incomplete_applications/${incomplete_application._id}`}>
            <IconButton className={"blueCircle"}>
              <AiOutlineArrowRight size={18} />
            </IconButton>
          </Link>
        );
      },
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <BsPencilSquare color="#6B97F6" />
        Unfinished applications
      </div>

      <Table<IncompleteDeal>
        columns={columns}
        useGetFunction={() => ({
          data: incompleteApplications,
          isLoading,
          error,
        })}
      />
    </div>
  );
};

export default UnfinishedApplications;
