import React from "react";
import styles from "./OrDivider.module.scss";

const OrDivider = () => (
  <div className={styles.dividerContainer}>
    <div className={styles.divider}></div>
    <span className={styles.dividerText}>or</span>
    <div className={styles.divider}></div>
  </div>
);

export default OrDivider;
