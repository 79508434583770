import styles from "./select.module.scss";
export interface Option {
  value: string | number;
  label: string;
}
interface Props {
  id?: string;
  required?: boolean;
  placeholder?: string;
  name: string;
  label?: string;
  inputStyle?: React.CSSProperties;
  options: Option[] | undefined;
  onChange?: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  value?: string | number;
  autofilled?: boolean;
}
const Select = ({
  id,
  name,
  options,
  label,
  onChange,
  placeholder,
  inputStyle,
  required,
  value = "",
  autofilled = false,
}: Props) => {
  return (
    <div
      className={`${styles.selectContainer} ${autofilled && styles.autofilled}`}
    >
      {placeholder && (
        <label className={styles.label} htmlFor="">
          {`${placeholder} ${required ? "*" : ""}`}
          {autofilled ? (
            <span className={styles.autofilledLabel}> (Autofilled)</span>
          ) : (
            ""
          )}
        </label>
      )}
      <select
        id={id}
        style={inputStyle}
        data-testid={id}
        required={required}
        onChange={onChange}
        value={value}
        name={name}
        className={styles.select}
      >
        <option disabled value={""}>
          {`${placeholder ?? label} ${required ? "*" : ""}`}
        </option>
        {(options ?? []).map((option, index) => {
          return (
            <option key={index} label={option.label} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
