import axios from "axios";

export const verifyRegisterToken = (
  token: string
): (() => Promise<{ email: string; userId: string }>) => {
  return async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/register/verify-token`,
      JSON.stringify({ token }),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.result;
  };
};
