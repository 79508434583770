interface LogoProps {
  className?: string;
}

function ColoredLogo({ className }: LogoProps) {
  return (
    <svg
      data-testid="colored-logo"
      className={className}
      width="84"
      height="40"
      viewBox="0 0 84 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.0156 0H40.7718L33.913 12.0029L27.0542 0H16.5755L28.7689 20.9575L25.53 26.1016L10.4787 0H0L22.8627 39.6287H28.1973L33.7225 29.912L39.2477 39.6287H44.5823L53.3463 24.3869H62.3009L67.445 15.4323H48.2022L41.915 26.1016L39.0571 20.9575L45.916 8.95456H64.0156C69.9218 8.95456 74.8754 13.7176 74.8754 19.8144C74.8754 25.9111 70.1123 30.6741 64.0156 30.6741H55.6326L50.679 39.6287H63.8251C74.6849 39.6287 83.6394 30.6741 83.6394 19.8144C83.6394 8.95456 74.8754 0 64.0156 0Z"
        fill="url(#paint0_linear_459_5588)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_459_5588"
          x1="-0.0905498"
          y1="19.8087"
          x2="83.5496"
          y2="19.8087"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2798E" />
          <stop offset="1" stopColor="#708EC9" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default ColoredLogo;
