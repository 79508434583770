import { Outlet, useLocation } from "react-router-dom";

import NotFound from "@Pages/NotFound";
import { PermissionEntities } from "@Types/roles";
import useCurrentUser from "@Hooks/useCurrentUser";
import { hasActionPermissions } from "@Utils/permissions";

const getPageFromPath = (path: string) => {
  const pathAsArray = path.split("/").filter((x) => x);
  if ([1, 2].includes(pathAsArray.length)) {
    switch (pathAsArray[0]) {
      case "members":
        return "dealership_user";
      case "incomplete_applications":
        return "incomplete_application";
      case "applications":
        return "deal";
      case "teams":
        return "dealership_team";
      case "roles":
        return "dealership_user";
      case "chat":
        return "chat";
      case "settings":
        return "settings";
      case "profit_report":
        return "profit_report";
      case "title_info":
        return "title_issue";
      case "add-co-applicant":
        return "deal";
      case "dashboard":
        return undefined;
      default:
        return pathAsArray[0].slice(0, pathAsArray[0].length - 1);
    }
  }
  return undefined;
};

function RequirePermissions() {
  const { pathname } = useLocation();
  const currentUser = useCurrentUser();
  const page = getPageFromPath(pathname) as PermissionEntities | undefined;

  if (!page) {
    return <Outlet />;
  }
  if (currentUser) {
    if (currentUser.data.info.userType === "owner") {
      return <Outlet />;
    }
    if (page && hasActionPermissions(page, "read", currentUser)) {
      return <Outlet />;
    }
  }
  return (
    <NotFound
      title={
        "You have no permissions for this page. Contact your administrator."
      }
    />
  );
}

export default RequirePermissions;
