import { FaBell } from "react-icons/fa";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";

import styles from "./Notifications.module.scss";
import useOnClickOutside from "@Hooks/useClickOutside";
import IconButton from "@Components/IconButton";
import Notification from "./Notification";
import useGetDealershipNotifications, {
  useDeleteAllDealershipNotifications,
} from "@Hooks/useNotifications";
import NotificationLoading from "./NotificationLoading";
import { RiDeleteBin6Line } from "react-icons/ri";

const Notifications = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);
  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useGetDealershipNotifications({
      query: {},
      options: {
        limit: 10,
        sort: { "data.info.level": "asc" as const, createdAt: "desc" as const },
        page: 1,
        pagination: true,
      },
    });
  const flattenedPages = data?.pages.flatMap((group) => group) ?? [];
  const notifications = flattenedPages.flatMap((x) => x.docs.flat());
  const { deleteAllDealershipNotifications } =
    useDeleteAllDealershipNotifications();
  const ref = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const closeFnCallback = useCallback(() => () => setOpen(false), [ref]);
  useOnClickOutside(ref, closeFnCallback);

  const handleDeleteAll = () => {
    deleteAllDealershipNotifications();
  };
  const handleScroll = () => {
    if (
      scrollContainerRef.current &&
      scrollContainerRef.current.clientHeight +
        scrollContainerRef.current.scrollTop >=
        scrollContainerRef.current.scrollHeight - 100 &&
      hasNextPage &&
      !isFetching
    ) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);

      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  const unread = notifications?.filter((x) => !x.data.info.seenAt);
  return (
    <div className={styles.container} data-testid={"notifications-menu"}>
      <IconButton
        dataTestId="notifications-menu-open-button"
        onClick={handleOpen}
      >
        {(unread?.length || 0) > 0 && (
          <div className={styles.badgeContainer}>
            <div>{unread?.length > 9 ? "9+" : unread?.length || 0}</div>
          </div>
        )}
        <FaBell size={30} color="#6B97F6" />
      </IconButton>
      {open && (
        <div
          data-testid={"notifications-menu-content"}
          ref={ref}
          className={styles.notificationsMenu}
          id="notifications-menu"
        >
          {notifications?.length ? (
            <div
              className={styles.notificationsContainer}
              ref={scrollContainerRef}
            >
              <div className={styles.notificationContainerHeader}>
                <div>Notifications</div>
                <div>
                  <button
                    className={styles.clearAllButton}
                    onClick={handleDeleteAll}
                  >
                    Clear all
                    <RiDeleteBin6Line color="red" size={20} />
                  </button>
                </div>
              </div>

              {notifications.map((notification, index) => (
                <Notification
                  notification={notification}
                  key={index}
                  setNotificationsMenuOpen={setOpen}
                />
              ))}
              {isFetching && (
                <Fragment>
                  <NotificationLoading />
                  <NotificationLoading />
                  <NotificationLoading />
                  <NotificationLoading />
                  <NotificationLoading />
                </Fragment>
              )}
            </div>
          ) : (
            <div className={styles.defaultTextContainer}>
              You have no notifications.
            </div>
          )}

          {(notifications?.length || 0) > 0 && (
            <div className={styles.controlButtonsContainer}>
              <div
                className={styles.loadMoreText}
                onClick={() => fetchNextPage()}
              >
                {isFetchingNextPage
                  ? "Loading more..."
                  : hasNextPage
                  ? "Load More"
                  : "No more notifications to load"}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;
