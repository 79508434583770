import { useQuery, useMutation } from "@tanstack/react-query";

import {
  getTitleIssueNotes,
  createTitleIssueNote,
} from "@Http/title_issue_notes";
import {
  TitleIssueNote,
  CreateTitleIssueNoteRequest,
} from "@Types/title_issue_notes";
import { MutationError } from "@Types/index";

export const useGetTitleIssueNotes = (titleIssueId: string) => {
  const queryKey = [`title_issue_notes-${titleIssueId}`];

  const { data, isLoading, error } = useQuery<
    TitleIssueNote[],
    MutationError,
    TitleIssueNote[]
  >({
    queryKey,
    queryFn: getTitleIssueNotes(titleIssueId),
  });

  return {
    data,
    isLoading,
    error,
  };
};

export const useCreateTitleIssueNote = () => {
  const mutation = useMutation<
    TitleIssueNote,
    MutationError,
    CreateTitleIssueNoteRequest
  >(createTitleIssueNote());
  return {
    createTitleIssueNote: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useGetTitleIssueNotes;
