import Dialog from "@Components/Dialog";
import { DialogContent } from "@Components/Dialog/DialogContent";
interface Props {
  fileName: string;
  url?: string;
  closeDialog: () => void;
}

export default function IFrameURLModal({ fileName, url, closeDialog }: Props) {
  const ext = fileName?.split(".")?.pop();
  if (ext !== "pdf" && ext !== "jpg" && ext !== "png" && ext !== "jpeg") {
    closeDialog();
    window.open(url, "_blank");
    return null;
  }

  return (
    <Dialog
      id={`${fileName}Dialog`}
      open={true}
      title={fileName}
      closeIcon
      titleColor="blue"
      size="lg"
      closeFn={() => {
        closeDialog();
      }}
    >
      <DialogContent>
        <div style={{ width: "1200px", height: "750px" }}>
          {url ? (
            <iframe
              src={url}
              frameBorder="0"
              width="100%"
              height="100%"
            ></iframe>
          ) : (
            "No url specified"
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
