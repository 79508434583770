import { UseMutateFunction } from "@tanstack/react-query";
import LoadingFullScreen from "@Components/Loading/LoadingFullScreen";
import IconButton from "@Components/IconButton";
import { AiOutlineDelete } from "react-icons/ai";
import {
  AdfType,
  EmailAdfData,
  Settings as SettingsType,
  SftpAdfData,
} from "@Types/settings";
import { MutationError } from "@Types/index";
import styles from "./Settings.module.scss";
import { Fragment } from "react";

function Actions({ adfEmailIndex }: { adfEmailIndex: number }) {
  return (
    <IconButton id={`delete-setting-${adfEmailIndex}`} className={"blueCircle"}>
      <AiOutlineDelete size={18} />
    </IconButton>
  );
}

interface SettingsTableProps {
  settings?: SettingsType;
  isLoading?: boolean;
  updateSettings: UseMutateFunction<
    SettingsType,
    MutationError,
    SettingsType | Omit<SettingsType, "_id">,
    unknown
  >;
}

const SettingsTable = ({
  settings,
  isLoading,
  updateSettings,
}: SettingsTableProps) => {
  const handleDelete = (adfData: SftpAdfData | EmailAdfData) => {
    if (settings?.data?.info?.adfEmails) {
      let adfEmails = settings?.data?.info?.adfEmails;
      if (adfData.sftp) {
        adfEmails = adfEmails.filter((settingsAdfData) => {
          if (
            settingsAdfData.sftp &&
            settingsAdfData.host === adfData.host &&
            settingsAdfData.dcid === adfData.dcid &&
            settingsAdfData.username === adfData.username &&
            settingsAdfData.directory === adfData.directory &&
            settingsAdfData.password === adfData.password &&
            settingsAdfData.port === adfData.port
          ) {
            return false;
          }
          return true;
        });
      } else {
        adfEmails = (adfEmails as EmailAdfData[]).filter(
          (settingsAdfData) => settingsAdfData.email !== adfData.email
        );
      }
      updateSettings({
        ...settings,
        data: {
          ...settings.data,
          info: {
            ...settings?.data.info,
            adfEmails,
          },
        },
      });
    }
  };

  const emailAdfColumns = [{ label: "Email" }, { label: "Actions" }];
  const sftpAdfColumns = [
    { label: "DCID" },
    { label: "Username" },
    { label: "Host" },
    { label: "Port" },
    { label: "Directory" },
    { label: "Actions" },
  ];
  const entries = settings?.data?.info?.adfEmails;
  const renderTableDataByAdfType = (
    adfData: SftpAdfData | EmailAdfData,
    index: number
  ) => {
    if (adfData.sftp) {
      return (
        <Fragment>
          <td className={styles.tableColumn}>
            <div className={styles.tableColumnContainer}>{adfData.dcid}</div>
          </td>
          <td className={styles.tableColumn}>
            <div className={styles.tableColumnContainer}>
              {adfData.username}
            </div>
          </td>
          <td className={styles.tableColumn}>
            <div className={styles.tableColumnContainer}>{adfData.host}</div>
          </td>
          <td className={styles.tableColumn}>
            <div className={styles.tableColumnContainer}>{adfData.port}</div>
          </td>
          <td className={styles.tableColumn}>
            <div className={styles.tableColumnContainer}>
              {adfData.directory}
            </div>
          </td>

          <td>
            <div className={styles.tableColumnActions}>
              <div onClick={() => handleDelete(adfData)}>
                <Actions adfEmailIndex={index} />
              </div>
            </div>
          </td>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <td className={styles.tableColumn}>
            <div className={styles.tableColumnContainer}>{adfData.email}</div>
          </td>
          <td>
            <div className={styles.tableColumnActions}>
              <div onClick={() => handleDelete(adfData)}>
                <Actions adfEmailIndex={index} />
              </div>
            </div>
          </td>
        </Fragment>
      );
    }
  };
  const renderTableByAdfType = (adfType: AdfType) => {
    switch (adfType) {
      case "email": {
        const emailEntries = entries?.filter((adfData) => !adfData.sftp);
        return (
          <div className={styles.tableContainer}>
            <h2 className={styles.tableTitle}>ADF Emails</h2>
            <table
              cellSpacing={0}
              cellPadding={0}
              border={0}
              className={styles.table}
            >
              <thead className={styles.tableHead}>
                <tr>
                  {emailAdfColumns.map((column, index) => (
                    <th key={index} className={styles.tableColumnHeader}>
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {emailEntries?.length ? (
                  emailEntries.map((entry, rowIndex) => {
                    return (
                      <tr key={`${rowIndex}-email`} className={styles.tableRow}>
                        {renderTableDataByAdfType(entry, rowIndex)}
                      </tr>
                    );
                  })
                ) : isLoading ? (
                  <tr>
                    <td colSpan={emailAdfColumns.length ?? 1}>
                      <LoadingFullScreen />
                    </td>
                  </tr>
                ) : (
                  <tr className={styles.tableRow}>
                    <td
                      style={{ textAlign: "center" }}
                      colSpan={emailAdfColumns.length ?? 1}
                    >
                      {"No entries found!"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        );
      }
      case "sftp": {
        const sftpEntries = entries?.filter((adfData) => adfData.sftp);
        return (
          <div className={styles.tableContainer}>
            <h2 className={styles.tableTitle}>SFTP ADF</h2>
            <table
              cellSpacing={0}
              cellPadding={0}
              border={0}
              className={styles.table}
            >
              <thead className={styles.tableHead}>
                <tr>
                  {sftpAdfColumns.map((column, index) => (
                    <th key={index} className={styles.tableColumnHeader}>
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sftpEntries?.length ? (
                  sftpEntries.map((entry, rowIndex) => {
                    return (
                      <tr key={`${rowIndex}-sftp`} className={styles.tableRow}>
                        {renderTableDataByAdfType(entry, rowIndex)}
                      </tr>
                    );
                  })
                ) : isLoading ? (
                  <tr>
                    <td colSpan={sftpAdfColumns.length ?? 1}>
                      <LoadingFullScreen />
                    </td>
                  </tr>
                ) : (
                  <tr className={styles.tableRow}>
                    <td
                      style={{ textAlign: "center" }}
                      colSpan={sftpAdfColumns.length ?? 1}
                    >
                      {"No entries found!"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        );
      }
    }
  };
  return (
    <Fragment>
      {renderTableByAdfType("email")}
      {renderTableByAdfType("sftp")}
    </Fragment>
  );
};

export default SettingsTable;
