import firebase from "firebase/compat/app";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RiLockPasswordFill } from "react-icons/ri";

import Grid from "@Components/Grid";
import TextField from "@Components/Inputs/TextField";
import Button from "@Components/Button";
import Loading from "@Components/Loading";
import { MfaState } from "./SignIn/SignIn";

import styles from "./Auth.module.scss";
import mixpanel from "mixpanel-browser";

function SignInMfa({ mfaState }: { mfaState: MfaState }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();

    const resolver = mfaState?.resolver;
    const verificationId = mfaState?.verificationId ?? "";
    const cred = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      code
    );
    const multiFactorAssertion =
      firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    return resolver
      ?.resolveSignIn(multiFactorAssertion)
      .then(() => {
        setLoading(false);
        mixpanel.track("Login MFA", {
          email: mfaState?.email,
        });
        navigate("/dashboard");
      })
      .catch((error: Error) => {
        setLoading(false);
        toast.error(error.message.replace("Firebase: ", ""), {
          position: "top-center",
        });
      });
  };
  if (loading) return <Loading />;

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Grid
        container
        rowSpacing={1}
        alignItems="center"
        direction="column"
        style={{ maxWidth: "485px", margin: "auto" }}
      >
        <Grid xs={12}>
          <h1
            style={{
              textAlign: "center",
              fontSize: "38px",
              color: "#29282E",
              fontWeight: "700",
            }}
          >
            Code confirmation
          </h1>
          <p>
            Web Finance Direct has sent you a verification code on your phone.
          </p>
        </Grid>
        <Grid xs={12}>
          <TextField
            onChange={(e) => {
              setCode(e.target.value);
            }}
            placeholder="Code"
            value={code}
            name="Code"
            className={styles.authInput}
            type="text"
            startIcon={<RiLockPasswordFill />}
          />
        </Grid>
        <Grid xs={12}>
          <Button
            className={styles.authButton}
            type="submit"
            style={{ fontSize: "30px", marginTop: "10px" }}
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default SignInMfa;
