import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import IconButton from "@Components/IconButton";
import SortBy, { SortProps } from "./types";
import { HttpOptions } from "@Types/http";

export const transformSort = (sort: SortBy): HttpOptions => ({
  sort: { [sort.path.join(".")]: sort.value },
});

const Sort = ({ path, sort, setSort }: SortProps) => {
  const isSamePath = path.join(".") === sort?.path.join(".");

  const handleSortClick = () => {
    if (isSamePath) {
      setSort({ value: sort?.value === "asc" ? "desc" : "asc", path });
    } else {
      setSort({ value: "desc", path });
    }
  };
  return (
    <IconButton style={{ padding: 2 }} onClick={handleSortClick}>
      {sort?.value === "desc" && isSamePath ? (
        <BiChevronUp size={20} />
      ) : (
        <BiChevronDown size={20} />
      )}
    </IconButton>
  );
};

export default Sort;
