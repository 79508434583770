import { SlLock } from "react-icons/sl";
import { RxEnvelopeClosed, RxPerson } from "react-icons/rx";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";

import Grid from "@Components/Grid";
import Button from "@Components/Button";
import TextField from "@Components/Inputs/TextField";
import Loading from "@Components/Loading";
import { auth } from "@Firebase/firebase";
import { verifyRegisterToken } from "@Http/auth";

import styles from "./Auth.module.scss";

interface Credentials {
  password: string;
  confirmPassword: string;
  lastName: string;
  firstName: string;
  email: string;
}

const SignUp = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const query = new URLSearchParams(window.location.search);
  const token = query.get("token") ?? "";
  const [credentials, setCredentials] = useState<Credentials>({
    password: "",
    confirmPassword: "",
    lastName: "",
    firstName: "",
    email: "",
  });
  const { isLoading, error } = useQuery({
    queryKey: ["verifyRegisterToken"],
    queryFn: verifyRegisterToken(token),
    retry: false,
    onSuccess: (data) => {
      setCredentials((x) => ({
        ...x,
        email: data.email,
      }));
    },
  });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!credentials.firstName || !credentials.lastName) {
      toast.error("Please enter first and last names!", {
        position: "top-center",
      });
      return;
    }
    if (!credentials.password) {
      toast.error("Please enter password!", {
        position: "top-center",
      });
      return;
    }
    if (credentials.password !== credentials.confirmPassword) {
      toast.error("Passwords does not match!", {
        position: "top-center",
      });
      return;
    }
    setLoading(true);
    auth
      .createUserWithEmailAndPassword(
        credentials.email.trim(),
        credentials.password
      )
      .then((response) => {
        setLoading(false);
        response.user?.sendEmailVerification();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message.replace("Firebase: ", ""), {
          position: "top-center",
        });
      });
  }
  if (isLoading || loading) return <Loading />;
  if (error)
    return (
      <h2 style={{ textAlign: "center" }}>
        Something went wrong!
        <br /> Contact Web Finance Direct team for help!
      </h2>
    );
  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Grid
        container
        rowSpacing={1}
        alignItems="center"
        direction="column"
        style={{ maxWidth: "485px", margin: "auto" }}
      >
        <Grid xs={12}>
          <h2 style={{ textAlign: "center", marginBottom: "80px" }}>
            Welcome to Web Finance Direct <br /> Dealer Platform
          </h2>
          <h1
            style={{
              textAlign: "center",
              fontSize: "38px",
              color: "#29282E",
              fontWeight: "700",
            }}
          >
            Create account
          </h1>
        </Grid>
        <Grid xs={12}>
          <TextField
            onChange={(e) => {
              setCredentials((x) => ({
                ...x,
                firstName: e.target.value,
              }));
            }}
            className={styles.authInput}
            placeholder="First Name"
            value={credentials.firstName ?? ""}
            name="firstName"
            startIcon={<RxPerson />}
            type="text"
            id="register-firstName"
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            onChange={(e) => {
              setCredentials((x) => ({
                ...x,
                lastName: e.target.value,
              }));
            }}
            className={styles.authInput}
            placeholder="Last Name"
            value={credentials.lastName ?? ""}
            name="lastName"
            id="register-lastName"
            startIcon={<RxPerson />}
            type="text"
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            onChange={(e) => {
              setCredentials((x) => ({
                ...x,
                email: e.target.value,
              }));
            }}
            className={styles.authInput}
            disabled
            placeholder="Email"
            value={credentials.email ?? ""}
            name="email"
            startIcon={<RxEnvelopeClosed />}
            type="text"
            id="register-email"
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            onChange={(e) => {
              setCredentials((x) => ({
                ...x,
                password: e.target.value,
              }));
            }}
            className={styles.authInput}
            value={credentials.password ?? ""}
            name="password"
            placeholder="Password"
            startIcon={<SlLock />}
            type="password"
            id="register-password"
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            onChange={(e) => {
              setCredentials((x) => ({
                ...x,
                confirmPassword: e.target.value,
              }));
            }}
            className={styles.authInput}
            value={credentials.confirmPassword ?? ""}
            name="confirmPassword"
            placeholder="Confirm Password"
            startIcon={<SlLock />}
            type="password"
            id="register-confirm-password"
          />
        </Grid>
        <Grid xs={12}>
          <Button
            className={styles.authButton}
            type="submit"
            style={{ fontSize: "30px", marginTop: "10px" }}
            id="register-button"
          >
            Create account
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SignUp;
