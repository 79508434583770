import Button from "@Components/Button";
import { signOut } from "@Firebase/firebase";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useAcceptTermsAndConditions } from "@Hooks/useMembers";
import { Outlet } from "react-router-dom";

import styles from "./TermsAndConditions.module.scss";
import LoadingFullScreen from "@Components/Loading/LoadingFullScreen";

const TermsAndConditions = () => {
  const currentUser = useCurrentUser();
  const { accept } = useAcceptTermsAndConditions();

  const handleAcceptTermsAndConditions = () => accept();
  const handleCancel = () => signOut(currentUser);

  if (!currentUser) return <LoadingFullScreen text={"Loading"} />;
  if (!currentUser?.data?.termsAndConditions) {
    return (
      <div className={styles.root}>
        <h1 className={styles.header}>Terms & conditions</h1>
        {renderTermsAndConditions()}
        <div className={styles.actions}>
          <Button
            className={styles.button}
            variant="blue"
            onClick={handleAcceptTermsAndConditions}
          >
            I have read and accept the terms and conditions
          </Button>
          <Button
            variant="white"
            className={styles.button}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
  return <Outlet />;
};

export const renderTermsAndConditions = () => (
  <div className={styles.container}>
    <div className={styles.paragraph}>
      This set of terms and conditions (this “Agreement”) is entered into as of
      the date on which Client agrees to accept the terms and conditions via any
      of the indicated methods herein (the “Effective Date”), between Web
      Finance Direct, LLC, a Wisconsin limited liability company having offices
      at 200 Mason St., No. 13, Onalaska, WI 54650 (“WFD”) and the entity,
      natural person or otherwise, desirous of using the Services (as defined
      herein) and entering into this Agreement in order to procure such use
      (“Client”). “Services” means the software-as-a-service offering(s) offered
      by WFD wherein Client transmits to WFD the Input Data for WFD to provide,
      through the software-as-a-service or otherwise, to third-parties that will
      consider the Input Data (as defined herein), and any other relevant data,
      to thereafter provide a loan estimate (the “Third Party Loan Providers”)
      to Client for Client’s use in its ordinary business purposes in the United
      States. Apart from the terms and conditions contained in this Agreement,
      WFD also publishes a privacy policy at: [INSERT URL] (the “Privacy
      Policy”). Although the Privacy Policy is not part of this Agreement, WFD
      encourages Client to read it to better understand how Client can update,
      manage, access, and delete Client’s information. WFD and Client may be
      individually referred to herein as a “Party” or collectively as the
      “Parties.”
    </div>
    <div className={styles.paragraph}>
      IN ACCEPTING THIS AGREEMENT, BY INDICATING ACCEPTANCE EITHER BY EXECUTING
      AN ORDER FORM OR OTHER AGREEMENT THAT REFERENCES THIS AGREEMENT, OR BY
      DOWNLOADING, INSTALLING, AND/OR UTILIZING THE SERVICES, CLIENT AGREES TO
      BE BOUND BY THIS AGREEMENT. THIS AGREEMENT IS A LEGALLY BINDING CONTRACT
      BETWEEN CLIENT AND WFD AND SETS FORTH THE TERMS THAT GOVERN THE LICENSE
      PROVIDED TO CLIENT HEREUNDER. ANY CHANGES, ADDITIONS OR DELETIONS MADE BY
      CLIENT TO THIS AGREEMENT WILL NOT BE ACCEPTED AND WILL NOT BE A PART OF
      THIS AGREEMENT. IF CLIENT DOES NOT AGREE TO THIS AGREEMENT, THEN CLIENT
      MUST NOT DOWNLOAD, INSTALL, OR USE THE SERVICES.
    </div>
    <div className={styles.secondaryContainer}>
      <div className={styles.title}>Use of the Services and Restrictions</div>
      <div className={styles.secondaryTitle}>Use of the Services by Client</div>
      <div className={styles.paragraph}>
        Client shall maintain responsibility for all activities associated with
        its, and its Affiliates’, use of the Services, and all use will be in
        accordance with this Agreement. “Affiliates” means with respect to any
        entity, all persons or entities directly or indirectly controlling,
        controlled by or under common control with such entity, where control
        may be by either management authority, contract, or equity interest. As
        used in this definition, “control” and correlative terms have the
        meanings ascribed to such words in Rule 12b-2 of the Securities Exchange
        Act of 1934, as amended.
      </div>
      <div className={styles.secondaryTitle}> Unauthorized Access</div>
      <div className={styles.paragraph}>
        Client will use reasonable efforts to prevent unauthorized access to, or
        use of, the Services.
      </div>

      <div className={styles.secondaryTitle}> Network Connections</div>
      <div className={styles.paragraph}>
        Client will provide all network connections and interfaces from the
        Client’s systems to the Services necessary for Client to access the
        Services.
      </div>

      <div className={styles.secondaryTitle}> Restrictions</div>
      <div className={styles.paragraph}>
        In addition to any other rights afforded to WFD under this Agreement,
        WFD reserves the right to remove or disable access to any material that
        violates the terms and conditions set forth in the Restrictions. WFD
        shall have no liability to Client in the event that WFD takes such
        action.
      </div>
      <div className={styles.secondaryTitle}> Client Systems</div>
      <div className={styles.paragraph}>
        Client will be responsible for obtaining and maintaining all systems,
        equipment, and ancillary services needed to connect to, access, or
        otherwise use the Services. WFD will not be liable for any systems,
        equipment, and/or ancillary services needed to connect to, access, or
        otherwise use the Services.
      </div>
      <div className={styles.secondaryTitle}> Compliance</div>
      <div className={styles.paragraph}>
        Client will be solely responsible for ensuring that its use of the
        Services, including, without limitation, Client’s provision of Input
        Data through the Services complies with all applicable laws (including,
        all Privacy Laws), rules, and regulations. At WFD’s written request,
        Client will furnish WFD with a certification signed by an officer of
        Client verifying that the Services are being used in accordance with the
        terms and conditions of this Agreement. “Privacy Laws” means all
        applicable laws, rules, regulations, ordinances, and legally binding
        self-regulatory guidelines or standards, in each case as amended,
        consolidated, re-enacted or replaced from time to time, relating to the
        privacy, security, or processing of Personal Information (as defined
        herein), data breach notification, website and mobile application
        privacy policies and practices, processing and security of payment card
        information, and email, text message, or telephone communications,
        including (to the extent applicable to the business of WFD and its Third
        Party Loan Providers): the Fair Credit Reporting Act; the Fair and
        Accurate Credit Transaction Act; the CAN-SPAM Act; Children’s Online
        Privacy Protection Act; California Consumer Privacy Act and other state
        privacy laws; state social security number protection laws; the Federal
        Trade Commission Act; the Gramm-Leach-Bliley Act; the Telephone Consumer
        Protection Act; the Telemarketing and Consumer Fraud and Abuse
        Prevention Act; the Computer Fraud and Abuse Act; the Payment Card
        Industry Data Security Standards; the GDPR; and the EU e-Privacy
        Directive 2002/58/EC as amended by Directive 2009/136/EC (and any
        European Union member states’ laws and regulations implementing it). Any
        such right(s) granted by, referred to, or relied on by any of the
        foregoing shall constitute a “Privacy Right.” “Personal Information”
        means any information that can identify, directly or indirectly, a
        person individually, and that is protected as “personal data,”
        “personally identifiable information,” or a comparable term under any
        Privacy Laws or any other applicable data privacy and security laws,
        regulations, and directives.
      </div>
      <div className={styles.secondaryTitle}> Restrictions</div>
      <div className={styles.paragraph}>
        Client shall not, and shall not cause or allow any third-party to: (a)
        decompile, disassemble, or reverse-engineer any part of the Services, or
        otherwise attempt to derive the source code, algorithms, or technology
        included in the Services; (b) remove or alter any copyright, trademark,
        logo, or any other product identification or proprietary rights notices
        included in the Services or any Documentation, or fail to preserve all
        copyright and other proprietary notices in all copies of the
        Documentation made by Client; (c) license, sublicense, sell, resell,
        lease, lend, use for timesharing or service bureau purposes, any part of
        the Services and/or the Documentation, except to the extent expressly
        permitted in this Agreement or as expressly authorized by WFD in
        writing; (d) copy, modify, adapt, tamper with, frame, or create
        derivative works of, the Services or the Documentation; (e) access or
        use the Services or the Documentation as part of any effort to develop
        software or service having any functional attributes, visual
        expressions, or other features similar to those of the Services or
        otherwise in order to build a competitive product or service; (f) except
        with WFD’s prior written permission, publish any performance or
        benchmark tests or analysis relating to the Services; (g) attempt to
        circumvent, disable, or otherwise interfere with security-related or
        access-related features of the Services; (h) represent that it possess
        any proprietary interest in the Services or the Documentation; (i)
        directly or indirectly, take any action to contest WFD’s Intellectual
        Property Rights (as defined herein) in the WFD IP (as defined herein),
        or infringe or misappropriate such rights in any way; (j) menace or
        harass any person, or cause damage or injury to any person or property;
        (k) use the Services or the Documentation to publish any material that
        is false, defamatory, harassing, or obscene; (l) violate the Privacy
        Rights of any third-party, or promote bigotry, racism, hatred, or harm;
        (m) publish any material via the Services that constitutes unsolicited
        bulk e-mail, “junk mail,” “spam,” or “chain letters”; (n) infringe or
        misappropriate the Intellectual Property Rights, Privacy Rights, or
        other proprietary rights of any third-party; or (o) otherwise violate
        any applicable laws, ordinances, rules, or regulations. In addition to
        any other rights afforded to WFD under this Agreement, WFD reserves the
        right to remove or disable access to any material that violates the
        foregoing restrictions. WFD shall have no liability to You in the event
        that WFD takes such action.
      </div>
      <div className={styles.title}>Data Ownership and Licenses</div>
      <div className={styles.secondaryTitle}> Input Data</div>
      <div className={styles.paragraph}>
        “Input Data” means all data or information of any kind that is entered
        into the Services by, or on behalf of, Client, and all data derived from
        such data. Client acknowledges that WFD and its Third Party Loan
        Providers will rely on the accuracy of the Input Data provided to WFD as
        they, and WFD, perform their requested functions under this Agreement.
        WFD owns all rights, title, and interest in and to all Input Data
        received into the Services. For the avoidance of doubt, Client hereby
        assigns any and all Intellectual Property Rights in the Input Data, to
        WFD, and agrees to take any further actions required to affect such
        assignment. WFD grants to Client, a limited license to use any and all
        such Input Data for the purposes of conducting Client’s business within
        the United States, for the Term (as defined herein) of this Agreement.
      </div>
      <div className={styles.secondaryTitle}>Intellectual Property</div>
      <div className={styles.paragraph}>
        “Intellectual Property” means any: (a) patents, patent applications,
        patent disclosures, and inventions (whether patentable or not); (b)
        trademarks, service marks, trade dress, trade names, logos, moral
        rights, corporate names, and domain names, together with all of the
        goodwill associated therewith; (c) copyrights and copyrightable works
        (including computer programs), and data and databases; (d) trade
        secrets, know-how; and (e) all other forms of intellectual property,
        however such intellectual property may arise, in each case whether
        registered or unregistered, whether first created before or after the
        Effective Date (including, where applicable, all derivative works of the
        foregoing), and including all applications for, and renewals or
        extensions of, such rights, and all similar or equivalent rights or
        forms of protection in any part of the world. “Intellectual Property
        Rights” means all rights, title, and interest in and to any Intellectual
        Property.
      </div>
      <div className={styles.secondaryTitle}>WFD IP</div>
      <div className={styles.paragraph}>
        “WFD IP” means the Services, the Documentation, and any and all
        Intellectual Property provided by or on behalf of WFD to Client in
        connection with the Services and the Documentation. WFD owns all rights,
        title, and interest in and to the WFD IP, and nothing contained in this
        Agreement shall be construed to provide such ownership to any other
        Party or third-party. WFD grants to Client, a non-exclusive,
        non-transferrable, revocable, limited license to use the Services for
        the purposes of conducting Client’s business within the United States,
        for the Term of this Agreement.
      </div>
      <div className={styles.secondaryTitle}>Use Data</div>
      <div className={styles.paragraph}>
        WFD may monitor Client’s use of the Services and collect and compile
        information and data regarding such use (“Use Data”). As between WFD and
        Client, all rights, title, and interest in and to such Use Data, and all
        Intellectual Property Rights therein, belong to and are retained solely
        by WFD. Client acknowledges that WFD may compile, use, resell, license,
        and otherwise publish such Use Data; provided, however, that any such
        action taken by WFD with regards to such Use Data shall comply with all
        applicable laws, rules, and regulations.
      </div>
      <div className={styles.secondaryTitle}>Feedback</div>
      <div className={styles.paragraph}>
        If Client sends or transmits any communications or materials to WFD
        suggesting or recommending changes to the Services, or any Preview,
        including without limitation, new features or functionality relating
        thereto, or any comments, questions, suggestions, or the like
        (“Feedback”), WFD is free to use such Feedback without regard to any
        other obligation or limitation between the Parties governing such
        Feedback. Client hereby assigns to WFD, all rights, title, and interest
        in and to such Feedback. WFD may use such Feedback, without any
        attribution or compensation to any Party or third-party, any ideas,
        know-how, concepts, techniques, or other Intellectual Property Rights
        contained in the Feedback, for any purpose whatsoever, although WFD is
        not required to use any Feedback.
      </div>
      <div className={styles.title}>Previews</div>
      <div className={styles.secondaryTitle}>Changes to the Services</div>
      <div className={styles.paragraph}>
        WFD may, from time to time, at its sole discretion, update and/or change
        any part of the Services, including the features, functions, layout, and
        design of the Services.
      </div>
      <div className={styles.secondaryTitle}>Preview Offerings</div>
      <div className={styles.paragraph}>
        WFD may, in its sole discretion, offer preview, beta or pre-release
        features or services, prior to their general commercial release
        (“Previews”), for the purpose of evaluating performance, identifying
        defects, and obtaining Feedback. Previews will be designated as
        “preview,” “pre-release,” “experimental,” “beta” or with a similar
        legend. Client may access and use Previews solely for internal
        evaluation purposes. WFD carries no obligation to maintain, assist with,
        or otherwise provide any services relating to any Preview. WFD may
        change or discontinue Previews at any time without notice and shall have
        no obligation to release a final version of any Preview. All information
        about a Preview, and the Preview itself, shall constitute WFD’s
        Confidential Information (as defined herein). Any access or use of
        Previews by Client will be at Client’s sole discretion and risk. WFD
        will not be liable for any damages in connection with Client’s use of
        any Previews. Previews are made available on an “as-is” basis, without
        any warranty, express or implied, of any kind.
      </div>
      <div className={styles.secondaryTitle}>Confidentiality</div>
      <div className={styles.paragraph}>
        “Confidential Information” means all information disclosed by a Party
        (“Disclosing Party”) to the other Party (“Receiving Party”) in
        connection with this Agreement. Confidential Information includes,
        without limitation, the Documentation, the WFD IP, the Services, copies
        or abstracts made thereof as well as any modules, samples, prototypes,
        Preview(s) (as defined herein) or parts thereto and the details of the
        employees of the Parties or their Affiliates. Confidential Information
        does not include any information that (a) is already known to the
        Receiving Party or received by the Receiving Party from a third-party,
        free of any obligation to keep it confidential; (b) becomes publicly
        known through no wrongful act of the Receiving Party; (c) is
        independently developed by the Receiving Party without the use of the
        Disclosing Party’s Confidential Information; (d) is approved for release
        by written authorization of the Disclosing Party; or (e) which
        information’s disclosure is contemplated and allowed under this
        Agreement. The Receiving Party shall not disclose or use any
        Confidential Information of the Disclosing Party for any purpose outside
        the scope of this Agreement without the Disclosing Party’s prior written
        permission; provided, however, that WFD may use the Input Data, Use
        Data, and Feedback both during the term of this Agreement and after the
        termination this Agreement.
      </div>
      <div className={styles.secondaryTitle}>Protection</div>
      <div className={styles.paragraph}>
        The Receiving Party agrees to keep confidential all Confidential
        Information disclosed to it by the Disclosing Party, and to protect the
        confidentiality thereof in the same manner as it protects the
        confidentiality of its own Confidential Information of a similar nature,
        but with no less than reasonable care.
      </div>
      <div className={styles.secondaryTitle}>Compelled Disclosure</div>
      <div className={styles.paragraph}>
        If the Receiving Party is compelled by law to disclose Confidential
        Information of the Disclosing Party, it shall provide the Disclosing
        Party with prior notice of such compelled disclosure (to the extent
        legally permitted) and reasonable assistance, at Disclosing Party’s
        cost, if the Disclosing Party wishes to contest the disclosure. Any such
        disclosure will be to the minimum extent legally required.
      </div>
      <div className={styles.title}> Reliance on Information Posted</div>
      <div className={styles.secondaryTitle}>Informational Purposes Only</div>
      <div className={styles.paragraph}>
        The information presented on or through the Services or the
        Documentation is made available solely for general information purposes.
        “Documentation” means the administrative and user manuals, in addition
        to any marketing materials, written or otherwise recorded description(s)
        of the Services, published by WFD and provided by WFD to Client with the
        Services, including but not limited to, the Order Form. “Order Form”
        means the Documentation provided by WFD to Client to define the scope of
        the Services, and set forth any further terms and conditions, including
        but not limited to payment, the Term (as defined herein), or additional
        service offerings. The information provided through the Services, or the
        Documentation, does not constitute legal or accounting advice. WFD does
        not warrant the accuracy, completeness, or usefulness of this
        information. Any reliance Client places on such information is strictly
        at Client’s own risk. WFD disclaims all liability and responsibility
        arising from any reliance placed on such materials by Client or any
        other users of the Services, or by anyone who may be informed of any of
        the contents of the Services or the Documentation.
      </div>
      <div className={styles.secondaryTitle}>Reliance</div>
      <div className={styles.paragraph}>
        The Services and the Documentation may include content provided by the
        Third Party Loan Providers and/or other third-parties, including
        materials provided by other users, bloggers, and third-party licensors,
        syndicators, aggregators, and/or reporting services. All statements
        and/or opinions expressed in these materials, and all articles and
        responses to questions and other content, other than the content
        provided by WFD, are solely the opinions and the responsibility of the
        person or entity providing those materials. These materials do not
        necessarily reflect the opinion of WFD. WFD is not responsible, or
        liable to Client or any third-party, for the content or accuracy of any
        materials provided by any Third Party Loan Providers and/or other
        third-parties.
      </div>
      <div className={styles.title}> Warranties</div>
      <ul className={styles.paragraph}>
        <li>
          Client represents and warrants that, at the time that Client
          transcribes the Input Data into the Services, Client owns all rights,
          title, and interest in and to the Input Data and/or has a license (and
          any applicable consents required by law or contract) granting Client
          the rights and authority necessary to use and process the Input Data
          with the Services in accordance with this Agreement and otherwise to
          grant to WFD the rights contemplated by this Agreement, including but
          not limited to Client’s assignment of all Intellectual Property Rights
          in the Input Data to WFD.
        </li>
        <li>
          Client represents and warrants that none of the Input Data or Feedback
          violates this Agreement, any applicable law, or any third-party’s
          Intellectual Property Rights or Privacy Rights.
        </li>
        <li>
          Client represents and warrants that, at all times when using the
          Services in any way, it shall comply with all applicable laws, rules,
          and regulations, including but not limited to Privacy Laws.
        </li>
        <li>
          Client represents and warrants that, at all times when using the
          Services in any way, it shall comply with all applicable laws, rules,
          and regulations, including but not limited to Privacy Laws.
        </li>
        <li>
          Client represents and warrants that it shall only use the Services in
          accordance with this Agreement.
        </li>
        <li>
          Client represents and warrants that it shall only use the Services in
          good faith.
        </li>
        <li>
          Client represents and warrants that, in the case that it is a natural
          person, Client is at least sixteen (16) years old.
        </li>
        <li>
          Client represents and warrants that, in the case that it is not a
          natural person, both that Client shall take physical and technical
          measures to prevent the use of the Services by anyone under the age of
          sixteen (16) years, and that Client shall prevent such use of the
          Services by anyone under the age of sixteen (16) years.
        </li>
        <li>
          Client represents and warrants that it shall not introduce in any way
          into the Services any lock, clock, timer, Trojan horse, Easter egg,
          time bomb, counter, copy protection feature, replication devices or
          defect (“virus” or “worm” as such terms are commonly used in the
          computer industry) or other device which might: (a) lock, disable, or
          erase the Services or any Input Data; (b) prevent the full utilization
          of the Services by any third-party or WFD; or (c) require action or
          intervention by WFD, third-parties, Client or other persons or
          entities to allow the utilization of the Services to resume.
        </li>
        <li>
          Client represents and warrants that it shall take physical and
          technical measures to safeguard any and all usernames, passwords, log
          ins, and any other information that could be used to gain access to
          the Services.
        </li>
        <li>
          Client represents and warrants that the person entering into this
          Agreement on behalf of Client has the ability to, and does, bind
          Client to the obligations, terms, and conditions of this Agreement.
        </li>
        <li>
          Both Parties shall cooperate to ensure that their respective
          processing of Personal Information hereunder does and will materially
          comply with all applicable Privacy Laws and take all reasonable
          precautions to avoid acts that place the other Party in breach of its
          obligations under any applicable Privacy Laws. Nothing in this Section
          6.11 shall be deemed to prevent either Party from taking the steps it
          reasonably deems necessary to comply with any applicable Privacy Laws.
        </li>
        <li>
          WFD represents and warrants that it shall perform its obligations
          under this Agreement in a professional and workmanlike manner.
        </li>
        <li>
          WFD represents and warrants that it possesses all necessary rights,
          title, and interest in and to the Services in order to provide the
          Services to Client in accordance with this Agreement.
        </li>
      </ul>
      <div className={styles.secondaryTitle}>Disclaimer</div>
      <div className={styles.paragraph}>
        WFD DOES NOT WARRANT THAT THE SERVICES WILL MEET CLIENT’S REQUIREMENTS,
        OR THAT THE OPERATION OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR
        FREE. CLIENT ACKNOWLEDGES THAT WFD DOES NOT CONTROL THE TRANSFER OF DATA
        OVER COMMUNICATIONS FACILITIES, INCLUDING THE INTERNET, THAT THE
        SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS OR OTHER PROBLEMS
        INHERENT IN THE USE OF COMMUNICATIONS FACILITIES, AND THAT WFD IS NOT
        RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES OR OTHER DAMAGE RESULTING
        FROM SUCH PROBLEMS. NEITHER WFD, NOR ITS THIRD PARTY LOAN PROVIDERS,
        AFFILIATES, INDEPENDENT CONTRACTORS, OR AGENTS MAKE ANY REPRESENTATIONS
        OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR
        OTHERWISE, AND WFD, ITS THIRD PARTY LOAN PROVIDERS, AFFILIATES,
        INDEPENDENT CONTRACTORS, AND AGENTS SPECIFICALLY DISCLAIM, WITHOUT
        LIMITATION, ANY WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT TO THE MAXIMUM
        EXTENT PERMITTED BY APPLICABLE LAW.
      </div>
      <div className={styles.secondaryTitle}>Limitation of Liability</div>
      <div className={styles.paragraph}>
        UNDER NO CIRCUMSTANCES SHALL WFD, ITS THIRD PARTY LOAN PROVIDERS,
        AFFILIATES, INDEPENDENT CONTRACTORS, OR AGENTS, BE LIABLE FOR ANY
        DIRECT, INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR
        CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES THAT ARISE FROM LOSS OF
        ANTICIPATED REVENUE, LOSS OF INFORMATION OR MATERIAL OF ANY KIND, LOST
        PROFITS, LOSS OF BUSINESS AND INJURY TO PROPERTY, EVEN IF WFD HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) THAT ARISE IN CONNECTION
        WITH OR RESULT FROM THE USE OF, OR INABILITY TO USE, THE SERVICES
        INCLUDING, BUT NOT LIMITED TO, RELIANCE ON ANY MATERIALS, CONTENT,
        INFORMATION, WEBSITES, DOCUMENTATION, SOFTWARE OR PRODUCTS OBTAINED FROM
        ITS THIRD PARTY LOAN PROVIDERS, AFFILIATES, INDEPENDENT CONTRACTORS, OR
        AGENTS, OR MISTAKES, OMISSIONS, INTERRUPTIONS, COMMUNICATIONS FAILURE,
        DELETION OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES, TROJAN HORSES,
        DELAYS IN OPERATION OR TRANSMISSION, OR ANY FAILURE OF PERFORMANCE, OR
        THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO RECORDS, PROGRAMS OR
        WEBSITES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
        LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, AND IN SUCH STATES
        WFD’S LIABILITY IS LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW. IF,
        NOTWITHSTANDING THE FOREGOING, WFD IS HELD LIABLE FOR DAMAGES, THEN
        REGARDLESS OF THE FORM OF ACTION (WHETHER IN CONTRACT, TORT, BREACH OF
        WARRANTY, OR OTHERWISE), IN NO EVENT SHALL THE MAXIMUM LIABILITY OF WFD
        OR ITS AFFILIATES, DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS,
        OR THIRD PARTY LOAN PROVIDERS EXCEED THE AGGREGATE DOLLAR AMOUNT PAID BY
        CLIENT IN THE TWELVE (12) MONTH PERIOD PRECEDING THE DATE OF THE CLAIMED
        INJURY OR DAMAGE.
      </div>
      <div className={styles.title}> Indemnification</div>
      <div className={styles.secondaryTitle}>Client Indemnification</div>
      <div className={styles.paragraph}>
        Client shall, at its own expense, indemnify, defend, and hold harmless
        WFD and its Third Party Loan Providers, and their respective employees,
        officers, directors, shareholders, agents, insurers or third-party
        providers (each, a “WFD Indemnified Party”) from and against any actual
        or threatened claim, demand, suit, proceeding or action (each, a
        “Claim”) and shall indemnify the WFD Indemnified Parties against any
        damages and costs (including but not limited to reasonable attorneys’
        fees and costs) finally awarded against them or amounts agreed to in a
        monetary settlement arising out of or in connection with such Claim to
        the extent the Claim arises out of: (a) Client’s use of the Services;
        (b) any breach of this Agreement by Client; (c) violation of any law or
        regulation, including Privacy Laws, by Client or the Input Data; or (d)
        the infringement or misappropriation of any Intellectual Property Right
        or Privacy Right of a third-party due to the Client’s actions or
        inactions regarding the Services, including but not limited to, the
        combination of any other software or services with the Services without
        the direction of WFD, or the transmission of any Input Data without the
        necessary rights, licenses, and/or consent(s) of any third-party.
      </div>
      <div className={styles.secondaryTitle}>
        WFD Indemnified Party Obligations
      </div>
      <div className={styles.paragraph}>
        Client’s obligations under this Section 8.2 are conditioned on the WFD
        Indemnified Party providing Client the following: (a) prompt notice of
        any Claim for which indemnification is sought; (b) sole control of the
        defense and settlement of such Claims; and (c) reasonable assistance and
        cooperation at Client’s expense; provided however that Client may not
        enter into any settlement imposing any liability or obligation on any
        WFD Indemnified Parties without the consent of the WFD Indemnified
        Parties, not to be unreasonably withheld or delayed.
      </div>
      <div className={styles.secondaryTitle}>WFD Indemnification</div>
      <div className={styles.paragraph}>
        WFD shall, at its own expense, indemnify, defend, and hold harmless
        Client and its, Affiliates and their respective employees, officers,
        directors, shareholders, agents, or insurers (each, a “Client
        Indemnified Party”) from and against any actual or threatened Claim, and
        shall indemnify the Client Indemnified Parties against any damages and
        costs (including but not limited to reasonable attorneys’ fees and
        costs) finally awarded against them or amounts agreed to in a monetary
        settlement arising out of or in connection with such Claim to the extent
        the Claim is made or brought by or on behalf of a third-party alleging
        that the Services infringe or misappropriate any Intellectual Property
        Rights of such third-party (“Client Claim”). WFD has no obligation under
        this Section 8 or otherwise to the extent a Client Claim is based on any
        use of: (a) the Services not in accordance with the Agreement or the
        applicable Documentation; (b) the Services in combination with other
        products not contemplated in the applicable Documentation to the extent
        the infringement is caused by such combination; (c) modification to the
        Services other than by or at WFD’s direction; (d) a superseded version
        of the Services if such an updated version of the Services has been made
        available to Client for no additional cost; or (e) a modification or
        change to the Services to the extent such modification or change is
        based on Client supplied Intellectual Property, materials,
        specifications or information. WFD maintains no liability or obligation
        under this Section 8 with respect to Client Claims involving Client
        software or Third Party Loan Providers.
      </div>
      <div className={styles.secondaryTitle}>
        WFD Recourse to a Client Claim
      </div>
      <div className={styles.paragraph}>
        If a Client Claim occurs, or if WFD reasonably believes a Client Claim
        may occur, WFD may at its sole discretion and at no cost to Client: (a)
        modify the Services so that they no longer infringe or misappropriate
        the Intellectual Property Rights of a third-party; (b) secure for Client
        the right to continue using the Services in accordance with this
        Agreement; or (c) if (a) or (b) are not commercially feasible, terminate
        this Agreement with respect to the Services that are or may be subject
        to the Client Claim. This Section 8.4 provides WFD’s entire liability
        for infringement and misappropriation Claims.
      </div>
      <div className={styles.secondaryTitle}>
        Client Indemnified Party Obligations
      </div>
      <div className={styles.paragraph}>
        WFD’s obligations under Sections 8.3 and 8.4 are conditioned on the
        Client Indemnified Party providing WFD with: (a) prompt notice of any
        Claim for which indemnification is sought; (b) sole control of the
        defense and settlement of such Claims or the appeal of any award; and
        (c) reasonable assistance and cooperation at WFD’s expense.
      </div>
      <div className={styles.title}> Term; Termination</div>
      <div className={styles.secondaryTitle}>Term</div>
      <div className={styles.paragraph}>
        The “Term” of this Agreement will be in accordance with the term set
        forth in the Order Form.
      </div>
      <div className={styles.secondaryTitle}>Termination</div>
      <div className={styles.paragraph}>
        WFD may terminate or suspend this Agreement, and its provision of the
        Services, at any time, for any reason, upon sending written notice of
        such termination to Client. Client may terminate this Agreement in
        accordance with the conditions for termination in the Order Form.
      </div>
      <div className={styles.secondaryTitle}>Effects of Termination</div>
      <div className={styles.paragraph}>
        Upon termination of this Agreement, for any reason: (a) all rights and
        obligations of the Parties under this Agreement will automatically
        terminate except for rights of action accruing prior to termination; and
        (b) all Fees due under any applicable Order Form shall be due within
        thirty (30) days after the date of termination. “Fees” means any
        compensation payable by Client to WFD in exchange for the use of the
        Services as described in the Documentation and prescribed in any
        applicable Order Form. Sections 1.7, 2, 4, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6,
        6.7, 6.8, 6.9, 6.10, 6.11, 6.14, 7.1, 8.1, 10.1, 10.4, 10.5, 10.9,
        10.10, and 10.12 of this Agreement shall survive termination of this
        Agreement, and the terms and conditions set forth in those Sections
        shall inure to the benefit of the Parties hereto, and their successors
        and assigns in perpetuity.
      </div>
      <div className={styles.title}> Miscellaneous</div>
      <div className={styles.secondaryTitle}> Order Form</div>
      <div className={styles.paragraph}>
        WFD and Client may enter into an Order Form that describes additional
        terms and conditions between the Parties. The Order Form shall be
        construed in accordance with this Agreement, and shall be incorporated
        into this Agreement as if fully set forth herein. If the terms and
        conditions of the Order Form conflict with this Agreement, then the
        applicable terms and conditions set forth in this Agreement shall
        control unless the Order Form explicitly articulates that the
        conflicting section therein shall control over this Agreement.
      </div>
      <div className={styles.secondaryTitle}> Independent Contractors</div>
      <div className={styles.paragraph}>
        Neither Party is, nor shall be deemed to be, an employee, agent,
        co-venturer, or legal representative of the other Party for any purpose.
        The Parties agree that they are independent contractors.
      </div>
      <div className={styles.secondaryTitle}> Non-Assignable</div>
      <div className={styles.paragraph}>
        Neither this Agreement, nor any interest granted hereunder, shall be
        transferable or assignable by Client without the prior written consent
        of WFD. Subject to the foregoing, the rights and obligations hereunder
        shall be binding on each Party and their respective permitted successors
        and assigns.
      </div>
      <div className={styles.secondaryTitle}> Permitted Disclosure</div>
      <div className={styles.paragraph}>
        WFD may use Client’s name, including any trade name(s) and logo(s), in
        WFD’s promotional materials, including its press releases, customer
        lists, and presentations to third-parties, and may include a brief
        description of the Services.
      </div>
      <div className={styles.secondaryTitle}> Reservation of Rights</div>
      <div className={styles.paragraph}>
        WFD reserves all rights not expressly granted to Client in this
        Agreement. Except for the limited rights and licenses expressly granted
        under this Agreement, nothing in this Agreement grants, by implication,
        waiver, estoppel, or otherwise, to Client or any third-party, any
        Intellectual Property Rights or other rights, title, or interests in or
        to any WFD IP, the Services, or any Documentation.
      </div>
      <div className={styles.secondaryTitle}> Interpretation</div>
      <div className={styles.paragraph}>
        Whenever possible, each provision of this Agreement shall be interpreted
        in such manner as to be effective, valid, and enforceable under
        applicable law, but if any provision of this Agreement is held to be
        prohibited by or invalid or unenforceable under applicable law, then
        such provision shall be ineffective only to the extent of such
        prohibition or invalidity or unenforceability, without invalidating, or
        rendering unenforceable, the remainder of this Agreement.
      </div>
      <div className={styles.secondaryTitle}> Entire Understanding</div>
      <div className={styles.paragraph}>
        This Agreement, along with the Order Form, contains the entire
        understanding and agreement between the Parties hereto with respect to
        the matters referred to herein, and supersedes any prior agreements on
        this subject matter between the Parties with respect to the subject
        matter hereof.
      </div>
      <div className={styles.secondaryTitle}> No Waiver</div>
      <div className={styles.paragraph}>
        No provision of this Agreement shall be waived by any act, omission or
        knowledge of a Party or its agents or employees except by an instrument
        in writing expressly waiving such provision and signed by a duly
        authorized officer of the waiving Party. No waiver shall constitute a
        consent to or waiver of any other breach of the same obligation or a
        breach of any other obligation contained herein.
      </div>
      <div className={styles.secondaryTitle}> Governing Law</div>
      <div className={styles.paragraph}>
        This Agreement shall be governed by and construed in accordance with the
        laws the State of Wisconsin, without regard to its principles of
        conflicts of laws. This Agreement will not be governed by the United
        Nations Convention on Contracts for the International Sale of Goods.
      </div>
      <div className={styles.secondaryTitle}> Venue and Jurisdiction</div>
      <div className={styles.paragraph}>
        Any suit brought by either Party against the other Party shall be
        brought exclusively in the federal or state courts sitting in the
        Eastern District of Wisconsin. Each Party hereby waives, and agrees not
        to assert, any argument claiming improper venue, lack of personal
        jurisdiction or forum non conveniens in any claim arising out of this
        Agreement. Client irrevocably consents to service of process in a manner
        to be either delivered personally or mailed by certified or registered
        mail, return receipt requested.
      </div>
      <div className={styles.secondaryTitle}> Force Majeure</div>
      <div className={styles.paragraph}>
        Except for either Party’s confidentiality or use restriction obligations
        under this Agreement, neither Party will be liable to the other Party
        for any delay or interruption in performance of any obligation under
        this Agreement resulting from any cause beyond its reasonable control,
        including, to the extent beyond that Party’s reasonable control, any
        unusually severe weather; any damage to machinery or equipment; any
        disruption or shortage in public or private services such as
        transportation, communications, electric power, other utilities, or
        other vital infrastructure; disruption or damage to, or failure of, the
        Internet or other computer networks, the networks of Third Party Loan
        Providers, or major computer or software systems; governmental emergency
        orders or Regulations; judicial or governmental actions; civil
        disturbances, riots, epidemics, pandemics, wars, terrorist attacks,
        sabotage, embargos, natural disasters, fires; or acts of God. The
        Parties acknowledge that WFD does not and cannot control the flow of
        data through the Internet, or to or from WFD, Third Party Loan
        Providers, or Client’s computer or telecommunications networks.
      </div>
      <div className={styles.secondaryTitle}> Telephone Communications</div>
      <div className={styles.paragraph}>
        To maintain quality service, WFD may monitor or record telephone
        communications between WFD and Client without further notice or
        disclosure.
      </div>
      <div className={styles.secondaryTitle}> Headings</div>
      <div className={styles.paragraph}>
        The section and subsection headings in this Agreement are for reference
        purposes only and are not intended to describe, interpret, define, or
        limit the extent or intent of the section or subsection.
      </div>
      <div className={styles.secondaryTitle}>
        Standard for Withholding Consent
      </div>
      <div className={styles.paragraph}>
        Except as otherwise provided in this Agreement, where a Party is
        required to obtain the consent of the other Party, the other Party’s
        consent may be withheld in its sole and absolute discretion.
      </div>
    </div>
  </div>
);
export default TermsAndConditions;
