import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SlLock } from "react-icons/sl";
import { RxPerson } from "react-icons/rx";

import Grid from "@Components/Grid";
import { signIn, signInWithCustomToken } from "@Firebase/firebase";
import Button from "@Components/Button";
import TextField from "@Components/Inputs/TextField";
import Loading from "@Components/Loading";

import styles from "../Auth.module.scss";
import mixpanel from "mixpanel-browser";
interface Credentials {
  password: string;
  email: string;
  code?: string;
}
const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  "captcha-container",
  {
    size: "invisible",
    callback: function () {
      // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
      // const container = window.document.getElementById("captcha-container");
      // if (container) container.style.visibility = "hidden";
    },
  }
);
export type MfaState =
  | {
      resolver: { resolveSignIn: (x: unknown) => Promise<void> };
      verificationId: string;
      email: string;
    }
  | undefined;

function SignIn({
  setMfaState,
}: {
  setMfaState: React.Dispatch<React.SetStateAction<MfaState>>;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<Credentials>({
    password: "",
    email: "",
  });

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const customToken = params.get("customToken");
  useEffect(() => {
    if (customToken) {
      signInWithCustomToken(customToken);
    }
  }, [customToken]);
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (credentials.email && credentials.password) {
      setLoading(true);
      signIn(credentials)
        .then((response) => {
          if (!response?.user?.emailVerified) {
            navigate("/sign-in/verify-email", { replace: true });
          } else {
            navigate("/dashboard", { replace: true });
            mixpanel.track("Login", {
              email: credentials.email,
            });
          }
        })
        .catch((error) => {
          if (error.code == "auth/multi-factor-auth-required") {
            const resolver = error?.resolver;
            // Ask user which second factor to use.
            const selectedIndex = 0;
            if (
              resolver.hints[selectedIndex].factorId ===
              firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
            ) {
              const phoneInfoOptions = {
                multiFactorHint: resolver.hints[selectedIndex],
                session: resolver.session,
              };
              const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
              // Send SMS verification code
              return phoneAuthProvider
                .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                .then((verificationId) => {
                  setLoading(false);
                  setMfaState({
                    resolver,
                    verificationId,
                    email: credentials.email,
                  });
                  navigate("/sign-in/mfa", {
                    replace: true,
                  });
                });
            }

            return;
          } else {
            setLoading(false);
            toast.error(error.message.replace("Firebase: ", ""), {
              position: "top-center",
            });
          }
        });
    } else {
      toast.error("Enter email and password!", {
        position: "top-center",
      });
    }
  }
  if (loading) return <Loading />;
  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Grid
        container
        rowSpacing={1}
        alignItems="center"
        direction="column"
        style={{ maxWidth: "485px", margin: "auto" }}
      >
        <Grid xs={12}>
          <h1
            style={{
              textAlign: "center",
              fontSize: "38px",
              color: "#29282E",
              fontWeight: "700",
            }}
          >
            Login to Your Account
          </h1>
        </Grid>
        <Grid xs={12}>
          <TextField
            id="login-email"
            onChange={(e) => {
              setCredentials((x) => ({
                ...x,
                email: e.target.value,
              }));
            }}
            placeholder="Email"
            value={credentials.email ?? ""}
            name="email"
            startIcon={<RxPerson />}
            type="text"
            className={styles.authInput}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            id="login-password"
            onChange={(e) => {
              setCredentials((x) => ({
                ...x,
                password: e.target.value,
              }));
            }}
            value={credentials.password ?? ""}
            name="password"
            placeholder="Password"
            startIcon={<SlLock />}
            className={styles.authInput}
            type="password"
          />
        </Grid>
        <Grid xs={12}>
          <Button
            id="login-button"
            type="submit"
            className={styles.authButton}
            style={{ fontSize: "30px", marginTop: "10px", width: "100%" }}
          >
            Log in
          </Button>
        </Grid>
        <div style={{ marginTop: "20px" }}>
          <Link to="/forgot-password"> Forgot your password?</Link>
        </div>
      </Grid>
    </form>
  );
}

export default SignIn;
