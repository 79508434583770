import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import styles from "./BackButton.module.scss";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div
      data-testid="back-button"
      className={styles.root}
      onClick={() => navigate(-1)}
    >
      <AiOutlineArrowLeft size={18} style={{ paddingRight: "10px" }} />
      <p>Back</p>
    </div>
  );
};

export default BackButton;
