import axios from "axios";

import { User } from "@Types/users";
import { HttpQuery } from "@Types/http";
import { auth } from "@Firebase/firebase";
import { Team, CreateTeamRequest } from "@Types/teams";

import { queryClient } from "../App";

export const getTeams = (query?: HttpQuery): (() => Promise<Team[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_team/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return response.data.result?.docs ?? [];
  };
};

export const getTeam = (id: string): (() => Promise<Team>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_team/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.result;
  };
};

export const updateTeam = (): ((team: Team) => Promise<Team>) => {
  return async (team) => {
    const token = await auth.currentUser?.getIdToken();
    const { roles, ...rest } = team.data;

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_team/${team._id}`,
      {
        ...team,
        data: {
          ...rest,
          rolesIds: roles?.map((x) => x._id),
        },
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    queryClient.setQueryData<User>(
      [`team-${team._id}`],
      () => response.data.result
    );
    return response.data.result;
  };
};
export const deleteTeam = (): ((team: Team) => Promise<Team>) => {
  return async (team) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.delete(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_team/${team._id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};

export const createTeam = (): ((team: CreateTeamRequest) => Promise<Team>) => {
  return async (team) => {
    const token = await auth.currentUser?.getIdToken();
    const { roles, ...rest } = team.data;

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_team/`,
      {
        ...team,
        data: {
          ...rest,
          rolesIds: roles?.map((x) => x._id),
        },
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};
