import {  Query } from "@Types/http";
import { Filters } from "./types";

export const transformFiltersToQuery = (
  filters: Filters
): { [maybeAnd: string]: Query } => {
  if (filters && Object.keys(filters)?.length) {
    const queries = Object.values(filters)
      ?.map((filter) => {
        return filter?.values &&
          (filter?.values?.length || Object.keys(filter?.values)?.length)
          ? filter?.query
          : undefined;
      })
      ?.filter((x) => x) as Query;

    if (Array.isArray(queries) && queries?.length) {
      return { $and: queries };
    }
  }
  return {};
};
