import { auth } from "@Firebase/firebase";
import { toast } from "react-toastify";

function useChangePassword() {
  const changePasswod = (email: string, callback: () => void) =>
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        toast.success(`Change password link sent to ${email} `, {
          position: "top-center",
        });
        callback();
      })
      .catch(() => {
        toast.error(
          "Something went wrong during password reset link generation!",
          {
            position: "top-center",
          }
        );
      });

  return changePasswod;
}

export default useChangePassword;
