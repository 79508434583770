import { IoDocumentOutline, IoEyeOutline } from "react-icons/io5";

import Grid from "@Components/Grid";
import { Deal } from "@Types/deal";
import DocumentsDropzone from "../DocumentsDropzone/DocumentsDropzone";
import styles from "./UploadFundedDocuments.module.scss";

import { useGetFundingDocuments } from "@Hooks/useDocuments";
import { useState } from "react";
import IFrameURLModal from "../PreviewDocuments/IFrameURLModal";
import { Document } from "@Types/documents";
import { hasSectionPermissions } from "@Utils/permissions";
import useCurrentUser from "@Hooks/useCurrentUser";

type UploadFundedDocumentsTinyProps = {
  deal: Deal;
};
const UploadFundedDocumentsTiny = ({
  deal,
}: UploadFundedDocumentsTinyProps) => {
  const currentUser = useCurrentUser();
  if (!hasSectionPermissions("deal", "documents", currentUser)) return null;

  const { data: docs } = useGetFundingDocuments(
    `${deal._id}_${deal.updatedAt}`,
    {
      query: { "data.dealId": deal._id },
    }
  );

  const [documentForDialog, setDocumentForDialog] = useState<
    Document | undefined
  >(undefined);

  return (
    <>
      <Grid container xs={12}>
        <div
          className={styles.container}
          id="uploadFundedDocuments"
          tabIndex={-1}
        >
          <Grid xs={12}>
            <p className={styles.title}>Upload Funding Documents</p>
          </Grid>
          <Grid xs={12} container className={styles.innerContainer}>
            <Grid xs={12} className={styles.docsTiny}>
              <Grid container xs={12}>
                {(docs ?? [])?.map((doc, index) => (
                  <Grid
                    key={index}
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                    className={styles.item}
                    onClick={() => setDocumentForDialog(doc)}
                  >
                    <Grid xs={2}>
                      <IoDocumentOutline size={40} color="#6B97F6" />
                    </Grid>
                    <Grid xs={8}>
                      <span className={styles.documentName}>
                        {doc.data.info.name}
                      </span>
                    </Grid>
                    <Grid xs={2}>
                      <IoEyeOutline
                        size={20}
                        color="#6B97F6"
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          marginTop: "2.5px",
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid xs={12}>
              <DocumentsDropzone
                section={"required-documents"}
                dealId={deal._id}
                dropZoneHeight="135px"
                fullWidth
                dashed
                useDocument="create-funded-documents"
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
      {documentForDialog && (
        <IFrameURLModal
          fileName={documentForDialog.data.info.name}
          url={documentForDialog.data.info.url}
          closeDialog={() => setDocumentForDialog(undefined)}
        />
      )}
    </>
  );
};

export default UploadFundedDocumentsTiny;
