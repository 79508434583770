import styles from "./Stepper.module.scss";

type NegativeIconProps = {
  text: string;
  size?: number;
  color?: string;
  style?: React.CSSProperties;
};

const WarningIcon = ({ style, color = "#FFBB6C", text }: NegativeIconProps) => {
  return (
    <div style={style} className={styles.negativeIcon}>
      <span
        style={{
          fontSize: "12px",
          fontWeight: 600,
          color: color,
          textTransform: "capitalize",
          textAlign: "center",
          position: "relative",
          top: -10,
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </span>
      <svg
        width="53"
        height="52"
        viewBox="0 0 53 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="26.2974" cy="25.75" r="25.75" fill="#FFE9CF" />
        <circle cx="26.2974" cy="25.75" r="15.5" fill="#FFBB6C" />
        <path
          d="M27.1657 28.7009H25.2611L24.7446 17.7685H27.6821L27.1657 28.7009ZM24.6155 32.1657C24.6155 31.5775 24.7661 31.165 25.0674 30.9283C25.3759 30.6844 25.7525 30.5624 26.1972 30.5624C26.6348 30.5624 27.0078 30.6844 27.3163 30.9283C27.6248 31.165 27.779 31.5775 27.779 32.1657C27.779 32.7396 27.6248 33.1557 27.3163 33.4139C27.0078 33.665 26.6348 33.7905 26.1972 33.7905C25.7525 33.7905 25.3759 33.665 25.0674 33.4139C24.7661 33.1557 24.6155 32.7396 24.6155 32.1657Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default WarningIcon;
