import { DealershipTicketNote } from "./dealership_ticket_notes";

export type DealershipTicket = {
  data: FormState;
  _id: string;
  createdAt: string | Date;
  updatedAt: string | Date;
};
export type FormState = {
  dealershipTicketNotes: DealershipTicketNote[];
  userId: string;
  dealershipId: string;
  info: Info;
};
export type Info = {
  description: string;
  url?: string;
  status: DealershipTicketStatus;
  user: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
  };
  browserInfo: {
    browserName: string;
    browserVersion: string;
  };
  deviceInfo: {
    deviceType: string;
    osName: string;
    osVersion: string;
  };
};
export enum DealershipTicketStatus {
  NEW = "new",
  IN_PROGRESS = "in progress",
  RESOLVED = "resolved",
  CLOSED = "closed",
}
export type CreateDealershipTicketRequest = {
  data: {
    info: Omit<Info, "url" | "user"> & {
      imageData: string;
    };
  };
};
