import Grid from "@Components/Grid";
import CurrencyField from "@Components/Inputs/CurrencyField";

import TextField from "@Components/Inputs/TextField";
import { FinancialInfo as FinancialInfoType } from "@Types/deal";
import styles from "./FinancialInfo.module.scss";

const FinancialInfo = ({
  state,
  setState,
}: {
  state: FinancialInfoType;
  setState: (v: React.SetStateAction<FinancialInfoType>) => void;
}) => {
  return (
    <Grid xs={12} container className={styles.container} spacing={2}>
      <Grid xs={12}>
        <p>Financial Info</p>
      </Grid>
      <Grid xs={6}>
        <CurrencyField
          id={"salePrice"}
          name="salePrice"
          required={true}
          placeholder="Sale Price"
          value={state.price.price}
          onValueChange={(values) => {
            setState((prev) => ({
              ...prev,
              price: {
                ...state.price,
                price: values.floatValue || null,
              },
            }));
          }}
        />
      </Grid>
      <Grid xs={6}>
        <CurrencyField
          id={"cashDown"}
          name="cashDown"
          required={true}
          placeholder="Cash down"
          value={state.price.totalCash}
          onValueChange={(values) => {
            setState((prev) => ({
              ...prev,
              price: {
                ...state.price,
                totalCash: values.floatValue || null,
              },
            }));
          }}
        />
      </Grid>
      <Grid xs={4}>
        <CurrencyField
          id={"tradeAllowance"}
          name="tradeAllowance"
          placeholder="Trade allowance"
          value={state.price.totalTrade}
          onValueChange={(values) => {
            setState((prev) => ({
              ...prev,
              price: {
                ...state.price,
                totalTrade: values.floatValue || null,
              },
            }));
          }}
        />
      </Grid>
      <Grid xs={4}>
        <CurrencyField
          id={"payoff"}
          name="payoff"
          placeholder="Payoff"
          value={state.price.payoff}
          onValueChange={(values) => {
            setState((prev) => ({
              ...prev,
              price: {
                ...state.price,
                payoff: values.floatValue || null,
              },
            }));
          }}
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              price: { ...prev.price, payoffBank: e.target.value },
            }))
          }
          required={
            typeof state.price.payoff === "number" && state.price.payoff > 0
          }
          placeholder="Lienholder"
          value={state.price.payoffBank ?? ""}
          name="payoffBank"
          type="text"
        />
      </Grid>
      <Grid xs={4}>
        <CurrencyField
          id={"titleTransferFee"}
          name="titleTransferFee"
          required={true}
          placeholder="Title/Transfer fees"
          value={state.taxesAndFees.titleTransfer}
          onValueChange={(values) => {
            setState((prev) => ({
              ...prev,
              taxesAndFees: {
                ...state.taxesAndFees,
                titleTransfer: values.floatValue || null,
              },
            }));
          }}
        />
      </Grid>
      <Grid xs={4}>
        <CurrencyField
          id={"docFee"}
          name="docFee"
          required={true}
          placeholder="Doc fee"
          value={state.taxesAndFees.documentRegistrationFees}
          onValueChange={(values) => {
            setState((prev) => ({
              ...prev,
              taxesAndFees: {
                ...state.taxesAndFees,
                documentRegistrationFees: values.floatValue || null,
              },
            }));
          }}
        />
      </Grid>
      <Grid xs={4}>
        <CurrencyField
          id={"salesTax"}
          name="salesTax"
          required={true}
          placeholder="Sales Tax"
          value={state.payment.totalTaxes}
          onValueChange={(values) => {
            setState((prev) => ({
              ...prev,
              payment: {
                ...state.payment,
                totalTaxes: values.floatValue || null,
              },
            }));
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FinancialInfo;
