import { FiInfo } from "react-icons/fi";
import { IoDocumentOutline, IoEyeOutline } from "react-icons/io5";

import Grid from "@Components/Grid";
import Tooltip from "@Components/Tooltip";
import { TitleDocumentInfo } from "@Types/title_issues";

import styles from "./TitleIssues.module.scss";

const PreviewDocument = ({
  data,
  title,
  tooltip,
  setPreviewUrl,
}: {
  title: string;
  tooltip: string;
  data: TitleDocumentInfo;
  setPreviewUrl: (url: string) => void;
}) => {
  return (
    <Grid
      container
      xs={12}
      alignItems="center"
      className={styles.previewDocumentContainer}
    >
      <Grid xs={1}>
        <Tooltip content={tooltip} tooltipStyle={tooltipStyle}>
          <FiInfo color="#6b97f6" size={20} />
        </Tooltip>
      </Grid>
      <Grid xs={7}>
        <div className={styles.previewDocumentTitle}>{title}</div>
      </Grid>
      <Grid xs={4}>
        <div className={`${styles.status} ${styles[data.status ?? "pending"]}`}>
          {data.status}
        </div>
      </Grid>
      <Grid xs={12} container className={styles.previewDocumentContainerList}>
        {data.documents.map((url, index) => (
          <Grid xs={6} key={index}>
            <Grid
              container
              xs={12}
              onClick={() => setPreviewUrl(url)}
              alignItems="center"
              justifyContent={"center"}
            >
              <Grid xs={2}>
                <IoDocumentOutline size={25} color="#6B97F6" />
              </Grid>
              <Grid xs={10}>
                <div style={{ position: "relative" }}>
                  <span className={styles.previewDocumentName}>{`Document №${
                    index + 1
                  }`}</span>
                  <IoEyeOutline
                    size={20}
                    color="#6B97F6"
                    style={{ position: "absolute", marginLeft: "10px" }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default PreviewDocument;
const tooltipStyle = {
  background: "#fff",
  color: "#000",
  padding: "10px",
  border: "1px solid #8080804a",
  fontWeight: "bold",
};
