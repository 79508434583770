import { useQuery } from "@tanstack/react-query";

import {
  getDailyDealershipProfit,
  getDetailedDealershipProfit,
  getPerformanceDealershipProfit,
} from "@Http/profit";
import { HttpQuery } from "@Types/http";
import { MutationError } from "@Types/index";
import {
  DailyDealershipProfit,
  DetailedDealershipProfit,
  PerformanceDealershipProfit,
} from "@Types/profit";

export const useGetDailyDealershipProfit = (query?: HttpQuery) => {
  const queryKey = ["daily_dealership_profit", query ?? []];

  const { data, isLoading, error } = useQuery<
    DailyDealershipProfit,
    MutationError,
    DailyDealershipProfit
  >({
    queryKey: queryKey,
    queryFn: getDailyDealershipProfit(query),
  });

  return {
    dailyDealershipProfit: data,
    isLoading,
    error,
  };
};

export const useGetDetailedDealershipProfit = (query?: HttpQuery) => {
  const queryKey = ["detailed_dealership_profit", query ?? []];

  const { data, isLoading, error } = useQuery<
    DetailedDealershipProfit,
    MutationError,
    DetailedDealershipProfit
  >({
    queryKey: queryKey,
    queryFn: getDetailedDealershipProfit(query),
  });

  return {
    detailedDealershipProfit: data,
    isLoading,
    error,
  };
};
export const useGetPerformanceDealershipProfit = (query?: HttpQuery) => {
  const queryKey = ["performance_dealership_profit", query ?? []];

  const { data, isLoading, error } = useQuery<
    PerformanceDealershipProfit,
    MutationError,
    PerformanceDealershipProfit
  >({
    queryKey: queryKey,
    queryFn: getPerformanceDealershipProfit(query),
  });

  return {
    performanceDealershipProfit: data,
    isLoading,
    error,
  };
};
