import mixpanel from "mixpanel-browser";

import Grid from "@Components/Grid";
import Paper from "@Components/Paper";
import BackButton from "@Components/BackButton";
import { useCreateRole } from "@Hooks/useRoles";
import { CreateRoleRequest } from "@Types/roles";
import useCurrentUser from "@Hooks/useCurrentUser";

import { RoleForm } from "./RoleForm";
import styles from "./Role.module.scss";
import { initialRoleState } from "./initialState";

const CreateRole = () => {
  const user = useCurrentUser();
  const { creatRole } = useCreateRole();

  const handleSaveChanges = (
    e: React.FormEvent<HTMLFormElement>,
    state: CreateRoleRequest | undefined
  ) => {
    e.preventDefault();
    if (state) {
      creatRole(state);
      mixpanel.track("New role created", {
        email: user?.data?.info?.email,
        dealershipName: user?.data?.dealership?.data?.info?.name,
        name: state.data.info.name,
      });
    }
  };
  return (
    <div className={styles.root}>
      <Grid container xs={12}>
        <Grid xs={1}>
          <BackButton />
        </Grid>
        <Grid xs={12}>
          <Paper title="New Role">
            <RoleForm<CreateRoleRequest>
              id="create-role"
              type="create"
              initialState={initialRoleState}
              handleSubmit={handleSaveChanges}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateRole;
