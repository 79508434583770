import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { MutationError } from "@Types/index";
import { sendNotification } from "@Http/pushNotification";

const useSendPushNotification = () => {
  const mutation = useMutation<string, MutationError, string>(
    sendNotification(),
    {
      onSuccess: () => {
        toast.success("Successfully sent a push notification.", {
          position: "top-center",
        });
      },
    }
  );
  return {
    sendPushNotification: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useSendPushNotification;
