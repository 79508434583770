import { useState } from "react";

import Grid from "@Components/Grid";
import Loading from "@Components/Loading";
import { TitleIssue } from "@Types/title_issues";
import DocumentsDropzone from "@Pages/Applications/Application/Documents/DocumentsDropzone";

import PreviewDocument from "./PreviewDocument";
import TitleIssueDocumentsDialog from "./TitleIssueDocumentsDialog";

export default function UploadTitleIssueDocument({
  titleIssue,
}: {
  titleIssue: TitleIssue;
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | undefined>();

  const openUrl = (url: string) => window.open(url, "_blank");

  const handleOpenDialog = (newFile: File) => {
    setOpen(true);
    setFile(newFile);
  };

  return (
    <Grid xs={12} container>
      {loading ? (
        <Grid xs={6} justifyContent="center" alignItems="center" display="flex">
          <Loading />
        </Grid>
      ) : (
        <DocumentsDropzone
          dealId=""
          section={"title-documents"}
          dashed
          handleCreate={handleOpenDialog}
        />
      )}
      <Grid container xs={6}>
        <PreviewDocument
          title="Copy of the original title"
          tooltip="Signed by dealer and buyer if done in person"
          data={titleIssue?.data?.info?.oldTitleRegistrationInfo}
          setPreviewUrl={(url: string) => openUrl(url)}
        />
        <PreviewDocument
          title="Copy of the completed title application"
          tooltip="Including lien-holder for the appropriate state"
          data={titleIssue?.data?.info?.titleRegistrationInfo}
          setPreviewUrl={(url: string) => openUrl(url)}
        />
        <PreviewDocument
          title="Paid receipt"
          tooltip="Paid receipt from appropriate state DMV"
          data={titleIssue?.data?.info?.receiptInfo}
          setPreviewUrl={(url: string) => openUrl(url)}
        />
      </Grid>

      <TitleIssueDocumentsDialog
        file={file}
        openDialog={open}
        setOpenDialog={setOpen}
        setLoading={setLoading}
        titleIssue={titleIssue}
      />
    </Grid>
  );
}
