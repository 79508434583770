import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { useEffect, useState } from "react";

import {
  TitleIssue,
  UploadedTitleIssueDocumentTypes,
} from "@Types/title_issues";
import Grid from "@Components/Grid";
import Button from "@Components/Button";
import Dialog from "@Components/Dialog";
import { AiFillInfoCircle } from "react-icons/ai";
import Checkbox from "@Components/Inputs/Checkbox";
import { useUpdateTitleIssue } from "@Hooks/useTitleIssues";
import { DialogActions } from "@Components/Dialog/DialogActions";
import { DialogContent } from "@Components/Dialog/DialogContent";

import styles from "./TitleIssues.module.scss";

const initialState: UploadedTitleIssueDocumentTypes = {
  receipt: false,
  newTitle: false,
  oldTitle: false,
};
export default function TitleIssueDocumentsDialog({
  file,
  openDialog,
  titleIssue,
  setLoading,
  setOpenDialog,
}: {
  file?: File;
  openDialog: boolean;
  titleIssue: TitleIssue;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { update, loading } = useUpdateTitleIssue();
  const [uploadedTitleIssueDocumentTypes, setUploadedTitleIssueDocumentTypes] =
    useState<UploadedTitleIssueDocumentTypes>(initialState);
  const [WFDDocumentUsed, setWFDDocumentUsed] = useState(true);

  const handleUpload = () => {
    if (
      (uploadedTitleIssueDocumentTypes.newTitle ||
        uploadedTitleIssueDocumentTypes.oldTitle ||
        uploadedTitleIssueDocumentTypes.receipt) &&
      file
    ) {
      update({
        titleIssue,
        file,
        uploadedTitleIssueDocumentTypes,
        WFDDocumentUsed,
      });
      setUploadedTitleIssueDocumentTypes(initialState);
      setOpenDialog(false);
    } else
      toast.warn("Please select a type before uploading.", {
        position: "top-center",
      });
  };

  useEffect(() => setLoading(loading), [loading]);

  const handleCloseDialog = () => {
    toast.warn("Document upload interrupted.Document not uploaded.", {
      position: "top-center",
    });
    setOpenDialog(false);
  };
  return (
    <Dialog
      titleColor="blue"
      id="upload-title-request"
      open={openDialog}
      title="Select Document Type"
      closeIcon
      size="lg"
      closeFn={handleCloseDialog}
    >
      <DialogContent>
        <Grid container xs={12}>
          <Grid xs={6} className={styles.selectedFile}>
            Selected file: <span className={styles.fileName}>{file?.name}</span>
          </Grid>

          <Grid xs={12} container>
            <Grid xs={12} className={styles.selectedFile}>
              Document is generated by WFD?
            </Grid>
            <Grid xs={2} container>
              <Grid xs={12}>
                <Checkbox
                  labelStyle={styles.labelStyle}
                  onChange={() => setWFDDocumentUsed(true)}
                  placeholder="Yes"
                  checked={WFDDocumentUsed}
                  name="WFDDocumentUsed"
                  id={"WFDDocumentUsed"}
                />
              </Grid>
              <Grid xs={12}>
                <Checkbox
                  labelStyle={styles.labelStyle}
                  onChange={() => setWFDDocumentUsed(false)}
                  placeholder="No"
                  checked={!WFDDocumentUsed}
                  name="WFDDocumentUsed"
                  id={"WFDDocumentUsed"}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12} container>
            <Grid xs={8} className={styles.selectedFile}>
              What kind of the document does the file contain?
            </Grid>
            <Grid xs={4} className={styles.selectedFile}>
              <AiFillInfoCircle size={30} color="#6b97f6" id="title-doc-info" />

              <Tooltip
                anchorId="title-doc-info"
                place="top"
                content="Use the
              checkboxes to select multiple document types if your file contains
              more than one document."
              ></Tooltip>
            </Grid>
            <Grid xs={2} container>
              <Grid xs={12}>
                <Checkbox
                  labelStyle={styles.labelStyle}
                  onChange={() =>
                    setUploadedTitleIssueDocumentTypes((prev) => ({
                      ...prev,
                      receipt: !prev.receipt,
                    }))
                  }
                  placeholder="Paid receipt"
                  checked={uploadedTitleIssueDocumentTypes.receipt}
                  name="receipt"
                  id={"receipt"}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} container>
            <Grid xs={2} container>
              <Grid xs={12}>
                <Checkbox
                  labelStyle={styles.labelStyle}
                  onChange={() =>
                    setUploadedTitleIssueDocumentTypes((prev) => ({
                      ...prev,
                      oldTitle: !prev.oldTitle,
                    }))
                  }
                  placeholder="Original title"
                  checked={uploadedTitleIssueDocumentTypes.oldTitle}
                  name="oldTitle"
                  id={"oldTitle"}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} container>
            <Grid xs={2} container>
              <Grid xs={12}>
                <Checkbox
                  labelStyle={styles.labelStyle}
                  onChange={() =>
                    setUploadedTitleIssueDocumentTypes((prev) => ({
                      ...prev,
                      newTitle: !prev.newTitle,
                    }))
                  }
                  placeholder="Complete title application"
                  checked={uploadedTitleIssueDocumentTypes.newTitle}
                  name="newTitle"
                  id={"newTitle"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="blue" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button
          form="change-request"
          variant="blue"
          onClick={() => handleUpload()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
