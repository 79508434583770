export default ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="62"
    height="52"
    viewBox="0 0 62 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.9838 1.74775C60.9838 1.71524 61 1.68273 61 1.65022V1.63396V1.60145C61 1.56894 60.9838 1.55269 60.9838 1.52018C60.9838 1.48767 60.9675 1.45515 60.9675 1.42264L60.9512 1.39013L60.935 1.35762V1.34137C60.9187 1.32511 60.9187 1.30886 60.9025 1.30886C60.8862 1.27634 60.87 1.26009 60.8537 1.22758L60.8374 1.21132L60.8212 1.19507L60.8049 1.17881H60.7887C60.7724 1.16256 60.7562 1.16256 60.7399 1.1463C60.7237 1.13005 60.7237 1.13005 60.7074 1.11379C60.6911 1.09753 60.6749 1.09753 60.6749 1.09753C60.6586 1.08128 60.6424 1.08128 60.6261 1.06502L60.6099 1.04877C60.5936 1.04877 60.5774 1.04877 60.5611 1.03251C60.5123 1.01626 60.4636 1 60.3986 1C60.3823 1 60.366 1 60.3498 1C60.3173 1 60.2848 1 60.2523 1.01626C60.2198 1.01626 60.2035 1.03251 60.171 1.03251C60.1547 1.03251 60.1547 1.03251 60.1385 1.03251L1.44003 21.3518C1.17994 21.4331 1.01737 21.6769 1.00112 21.937C0.984862 22.1971 1.14742 22.4572 1.39125 22.5547L19.5161 30.4224L23.4174 45.036V45.0523V45.0685C23.4499 45.1661 23.4987 45.2636 23.58 45.3286C23.5962 45.3449 23.5962 45.3449 23.6125 45.3611C23.6612 45.4099 23.7262 45.4587 23.8075 45.4912C23.8238 45.4912 23.8563 45.5074 23.8726 45.5074C23.8888 45.5074 23.8888 45.5237 23.9051 45.5237C23.9538 45.54 24.0026 45.54 24.0513 45.54C24.2464 45.54 24.4252 45.4587 24.5553 45.3124L32.0978 36.3231L46.3701 42.8741C46.5326 42.9553 46.7277 42.9553 46.8903 42.8741C47.0528 42.7928 47.1829 42.6627 47.2479 42.4839L60.9675 1.84529C60.9675 1.82903 60.9675 1.82903 60.9675 1.81277C60.9838 1.81277 60.9838 1.78026 60.9838 1.74775ZM50.5803 9.3228L24.6691 31.9179C24.604 31.9829 24.539 32.0642 24.5065 32.1617C24.4902 32.1942 24.4903 32.2105 24.4903 32.243C24.474 32.2755 24.4577 32.3243 24.4577 32.3568L23.7262 41.151L20.8165 30.2598L50.5803 9.3228ZM56.2046 3.77968L19.9875 29.2195L3.43946 22.0346L56.2046 3.77968ZM25.6607 33.3809L30.8624 35.7704L24.8641 42.9228L25.6607 33.3809ZM26.2784 32.2267L58.9681 3.71466L46.24 41.3948L26.2784 32.2267Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path
      d="M5.4386 32.9903C5.55239 32.9903 5.64995 32.9578 5.74748 32.9091L10.104 30.552C10.4128 30.3895 10.5428 29.9831 10.364 29.6742C10.2015 29.3654 9.79509 29.2353 9.48624 29.4141L5.12976 31.7712C4.86968 31.9175 4.73963 32.2101 4.80466 32.5027C4.88593 32.779 5.146 32.9903 5.4386 32.9903Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path
      d="M14.3305 36.4202L4.49594 44.0115C4.36589 44.109 4.26836 44.2716 4.25211 44.4341C4.23585 44.613 4.28458 44.7755 4.38212 44.9218C4.47965 45.0519 4.64224 45.1494 4.82105 45.1656C4.99986 45.1819 5.16241 45.1331 5.30871 45.0356L15.1433 37.4443C15.2733 37.3468 15.3708 37.1842 15.3871 37.0217C15.4033 36.8591 15.3546 36.6803 15.257 36.534C15.1595 36.4039 14.997 36.3064 14.8182 36.2902C14.6393 36.2577 14.4605 36.3064 14.3305 36.4202Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path
      d="M35.3813 41.8508C35.0725 41.6557 34.6823 41.7532 34.4873 42.0621L31.7238 46.4998C31.5288 46.8087 31.6263 47.1988 31.9351 47.3939C32.244 47.5889 32.6341 47.4914 32.8292 47.1826L35.5926 42.7448C35.7714 42.436 35.6902 42.0296 35.3813 41.8508Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path
      d="M30.6678 49.7014C30.4727 49.5226 30.1963 49.4575 29.9525 49.5551C29.79 49.6201 29.6762 49.7501 29.6112 49.8964C29.4974 50.1403 29.5624 50.4329 29.7575 50.6117C29.8225 50.6604 29.8875 50.7092 29.9688 50.758C30.0501 50.7905 30.1313 50.8067 30.2126 50.8067C30.3914 50.8067 30.554 50.7417 30.684 50.6279C30.8141 50.4979 30.8791 50.3353 30.8628 50.1565C30.8628 50.0752 30.8466 49.994 30.8141 49.9127C30.7815 49.8314 30.7328 49.7664 30.6678 49.7014Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
  </svg>
);
