import Grid from "@Components/Grid";
import ApplicantViewForm from "@Pages/Applicants/ApplicantViewForm";

import { ApplicantInfo } from "@Types/applicants";
import { Collateral } from "@Types/deal";

import styles from "./ReviewApplication.module.scss";

type Props = {
  applicant: ApplicantInfo;
  coApplicant: ApplicantInfo;
  collateral: Collateral;
  variant?: "blue" | "default";
};

export const renderFieldData = (label: string, data: string) => {
  return (
    <div className={styles.dataContainer}>
      <div className={styles.label}>{label}</div>
      <div className={styles.data}>{data}</div>
    </div>
  );
};

const ReviewApplication = ({
  applicant,
  coApplicant,
  collateral,
  variant = "default",
}: Props) => {
  return (
    <Grid container xs={12} spacing={2} className={styles.root}>
      <ApplicantViewForm
        type="applicant"
        applicant={applicant}
        variant={variant}
        noWrapper
      />
      <ApplicantViewForm
        type="coApplicant"
        applicant={coApplicant}
        variant={variant}
        noWrapper
      />
      <Grid xs={12}>
        <div className={`${styles.container} ${styles[variant]}`}>
          <div className={styles.title}>Collateral</div>
          {renderFieldData("VIN", `${collateral.VIN ?? "-"}`)}
          {renderFieldData("Year", `${collateral.year ?? "-"}`)}
          {renderFieldData("Make", `${collateral.make ?? "-"}`)}
          {renderFieldData("Model", `${collateral.model ?? "-"}`)}
          {renderFieldData("Miles", `${collateral.odometer ?? "-"}`)}
        </div>
      </Grid>
    </Grid>
  );
};

export default ReviewApplication;
