import React from "react";
import { toast } from "react-toastify";

import { lookupVIN } from "@Http/vinLookup";
import { Collateral as CollateralType } from "@Types/deal";
import { TradeIn as TradeInType } from "@Types/deal";

export const useVinLookup = <
  T extends CollateralType | TradeInType | undefined
>(
  setState: React.Dispatch<React.SetStateAction<T>>
) => {
  const requiredVariables = new Set(["Model", "Model Year", "Make"]);

  const lookup = (vin: string) => {
    lookupVIN(vin)
      .then((vinLookupResult) => {
        const extractedValues: Record<string, string | number | null> = {
          model: null,
          "model year": null,
          make: null,
        };

        vinLookupResult.Results.forEach((result) => {
          if (requiredVariables.has(result.Variable) && result.Value !== null) {
            extractedValues[
              result.Variable.toLowerCase() as keyof typeof extractedValues
            ] = result.Value;
          }
        });

        setState((prevState) => ({
          ...prevState,
          model: extractedValues.model as string,
          year: extractedValues["model year"]
            ? parseInt(extractedValues["model year"] as string)
            : null,
          make: extractedValues.make as string,
        }));

        if (Object.values(extractedValues).some((value) => value !== null)) {
          toast.success("Successfully looked up VIN.", {
            position: "top-center",
          });
        } else {
          toast.success(
            "Successfully looked up VIN but there was no data provided.",
            {
              position: "top-center",
            }
          );
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message?.toString() || err?.toString(),
          {
            position: "top-center",
          }
        );
      });
  };

  return {
    lookupVIN: lookup,
  };
};
