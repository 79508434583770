import Button from "@Components/Button";
import Paper from "@Components/Paper";
import { getApplicationPDF } from "@Http/applications";
import { Deal } from "@Types/deal";
import styles from "./ApplicationPdf.module.scss";

const ApplicationPdf = ({ deal }: { deal: Deal }) => {
  return (
    <Paper
      title={"Credit Application"}
      style={{ height: "100%", boxSizing: "border-box" }}
    >
      If you need a credit application click the &quot;Download&quot; button
      below.
      <br />
      <Button
        className={styles.button}
        onClick={async () => {
          await getApplicationPDF(deal)();
        }}
        variant="blue"
      >
        Download
      </Button>
    </Paper>
  );
};

export default ApplicationPdf;
