import Button from "@Components/Button";
import Dialog from "@Components/Dialog";
import { DialogActions } from "@Components/Dialog/DialogActions";
import { DialogContent } from "@Components/Dialog/DialogContent";
import IconButton from "@Components/IconButton";
import Checkbox from "@Components/Inputs/Checkbox";
import Select from "@Components/Select";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useCreateIncompleteApplication } from "@Hooks/useIncompleteApplications";
import { useUpdateMember } from "@Hooks/useMembers";
import { Box } from "@mui/system";
import { defaultState } from "@Types/incomplete_application";
import { PermissionEntities } from "@Types/roles";
import { DashboardType, User } from "@Types/users";
import { hasActionPermissions } from "@Utils/permissions";
import { useState } from "react";
import { RiHomeGearLine } from "react-icons/ri";
import CustomerDashboard from "./CustomerDashboard";
import styles from "./Dashboard.module.scss";
import ProfitDashboard from "./ProfitDashboard";
import RecentApplications from "./RecentApplicationsDashboard/RecentApplications";
import WelcomeGraphic from "./WelcomeGraphic";

const dashboards = [
  {
    value: "recent_applications_dashboard",
    label: "Recent applications",
  },
  {
    value: "profit_dashboard",
    label: "Profit dashboard",
  },
  {
    value: "customer_dashboard",
    label: "Customers dashboard",
  },
];
const sanitizeDashboardOptionsByPermissions = (
  dashboardOptions: { value: string; label: string }[],
  user: User | undefined
) => {
  return dashboardOptions.filter((option) => {
    if (option.value === "recent_applications_dashboard") {
      return hasActionPermissions("deal", "read", user);
    }
    return hasActionPermissions(
      option.value as PermissionEntities,
      "read",
      user
    );
  });
};
const Dashboard = () => {
  const user = useCurrentUser();
  const [selectedDashboard, setSelectedDashboard] = useState<{
    type: DashboardType;
    isDefault: boolean;
  }>({
    type: user?.data?.info?.dashboard?.type ?? "recent_applications_dashboard",
    isDefault: user?.data?.info?.dashboard?.isDefault ?? false,
  });
  const [open, setOpen] = useState(false);

  const { updateUser } = useUpdateMember({ isDashboardUpdateOnly: true });
  const handleChangeDashboard = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDashboard({
      type: e.target.value as DashboardType,
      isDefault:
        (e.target.value as DashboardType) === user?.data?.info?.dashboard?.type
          ? user?.data?.info?.dashboard?.isDefault
          : false,
    });
  };
  const { createDeal } = useCreateIncompleteApplication();

  const handleSaveDashboardSettings = () => {
    if (user) {
      updateUser({
        ...user,
        data: {
          ...user?.data,
          info: { ...user?.data?.info, dashboard: selectedDashboard },
        },
      });
    }
  };
  const handleCreateNewIncompleteDeal = () => createDeal(defaultState);
  const renderSelectedDashboard = () => {
    switch (selectedDashboard.type) {
      case "recent_applications_dashboard":
        return (
          hasActionPermissions("deal", "read", user) && <RecentApplications />
        );
      case "profit_dashboard":
        return (
          hasActionPermissions("profit_dashboard", "read", user) && (
            <ProfitDashboard />
          )
        );
      case "customer_dashboard":
        return (
          hasActionPermissions("customer_dashboard", "read", user) && (
            <CustomerDashboard />
          )
        );
      default:
        return <></>;
    }
  };
  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.welcomeBanner}>
        <h1 className={styles.greeting}>{`Welcome back, ${
          user?.data.info.firstName ?? ""
        }`}</h1>
        <Box display={{ xs: "none", xl: "block" }}>
          <WelcomeGraphic />
        </Box>
        {hasActionPermissions("deal", "create", user) && (
          <button
            id="create-new-application-button"
            className={styles.button}
            onClick={handleCreateNewIncompleteDeal}
          >
            Create New Application
          </button>
        )}
      </div>
      <div
        style={{
          padding: "10px",
          margin: "10px 0px",
          display: "flex",
          borderRadius: "5px",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ display: "flex", width: "30%" }}>
          <Select
            inputStyle={{ border: "1px solid #f3f3f3" }}
            name="Dashboard type"
            placeholder="Dashboard"
            value={selectedDashboard.type ?? ""}
            onChange={handleChangeDashboard}
            options={sanitizeDashboardOptionsByPermissions(dashboards, user)}
          />
          <IconButton
            onClick={() => setOpen(true)}
            style={{ padding: "0px 10px" }}
          >
            <RiHomeGearLine size={35} />
          </IconButton>
        </div>
      </div>

      {renderSelectedDashboard()}
      {open && (
        <Dialog
          titleColor="blue"
          id="dashboard-settings"
          open={true}
          spacing={false}
          title="Dashboard settings"
          closeIcon
          size="sm"
          closeFn={() => {
            setOpen(false);
          }}
        >
          <DialogContent>
            <Checkbox
              onChange={() =>
                setSelectedDashboard((prev) => ({
                  ...prev,
                  isDefault: !prev.isDefault,
                }))
              }
              placeholder="Set as default"
              checked={selectedDashboard.isDefault}
              name="defaultDashboard"
              id={"defaultDashboard"}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="blue" onClick={() => setOpen(false)}>
              Close
            </Button>
            <Button
              variant="blue"
              onClick={() => handleSaveDashboardSettings()}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
export default Dashboard;
